import React from "react";

const renderItem = ({ styles, item, index }) => {
  const columnColor = (index) => {
    if (index % 2 !== 0) {
      return { backgroundColor: "#F2F2F2" };
    }
    return "";
  };
  return (
    <div
      style={{ ...styles.containerColuna, ...columnColor(index) }}
      key={index}
    >
      <div style={styles.colunaDescricao}>
        <div style={styles.text}>{item.documento}</div>
      </div>
      <div style={styles.colunaDescricao}>
        <div style={styles.text}>{item.total || 0}</div>
      </div>
    </div>
  );
};

const RelatorioPacientesDuplicadosPdfDocument = (props) => {
  const { pacientesDuplicados, pesquisadoPor } = props;

  return (
    <>
      <div style={styles.titulo}>
        <div style={styles.textBold}>Relatório de Pacientes Duplicados</div>
      </div>
      <div style={styles.subTitulo}>
        <div style={styles.textBold}>Pesquisa: {pesquisadoPor || " - "}</div>
      </div>

      <div style={styles.containerColuna}>
        <div style={styles.colunaDescricao}>
          <div style={styles.textBold}>CPF</div>
        </div>
        <div style={styles.colunaDescricao}>
          <div style={styles.textBold}>Quantidade</div>
        </div>
      </div>

      {pacientesDuplicados.map((item, index) =>
        renderItem({ styles, item, index })
      )}
    </>
  );
};

const styles = {
  text: {
    fontSize: 12,
  },
  textBold: {
    fontSize: 12,
    fontWeight: 700,
  },
  titulo: {
    marginLeft: 0,
    marginBottom: 10,
    marginTop: 5,
    paddingBottom: 5,
    textAlign: "center",
  },
  subTitulo: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #505050",
    marginBottom: 15,
    paddingBottom: 5,
    justifyContent: "flex-start",
  },
  containerColuna: {
    display: "flex",
    flexDirection: "row",
  },
  coluna: {
    flexGrow: "1",
    width: "50px",
  },
  colunaDescricao: {
    flexGrow: "2",
    width: "90px",
    marginBottom: "10px",
    marginRight: "3px",
  },
};

export default RelatorioPacientesDuplicadosPdfDocument;
