import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Tooltip, withStyles } from '@material-ui/core'
import ButtonWhatsApp from '../../Button/ButtonWhatsApp'
import ProcedimentoIcon from '../../Icon/ProcedimentoIcon'
import CircleInfoIcon from '../../Icon/CircleInfoIcon'
import VideoIcon from '../../Icon/Video'
import LocationIcon from '../../Icon/Location'
import AniversarioIcon from '../../Icon/AniversarioIcon'
import ClipboardTaskListIcon from '../../Icon/ClipboardTaskListIcon'
import MoneyIcon from '../../Icon/Money'
import CallIcon from '../../Icon/Call'
import { createChamada } from '../../../services/ProntuarioService'
import { observer } from 'mobx-react-lite'
import { inject } from 'mobx-react'
import { Button } from '../../../components/ui/Buttons';
import { Description } from '@material-ui/icons'
import DocumentOnePageIcon from '../../Icon/DocumentOnePage'
import TimerIcon from '../../Icon/utils/TimerIcon'
import OrcamentoEmitidoIcon from '../../Icon/OrcamentoEmitidoIcon'

const COLORS_TOOLTIP = {
  PROCEDIMENTO_SOLICITADO: '#FC71B4',
  OBSERVACAO: '#717FFC',
  TIPO_CONSULTA_PRESENCIAL: '#707C97',
  TIPO_CONSULTA_TELEMEDICINA: '#9871FC',
  ANIVERSARIO: '#F9BE73',
  FIVE_DAYS_TO_BIRTHDAY: '#868686',
  PROCEDIMENTOS: '#DF1F7B',
  VOUCHER: '#00908B',
  FATURADO: '#219A97',
  CHAMADA_PACIENTE: '#F9BE73',
  NOTA_FISCAL_EMITIDA: '#00B0AE',
  NOTA_FISCAL_NAO_EMITIDA: '#FB7676',
  AGENDAMENTO_HEALTH: '#219A97',
  AGENDAMENTO_ONLINE: '#6639F4',
  ORCAMENTO: "#219A97",
}
const COLORS_ASSINATURA = {
  Signed: '#219a97',
  Unsigned: '#F9BE73',
  Cancelled: '#fb7676',
}

const ColumnInformation = observer(({
  classes,
  event,
  handleClickWhatsapp,
  nomesProcedimentosPedido,
  isAniversarioProximo,
  isAniversariante,
  salaDay,
  verificaProcedimentoPedido,
  atendimentoStore,
  listaAgendamentoPaciente,
  hiddenOrigemAgendamento,
}) => {
  const [loadingChamada, setLoadingChamada] = useState(false)
  const [disabledChamada, setDisabledChamada] = useState(false)

  const isAgendamentoId = Number.isInteger(Number(event?.id))
  const showChamarPacienteButton = salaDay && isAgendamentoId
  const hasProcedimentoSolicitado =
  event?.procedimentoPedido && event.procedimentoPedido[0]?.procedimentosSolicitados?.length > 0 
  
  const procedimentoSolicitadoPago = event?.procedimentos?.length > 0 && event?.procedimentos?.some((procedimento) => {
    const { procedimentoSolicitado } = procedimento || {};
    return procedimentoSolicitado?.id && procedimentoSolicitado?.titulo?.ativo;
  });
  const possuiNotaFiscal = procedimentoSolicitadoPago && event?.procedimentos?.some((procedimento) => {
    const { procedimentoSolicitado } = procedimento || {};
    return procedimentoSolicitado?.possuiNota;
  });

  const nomesProcedimentos =
    (event?.procedimentos?.length > 0 &&
      event?.procedimentos?.map?.(item => item?.procedimento?.nome).join(', ')) ||
    event?.procedimentos

  const isFaturado = event?.titulos?.length > 0 && event?.titulos?.some(item => item.ativo);

  const quantityProcedimentos = () => {
    const quantity = event?.procedimentos?.length > 0 && event?.procedimentos?.length
    const quantitySala =
      event?.procedimentos?.split?.(',')?.length > 0 && event?.procedimentos?.split?.(',')?.length
    const isSala = event?.procedimentos?.split?.(',')?.length > 0
    return isSala ? quantitySala : quantity
  }
  
  const onCallAgendamento= async (e)=>{
    e.stopPropagation();
    setLoadingChamada(true)
    const agendamentoId = event.id
    
    try{
      await createChamada(agendamentoId)
      atendimentoStore.openNotification('Chamada Solicitada!','success')
      setDisabledChamada(true)
    }
    catch(error){
      atendimentoStore.openNotification('Chamada não solicitada!','error')
    }
    finally{
      setLoadingChamada(false)
    }
  }
  
  const getSituacaoDocumento = (possuiDocumentosParaAssinatura) => {
    if (!possuiDocumentosParaAssinatura?.length) return;

    if (possuiDocumentosParaAssinatura.some(item => item.situacao === "Unsigned")) {
      return COLORS_ASSINATURA.Unsigned;
    }
 
    if (possuiDocumentosParaAssinatura.some(item => item.situacao === "Signed")) {
      return COLORS_ASSINATURA.Signed;
    }
 
    return COLORS_ASSINATURA.Cancelled;
 
  }


  useEffect(() => {
    event?.procedimentoPedido && event.procedimentoPedido[0]?.procedimentosSolicitados?.length > 0 && verificaProcedimentoPedido();
  }, [event])

  useEffect(() => {
    if (disabledChamada) {
      setTimeout(() => {
        setDisabledChamada(false)
      }, 10000)
    }
  }, [disabledChamada]);

  const getWaitingTime = (event) => {
    const dataChegadaSujeitoAtencao = typeof event?.dataChegadaSujeitoAtencao === 'string' && (moment(event.dataChegadaSujeitoAtencao));
    const dataFinalEspera = typeof event?.dataInicioAtendimento === 'string' ? moment(event.dataInicioAtendimento) : moment();
    const tempoTotalAguardando = moment(dataFinalEspera).diff(dataChegadaSujeitoAtencao, 'miliseconds');
    const minutosAguardando = dataChegadaSujeitoAtencao && (dataFinalEspera.diff(dataChegadaSujeitoAtencao, 'minutes'));
    const segundosAguardando = dataChegadaSujeitoAtencao && (dataFinalEspera.diff(dataChegadaSujeitoAtencao, 'seconds'));
    const horasAguardando = dataChegadaSujeitoAtencao && (dataFinalEspera.diff(dataChegadaSujeitoAtencao, 'hours'));

    if (!dataChegadaSujeitoAtencao || tempoTotalAguardando < 0) {
      return ' 00 : 00 : 00 '
    }

    const segundos = segundosAguardando ? moment.duration(segundosAguardando, 'seconds').get('seconds') : '0';
    const minutos = minutosAguardando ? moment.duration(minutosAguardando, 'minutes').get('minutes') : '0';
    const horas = horasAguardando ? moment.duration(horasAguardando, 'hours').get('hours') : '0';

    const doisDigitosHoras = `${horas}`.padStart(2, '0');
    const doisDigitosMinutos = `${minutos}`.padStart(2, '0');
    const doisDigitosSegundos = `${segundos}`.padStart(2, '0');

    return (`${doisDigitosHoras} : ${doisDigitosMinutos} : ${doisDigitosSegundos}`);
  }
 
  const getOrigemteAgendamento = () => {
    return (
      event?.origemHealth ? 
      <Tooltip title={'Agendamento realizado pelo App Health'} placement='top'>
        <div
          className={classes.origemAgendamento}
          style={{
            backgroundColor: COLORS_TOOLTIP.AGENDAMENTO_HEALTH
          }}
        >
          App Health
        </div>
      </Tooltip> :
      <Tooltip title={`Agendamento realizado online`} placement='top'>
        <div
          className={classes.origemAgendamento}
          style={{
            backgroundColor: COLORS_TOOLTIP.AGENDAMENTO_ONLINE
          }}
        >
          Online
        </div>
      </Tooltip>
    )
  }

  const possuiDocumentosAssinadosCliente = event?.documentosAssinadosCliente?.length > 0;
  const possuiAgendamento =  typeof event?.id === 'number';
  const possuiOrcamento = event.possuiOrcamento 

  return (
    <div className={classes.container}>
      {event.dataChegadaSujeitoAtencao && event.status === "AGUARDANDO" && !listaAgendamentoPaciente &&
        <Tooltip title={"Tempo de espera"} placement={"top"}>
          <div
            className={classes.contentTimer}
          >

            <div className={classes.iconRelogio}>
              <TimerIcon width={"14px"} color={"#FFF"}></TimerIcon>
            </div>
            <div>{getWaitingTime(event)}</div>

          </div>
        </Tooltip>
      }
      {event?.telefone && event?.telefoneDDI && !salaDay && (
        <ButtonWhatsApp
          withCount
          messageSent={event?.mensagemWhatsappEnviada}
          labelTooltip='Mensagem enviada, deseja enviar novamente?'
          quantidadeEnviado={event?.whatsappMensagemQuantidade}
          onClick={(e)=>  handleClickWhatsapp(e)}
        />
      )}
      {typeof event?.id === 'number' && (
        <Tooltip title={event?.remoto ? 'Telemedicina' : 'Presencial'} placement='top'>
          <div
            className={classes.contentIcon}
            style={{
              backgroundColor: event?.remoto
                ? COLORS_TOOLTIP.TIPO_CONSULTA_TELEMEDICINA
                : COLORS_TOOLTIP.TIPO_CONSULTA_PRESENCIAL,
            }}
          >
            {event?.remoto ? <VideoIcon color='#fff' size='14' /> : <LocationIcon color='#fff' />}
          </div>
        </Tooltip>
      )}
      {possuiOrcamento && (
        <Tooltip title={'Orçamento emitido'} placement='top'>
          <div
            className={classes.contentIcon}
            style={{ backgroundColor: COLORS_TOOLTIP.ORCAMENTO }}
          >
            <OrcamentoEmitidoIcon color='#fff' bsize='14' />
          </div>
        </Tooltip>
      )}
      {event?.observacao && (
        <Tooltip title={event?.observacao} placement='top'>
          <div
            className={classes.contentIcon}
            style={{ backgroundColor: COLORS_TOOLTIP.OBSERVACAO }}
          >
            <CircleInfoIcon color='#fff' />
          </div>
        </Tooltip>
      )}
      {hasProcedimentoSolicitado && (
        <Tooltip title={nomesProcedimentosPedido} placement='top'>
          <div
            className={classes.contentIcon}
            style={{ backgroundColor: COLORS_TOOLTIP.PROCEDIMENTO_SOLICITADO }}
          >
            <ProcedimentoIcon color='#fff' size='14' />
          </div>
        </Tooltip>
      )}
      {event?.dataNascimento && isAniversarioProximo && (
        <Tooltip title={moment(event.dataNascimento).format('DD/MM/YYYY')} placement='top'>
          <div
            className={classes.contentIcon}
            style={{
              backgroundColor: isAniversariante
                ? COLORS_TOOLTIP.ANIVERSARIO
                : COLORS_TOOLTIP.FIVE_DAYS_TO_BIRTHDAY,
            }}
          >
            <AniversarioIcon color='#fff' size='14' />
          </div>
        </Tooltip>
      )}
      {event?.procedimentos?.length > 0 && (
        <Tooltip title={nomesProcedimentos} placement='top'>
          <div
            className={classes.contentIcon}
            style={{
              backgroundColor: COLORS_TOOLTIP.PROCEDIMENTOS,
            }}
          >
            <ClipboardTaskListIcon color='#fff' size='14' />
            <div className={classes.roundedTag}>{quantityProcedimentos()}</div>
          </div>
        </Tooltip>
      )}
      {event?.possuiVoucher && (
        <Tooltip title={'Voucher'} placement='top'>
          <div
            className={classes.contentIcon}
            style={{
              backgroundColor: COLORS_TOOLTIP.VOUCHER,
            }}
          >
            <MoneyIcon color='#fff' size='14' />
          </div>
        </Tooltip>
      )}
      {isFaturado && (
        <Tooltip title={'Faturado'} placement='top'>
          <div
            className={classes.contentIcon}
            style={{
              backgroundColor: COLORS_TOOLTIP.FATURADO,
            }}
          >
            <MoneyIcon color='#fff' size='14' />
          </div>
        </Tooltip>
      )}
      {showChamarPacienteButton && !loadingChamada && (
        <Tooltip title={`Chamar `} placement='bottom'>
          <Button 
            shape='circle'
            disabled={disabledChamada}
            bgColor={COLORS_TOOLTIP.CHAMADA_PACIENTE}
            onClick={onCallAgendamento}
            padding={0}
            style={{height: 20, width: 20}}
          >
            <CallIcon size={14} color="#FFF"/>
          </Button>
      </Tooltip>
      )}
      {possuiDocumentosAssinadosCliente && (
        <Tooltip title={"Documentos"} placement='bottom'>
          <Button
            shape='circle'
            bgColor={getSituacaoDocumento(event.documentosAssinadosCliente)}
            padding={0}
            style={{ height: 20, width: 20 }}
          >
            <Description style={{ fontSize: "14px" }} color="#FFF" />
          </Button>
        </Tooltip>
     )}
      {procedimentoSolicitadoPago &&  
        event?.status === "ATENDIDO" &&
        (
          <Tooltip title={'Nota fiscal'} placement='top'>
            <div
              className={classes.contentIcon}
              style={{
                backgroundColor: possuiNotaFiscal ? COLORS_TOOLTIP.NOTA_FISCAL_EMITIDA : COLORS_TOOLTIP.NOTA_FISCAL_NAO_EMITIDA,
              }}
            >
              <DocumentOnePageIcon color='#fff' size='14' />
            </div>
          </Tooltip>
        )
      }
      {
        possuiAgendamento && !hiddenOrigemAgendamento &&
          getOrigemteAgendamento()
      }
    </div>
  )
})

const styles = type => ({
  container: {
    display: 'flex',
    gap: '8px',
    '& > div': {
      marginLeft: '0px',
      marginRight: '0px',
    },
    alignItems: 'center',
  },
  contentIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px',
    borderRadius: '16px',
    position: 'relative',
  },
  roundedTag: {
    width: '16px',
    height: '16px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px !important',
    fontWeight: '700 !important',
    color: '#FFF',
    backgroundColor: '#5462E0',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    position: 'absolute',
    top: '-8px',
    right: '-8px',
  },
  contentTimer: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    padding: '0 6px',
    borderRadius: '20px',
    backgroundColor: '#F9BE73',
    color: "#FFF",
    width: "100px",
    height: "24px",
  },
  iconRelogio: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  origemAgendamento: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60px',
    height: '20px',
    borderRadius: '8px',
    position: 'relative',
    fontSize: '9px !important',
    fontWeight: '700 !important',
    color: '#FFF',
  }
})

const stores=['atendimentoStore']
const ColumnInformationStyles=withStyles(styles)(ColumnInformation)
export default inject(...stores)(ColumnInformationStyles)
