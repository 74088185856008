import Api from "../config/api"

export const createAssinaturaAgendamento = async (variables) => {
    const response = await Api.post("", {
        query: `mutation CreateAssinaturaAgendamento($agendamentoId: Long, $objetoAmazonS3Dto: ObjetoAmazonS3DTOInput) {
        createAssinaturaAgendamento(agendamentoId: $agendamentoId, objetoAmazonS3DTO: $objetoAmazonS3Dto) {
            id
        }
        }`, variables
    })
    if(response.data.errors){
        throw new Error(response.data.errors[0].message)
    }
    else{
        return response.data.data.createAssinaturaAgendamento
    }
}

export const findBase64ByAgendamentoId = async (variables) => {
    const response = await Api.post("", {
        query: `query findBase64ByAgendamentoId($agendamentoId: Long) {
  findBase64ByAgendamentoId(agendamentoId: $agendamentoId)
}`, variables
    })
    if (response.data.errors) {
        throw new Error(response.data.errors[0].message)
    }
    else {
        return response.data.data.findBase64ByAgendamentoId
    }
}