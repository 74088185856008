import React, { useEffect, useRef, useState } from "react";
import { CircularProgress, withStyles } from "@material-ui/core";
import { Button } from "../../../../../components/ui/Buttons";
import PreencherDocumento from "../../../../Configuracoes/ModelosDocumento/Documento/PreencherDocumento";
import { UNIDADE_LOGADA_KEY } from "../../../../../services/storage";
import { inject } from "mobx-react";
import Scroll from "../../../../../components/InfiniteScroll/Scroll";
import ContentDocumentos from "../../components/ContentDocuments";
import { findAllDocumentoModelo } from "../../../../../services/DocumentoService";
import { getProfissionalSaudeLogado } from "../../../../../services/UsuarioService";
import { observer } from "mobx-react-lite";
import PreviewDocumento from "../../../Prontuario/PreviewDocumento";

let page = {
    pageNumber: 0,
    pageSize: 9999
};

const filter = [
    "RECEITA",
    "GUIA_SERVICO",
    "GUIA_INTERNACAO",
    "TEXTO",
    "PROCEDIMENTO_REALIZADO",
    "ANAMNESE",
    "AVALIACAO_ANTROPOMETRICA",
    "AFERICAO_VITAL",
    "IMAGEM",
    "ARQUIVO"]

const isPrever =
    JSON.parse(localStorage.getItem("_immortal|" + UNIDADE_LOGADA_KEY))?.rede
        ?.whitelabel?.nome === "ANGEPLUS";

const Documentos = observer(({
    classes,
    prontuarioStore,
    sujeitoAtencaoStore,
    usuarioStore,
    atendimentoStore,
    history,
    googleAnalyticsStore,
}) => {
    const [openModalNovoDocumento, setOpenModalNovoDocumento] = useState(false);
    const [profissionalSaudeLogado, setProfissionalSaudeLogado] = useState(null)
    const [unidade, setUnidade] = useState(null)
    const [modelosDocumentos, setModelosDocumentos] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isDuplicar,setIsDuplicar]=useState(false)
    const [documentosModelos,setDocumentosModelo]=useState({})

    let pageNumberEntradas = 0
    const rootRef = useRef(null)

    const handleInserirDocumento = () => {
        setOpenModalNovoDocumento(true)
        prontuarioStore.previewDocumento = true;
    };

    useEffect(() => {
        selectAgendamento()
        getProfissionalSaudeEUnidade()
        getDocumentosModelo({ tipo: "PROFISSIONAL_SAUDE" })
        findAllDocumentos()
    }, [])

    useEffect(() => {
        if (rootRef.current && prontuarioStore.messagesScrollToBottom) {
            messagesScrollToBottom();
        }
    }, [prontuarioStore.listEntradas]);

    const messagesScrollToBottom = () => {
        if (rootRef && rootRef.current) {
            rootRef.current.scrollTop = rootRef.current.scrollHeight;
            prontuarioStore.messagesScrollToBottom = false;
        }
    };

    const selectAgendamento = () => {
        const { objView } = atendimentoStore;
        prontuarioStore.selectAgendamento(objView?.id)
    }

    const findAllDocumentos = async () => {
        try {
            setIsLoading(true)

            if (prontuarioStore.loadingMoreMensagens) {
                return;
            }

            prontuarioStore.tipos = filter
            prontuarioStore.showEntradasOnlyProfissionalLogado = true;

            pageNumberEntradas += 1
            await prontuarioStore.findAllEntradaProntuario()
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    };

    const getDocumentosModelo = async ({ tipo }) => {
        try {
            setIsLoading(true)
            const response = await findAllDocumentoModelo({
                ativo: true,
                search: "",
                pageableDTO: {
                    ...page,
                    sortDir: 'ASC',
                    sortField: 'nome',
                },
                profissionalSaudeId: profissionalSaudeLogado?.id,
                tipo,
            })

            setModelosDocumentos(response.content,)

        } catch (error) {
            prontuarioStore.openNotification('Erro ao carregar documentos modelo', 'error');

        }finally{
            setIsLoading(false)
        }
    }

    const getProfissionalSaudeEUnidade = async () => {
        const profissionalSaudeLogado = await getProfissionalSaudeLogado();
        setProfissionalSaudeLogado(profissionalSaudeLogado)
        const unidadeLogada = await usuarioStore.getUnidadeAtual();
        setUnidade(unidadeLogada)

    }

    const {
        loadingMoreMensagens,
        hasMoreMensagens,
        sujeitoAtencaoSelected,
        listEntradas,
        agendamentoSelected,
        previewDocumento,
    } = prontuarioStore;

    const setDocumento = (e) => {
        setDocumentosModelo({ modelosDocumentos: e.content, })
    }

    const loadContratos = async () => {
        const { idOpened } = sujeitoAtencaoStore;

        const variables = {
            search: "",
            pageableDto: {
                pageNumber: 0,
                pageSize: 30,
                sortDir: "DESC",
                sortField: "nome"
            },
            sujeitoAtencao: {
                id: idOpened,
            }
        }
        try {

            await findAllDocumentoClienteAssinatura(variables)
        }
        catch (error) {
            console.error(error)
        }

    }

    const handleClickMessage = async (entrada) => {
        
        if(entrada.tipo ==="DOCUMENTO"){
            entrada.tipo = "LOADING_DOCUMENTO";
            await prontuarioStore.openDocumentoByIdEntradaProntuario(entrada.id);
            entrada.tipo = "DOCUMENTO";
        }   
    };

    const handleClosePreencherDocumento = () => {
        setOpenModalNovoDocumento(false)
        setIsDuplicar(false)
        prontuarioStore.previewDocumento = false
    }

    const handleExcluirArquivo = (arquivo) => {
        const posicao = parseInt(arquivo.id);
        const files = prontuarioStore.previewDocumento.files;

        const listaArquivos = [
            ...files.slice(0, posicao),
            ...files.slice(posicao + 1, files.length),
        ];

        prontuarioStore.previewDocumento = {
            ...prontuarioStore.previewDocumento,
            files: listaArquivos,
        };
    };

    const handleClosePreviewDocumento = () => {
        prontuarioStore.closePreviewDocumento();
    };

    const handleDuplicarDocumento = () => {
        setOpenModalNovoDocumento(true)
        setIsDuplicar(true)
        console.log(previewDocumento, "PREVIW ''''''+++")
        prontuarioStore.previewDocumento = {
            ...prontuarioStore.previewDocumento,
            open: false,
        };
    };

    const handleChangeDescricaoPreviewDocumento = (text) => {
        prontuarioStore.previewDocumento.descricao = text;
    };
    const handleSendDocumento = async () => {
        try {
            await prontuarioStore.createEntrada();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                <div>
                    <Button bgColor="#707C97" width="220px" onClick={handleInserirDocumento}>
                        Inserir Documento
                    </Button>
                </div>

                <Scroll
                    initialLoad={false}
                    isReverse
                    hasMore={hasMoreMensagens}
                    loadMore={findAllDocumentos}
                    pageStart={0}
                    isLoading={loadingMoreMensagens}
                    className={classes.scroll}
                    rootRef={rootRef}
                >
                    {loadingMoreMensagens ?
                        (
                            <div className={classes.circularProgress}><CircularProgress /></div>
                        )
                        :
                        (
                            <ContentDocumentos
                                handleClickMessage={handleClickMessage}
                                documentos={listEntradas}
                                isLoading={isLoading}
                            />
                        )
                    }
                </Scroll>
            </div>

            {openModalNovoDocumento && !isLoading &&
                <PreencherDocumento
                    open={previewDocumento}
                    handleClose={() => {
                        handleClosePreencherDocumento()
                    }}
                    isDuplicar={isDuplicar}
                    previewDocumento={prontuarioStore.previewDocumento}
                    sujeitoAtencaoId={sujeitoAtencaoSelected?.id}
                    sujeitoAtencao={sujeitoAtencaoSelected}
                    profissionalSaude={profissionalSaudeLogado}
                    unidadeAtual={unidade}
                    options={modelosDocumentos}
                    history={history}
                    page={page}
                    setDocumentosModelo={(e) => setDocumento(e)}
                    getDocumentosModelo={getDocumentosModelo}
                    loadContratos={loadContratos}
                    idAgendamentoProntuario={agendamentoSelected?.id}
                    isPrever={isPrever}
                />}
            {previewDocumento.open && (
                <PreviewDocumento
                    excluirArquivo={handleExcluirArquivo}
                    preview={previewDocumento}
                    onClose={handleClosePreviewDocumento}
                    duplicarDocumento={handleDuplicarDocumento}
                    onChangeDescricao={handleChangeDescricaoPreviewDocumento}
                    onSend={handleSendDocumento}
                />
            )}
        </div>
    );
})

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        height: "100%",
        overflow: "auto",
        marginTop: "16px",
        width: "100%",
    },
    wrapper: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
        overflowY: 'hidden',
        minHeight: '200px',
        justifyContent: 'space-between'
    },
    scroll: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%",
        height: "100%",
        overflowY: "auto",
    },
    contentDocumentos: {
        flexGrow: 1,
        width: "100%",
    },
    circularProgress: {
        display: 'flex',
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
    }

};

const DocumentosWithStyles = withStyles(styles)(Documentos);
const stores = ["prontuarioStore", 'usuarioStore', 'sujeitoAtencaoStore', "atendimentoStore", 'googleAnalyticsStore']
export default inject(...stores)(DocumentosWithStyles)
