import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const AltaIcon = props => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <rect width="32" height="32" rx="16" fill="#00ACA9" />
      <path d="M18.4838 11.0866V7C18.4838 6.44772 18.0361 6 17.4838 6H11.5835C11.0313 6 10.5835 6.44772 10.5835 7V11.0866" stroke="white" stroke-width="2" />
      <path d="M5 10.8306C4.44772 10.8306 4 11.2783 4 11.8306V22.7123C4 23.2646 4.44772 23.7123 5 23.7123H23.6026C24.1549 23.7123 24.6026 23.2646 24.6026 22.7123V11.8306C24.6026 11.2783 24.1549 10.8306 23.6026 10.8306H5Z" fill="#F2F2F2" stroke="white" stroke-width="2" />
      <path d="M14.5337 13.6299V21.2598" stroke="#DD3E3E" stroke-width="2.5" />
      <path d="M10.5835 17.4449H14.5337H18.4838" stroke="#DD3E3E" stroke-width="2.5" />
      <path d="M23.2338 16.0984C20.7757 16.0984 18.783 18.0911 18.783 20.5492V20.5492C18.783 23.0073 20.7757 25 23.2338 25H23.5492C26.0073 25 28 23.0073 28 20.5492V20.5492C28 18.0911 26.0073 16.0984 23.5492 16.0984H23.3915H23.2338Z" fill="#2EDF63" stroke="#28BA53" />
      <path d="M21.0574 19.4646L23.0068 21.7087L25.606 18.3425" stroke="#13712F" stroke-width="1.5" />
    </svg>
  )
}
export default AltaIcon
