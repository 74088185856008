import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./LoginRecuperarSenhaStyles";
import PageTitle from "../../components/PageTitle/PageTitle";
import { observer } from "mobx-react-lite";
import { LogotipoHealth } from "../../assets/img/svg";
import { redesSocial, termos } from "./Constants";
import LoginImagesSlider from "./LoginImagesSlider";

const LoginRecuperarSenha = observer((props) => {
  const { classes, children } = props;

  const abrirLink = (link) => {
    window.open(link);
  };

  return (
    <div>
      <PageTitle title="Login" />
      <div className={classes.header}>
        <img src={LogotipoHealth} alt={"logo"} />
        <div className={classes.redesSocial}>
          {redesSocial.map((item, index) => (
            <div onClick={() => abrirLink(item.link)} key={index}>
              <img
                className={classes.buttonRedeSocial}
                src={item.icon}
                alt={item.name}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.imagemLoginContent}>
          <LoginImagesSlider />
        </div>
        <div className={classes.contentRight}>
          <div className={classes.contentChildren}> {children} </div>
          <div className={classes.infosFooter}>
            <div className={classes.contentTermos}>
              {termos.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => abrirLink(item.link)}
                    className={classes.linkTermosPoliticas}
                  >
                    {item.label}
                  </div>
                );
              })}
            </div>
            <div className={classes.direitosReservados}>
              2022 © AppHealth - Todos os direitos reservados
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default withStyles(styles)(LoginRecuperarSenha);
