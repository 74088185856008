import { Fab, withStyles } from "@material-ui/core";
import React from "react";
import Dialog from "../../../../components/Dialog/Dialog";
import CloseIcon from "../../../../components/Icon/Close";
import { Button } from "../../../../components/ui/Buttons";

const ModalAlerta = ({
  classes,
  open,
  onPrimaryAction,
  onClose,
  messageContent,
  primaryButtonLabel,
  onSecondaryAction,
  modalIcon,
  secondaryButtonLabel
}) => {
  return (
    <Dialog
      open={open}
      classes={{ paper: classes.Dialog }}
    >
      <div className={classes.headerModal}>
        <div className={classes.title}>Dados não Salvos!</div>
        <Fab onClick={onClose} size={"medium"} color={"secondary"}>
          <CloseIcon />
        </Fab>
      </div>
      <div className={classes.contentIcon}>
        {modalIcon}
      </div>
      <div className={classes.message}>{messageContent}</div>
      <div className={classes.contentButton}>
        <Button onClick={onPrimaryAction} kind={'transparent'}>
          {primaryButtonLabel}
        </Button>
        <Button onClick={onSecondaryAction} >
          {secondaryButtonLabel}
        </Button>
      </div>
    </Dialog>
  );
}

const styles = {
  Dialog: {
    display: "flex",
    flexDirection: "column",
    width: "420px",
    height: "370px",
    padding: "16px",
    alignItems: "center",
    overflow: "hidden",
    justifyContent: "space-between",
  },
  content: {
    display: "flex"
  },
  headerModal: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    padding: "10px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "600",
  },
  message: {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: '500',
    color: '#505050'
  },
  contentButton: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "space-around",
  },
  contentIcon: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  }
}

export default withStyles(styles)(ModalAlerta);