import BaseStore from './Base.store';
import ConfiguracaoHorarioAtendimentoStore from './ConfiguracaoHorarioAtendimento.store';
import LoginStore from './Login.store';
import SujeitoAtencaoStore from './SujeitoAtencao.store';
import AtendimentoStore from "./Atendimento.store"
import LoaderStore from "./Loader.store";
import ProntuarioStore from "./Prontuario.store";
import UsuarioStore from "./Usuario.store";
import FiltroHeaderStore from './FiltroHeader.store';
import ProfissionalSaudeStore from './ProfissionalSaude.store';
import ReceitaStore from './Receita.store';
import ContaBancariaStore from './Financeiro/ContaBancaria.store';
import CentroDeCusto from './Financeiro/CentroDeCusto.store';
import CategoriaFinanceiraStore from './Financeiro/CategoriaFinanceira.store';
import ExtratoStore from './Financeiro/Extrato.store';
import DashboardStore from './Dashboard.store';
import ChatStore from './Chat.store';
import SocketStore from './Socket.store';
import RecuperarSenhaStore from "./RecuperarSenha.store";
import ConfiguracaoImpressaoStore from './ConfiguracaoImpressaoStore';
import SADTStore from './SADT.store';
import MedicamentoPersonalizadoStore from './MedicamentoPersonalizado.store';
import ConvenioStore from './Convenio.store';
import UnidadeStore from './UnidadeStore.store';
import ReceituarioStore from './Receituario.store';
import AgendamentoTipoStore from './AgendamentoTipo.store';
import TermoUsoStore from './TermoUso.store';
import GoogleAnalyticsStore from './GoogleAnalytics.store';
import DocumentoStore from './Documento.store';
import ProcedimentosRealizadosStore from './ProcedimentosRealizados.store';
import ProcedimentoStore from './Procedimento.store';
import AvaliacaoAntropometricaStore from './AvaliacaoAntropometrica.store';
import SujeitoAtencaoAnexoStore from './SujeitoAtencaoAnexo.store';
import AuditoriaStore from './Auditoria.store';
import ModalConfiguracoesStore from './ModalConfiguracoes.store';
import ModeloDocumentoStore from './ModeloDocumento.store';
import PerfilPublicoStore from './PerfilPublico.store';
import ProfissionaSaudeBraspagStore from './ProfissionalSaudeBraspagStore';
import UnidadeBraspagStore from './UnidadeBraspagStore';
import SolicitacoesPacientesStore from './SolicitacoesPacientes.store';
import OdontogramaStore from './Odontograma.store';
import OrcamentoStore from './Orcamento.store';
import ControleSessaoStore from './ControleSessao.store';
import NotificationStore from './Notifications.store';
import GuiaInternacaoStore from './GuiaInternacao.store';
import WhitelabelStore from './Whitelabel.store';
import RelatorioStore from './Relatorio.store';

class RootStores {

    constructor() {
        this.baseStore = new BaseStore();
        this.usuarioStore = new UsuarioStore();
        this.socketStore = new SocketStore(this);
        this.configuracaoHorarioAtendimentoStore = new ConfiguracaoHorarioAtendimentoStore();
        this.medicamentoPersonalizadoStore = new MedicamentoPersonalizadoStore();
        this.unidadeStore = new UnidadeStore();
        this.atendimentoStore = new AtendimentoStore();
        this.sujeitoAtencaoStore = new SujeitoAtencaoStore(this);
        this.agendamentoTipoStore = new AgendamentoTipoStore();
        this.atendimentoStore = new AtendimentoStore(this);
        this.loaderStore = new LoaderStore();
        this.recuperarSenhaStore = new RecuperarSenhaStore();
        this.loginStore = new LoginStore(this);
        this.chatStore = new ChatStore(this);
        this.profissionalSaudeStore = new ProfissionalSaudeStore();
        this.profissionalSaudeBraspagStore = new ProfissionaSaudeBraspagStore(this);
        this.unidadeBraspagStore = new UnidadeBraspagStore(this);
        this.prontuarioStore = new ProntuarioStore(this);
        this.odontogramaStore = new OdontogramaStore(this);
        this.orcamentoStore = new OrcamentoStore(this);
        this.filtroHeaderStore = new FiltroHeaderStore(this);
        this.receitaStore = new ReceitaStore();
        this.dashboardStore = new DashboardStore();
        this.configuracaoImpressaoStore = new ConfiguracaoImpressaoStore(this);
        this.SADTStore = new SADTStore();
        this.convenioStore = new ConvenioStore();
        this.receituarioStore = new ReceituarioStore();
        this.googleAnalyticsStore = new GoogleAnalyticsStore();
        this.contaBancariaStore = new ContaBancariaStore();
        this.centroDeCustoStore = new CentroDeCusto();
        this.categoriaFinanceiraStore = new CategoriaFinanceiraStore();
        this.extratoStore = new ExtratoStore(this);
        this.termoUsoStore = new TermoUsoStore();
        this.documentoStore = new DocumentoStore();
        this.procedimentosRealizadosStore = new ProcedimentosRealizadosStore();
        this.avaliacaoAntropometricaStore = new AvaliacaoAntropometricaStore();
        this.sujeitoAtencaoAnexoStore = new SujeitoAtencaoAnexoStore(this);
        this.auditoriaStore = new AuditoriaStore(this);
        this.modalConfiguracoesStore = new ModalConfiguracoesStore(this);
        this.modeloDocumentoStore = new ModeloDocumentoStore(this);
        this.perfilPublicoStore = new PerfilPublicoStore(this);
        this.solicitacoesPacientesStore = new SolicitacoesPacientesStore();
        this.procedimentoStore = new ProcedimentoStore();
        this.controleSessaoStore = new ControleSessaoStore();
        this.notificationStore = new NotificationStore();
        this.guiaInternacaoStore = new GuiaInternacaoStore();
        this.whitelabelStore = new WhitelabelStore();
        this.relatorioStore = new RelatorioStore(this);
        
        this.contaBancariaStore.usuarioStore = this.usuarioStore;
        
        this.centroDeCustoStore.usuarioStore = this.usuarioStore;
        
        this.categoriaFinanceiraStore.usuarioStore = this.usuarioStore;
        
        this.extratoStore.usuarioStore = this.usuarioStore;
        this.medicamentoPersonalizadoStore.usuarioStore = this.usuarioStore;
        this.sujeitoAtencaoStore.usuarioStore = this.usuarioStore;
        this.atendimentoStore.extratoStore = this.extratoStore;
        this.atendimentoStore.controleSessaoStore = this.controleSessaoStore;
        this.extratoStore.atendimentoStore = this.atendimentoStore;

        this.filtroHeaderStore.usuarioStore = this.usuarioStore;
        this.filtroHeaderStore.profissionalSaudeStore = this.profissionalSaudeStore;
        
        this.atendimentoStore.prontuarioStore = this.prontuarioStore;
        this.atendimentoStore.filtroHeaderStore = this.filtroHeaderStore;
        this.atendimentoStore.odontogramaStore = this.odontogramaStore;
        this.profissionalSaudeStore.usuarioStore = this.usuarioStore;
        this.profissionalSaudeStore.perfilPublicoStore = this.perfilPublicoStore;
        this.dashboardStore.usuarioStore = this.usuarioStore;
        
        this.configuracaoHorarioAtendimentoStore.filtroHeaderStore = this.filtroHeaderStore;
        
        this.loginStore.filtroHeaderStore = this.filtroHeaderStore;
        
        this.odontogramaStore.orcamentoStore = this.orcamentoStore;
    }
}

export default new RootStores();
