import Api from '../config/api';

export const findAllProcedimentosRealizados = (variables) => {
  return Api.post('', {
    query: `
    query ($search: String, $idSujeitoAtencao: UUID, $dataInicial: LocalDateTime, $idProfissionalSaude: Long, $idSala: UUID, $pageableDTO: PageableDTOInput) {
      findAllProcedimentoRealizado(search: $search, idSujeitoAtencao: $idSujeitoAtencao, dataInicial: $dataInicial, idProfissionalSaude: $idProfissionalSaude, idSala: $idSala, pageableDTO: $pageableDTO) {
        last
        content {
          id
          ativo
          dataHoraLancamento
          descricao
          valor
          controleSessaoProcedimento {
            id
            agendamento {
              id
            }
          }
          recebedor {
            id
            nome
          }
          solicitante {
            id
            nome
          }
          convenio{
            descricao
            id
            convenioProcedimentos{
              valor
            }
          }
          profissionalSaude {
            id
            nome
          }
          procedimento {
            id
            nome
            convenioProcedimentos{
              convenio{
                descricao
                id
              }
              valor
            }
          }
          titulo {
            id
          }
          procedimentoRealizadoAmazonS3Objetos {
            descricao
            entradaProntuarioAmazonS3Objeto {
              id
              nome
              nomeComExtensao
            }
          }
          sujeitoAtencao {
            id
            nome
            contato {
              telefonePrincipal
            }
            dataNascimento
            dadosConvenio {
              convenio {
                id
                descricao
              }
            }
          }
          sala {
            id
            nome
            procedimentos {
              id
              nome
            }
          }
        }
      }
    }`,
    variables: variables,
  });
};

export const findByIdProcedimentoRealizado = (idProcedimentoRealizado) => {
  return Api.post('', {
    query: `
    query ($idProcedimentoRealizado: UUID) {
      findByIdProcedimentoRealizado(idProcedimentoRealizado: $idProcedimentoRealizado) {
        id
        descricao
        urlDownload
        procedimento{
          id
          nome
          convenioProcedimentos {
            convenio {
              id
              descricao
            }
            valor
          }
        }
        sala{
          id
        }
        profissionalSaude {
          id
          nome
        }
        recebedor {
          id
          nome
        }
        solicitante {
          id
          nome
        }
        procedimentoRealizadoAmazonS3ObjetosAtivos{
          descricao
          entradaProntuarioAmazonS3Objeto{
            id
            nome
            nomeComExtensao
            extensao
          }
        }
      }
    }`,
    variables: { idProcedimentoRealizado},
  });
};

export const saveProcedimentoRealizado = variables => {
  const id = variables?.procedimentoRealizado?.id || variables.idProcedimentoRealizado;
  const method = id ? 'updateProcedimentoRealizado' : 'createProcedimentoRealizado';

  const argumentsUpDateType = method === 'updateProcedimentoRealizado' ? ',$idProcedimentoRealizado: UUID, $valor: BigDecimal, $convenio: ConvenioInput, $recebedor: ProfissionalSaudeInput' : ''
  const argumentsUpDate = method === 'updateProcedimentoRealizado' ? ',idProcedimentoRealizado: $idProcedimentoRealizado, valor: $valor, convenio: $convenio, recebedor: $recebedor' : ''
  return Api.post('', {
    query: `
    mutation ($objetosAmazonS3DTO: [ObjetoAmazonS3DTOInput], $procedimentoRealizado: ProcedimentoRealizadoInput ${argumentsUpDateType}) {
      ${method}(objetosAmazonS3DTO: $objetosAmazonS3DTO, procedimentoRealizado: $procedimentoRealizado ${argumentsUpDate}) {
        dataHoraLancamento
        descricao
        id
        procedimento{
          id
          nome
          tuss22ProcedimentoEventoSaude{
            id
          }
        }
        procedimentoRealizadoAmazonS3Objetos{
          procedimentoRealizadoEntradaProntuarioAmazonS3ObjetoId{
            procedimentoRealizado{
              id
            }
          }
        }
        profissionalSaude{
          id
        }
        sala{
          id
        }
        sujeitoAtencao{
          id
        }
      }
    }`,
    variables: variables,
  });
};

export const createProcedimentoRealizadoInternacao = (variables) => {
  return Api.post("", {
    query: `mutation CreateProcedimentoRealizadoInternacao($objetosAmazonS3DTO: [ObjetoAmazonS3DTOInput], $agendamentoId: Long, $procedimentoRealizado: ProcedimentoRealizadoInput) {
      createProcedimentoRealizadoInternacao(objetosAmazonS3DTO: $objetosAmazonS3DTO, agendamentoId: $agendamentoId, procedimentoRealizado: $procedimentoRealizado) {
        id
      }
    }`, variables
  })
} 

export const findByIdEntradaProntuarioProcedimentoRealizado = (
  idEntradaProntuario,
) => {
  return Api.post('', {
    query: `
    query ($idEntradaProntuario: Long) {
      findByIdEntradaProntuarioProcedimentoRealizado(idEntradaProntuario: $idEntradaProntuario) {
        id
        descricao
        procedimento {
          nome
        }
        procedimentoRealizadoAmazonS3Objetos {
          procedimentoRealizado{
            id
          }
          entradaProntuarioAmazonS3Objeto {
            nome
            nomeComExtensao
          }
        }
      }
    }`,
    variables: { idEntradaProntuario },
  });
};

export const createTitulo = (variables) => {
  return Api.post('', {
    query: `
    mutation ($titulo: TituloInput) {
      createTitulo(titulo: $titulo) {
        id
      }
    }`,
    variables: variables,
  });
};

export const atualizaAgendamentoProcedimentoRealizado = (variables) => {
  return Api.post('', {
    query: `
    mutation ($agendamentoProcedimentos: [AgendamentoProcedimentoInput], $agendamentoId: Long) {
      updateAgendamentoProcedimentosSolicitados(agendamentoProcedimentos: $agendamentoProcedimentos, agendamentoId: $agendamentoId) {
        id
      }
    }`,
    variables: variables,
  });
}

export const excluirArquivoProcedimentoRealizado = (variables) => {
  return Api.post('', {
    query: `
    mutation ($entradaProntuarioAmazonS3ObjetId: String) {
      excluirArquivoProcedimentoRealizado(entradaProntuarioAmazonS3ObjetId: $entradaProntuarioAmazonS3ObjetId)
    }`,
    variables: variables,
  });
}
