import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "../../../../assets/jss/pages/profissionalSaudeStyle";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import CardDadosCadastrais from "../../../../components/Cards/CardDadosCadastrais";

import EspecialidadesValores from "../../components/EspecialidadesValores";
import CartaoCreditoWarning from "../../components/CartaoCreditoWarning";
import {
  perfilViziAtendimentoPresencialOption,
  perfilViziTeleconsultaOption,
  unidadeEspecialidadeOBS,
  unidadeEspecialidadeText,
} from "../../constants/perfilViziUnidadeConstants";
import ProfissionalSaudeConfigOptions from "../../components/ProfissionalSaudeConfigOptions";
import { buildUrlPerfilVizi, getViziWebUrl } from "../../../../config/config";

const Atividade = observer((props) => {
  const { classes, perfilPublicoStore } = props;

  const handleChangeEspecilidadeValor = (value, field, index) => {
    const { especialidades } = perfilPublicoStore.perfilPublicoUnidade;
    especialidades[index] = {
      ...especialidades[index],
      [field]: value,
    };
  };

  const handleSwitchChange = (option) => {
    perfilPublicoStore.perfilPublicoUnidade[
      option.attribute
    ] = !perfilPublicoStore.perfilPublicoUnidade[option.attribute];
  };

  const switchIsChecked = (option) => {
    if (option.storedIn === "perfilPublicoStore") {
      return perfilPublicoStore.perfilPublicoUnidade[option.attribute];
    }
  };
   const getUrlBaseViziEspecialidade = () =>{
        if(!perfilPublicoStore.perfilPublicoUnidade){
          return null
        }
       
        const {utilizaLinkPersonalizado} =  perfilPublicoStore.perfilPublicoUnidade || {}
        
        if(!utilizaLinkPersonalizado){
          return null
        }
        return `${getViziWebUrl()}${buildUrlPerfilVizi(perfilPublicoStore.perfilPublicoUnidade)}`
      }
  const urlViziPerfilBase = getUrlBaseViziEspecialidade()
  return (
    <>
      <div className={classes.cardContainer}>
        <CardDadosCadastrais titulo="Especialidades">
          <span className={classes.viziWarningText}>
            {unidadeEspecialidadeText}
          </span>
          <span className={classes.viziWarningText}>
            <b>{unidadeEspecialidadeOBS}</b>
          </span>

          <ProfissionalSaudeConfigOptions
            options={[
              {
                ...perfilViziAtendimentoPresencialOption,
              },
              {
                ...perfilViziTeleconsultaOption,
              },
            ]}
            handleSwitchChange={handleSwitchChange}
            isChecked={switchIsChecked}
          />
          <EspecialidadesValores
            urlViziPerfilBase={urlViziPerfilBase}
            especialidadesItems={
              perfilPublicoStore.perfilPublicoUnidade.especialidades
            }
            perfilType="unidade"
            atendePresencial={
              perfilPublicoStore.perfilPublicoUnidade.atendePresencial
            }
            utilizaTelemedicina={
              perfilPublicoStore.perfilPublicoUnidade.utilizaTelemedicina
            }
            onChangeValue={handleChangeEspecilidadeValor}
          />
          {perfilPublicoStore.perfilPublicoUnidade.utilizaTelemedicina && (
            <CartaoCreditoWarning tax={6} />
          )}
        </CardDadosCadastrais>
      </div>
    </>
  );
});

const AtividadeWithStyles = withStyles(styles)(Atividade);
const stores = [
  "unidadeStore",
  "perfilPublicoStore",
  "profissionalSaudeStore",
  "unidadeBraspagStore",
];
export default inject(...stores)(AtividadeWithStyles);
