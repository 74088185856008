import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from "react";
import string from "../../../../../../../utils/string";
import {
  defaultErrors,
  orcamentoDefault,
} from "../../../../../../../stores/Orcamento.store";
import { getProfissionalSaudeLogado } from "../../../../../../../services/UsuarioService";
import {
  saveOrcamento,
} from "../../../../../../../services/OdontogramaService";
import Notification from "../../../../../../../components/Notification";
import ModalOrcamento from "./ModalOrcamento";
import { findAllConvenioList, urlPainel3dpe } from "../../../../../../../services/OrcamentoService";

import ModalConfirmacao from "../../../../../../../components/Modal/ModalConfirmacao/ModalConfirmacao";

import { withStyles } from "@material-ui/core";

const ModalOrcamentoOrtopedico = observer(
  ({
    orcamentoStore,
    setOpenModalOrcamento,
    openModalOrcamento,
    sujeitoAtencaoSelected,
    loadList,
    classes
  }) => {
    const { nome: sujeitoAtencaoNome } = sujeitoAtencaoSelected || {};
    const {
      orcamento,
      handleFieldModal,
      notification,
      closeAlertMessage,
    } = orcamentoStore;

    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [showModalConfirmComprar, setShowModalConfirmComprar] = useState(false);

    const {
      nome,
      convenio,
      id,
      observacao,
      orcamentoProcedimentos,
      profissionalSaude,
      status,
      tipoPagamento,
      totalParcelas,
      valor,
      valorEntrada,
      locaisPalmilhaPeDireito,
      locaisPalmilhaPeEsquerdo,
      numeroPalmilha,
      centimetrosChinelo,
    } = orcamento || {};

    useEffect(() => {
      if(!id) {
        getDadosOrcamento();
        alteraNomeOrcamento();
      };

      orcamentoStore.errors = defaultErrors;
    }, []);

    const alteraNomeOrcamento = () => {
      handleFieldModal('nome', sujeitoAtencaoNome);

    };

    const getDadosOrcamento = async () => {
      getDadosSemOrcamento();
    };

    const checkPalmilha = () =>{
      const locaisDireito = locaisPalmilhaPeDireito.map((item) => {
        return {
          ...item,
          espessura: parseFloat(item.espessura),
        }
      });

      const locaisEsquerdo = locaisPalmilhaPeEsquerdo.map((item) => {
        return {
          ...item,
          espessura: parseFloat(item.espessura),
        }
      });
      const isInvalidPalmilha = (locais) => ({
        hasChecked: locais.some((item) => item.checked),
        hasCheckedWithoutEspessura: locais.some((item) => item.checked && !item.espessura),
        hasEspessuraWithoutChecked: locais.some((item) => !item.checked && item.espessura),
      });
    
      const direito = isInvalidPalmilha(locaisDireito);
      const esquerdo = isInvalidPalmilha(locaisEsquerdo);
    
      const hasCheckedPalmilha = direito.hasChecked || esquerdo.hasChecked;
    
      return (
        !hasCheckedPalmilha ||
        direito.hasCheckedWithoutEspessura ||
        esquerdo.hasCheckedWithoutEspessura ||
        direito.hasEspessuraWithoutChecked ||
        esquerdo.hasEspessuraWithoutChecked
      );
    };

    const contemErros = () => {
     
      const errors = {
        nome: !nome,
        locaisPalmilha: checkPalmilha(),
        procedimentos: orcamentoProcedimentos.length < 2,
        numero: !numeroPalmilha && !centimetrosChinelo,
      };
      
      orcamentoStore.errors = errors;
      const possuiErros = Object.values(errors).some((item) => item);

      return possuiErros;
    };

    const getLocaisPalmilha = () => {
      const locaisDireito = locaisPalmilhaPeDireito.map((item) => {
        return {
          localPalmilha: {
            id: item.id,
          },
          espessura: item.checked ? parseFloat(item.espessura) : null,
        }
      });

      const locaisEsquerdo = locaisPalmilhaPeEsquerdo.map((item) => {
        return {
          localPalmilha: {
            id: item.id,
          },
          espessura: item.checked ? parseFloat(item.espessura) : null,
        }
      });

      return {
        locaisPalmilhaPeDireito: locaisDireito,
        locaisPalmilhaPeEsquerdo: locaisEsquerdo,
      };
    };

    
    const handleShowModalConfirmComprar = () =>{
      setShowModalConfirmComprar(true)
    }
    const handleCloseModalConfirmComprar = () =>{
      setShowModalConfirmComprar(false);
      setOpenModalOrcamento(false);
    }
    const handleGoPagamento = async() =>{
      // const link3dpe = await urlPainel3dpe();
      const link3dpe = "https://interno.3dpedoctors.com.br/compra-palmilha";


      window.open(link3dpe, "_blank");
      setOpenModalOrcamento(false);
    }
    
    const handleSave = async (action = "") => {
      try {
        if (contemErros()) {
          const message = "Favor verificar os campos obrigatórios";

          orcamentoStore.openNotification(message, "error");
          return;
        }

        setIsLoadingSave(true);
        const procedimentos = orcamentoProcedimentos.map((item) => {
          return {
            procedimento: {
              id: item.procedimento.id,
            },
            ...( item?.espessura && { espessura: item.espessura }),
            valorUnitario:
              typeof item.valorUnitario === "string"
                ? string.currencyMaskToFloat(item.valorUnitario)
                : item.valorUnitario,
          };
        });

        const isTipoPalmilha = orcamentoProcedimentos.some(item => item.procedimento.tiposProcedimentos[0] === "PALMILHA");
        const locaisPalmilha = getLocaisPalmilha();
       
        const variables = {
          ...(id && { id }),
          nome,
          observacao,
          tipo: "ORTOPEDICO",
          status: action || status || "PENDENTE",
          orcamentoProcedimentos: procedimentos,
          ...(isTipoPalmilha ? {numeroPalmilha} : {centimetrosChinelo}),
          valor:
            typeof valor === "string"
              ? string.currencyMaskToFloat(valor)
              : valor,
          profissionalSaude: {
            id: profissionalSaude?.id,
          },
          ...(totalParcelas && { totalParcelas: totalParcelas.value }),
          ...(valorEntrada && {
            valorEntrada:
              typeof valorEntrada === "string"
                ? string.currencyMaskToFloat(valorEntrada)
                : valorEntrada,
          }),
          ...(convenio?.value && {
            convenio: { id: convenio?.value },
          }),
          ...(tipoPagamento?.value && {
            tipoPagamento: tipoPagamento.value,
          }),
          sujeitoAtencao: {
            id: sujeitoAtencaoSelected.id,
          },
          locaisPalmilhaPeDireito: locaisPalmilha.locaisPalmilhaPeDireito,
          locaisPalmilhaPeEsquerdo: locaisPalmilha.locaisPalmilhaPeEsquerdo,
        };

        const response = await saveOrcamento(variables);
        await loadList();
        setShowModalConfirmComprar(true);

        if (response.status === "PENDENTE") {
          orcamentoStore.orcamento = orcamentoDefault;
        } else if (response.status === "APROVADO") {
          orcamentoStore.orcamento = {
            ...orcamentoStore.orcamento,
            id: response?.id,
          };
        }

        orcamentoStore.openNotification(
          "Orçamento salvo com sucesso!",
          "success"
        );
      } catch (e) {
        orcamentoStore.openNotification("Erro ao salvar o orçamento", "error");
      } finally {
        setIsLoadingSave(false);
      }
    };

    const getDadosSemOrcamento = async () => {
      const profissionalLogado = await getProfissionalSaudeLogado();
      const convenios = await findAllConvenioList();

      const profissionalSaude = {
        id: profissionalLogado.id,
        value: profissionalLogado.id,
        label: profissionalLogado.nome,
      };

      orcamentoStore.orcamento = {
        ...orcamento,
        profissionalSaude,
        convenio: convenios[0],
      };
    };

    return (
      <>
        <ModalOrcamento
          open={openModalOrcamento}
          onClose={() => setOpenModalOrcamento(false)}
          title={"Prescrição Palmilhas 3DPÉ"}
          handleSave={handleSave}
          orcamento={orcamento}
          handleFieldModal={handleFieldModal}
          isLoadingSave={isLoadingSave}
          setIsLoadingSave={setIsLoadingSave}
          showModalConfirmComprar={handleShowModalConfirmComprar}
        />
      
        <Notification
          close={closeAlertMessage}
          reset={closeAlertMessage}
          isOpen={notification.isOpen}
          variant={notification.variant}
          message={notification.message}
        />
        <ModalConfirmacao 
          open={showModalConfirmComprar}
          title={'Pedido salvo com sucesso!'}
          classes={classes}
          buttons={{
              labelPrimaryButton: "Continuar Prescrevendo",
              handlePrimaryButton: () => handleCloseModalConfirmComprar(),
              labelSecondButton: "Ir para Pagamento",
              handleSecondButton: () => handleGoPagamento(),
          }}
        />
      </>
    );
  }
);
const styles = {
  contentButtons:{
    flexDirection:"column",
    gap:"10px"
  }

};
const ModalOrcamentoOrtopedicoWithStyles = withStyles(styles)(ModalOrcamentoOrtopedico);
export default inject(
  "odontogramaStore",
  "orcamentoStore"
)(ModalOrcamentoOrtopedicoWithStyles);
