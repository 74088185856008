import React, { useState, useEffect, useRef } from 'react';

import { withStyles, Fab, CircularProgress, Switch } from '@material-ui/core';
import { style } from './style';

import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';

import { criarEImprimirDocumentoInternacao, criarEImprimirDocumento, getConfiguracaoUnidade,  } from '../../../../services/DocumentoService';
import { imprimirDocumentoAssinado } from '../../../../services/ImpressaoService';
import localStorageService, { ACCESS_TOKEN_KEY } from '../../../../services/storage';

import InformacoesImportantesModal from '../../../Atendimento/InformacoesImportantes/InformacoesImportantesModal';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Dialog from '../../../../components/Dialog/Dialog';
import CloseIcon from '../../../../components/Icon/CloseIcon';
import ModelDocumentEditor from '../../../../components/ModelDocumentEditor';
import { Button } from '../../../../components/ui/Buttons';
import PrintIcon from '../../../../components/Icon/Print';
import TokenDialog from '../../../../components/Dialog/TokenDialog';
import InputTokenDialog from '../../../../components/Dialog/InputTokenDialog';
import { InputForm } from '../../../../components/Modal/Input';
import { checkUserRole } from '../../../../utils/checkUserRole';
import { isIOS } from '../../../../utils/OS';
import printPage from '../../../../utils/printPage';
import base64ToBlob from '../../../../utils/base64ToBlob';
import string from '../../../../utils/string';
import { buildUrlDownload } from '../../../../config/config';
import HorizontalLineIcon from '../../../../components/Icon/HorizontalLineIcon';
import { ReactSelect } from '../../../../components/Select/SelectSearch';
import ModalCompatilhar from '../../../Atendimento/Prontuario/components/ModalCompatilhar';
import CompartilharIcon from '../../../../components/Icon/CompartilharIcon';
import MultiToggleButtons from '../../../../components/Button/MultiToggleButtons';
import ContratoForm from '../../../../components/ContratoForm/ContratoForm';
import { assinarDocumentoPlugssign, findAllDocumentoClienteAssinatura } from '../../../../services/ContratoService';
import { getUnidadeLogado } from '../../../../services/UsuarioService';
import DocumentPreview from '../../../../template/pdf/documento/Documento'
import { findAgendamentoById } from '../../../../services/ProntuarioService';

const tabs = {
  MODELOSCRIADOS: 0,
  ASSINATURAS: 1,
};

const PreencherDocumento = observer(({
  classes, 
  open, 
  isDuplicar, 
  previewDocumento, 
  screen, 
  handleClose,
  options,
  sujeitoAtencaoId,
  sujeitoAtencao,
  profissionalSaude: profissionalSaudeDefault,
  configuracaoImpressaoStore,
  profissionalSaudeStore,
  prontuarioStore,
  documentoStore,
  receituarioStore,
  modeloDocumentoStore,
  history,
  handleMinimize,
  documentoAssinatura,
  setOptions,
  sujeitoAtencaoStore,
  readOnly,
  modeloDocumento,
  config,
  onPageChange,
  getDocumentosModelo,
  isTelaPaciente,
  loadContratos,
  idAgendamentoProntuario,
  utilizaPlugzapi,
  atendimentoStore,
}) => {

  const formDataDefault = {
    nome: '',
    destinatarios: '',
    tituloMensagem: 'Olá, estamos enviando o documento para sua assinatura',
    dataHoraValidade: '',
    profissionalSaude: profissionalSaudeDefault || null,
    procedimentos: [],
    idAgendamento: null
  }

  const [loadingButtonImpressao, setLoadingButtonImpressao] = useState(false);
  const [documento, setDocumento] = useState(null);
  const [textDocumento,setTextDocumento]= useState(null)
  const [inputs, setInputs] = useState(null);
  const [camposEntrada, setCamposEntrada] = useState([]);
  const [loadingDocument, setLoadingDocument] = useState(true);
  const [showInformacoesImportantesModal, setshowInformacoesImportantesModal] = useState(false);
  const [formData, setFormData] = useState(formDataDefault)
  const [printType, setPrintType] = useState('');
  const [isSigned, setIsSigned] = useState(false);
  const [openSignQuestionDialog, setOpenSignQuestionDialog] = useState(false);
  const [openTokenDialog, setOpenTokenDialog] = useState(false);
  const [signToken, setSignToken] = useState('');
  const [tokenError, setTokenError] = useState(false);
  const [tokenDialogActionClicked, setTokenDialogActionClicked] = useState(false);
  const [menuIsOpen,setMenuIsOpen]=useState(true);
  const [openModalConfirmacao,setOpenModalConfirmacao]=useState(false)
  const  [compartilhar,setCompartilhar]=useState(false)
  const [idDocumento, setIdDocumento]=useState(null)
  const [tabSelected, setTabSelected] = useState(tabs.MODELOSCRIADOS);
  const [switchValue, setSwitchValue] = useState(false);
  const [isLoading , setIsLoading]=useState(false)
  const [utilizaPlugsign , setUtilizaPlugsign]=useState(false);
  const[pageableDto , setPageableDto]=useState({
    pageNumber:0,
    pageSize: 30,
    sortDir: "ASC",
    sortField:"nome"
  })

  const editorRef = useRef(null);
  const notInitialRender = useRef(false);
  const prevOptionsRef = useRef(null);

  useEffect(() => {
    setDefaultOptions();
    verificaConfigUnidade();
  }, [])


  useEffect(() => {
    if(idAgendamentoProntuario){
      setFormData({...formData,idAgendamento:idAgendamentoProntuario})
    }
  },[idAgendamentoProntuario])

  useEffect(()=>{
    const agendamento = history
   if(agendamento?.agendamentoId){
    getDadosAgendamento(agendamento.agendamentoId)
   }
  },[])


  useEffect(()=>{
    if(isTelaPaciente){
      handleChangeSwitch()
      setDocumento({})
      getDocumentosModelo({ tipo: (switchValue || isTelaPaciente) ? "ASSINATURA" : "PROFISSIONAL_SAUDE" }) 
    }
  },[])

  useEffect(() => {
    if (documento) {
      setInitialDocumentConfig();
    }
  }, [documento]);

  useEffect(() => {
    const optionsChanged =  options instanceof Array &&
      prevOptionsRef.current instanceof Array &&
      options.length !== prevOptionsRef.current.length;

    if (optionsChanged) {
      setDefaultOptions();
    }

    prevOptionsRef.current = options;
  }, [options]);

  const getDadosAgendamento=async(id)=>{
    
    const response = await findAgendamentoById(id)
    const dadosForm = response.data.data.findByIdAgendamento
    const procedimentos = dadosForm?.procedimentos?.map((item) => ({id:item.procedimento.id, nome:item.procedimento.nome}))
    const procedimentosSelecionados= procedimentos?.map((item) => ({id:item.id, nome:item.nome}))
    setFormData({
      ...formData,
      destinatarios:dadosForm?.telefone,
      profissionalSaude: dadosForm?.profissionalSaude,
      ...(procedimentosSelecionados && { procedimentos:procedimentosSelecionados }),
      idAgendamento:dadosForm?.id
    }) 
  }

  const verificaConfigUnidade = async () => {
    const configuracaoUnidade = await getConfiguracaoUnidade();
    setUtilizaPlugsign(configuracaoUnidade.utilizaPlugsign);
  };

  const setInitialDocumentConfig = async () => {
    const { sujeitoAtencaoSelected } = prontuarioStore;
    await profissionalSaudeStore.carregaProfissionalSaude();
    await prontuarioStore.checkUseDigitalSignature();
    await configuracaoImpressaoStore.getConfig('DOCUMENTO');
    const roleEntradaProntuarioRead = await checkUserRole('ROLE_ENTRADA_PRONTUARIO_READ');
    const showInformacoesImportantesModal = roleEntradaProntuarioRead && sujeitoAtencaoSelected?.informacoesImportantes?.length > 0;
    setshowInformacoesImportantesModal(showInformacoesImportantesModal);
  };

  const setDefaultOptions = () => {
    if (options instanceof Array && options.length === 1) {
      setDocumento({
        ...options[0],
        label: options[0].name,
      });
      handleSetCamposEntrada(options[0]);
    }
    if(isDuplicar) {
      setDocumento({
        ...previewDocumento,
        label: previewDocumento.content.name,
      });
      setLoadingDocument(false);
    }
  };

  const handleSetCamposEntrada = (value) => {
    if (value) {
      const { camposEntrada } = value;
      setCamposEntrada(camposEntrada || []);
      setLoadingDocument(true);
      if(camposEntrada?.length > 0) {
        let inputs = {};
        camposEntrada.forEach((campoEntrada => {
          inputs[campoEntrada?.campo] = { valor: "", campo: campoEntrada.campo };
        }));

        setInputs(inputs);
      } else {
        setInputs(null);
      }
    setLoadingDocument(false);
    }
  }

  const handleDocumentoChange = (e) => {
    setMenuIsOpen(false)
    setDocumento(e);
    setFormData({ ...formData, nome: e.name });
    handleSetCamposEntrada(e);
  };

  const handleDocumentoSearch = (e) => {
    e && !menuIsOpen && setMenuIsOpen(true)
  };

  useEffect(() => {
    if (notInitialRender.current) {
      const { possuiToken } = receituarioStore;

      const isPrintingUnsigned = tokenDialogActionClicked && !isSigned && printType === 'unsigned';
      const isPrintingSigned = tokenDialogActionClicked && isSigned && (signToken || possuiToken) && printType === 'signed';

      if (isPrintingSigned) {
        onPrintSigned();
      }

      if (isPrintingUnsigned) {
        onPrintUnsigned()
      }

    } else {
      notInitialRender.current = true;
    }
  }, [isSigned, signToken, tokenDialogActionClicked, printType]);

  const handleClickPrint = async () => {
    const {assinaturaDigitalObrigatoria} = prontuarioStore;
    const {utilizaAssinaturaDigital} = profissionalSaudeStore.profissionalSaude
    const openSignDialog = assinaturaDigitalObrigatoria || utilizaAssinaturaDigital;
    if (openSignDialog) {
      setOpenSignQuestionDialog(true);
    } else {
      handlePrintDocumentUnsigned()
    }
  };

  const handlePrintDocumentUnsigned = () => {
    setIsSigned(false);
    setOpenSignQuestionDialog(false);
    setTokenDialogActionClicked(true);
    setPrintType('unsigned')
  };

  const handlePrintDocumentSigned = async () => {
    setTokenDialogActionClicked(true);
    receituarioStore.possuiToken = await receituarioStore.possuiTokenAssinaturaValido()

    const { possuiToken } = receituarioStore;

    setPrintType('signed');
    setOpenSignQuestionDialog(false);
    setOpenTokenDialog(!possuiToken);
    setIsSigned(possuiToken);
  };

  const handleCancelSignedPrint = () => {
    resetTokenDialogs()
  };

  const onPrintUnsigned = async () => {
    const {isModalInternacao}=atendimentoStore
    setLoadingButtonImpressao(true);

    if (!isDuplicar && contemErros()) {
      resetTokenDialogs();
      return setLoadingButtonImpressao(false);
    }

    if (isIOS) {
      const newWindowRef = window.open('', 'print_documento');

      if (newWindowRef) {
        const loadingContent = `<div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%">
          <h1 style="font-family: -apple-system;">Aguarde...</h1>
        </div>`;
        newWindowRef.document.open();
        newWindowRef.document.write(loadingContent);
        newWindowRef.document.close();
      }
    }

    const variablesDTO = buildVariablesDTO();
    try {
      const response = isModalInternacao ? await criarEImprimirDocumentoInternacao(variablesDTO) : await criarEImprimirDocumento(variablesDTO);
      if (response?.data?.data?.criarEImprimirDocumentoInternacao) {
        prontuarioStore.findAllEntradaProntuario()
      }
      const conteudoImpressao = response?.data?.data?.criarEImprimirDocumento || response?.data?.data?.criarEImprimirDocumentoInternacao;

      if (!conteudoImpressao) {
        setTokenError(true);
        throw response.data.errors[0];
      }

      printPage(base64ToBlob(conteudoImpressao?.pdf));
      setLoadingButtonImpressao(false);
      resetTokenDialogs();
      if(compartilhar){
        setOpenModalConfirmacao(true)
        setIdDocumento(conteudoImpressao.entradaProntuario.id)
      }else{
        handleClose();
      }
    } catch(error) {
      setLoadingButtonImpressao(false);
      resetTokenDialogs();
      console.error(error);
    }
  };

  const handleSubmitSignToken = async () => {
    setLoadingButtonImpressao(true);
    setPrintType('signed');
    setIsSigned(true)
  };
  
  const handleClickEnviar = async () => {    
    try {
    setIsLoading(true)
    const {destinatarios,nome,procedimentos,profissionalSaude,tituloMensagem,dataHoraValidade,idAgendamento}=formData;
    const{idOpened}=sujeitoAtencaoStore;

      const unidade = await getUnidadeLogado()
      const date = new Date(dataHoraValidade);
      const dataVencimento=date.toISOString().split('T')[0]
      const chaveDestinatarios = destinatarios?.split(',')
      const destinatariosOrdenados = string.formatArrayStrings(chaveDestinatarios)
      const camposEntradas= inputs ? Object.values(inputs) : []

    const variables = {
      camposEntrada:[
        ...camposEntradas
      ],
      dataVencimento: dataVencimento,
      tituloMsg: tituloMensagem,
      destinatarios: destinatariosOrdenados,
      assinarDocumentoDto: {
        nome: nome,
        documento: textDocumento,
        unidade: { id: unidade.id, },
        sujeitoAtencao: isTelaPaciente?{ id: idOpened, }:{id:sujeitoAtencaoId},
        profissionalSaude: {
          id: profissionalSaude.id
        },
        procedimentos:procedimentos,
        tipo:"DOCUMENTO",
        ...(idAgendamento && { agendamento: { id: idAgendamento } })

      }
    }
   
      await assinarDocumentoPlugssign(variables)
      const variaveis = { search: "", pageableDto: pageableDto, sujeitoAtencao: { id: idOpened } ,atualizarSituacoes:true}
      findAllDocumentoClienteAssinatura(variaveis)
      prontuarioStore.openNotification('Documento salvo com sucesso!','success')
      loadContratos({isClear:true}) 
      handleClose()
    }
    catch (e){
      prontuarioStore.openNotification('Erro ao criar!','error')
    }
    finally {
      setIsLoading(false)
      setFormData({})
    }
  }


  const onPrintSigned = async () => {
    if (!isDuplicar && contemErros()) {
      resetTokenDialogs();
      return setLoadingButtonImpressao(false);
    }

    setLoadingButtonImpressao(true);
    setTokenDialogActionClicked(false);

    let variablesDTO = buildVariablesDTO();
    if (!receituarioStore?.possuiToken) {
      variablesDTO.tokenAplicativo = signToken.replace(' ', '');
    }

    try {
      const response = await imprimirDocumentoAssinado(variablesDTO);
      setIdDocumento(response.data.data.criarEImprimirDocumento?.entradaProntuario?.id)
    
      if (response.data.errors || !response.data.data.criarEImprimirAssinadaDocumento) {
        throw response.data.errors[0];
      }
      const token = await localStorageService.get(ACCESS_TOKEN_KEY);
      const { entradaProntuario } = response?.data?.data?.criarEImprimirAssinadaDocumento;
      window.open(buildUrlDownload(
        entradaProntuario?.urlDownload || response, 
        token,
        "_blank"
      ));
      resetTokenDialogs();
      if(compartilhar){
        setOpenModalConfirmacao(true)
      }else{
        handleClose();
      }
    } catch(error) {
      setLoadingButtonImpressao(false);
      setTokenError(true);
      setIsSigned(false);
      setTokenDialogActionClicked(true);
      console.error(error);
    }
  };

  const changeTabSelected = async (tabSelected) => {
    const modeloDocumento = editorRef?.current?.handleSubmitFromChild()

    setTabSelected(tabSelected)
    setTextDocumento(modeloDocumento)
  }

  const buildVariablesDTO = () => {
    const {isModalInternacao,objView}=atendimentoStore;
    const modelDucumentChanged = editorRef.current.handleSubmitFromChild();

    let variablesDTO = {
      sujeitoAtencao: {
        id: sujeitoAtencaoId,
      },
      ...(isModalInternacao && {agendamentoId:objView.id})
    };

    if (isDuplicar && isModalInternacao) {
      variablesDTO.documentoModelo = {
        modelo: modelDucumentChanged,
        nome: previewDocumento.content.nome,
      };
      variablesDTO.camposEntrada = camposEntrada.reduce((acc, campo) => {
        const input = { ...inputs[campo.campo], error: undefined };
        return input ? [...acc, input] : acc;
      }, []);
      return variablesDTO;
    }

    if (isDuplicar && !isModalInternacao) {
      variablesDTO.nome = previewDocumento.content.nome;
      variablesDTO.documento = modelDucumentChanged;
    } else {
      variablesDTO.camposEntrada = camposEntrada.reduce((acc, campo) => {
        const input = { ...inputs[campo.campo], error: undefined };
        return input ? [...acc, input] : acc;
      }, []);

      variablesDTO.documentoModelo = {
        id: documento.value,
        modelo: modelDucumentChanged,
        nome: documento.name
      };
    }
    return variablesDTO;
  }


  const contemErros = () => {
    if (!documento) return true;
    if (!inputs) return false;

    let updatedInputs = {
      ...inputs,
    }

    Object.keys(updatedInputs).forEach((input) => {
      updatedInputs[input].error = string.isEmpty(inputs[input].valor); 
    });
  
    return Object.keys(updatedInputs).reduce((acc, input) => {
        const error = updatedInputs[input].error;
        return acc || Boolean(error);
      }, false);
  };

  const resetTokenDialogs = () => {
    setOpenTokenDialog(false);
    setOpenSignQuestionDialog(false);
    setTokenError(false);
    setSignToken('');
    setTokenDialogActionClicked(false);
    setPrintType('')
  };

  const handleInputClick = (event, campo) => {
    let updatedInputs = inputs;
    updatedInputs[campo] = { valor: event.target.value, campo };

    setInputs(updatedInputs);
  };

  const handlePushToModelosDocumentos = () => {
    history.push('/configuracoes/modelos-documento');
  };

  const handleCloseModalConfirmacao = () => {
    setOpenModalConfirmacao(false)
    handleClose()
  }

  const handleCompartilhar = async () => {
    try {
      setCompartilhar(true)
      if (!idDocumento) {
        await handleClickPrint()
      } else {
        setOpenModalConfirmacao(true)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleClickCompartilharWhatsApp = async () => {
    const { agendamentoSelected } = prontuarioStore
    const telefone = `${agendamentoSelected.telefoneDDI + agendamentoSelected.telefone}`
    try {
      await prontuarioStore.enviarItemProntuarioPaciente({ telefone, entradaProntuarioId: idDocumento });
     
      setCompartilhar(false)
    } catch (e) {
      console.error(e)
    }
    finally {
      setOpenModalConfirmacao(false)
      handleClose()

    }
  }

  const handleChangeSwitch = async () => {
    const newValue =!switchValue
    setSwitchValue(newValue) 
        setDocumento({})
        await getDocumentosModelo({tipo:newValue?"ASSINATURA":"PROFISSIONAL_SAUDE"}) 
  }


  const handleChange = (value, key) => {
    setFormData((contratoData) => ({ ...contratoData, [key]: value }))
  }

  const handleProcedimentos = (value) => {
    const { procedimentos = [] } = formData
    const hasProcedimentos = procedimentos?.some((procedimento) => procedimento.id === value.id)
    if (hasProcedimentos) {
      return
    }
    setFormData((contratoData) => ({ ...contratoData, procedimentos: [...procedimentos, {id:value.id , nome:value.nome}] }))

  }

  const handleDeleteProcedimentos = (value) => {
    const procedimentosFormated = formData?.procedimentos?.filter((procedimento) => procedimento.id !== value.id)
    setFormData((contratoData) => ({ ...contratoData, procedimentos: procedimentosFormated }))
  }
  
  const renderTabModelosCriados = () => {
    return (!readOnly ? (
      <>
        {documento && (
          <ModelDocumentEditor
            classes={{
              textAreaWrapper: classes.textEditorWrapper,
              scrollEditor: classes.textEditorScroll,
              editor: classes.editor,
              jodit: classes.jodit,
            }}
            id={documento?.value}
            key={documento?.value}
            refChild={editorRef}
            disabledButtons={['image']}
            textoHtml={previewDocumento?.content?.documento}
            isDuplicar={isDuplicar}
            modeloDocumentoStore={modeloDocumentoStore}
            disabled={readOnly}
          />
        )}
        {noOptions && (
          <div className={classes.emptyContainer}>
            <span className={classes.emptyMessage}>
              Nenhum documento foi criado
            </span>
            <span
              onClick={handlePushToModelosDocumentos}
              className={classes.emptyMessageLink}
            >
              Criar documento
            </span>
          </div>
        )}
      </>
    ) : (
      <DocumentPreview
        modeloDocumento={modeloDocumento}
        config={config}
        documentoStore={documentoStore}
        onPageChange={onPageChange}
        classes={{ pdfContainer: classes.pdfContainer }}
        isTelaPaciente
      />
    ))
  }

 
  const {nome,tituloMensagem,dataHoraValidade,destinatarios,profissionalSaude,procedimentos}=formData;
  const noOptions = !documento && options?.length === 0;
  const optionsChecked = !nome || !tituloMensagem || !dataHoraValidade||!destinatarios || !profissionalSaude || !procedimentos
  const disabled= isLoading || noOptions || optionsChecked 

  return (
    <>
      <PageTitle title={`${screen} - Documento`} />
      <Dialog
        classes={{ 
          paper: showInformacoesImportantesModal ? classes.fullSizePaper : classes.paper
        }}
        open={open}
        keepMounted
        fullWidth
      >
        {showInformacoesImportantesModal && 
          <div>
            <InformacoesImportantesModal />
          </div>
        }
        <div className={classes.dialogContainer}>
          <div className={classes.dialogHeader}>
            <div className={classes.headerArea}>
              <span className={classes.modalTitle}>Documento</span>
              <div className={classes.wrapperButtons}>

              {utilizaPlugsign && <div className={classes.contentSwitch}>
                <label>Assinatura online do paciente</label>
                <Switch
                    onChange={handleChangeSwitch}
                    color="primary"
                    checked={switchValue}
                    disabled={documentoAssinatura}
                    />
              </div>}
              {tabSelected === 0 && utilizaPlugzapi && <Fab
									className={classes.buttonSave}
									onClick={handleCompartilhar}
								>
									<CompartilharIcon></CompartilharIcon>
								</Fab>}
               {!documentoAssinatura && <Fab 
                  className={classes.minimizeButton}
                  onClick={handleMinimize}
                >
                  <HorizontalLineIcon color="white"/>
                </Fab>}
                <Fab 
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <CloseIcon color="white"/>
                </Fab>
              </div>
            </div>
            {!isDuplicar && (
            <div className={classes.contentHeaderSearch}>
              {utilizaPlugsign &&<MultiToggleButtons
                options={["Modelos criados", "Dados para assinatura"]}
                tabSelected={tabSelected}
                changeTabSelected={changeTabSelected}
                disabled={!switchValue}
                posicaoDesabilitada={[1]}
              />}
             {tabSelected === 0 && !readOnly && !compartilhar && <div className={classes.searchArea} onClick={()=> setMenuIsOpen(!menuIsOpen)}>
                <label className={classes.searchInputLabel}>
                  Pesquisar documentos
                </label> 
                <ReactSelect
                  autoFocus
                  menuIsOpen={menuIsOpen}
                  customStylesSelect={{
                    menu: {
                      maxHeight: '300px',
                    }
                  }}
                  className={classes.selectDocumento}
                  value={documento}
                  options={options || []}
                  onChange={handleDocumentoChange}
                  onInputChange={handleDocumentoSearch}
                  getOptionLabel={(option) => option.label}
                />
              </div>}
            </div>
            )}
            {loadingDocument && documento && (
              <CircularProgress color='primary' className={classes.loading} />
            )}
            {documento && !isDuplicar && camposEntrada.length > 0 && (
              <div className={classes.inputsContainer}>
                {camposEntrada.map((input) => (
                  <div
                    key={`input-document-${input.campo}`}
                    className={classes.inputLabel}
                  >
                    <label>{input.campoAmigavel}</label>
                    <InputForm
                      error={inputs && inputs[input.campo] ? 
                        inputs[input.campo]?.error : 
                        null
                      }
                      onChange={(e) => handleInputClick(e, input.campo)}
                      placeholder={input.campoAmigavel}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={classes.dialogContent}>
            {tabSelected === 0 ? (
              renderTabModelosCriados()
            ) : (
              <div className={classes.contratoContainer}>
                <ContratoForm
                  contratoData={formData}
                  handleDeleteProcedimentos={handleDeleteProcedimentos}
                  handleProcedimentos={handleProcedimentos}
                  handleChange={handleChange}
                  sujeitoAtencao={sujeitoAtencao}
                  readOnly={readOnly}
                  documentoSelecionado={modeloDocumento}
                />
              </div>
            )}
          </div>
          <div className={classes.dialogFooter}>
            {tabSelected === 0 ? (
            <Button 
              width="50%" 
              onClick={(documentoAssinatura || switchValue)? ()=> changeTabSelected(1) : handleClickPrint} 
              disabled={loadingButtonImpressao || noOptions}
              isLoading={loadingButtonImpressao}
            >
              <div className={classes.printButtonContent}>
              { !documentoAssinatura && !switchValue &&<PrintIcon color="white" size={20} />}
               { `${(documentoAssinatura || switchValue)? "Próximo":"Imprimir"}`}
              </div>
            </Button> ): (!readOnly &&
            <Button 
             width="50%" 
             onClick={handleClickEnviar} 
             disabled={disabled}
             isLoading={isLoading}
            >
             <div className={classes.printButtonContent}>
               Enviar documento
             </div>
            </Button>)
            }
          </div>
        </div>
        <ModalCompatilhar
          open={openModalConfirmacao} 
          onClose = {handleCloseModalConfirmacao}
          onClick={handleClickCompartilharWhatsApp}
        />
        <TokenDialog
          open={openSignQuestionDialog}
          handleClose={resetTokenDialogs}
          onCancel={handlePrintDocumentUnsigned}
          onOk={handlePrintDocumentSigned}
          isLoading={tokenDialogActionClicked}
        />
        <InputTokenDialog
          inputValue={signToken}
          onChange={(e) => setSignToken(e.target.value)}
          open={openTokenDialog}
          onCancel={handleCancelSignedPrint}
          onOk={handleSubmitSignToken}
          error={tokenError}
          loading={loadingButtonImpressao}
          canPrintSigned={profissionalSaudeStore.canPrintSigned}
          history={history}
        />
      </Dialog>
    </>
  )
});

const stores = ['atendimentoStore', 'prontuarioStore', 'configuracaoImpressaoStore', 'profissionalSaudeStore', 'receituarioStore', 'documentoStore', 'modeloDocumentoStore', 'sujeitoAtencaoStore'];
const PreencherDocumentoStyle = withStyles(style)(PreencherDocumento);
export default inject(...stores)(PreencherDocumentoStyle);
