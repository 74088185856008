import React, { useState, useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core';
import Modal from '../../../components/ui/Modal';
import { styles } from './styles';
import { Button } from '../../../components/ui/Buttons';
import CloseIcon from '../../../components/Icon/Close';
import { SelectForm } from '../../../components/Modal/Input';
import { ATENDIMENTO_MODAL_ETIQUETAS_SIZE, ATENDIMENTO_MODAL_ETIQUETAS_OPTIONS, ATENDIMENTO_MODAL_ETIQUETAS_TIPOS } from './constants/constants';
import { getAgeCategoryByBirthDate } from './utils/utils';
import Etiqueta from './components/Etiqueta';
import { useReactToPrint } from 'react-to-print';

const AtendimentoModalEtiquetas = ({ isOpen, agendamento, classes, onClose }) => {
  const [etiquetaSizeSelected, setEtiquetaSizeSelected] = useState(null);
  const [etiquetaTipo, setEtiquetaTipo] = useState(null);
  const [isInternacao, setIsInternacao] = useState(false);
  const etiquetaRef = useRef(null);
  const { contentWidth, contentHeight } = ATENDIMENTO_MODAL_ETIQUETAS_SIZE[etiquetaSizeSelected] || {};

  const handlePrint = useReactToPrint({
    content: () => etiquetaRef.current,
    documentTitle: "Print This Document",
    pageStyle: `@media print { @page { size: ${contentWidth}mm ${contentHeight}mm; padding: 0; margin: 0; }}`,
    onAfterPrint: () => onClose(),
    removeAfterPrint: true,
  });

  if (!isOpen) return null;

  const handleSelectEtiquetaSize = (event) => {
    event.preventDefault();
    const { value } = event?.target || {};
    setEtiquetaSizeSelected(value);
  };

  const getEtiquetaData = () => {
    const { procedimentos, sujeitoAtencao, convenio, id, profissionalSaude, internacao,tipo,data } = agendamento || {};
    const { nome, documento, dataNascimento,sexo } = sujeitoAtencao || {};
    return {
      agendamentoId: id,
      nome,
      documento,
      dataNascimento,
      convenio,
      procedimento: procedimentos?.map((procedimento) => procedimento.procedimento?.nome).join(', ') || '',
      profissionalSaude: profissionalSaude?.nome || '',
      internacao,
      tipo,
      sexo: sexo?.descricao,
      data,
    };
  };

  const handlePrintEtiquetas = (event) => {
    event.preventDefault();
    handlePrint();
  };
  
  const handleSelectEtiquetaTipo = (e)=>{
    e.preventDefault();
    setEtiquetaTipo(e?.target?.value)
  }

  useEffect(() => {
    if (isOpen) {
      const { dataNascimento } = agendamento.sujeitoAtencao || {};
      const { internacao } = agendamento || {}
      setIsInternacao(internacao?.id)

      if (dataNascimento) {
        const ageCategory = getAgeCategoryByBirthDate(dataNascimento);
        const etiqueta = ATENDIMENTO_MODAL_ETIQUETAS_OPTIONS.find(etiqueta => etiqueta.value === ageCategory);
        setEtiquetaSizeSelected(etiqueta?.value);
      }
    }
  }, [isOpen, agendamento]);

  const etiquetaSize = ATENDIMENTO_MODAL_ETIQUETAS_SIZE[etiquetaSizeSelected];
  const dadosEtiqueta = getEtiquetaData();

  return (
    <Modal isOpen={isOpen}>
      <div className={classes.container}>
        <div className={classes.header}>
          <span className={classes.title}>Imprimir etiqueta de identificação</span>
          <Button kind="transparent" shape="circle" onClick={onClose}>
            <CloseIcon />
          </Button>
        </div>
        <div className={classes.content}>
          <SelectForm
            elements={ATENDIMENTO_MODAL_ETIQUETAS_OPTIONS}
            value={etiquetaSizeSelected}
            onChange={handleSelectEtiquetaSize}
          />
          {isInternacao && <div className={classes.content}>
            <SelectForm
              elements={ATENDIMENTO_MODAL_ETIQUETAS_TIPOS}
              value={etiquetaTipo}
              onChange={handleSelectEtiquetaTipo}
            />
          </div>}
          <Button onClick={handlePrintEtiquetas}>Imprimir</Button>
        </div>
        <div style={{ display: 'none' }}>
          <Etiqueta ref={etiquetaRef} dadosAgendamento={dadosEtiqueta} etiquetaSize={etiquetaSize} etiquetaTipo={etiquetaTipo} etiquetaSizeSelected={etiquetaSizeSelected}/>
        </div>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(AtendimentoModalEtiquetas);
