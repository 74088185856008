import React from "react";
import { withStyles } from "@material-ui/core";
import Table from "../../../../../components/Table/Table";
import Dialog from "../../../../../components/Dialog/Dialog";
import CloseIcon from "../../../../../components/Icon/Close";
import { Button } from "../../../../../components/ui/Buttons";
import { columnsModal } from "../../constants";

const ModalPacientesDuplicados = ({
  classes,
  open,
  handleClose,
  pacientes,
  handleUnificar,
  changeCheckbox
}) => {

  return (
    <Dialog open={open} classes={{ paper: classes.Dialog }}>
      <div className={classes.headerModal}>
        <div className={classes.title}>Visualizar Paciente</div>
        <Button onClick={handleClose} shape="circle" bgColor="#FB7676">
          <CloseIcon />
        </Button>
      </div>
      <div className={classes.content}>
        <div className={classes.contentList}>
          <Table dados={pacientes} columns={columnsModal({changeCheckbox})} clickable={false} />
        </div>
        <div className={classes.contentButton}>
          <Button onClick={handleUnificar}>Unificar</Button>
        </div>
      </div>
    </Dialog>
  );
};

const styles = {
  Dialog: {
    display: "flex",
    flexDirection: "column",
    width: "600px",
    height: "400px",
    padding: "16px",
    alignItems: "center",
    overflow: "hidden",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "calc(100% - 60px)",
    width: "100%",
  },
  headerModal: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    padding: "10px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "600",
  },
  contentList: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  "table th, table td": {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  },
  contentButton: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    marginTop: "16px",
  },
};

export default withStyles(styles)(ModalPacientesDuplicados);
