import React from "react"
import MensagemAtendimento from "../../../../components/MensagemAtendimento"
import { CircularProgress, withStyles } from "@material-ui/core"
import { entradaTipos, mensagensColor } from "../../Tabs/Prontuario/Constants";

const ContentDocumentos = ({ classes, handleClickMessage, documentos, isLoading }) => {


    const profileDefault = require('../../../../assets/img/svg/img-profile-default.svg')

    const entradaTipoRight = (entrada) => {
        return entradaTipos.includes(entrada);
    };

    return (
        <div className={classes.container}>
            {isLoading ? (
                <div className={classes.circularProgress}>
                    <CircularProgress />
                </div>

            ) : (
                documentos.map((entrada, index) => (
                    <div
                        key={`ativo-${entrada.ativo}-${index}`}
                        className={classes.messagesContainer}
                    >
                        {entrada.showDate && (
                            <div className={classes.messagesDateContainer}>
                                <span className={classes.messagesDate}>
                                    {entrada.date}
                                </span>
                            </div>
                        )}
                        <MensagemAtendimento
                            classes={classes.MensagemAtendimento}
                            hora={entrada.hour}
                            text={entrada.texto}
                            color={mensagensColor[entrada.tipo]}
                            isRight={entradaTipoRight(entrada.tipo)}
                            image={entrada.urlFotoPerfil || profileDefault}
                            tipo={entrada.tipo}
                            urlDownload={entrada.urlDownload}
                            urlMiniatura={entrada.urlMiniatura}
                            urls={entrada?.urls}
                            entrada={entrada}
                            onClickImage={entrada.tipo === "IMAGEM" ? handleOpenModalImage : null}
                            onClickMessage={entradaTipoRight ? () => handleClickMessage(entrada) : null}
                        />
                    </div>
                ))
            )}
        </div>
    );
}

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        height: "100%",
        overflow: "auto",
        marginTop: "16px",
        width: "100%",
    },
    messagesContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    MensagemAtendimento: {
        background: "black"
    },
    messagesDateContainer: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        marginBottom: "15px",
        marginTop: "15px",
    },
    messagesDate: {
        border: "1px solid #969696",
        borderRadius: "20px",
        padding: "3px 15px",
        fontSize: "0.8rem !important",
        fontWeight: 400,
        color: "#333333",
    },
    circularProgress: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center'
    }
}

const ContentDocumentosWithStyles = withStyles(styles)(ContentDocumentos)
export default ContentDocumentosWithStyles