import React, { useEffect, useState } from "react";
import {
  Row,
  TextFieldSearchStyled,
  ContainerProcedimentos,
  ContentButtons,
  ContentTable,
} from "../../styles";
import Field from "../../components/Field";
import { InputForm } from "../../../../../components/Modal/Input";
import {
  findAllProcedimentosValor,
  findAllProfissionalSaudeComAgenda,
  findAllTuss35TerminologiaGrauParticipacao,
} from "../../../../../services/InternacaoService";
import { Button } from "../../../../../components/ui/Buttons";
import {
  columnsProcedimentosSolicitados,
  procedimentoDefault,
  tecnicasUtilizadas,
  viaAcessos,
} from "../../constants";
import Table from "../../../../../components/Table/Table";
import InputDateForm from "../../../../../components/Input/InputDateForm";
import { ReactSelect } from "../../../../../components/Select/SelectSearch";
import { inject } from "mobx-react";

const Procedimentos = ({ internacao, handleField, atendimentoStore }) => {
  const { procedimentos } = internacao;

  const [procedimentoNovo, setProcedimentoNovo] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [grausDeParticipacao, setGrausDeParticipacao] = useState([]);

  const {
    procedimento,
    profissionalSaude,
    sequencia,
    quantidade,
    dataRealizacao,
    viaAcesso,
    tecnicaUtilizada,
  } = procedimentoNovo || {};

  const { data } = atendimentoStore.objView || {};

  const unidadeLogada = JSON.parse(localStorage["_immortal|unidadeLogada"]);

  useEffect(() => {
    findAllGrausDeParticipacao();
    dadosPadrao();
  }, []);

  const dadosPadrao = () => {
    const sequencia = procedimentos.length > 0 ? procedimentos.length + 1 : 1;
    setProcedimentoNovo({
      ...procedimentoDefault,
      sequencia,
      dataRealizacao: data,
      quantidade: 1,
    });
  };

  const findAllGrausDeParticipacao = async () => {
    try {
      const variables = {
        pageableDto: {
          pageNumber: 0,
          pageSize: 30,
          sortDir: "ASC",
          sortField: "termo",
        },
      };
      const response = await findAllTuss35TerminologiaGrauParticipacao(
        variables
      );
      setGrausDeParticipacao(response.content);
    } catch (error) {
      console.log(error);
    }
  };

  const handleProcedimentoSelected = (value) => {
    setProcedimentoNovo((prevState) => ({
      ...prevState,
      procedimento: value,
    }));
  };

  const handleProcedimento = (value, field) => {
    setProcedimentoNovo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleInsereProfissionalEquipeMedica = (data) => {
    const equipeMedica = internacao.equipeMedica || [];
    const profissionalEquipeMedica = {
      id: null,
      profissionalSaude: data.profissionalSaude,
      procedimentos: [data.procedimento],
      tuss35TerminologiaGrauParticipacao: grausDeParticipacao.find(
        (grauParticipacao) => grauParticipacao.termo === "Cirurgião"
      ),
    };

    equipeMedica.push(profissionalEquipeMedica);

    handleField("equipeMedica", equipeMedica);
    const notification = {
      isOpen: true,
      message: "Profissional adicionado na equipe médica com sucesso!",
      variant: "success",
    };
    atendimentoStore.showAlertMessage(notification);
  };

  const handleAdicionaProcedimento = () => {
    const dadosProcedimentos = internacao.procedimentos;
    dadosProcedimentos.push(procedimentoNovo);

    dadosPadrao();

    handleField("procedimentos", dadosProcedimentos);
  };

  const handleDelete = (data) => {
    const dadosProcedimentos = internacao.procedimentos;
    const index = dadosProcedimentos.findIndex(
      (procedimento) => procedimento.id === data.id
    );

    dadosProcedimentos.splice(index, 1);
    const procedimentosSequencial = alteraSequencia(dadosProcedimentos);

    handleField("procedimentos", procedimentosSequencial);
  };

  const alteraSequencia = (procedimentos) => {
    setProcedimentoNovo((prevState) => ({
      ...prevState,
      sequencia: procedimentos.length + 1,
    }));

    const procedimentosSequencial = procedimentos.map((procedimento, index) => {
      return {
        ...procedimento,
        sequencia: index + 1,
      };
    });

    return procedimentosSequencial;
  };

  const handleEdit = (data) => {
    setProcedimentoNovo(data);
    setIsEdit(true);
  };

  const handleCancelarProcedimentoEdit = () => {
    dadosPadrao();
    setIsEdit(false);
  };

  const handleSalvarProcedimentoEdit = () => {
    const dadosProcedimentos = internacao.procedimentos;
    const index = dadosProcedimentos.findIndex(
      (procedimento) => procedimento.id === procedimentoNovo.id
    );

    dadosProcedimentos[index] = procedimentoNovo;

    handleField("procedimentos", dadosProcedimentos);
    setIsEdit(false);
    dadosPadrao();
  };

  const loadAllProcedimentos = async (search, loadedOptions, { page }) => {
    const variables = {
      nome: search,
      pageableDTO: {
        pageNumber: page,
        pageSize: 20,
        sortDir: "ASC",
        sortField: "nome",
      },
      ativo: true,
    };

    const response = await findAllProcedimentosValor(variables);
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadAllProfissional = async (search, loadedOptions, { page }) => {
    const variables = {
      search,
      pageableDTO: {
        pageNumber: page,
        pageSize: 20,
        sortDir: "ASC",
        sortField: "nome",
      },
      ativo: true,
      unidadeId: unidadeLogada.id,
    };

    const response = await findAllProfissionalSaudeComAgenda(variables);
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <ContainerProcedimentos>
      <Row>
        <Field label="Sequencia" width="10%">
          <InputForm
            value={sequencia}
            onChange={(e) => handleProcedimento(e.target.value, "sequencia")}
            disabled={true}
          />
        </Field>
        <Field label="Procedimento" width="30%">
          <TextFieldSearchStyled
            placeholder="Selecione"
            loadOptions={loadAllProcedimentos}
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.id}
            value={procedimento}
            onChange={handleProcedimentoSelected}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </Field>
        <Field label="Profissional" width="30%">
          <TextFieldSearchStyled
            placeholder="Selecione"
            loadOptions={loadAllProfissional}
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.id}
            value={profissionalSaude}
            onChange={(e) => handleProcedimento(e, "profissionalSaude")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </Field>
        <Field label="Data exame" width="20%">
          <InputDateForm
            iconposition="end"
            value={dataRealizacao}
            onChange={(e) => handleProcedimento(e, "dataRealizacao")}
          />
        </Field>
        <Field label="Quantidade" width="10%">
          <InputForm
            type="number"
            value={quantidade}
            onChange={(e) => handleProcedimento(e.target.value, "quantidade")}
          />
        </Field>
      </Row>
      <Row>
        <Field label="Via de acesso" width="20%">
          <ReactSelect
            placeholder="Selecione"
            value={viaAcesso}
            onChange={(value) => handleProcedimento(value, "viaAcesso")}
            options={viaAcessos}
            isClearable
          />
        </Field>
        <Field label="Técnica utilizada" width="20%">
          <ReactSelect
            placeholder="Selecione"
            value={tecnicaUtilizada}
            onChange={(value) => handleProcedimento(value, "tecnicaUtilizada")}
            options={tecnicasUtilizadas}
            isClearable
          />
        </Field>
        {isEdit ? (
          <ContentButtons>
            <Button
              bgColor="#FB7676"
              style={{ height: 40 }}
              padding={0}
              onClick={handleCancelarProcedimentoEdit}
            >
              Cancelar
            </Button>
            <Button
              bgColor={"#00B0AE"}
              style={{ height: 40 }}
              padding={0}
              onClick={handleSalvarProcedimentoEdit}
              disabled={!procedimento?.id || !profissionalSaude?.id}
            >
              Salvar
            </Button>
          </ContentButtons>
        ) : (
          <Button
            bgColor={"#707C97"}
            style={{ height: 40 }}
            padding={0}
            onClick={handleAdicionaProcedimento}
            disabled={!procedimento?.id}
          >
            Adicionar
          </Button>
        )}
      </Row>
      <ContentTable>
        <Table
          columns={columnsProcedimentosSolicitados({
            handleDelete,
            handleEdit,
            handleInsereProfissionalEquipeMedica,
          })}
          dados={procedimentos}
        />
      </ContentTable>
    </ContainerProcedimentos>
  );
};

export default inject("atendimentoStore")(Procedimentos);
