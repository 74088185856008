import Api from "../config/api";

export const findAllLeito = async (variables) => {
  const response = await Api.post("", {
    query: `
      query ($tipo: TipoLeitoInput, $ativo: Boolean, $search: String, $pageableDTO: PageableDTOInput) {
        findAllLeito(tipo: $tipo, ativo: $ativo, search: $search, pageableDTO: $pageableDTO) {
          last
          content {
            ativo
            id
            nome
            tipoLeito {
              descricao
            }
          }
        }
      }
    `,
    variables: variables,
  });

  if (response?.data?.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findAllLeito;
};

export const saveLeito = async (variables) => {
  const nomeMutation = variables.leito.id ? "updateLeito" : "createLeito";

  const response = await Api.post("", {
    query: `
      mutation ($leito: LeitoInput) {
        ${nomeMutation}(leito: $leito) {
          id
        }
      }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors;
  }
  return response.data.data[nomeMutation];
};

export const findByIdLeito = async (variables) => {
  const response = await Api.post("", {
    query: `
      query ($id: UUID) {
        findByIdLeito(id: $id) {
          ativo
          id
          nome
          tipoLeito {
            id
            descricao
            codigo
          }
          tuss18TaxasGases {
            id
            descricao
            codigoTermo
          }
          leitoConvenios {
            convenio {
              id
            }
            valor
          }
        }
      }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors;
  }
  return response.data.data.findByIdLeito;
};

export const inativarLeito  = async (id) => {
  const response = await Api.post("", {
    query: `
    mutation($id: UUID){
      inativarLeito(id: $id) {
        id
      }
    }
    `,
    variables: { id },
  });

  if (response.data.errors) {
    throw response.data.errors;
  }
  return response.data.data.inativarLeito;
};

export const ativarLeito  = async (id) => {
  const response = await Api.post("", {
    query: `
    mutation($id: UUID){
      ativarLeito(id: $id) {
        id
      }
    }
    `,
    variables: { id },
  });

  if (response.data.errors) {
    throw response.data.errors;
  }
  return response.data.data.ativarLeito;
};

export const findAllConvenio = async (variables) => {
  const response = await Api.post("", {
    query: `
      query ($searchDTO: SearchDTOInput, $pageableDTO: PageableDTOInput) {
        findAllConvenio(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
          last
          content {
            leitoConvenios {
              leito {
                id
              }
              valor
            }
            descricao
            id
          }
        }
      }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors;
  }
  return response.data.data.findAllConvenio;
};

export const findAllTuss18 = async (variables) => {
  const response = await Api.post("", {
    query: `
      query ($pageableDTO: PageableDTOInput, $search: String) {
        findAllTuss18(pageableDTO: $pageableDTO, search: $search) {
          last
          content {
            id
            codigoTermo
            descricao
          }
        }
      }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors;
  }
  return response.data.data.findAllTuss18;
};

export const findAllTipoLeito = async (variables) => {
  const response = await Api.post("", {
    query: `
      query ($pageableDTO: PageableDTOInput, $search: String) {
        findAllTipoLeito(pageableDTO: $pageableDTO, search: $search) {
          id
          descricao
          codigo
        }
      }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors;
  }
  return response.data.data.findAllTipoLeito;
};