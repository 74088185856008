import relatorioBanner from '../../assets/img/login-banners/app_health_banner_relatorio.jpg';
import gestaoEstoqueBanner from '../../assets/img/login-banners/app_health_banner_gestao_estoque.jpg';
import financeiroBanner from '../../assets/img/login-banners/app_health_banner_financeiro.jpg';
import controleSessaoBanner from '../../assets/img/login-banners/app_health_banner_controle_sessao.jpg';

export const loginBannersFullSize = [
  {
    src: relatorioBanner,
    alt: 'Propaganda do financeiro completo.',
    isClickable: true,
    redirectURL: 'https://api.whatsapp.com/send?phone=554499763397&text=Ol%C3%A1!%20Tenho%20interesse%20em%20saber%20mais%20sobre%20o%20Financeiro'
  },
  {
    src: gestaoEstoqueBanner,
    alt: 'Propaganda da gestão de estoque.',
    isClickable: true,
    redirectURL: 'https://api.whatsapp.com/send?phone=554499763397&text=Ol%C3%A1!%20Tenho%20interesse%20em%20saber%20mais%20sobre%20a%20Gest%C3%A3o%20de%20estoque'
  },
  {
    src: financeiroBanner,
    alt: 'Propaganda do faturamento.',
    isClickable: true,
    redirectURL: 'https://api.whatsapp.com/send?phone=554499763397&text=Ol%C3%A1!%20Tenho%20interesse%20em%20saber%20mais%20sobre%20o%20Faturamento%20dos%20conv%C3%AAnios'
  },
  {
    src: controleSessaoBanner,
    alt: 'Propaganda do controle de sessão.',
    isClickable: true,
    redirectURL: 'https://wa.me/554499763397?text=Ol%C3%A1!%20Tenho%20interesse%20em%20saber%20mais%20sobre%20o%20Controle%20de%20Sess%C3%B5es'
  },
];
