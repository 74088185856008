import colors from "../../../../template/Colors";

const styles = (theme) => ({
  contentGridContainer: {
    display: "grid",
    gridTemplateRows: "1fr auto",
    overflowY: "hidden",
    position: "relative",
    height:'100%',
  },
  contentMensagens: {
    display: "flex",
    overflowY: "hidden",
    minHeight: "200px",
  },
  messagesContainer: {
    display: "flex",
    flex: "100%",
    flexDirection: "column",
    paddingRight: 20,
  },
  messagesDateContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginBottom: "15px",
    marginTop: "15px",
  },
  messagesDate: {
    border: "1px solid #969696",
    borderRadius: "20px",
    padding: "3px 15px",
    fontSize: "0.8rem !important",
    fontWeight: 400,
    color: "#333333",
  },
  contentOptions: {
    display: "flex",
    position: "absolute",
    right: "65px",
    flexDirection: "column",
    bottom: "90px",
    alignItems: "flex-end",
    [theme.breakpoints.down("lg")]: {
      right: 35,
    },
  },
  buttonYellow: {
    minWidth: "40px",
    width: "40px !important",
    minHeight: "40px",
    height: "40px !important",
    margin: "4px 0",
    background: colors.commons.yellow,
    "&:hover": {
      background: "#e0a048",
    },
  },
  searchPaciente: {
    padding: "20px 40px",
  },
  contentNotFoundContainer: {
    display: "flex",
    justifyContent: "center",
  },
  notFoundTitle: {
    fontSize: "1.2rem",
    color: "#00ACA9",
    fontWeight: "normal",
  },
  contentLoadingContainer: {
    display: "flex",
    justifyContent: "center",
  },
  scroll: {
    display: "flex",
    flexDirection: "column",
  },
  message: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    paddingTop: '10px',
    FONTSIZE: '15PX',
    COLOR: '#505050'
  }
});

export default styles;
