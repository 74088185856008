import React from "react";
import InputForm from "../../../../../components/Input/InputForm";
import { withStyles } from "@material-ui/core";

const Observacao = ({ classes, handleField, internacao }) => {
    return (
        <div className={classes.content}>
            <label className={classes.label}>Observação</label>
            <InputForm
                placeholder="Digite a observação"
                value={internacao?.observacao}
                onChange={(e) => handleField("observacao",e.target.value,)}
                classes={{
                    input: classes.inputInfos,
                }}
                multiline
            />

        </div>
    )
}

const styles = {
    content: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingTop: "36px"
    },
    label: {
        fontSize: "14px",
        fontWeight: 600,
        color: "#868686"
    },
    inputInfos: {
        color: "#505050",
        background: "#F2F2F2",
        border: "0 !important",
        borderRadius: "8px",
        boxSizing: "border-box",
        fontSize: "14px",
        minHeight: "300px",
        height: '300px',
        maxHeight: "300px",
    },

}

export default withStyles(styles)(Observacao)