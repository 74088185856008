import Api from "../config/api";

export const findSujeitoAtencaoInternacaoByAgendamentoId = async (
  agendamentoId
) => {
  const response = await Api.post("", {
    query: `
      query ($agendamentoId: Long) {
        findSujeitoAtencaoInternacaoByAgendamentoId(agendamentoId: $agendamentoId) {
          id
          alta {
            dataAlta
            motivoAlta
            tuss39TerminologiaMotivoEncerramento {
              id
              codigoTermo
              termo
            }
          }
          sujeitoAtencao {
            id
            nome
            endereco {
              cep
              bairro
              municipio {
                id
                nome
                uf
              }
              nomeLogradouro
              numero
            }
            contato {
              telefonePrincipal
              telefoneSecundario
              email
            }
            documento
            rg
            nomeConjuge
            nomeMae
            nomePai
            sexo {
              id
              descricao
            }
            dataNascimento
            idade
            dadosConvenio {
              convenio {
                id
                descricao
              }
              numeroCarteira
              validadeCarteira
            }
            ocupacao {
              id
              descricao
            }
            estadoCivil {
              id
              descricao
            }
            origem {
              municipio {
                id
                nome
                uf
              }
            }
            quemIndicou
          }
          acompanhante {
            nome
            endereco {
              cep
              bairro
              municipio {
                id
                nome
                uf
              }
              nomeLogradouro
              numero
            }
            contato {
              telefonePrincipal
              telefoneSecundario
            }
            documento
            rg
            vinculo
          }
          responsavel {
            nome
            endereco {
              cep
              bairro
              municipio {
                id
                nome
                uf
              }
              nomeLogradouro
              numero
            }
            contato {
              telefonePrincipal
              telefoneSecundario
            }
            documento
            rg
            vinculo
          }
          dadosComplementares {
            convenio {
              id
              descricao
            }
            dataGuia
            senha
            dataValidadeSenha
            encaminhamento
            numeroGuia
            observacoesGerais
            observacoesMedico
            transferido
            profissionalAtendente {
              id
              nome
            }
            profissionalSolicitante {
              id
              nome
            }
            ambulante
          }
          leitosOcupados {
            dataInicioOcupacao
            dataFimOcupacao
            leito {
              id
              nome
            }
            tipoLeito {
              id
              descricao
              codigo
            }
            convenio {
              id
              descricao
            }
            id
          }
          procedimentos {
            id
            sequencia
            dataRealizacao
            quantidade
            procedimento {
              id
              nome
            }
            profissionalSaude {
              id
              nome
            }
            viaAcesso
            tecnicaUtilizada
          }
          cids {
            id
            sequencia
            cid10Subcategoria {
              id
              descricao
              codigo
              descricaoAbreviada
            }
          }
          observacao
          internacaoProdutos {
            id
            produto {
              id
              nome
              tipo
            }
          }
          equipeMedica{
            id
            procedimentos {
              id
              nome
            }
            profissionalSaude {
              id
              nome
            }
            tuss35TerminologiaGrauParticipacao {
              id
              codigoTermo
              termo
            }
          }   
          internacaoPrescricaoItens {
            id
            produto {
              id
              nome
            }
            quantidade
            tipoUso
            viaAdministracao
            condutaEnfermagem
            unidadeMedida {
              id
              descricao
            }
          }
        }
      }     
    `,
    variables: { agendamentoId },
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }
  return response.data.data.findSujeitoAtencaoInternacaoByAgendamentoId;
};

export const findByIdSujeitoAtencao = async (id) => {
  const response = await Api.post("", {
    query: `
      query ($id: UUID) {
        findByIdSujeitoAtencao(id: $id) {
          id
          nome
          nomeResponsavel
          documentoResponsavel
          endereco {
            cep
            bairro
            municipio {
              id
              nome
              uf
            }
            nomeLogradouro
            numero
          }
          contato {
            telefonePrincipal
            telefoneSecundario
            email
          }
          documento
          rg
          nomeConjuge
          nomeMae
          nomePai
          sexo {
            id
            descricao
          }
          dataNascimento
          idade
          dadosConvenio {
            convenio {
              id
              descricao
            }
            numeroCarteira
            validadeCarteira
          }
          ocupacao {
            id
            descricao
          }
          estadoCivil {
            id
            descricao
          }
          origem {
            municipio {
              id
              nome
              uf
            }
          }
          quemIndicou
        }
      }`,
    variables: { id },
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }
  return response.data.data.findByIdSujeitoAtencao;
};

export const findAllMunicipio = async (variables) => {
  const response = await Api.post("", {
    query: `
        query ($searchDTO: SearchDTOInput) {
        findAllMunicipio(searchDTO: $searchDTO) {
          last
          content {
            id
            nome
            uf
          }
        }
      }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.findAllMunicipio;
};

export const getInternacao = async () => {
  const response = await Api.post("", {
    query: `
      agendamentoPossuiInternacao(agendamentoId: Long) {
      }
    `,
    variables: { agendamentoId },
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.agendamentoPossuiInternacao;
};

export const findAllSexo = async (variables) => {
  const response = await Api.post("", {
    query: `
        query ($searchDTO: SearchDTOInput) {
        findAllSexo(searchDTO: $searchDTO) {
          last
          content {
            id
            descricao
          }
        }
      }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.findAllSexo;
};

export const findAllConvenio = async (variables) => {
  const response = await Api.post("", {
    query: `
        query ($searchDTO: SearchDTOInput) {
        findAllConvenio(searchDTO: $searchDTO) {
          last
          content {
            id
            descricao
          }
        }
      }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.findAllConvenio;
};

export const findAllCbo = async (variables) => {
  const response = await Api.post("", {
    query: `
        query ($searchDTO: SearchDTOInput) {
        findAllCbo(searchDTO: $searchDTO) {
          last
          content {
            id
            descricao
          }
        }
      }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.findAllCbo;
};

export const findAllEstadoCivil = async (variables) => {
  const response = await Api.post("", {
    query: `
        query ($searchDTO: SearchDTOInput) {
        findAllEstadoCivil(searchDTO: $searchDTO) {
          last
          content {
            id
            descricao
          }
        }
      }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.findAllEstadoCivil;
};

export const findProfissionalSaudeByUnidadeSemAgenda = async (variables) => {
  const response = await Api.post("", {
    query: `
      query ($unidadeId: Long, $pageableDTO: PageableDTOInput) {
        findProfissionalSaudeByUnidadeSemAgenda(unidadeId: $unidadeId, pageableDTO: $pageableDTO) {
          id
          nome
        }
      }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.findProfissionalSaudeByUnidadeSemAgenda;
};

export const findAllProfissionalSaudeComAgenda = async (variables) => {
  const response = await Api.post("", {
    query: `
      query($unidadeId: Long, $search: String, $pageableDTO: PageableDTOInput, $ativo: Boolean, $externos: Boolean) {
					findProfissionalSaudeByUnidadeComAgendaPage(unidadeId: $unidadeId, search: $search, pageableDTO: $pageableDTO, ativo: $ativo, externos: $externos) {
						last
						content {
							id
							nome
						}
					}
				}`,
    variables: variables,
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }
  return response.data.data.findProfissionalSaudeByUnidadeComAgendaPage;
};

export const findAllProcedimentos = async (variables) => {
  const response = await Api.post("", {
    query: `
      query($nome: String, $ativo: Boolean, $pageableDTO: PageableDTOInput) {
					findAllProcedimentos(nome: $nome, ativo: $ativo, pageableDTO: $pageableDTO) {
						last
						content {
							id
							nome
						}
					}
				}`,
    variables: variables,
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }
  return response.data.data.findAllProcedimentos;
};

export const findAllProcedimentosValor = async (variables) => {
  const response = await Api.post("", {
    query: `
      query($nome: String, $ativo: Boolean, $pageableDTO: PageableDTOInput) {
        findAllProcedimentos(nome: $nome, ativo: $ativo, pageableDTO: $pageableDTO) {
          last
          content {
            id
            nome
            convenioProcedimentos {
              convenio {
                id
              }
              procedimento {
                id
              }
              valor
            }
          }
        }
      }`,
    variables: variables,
  });

  if (response.data.errors) {
    throw Error(response.data.errors[0].message);
  }
  return response.data.data.findAllProcedimentos;
};

export const findAllCid10Subcategoria = async (variables) => {
  const response = await Api.post("", {
    query: `
    query FindAllCid10Subcategoria($searchDTO: SearchDTOInput) {
      findAllCid10Subcategoria(searchDTO: $searchDTO) {
        last
        content {
          descricao
          id
          codigo
          descricaoAbreviada
        }
      }
    }
      `,
    variables,
  });

  if (response.data.errors) {
    throw new Error(response.data.errors[0].message);
  }

  return response.data.data.findAllCid10Subcategoria;
};

export const saveInternacao = async (variables) => {
  const name = variables?.sujeitoAtencaoInternacao?.id
    ? "updateSujeitoAtencaoInternacao"
    : "createSujeitoAtencaoInternacao";

  const response = await Api.post("", {
    query: `
        mutation ($sujeitoAtencaoInternacao: SujeitoAtencaoInternacaoInput) {
        ${name}(sujeitoAtencaoInternacao: $sujeitoAtencaoInternacao) {
          id
        }
      }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }
  return response.data.data[name];
};

export const findAllLeito = async (variables) => {
  const response = await Api.post("", {
    query: `
    query ($pageableDTO: PageableDTOInput, $livre: Boolean, $search: String, $ativo: Boolean) {
      findAllLeito(pageableDTO: $pageableDTO, livre: $livre, search: $search, ativo: $ativo) {
        last  
        content {
          id
          nome 
          tipoLeito {
            id
            codigo
            descricao
          }
        }
      }
    }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.findAllLeito;
};

export const findAllMotivosAltaInternamento = async (variables) => {
  const response = await Api.post("", {
    query: `query FindAllMotivosAltaInternamento($search: String, $pageableDto: PageableDTOInput) {
      findAllMotivosAltaInternamento(search: $search, pageableDTO: $pageableDto) {
        content {
          codigo
          id
          nome
        }
      }
    }`, variables
  })
  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.findAllMotivosAltaInternamento;
}

export const findAllTuss39TerminologiaMotivoEncerramento = async (variables, query) => {
  const response = await Api.post('', 
    {
      query: `
        query ($termo: String, $pageableDTO: PageableDTOInput) {
          findAllTuss39TerminologiaMotivoEncerramento(termo: $termo, pageableDTO: $pageableDTO) {
            last
            content {
              id
              termo
              codigoTermo
            }
          }
        }
      `,
      variables: variables,
    });

    if (response.data.errors) {
      throw response.data.errors[0];
    }
  
    return response.data.data.findAllTuss39TerminologiaMotivoEncerramento;
}

export const findAllProduto = (variables) => Api.post("", {
  query: `
    query ($search: String, $ativo: Boolean, $pageableDTO: PageableDTOInput, $tipo: TissTipoDespesa) {
      findAllProduto(search: $search, ativo: $ativo, pageableDTO: $pageableDTO, tipo: $tipo) {
        last
        content {
          ativo
          id
          nome
          tipo
        }
      }
    }
  `,
  variables: variables
});

export const darAltaPaciente = async (variables) => {
  const response = await Api.post("", {
    query: `
      mutation ($sujeitoAtencaoInternamentoId: UUID, $alta: SujeitoAtencaoInternacaoAltaInput) {
        marcarComoAltaSujeitoAtencaoInternacao(sujeitoAtencaoInternamentoId: $sujeitoAtencaoInternamentoId, alta: $alta) {
          id
        }
      }
    `,
    variables: variables
  })

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.marcarComoAltaSujeitoAtencaoInternacao
}

export const findAllTuss35TerminologiaGrauParticipacao = async (variables) => {
  const response = await Api.post("", {
    query: `query FindAllTuss35TerminologiaGrauParticipacao($pageableDto: PageableDTOInput, $search: String, $codigoTermo: String, $termo: String) {
      findAllTuss35TerminologiaGrauParticipacao(pageableDTO: $pageableDto, search: $search, codigoTermo: $codigoTermo, termo: $termo) {
        content {
          codigoTermo
          dataFimImplantacao
          dataFimVigencia
          dataInicioVigencia
          id
          termo
        }
      }
    }`, variables
  })
  if (response.data.errors) {
    throw response.data.errors[0].message;
  }

  return response.data.data.findAllTuss35TerminologiaGrauParticipacao
}