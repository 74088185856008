import React, { useEffect, useState } from 'react'
import { Paper, Popover, withStyles } from '@material-ui/core'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'

import { Button } from '../../ui/Buttons'
import TabSelector from '../../TabSelector'
import Scroll from '../../InfiniteScroll/Scroll'
import CardNotification from './components/CardNotification'
import FilterTypesNotification from './components/Filter'

import NotificationIcon from '../../Icon/NotificationIcon'
import CloseIcon from '../../Icon/Close'
import FilterIcon from '../../Icon/FilterIcon'
import CheckmarkCircleIcon from '../../Icon/CheckmarkCircleIcon'
import { countUnreadNotifications } from '../../../services/NotificationsService'
import localStorageService, { ACCESS_TOKEN_KEY } from '../../../services/storage'

const NotificationModal = observer(({ classes, notificationStore, atendimentoStore, history }) => {
  const [accessToken, setAccessToken] = useState(null)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [anchorElFilter, setAnchorElFilter] = useState(null)
  const [quantityUnreadNotifications, setQuantityUnreadNotifications] = useState(0)

  const {
    openModalNotifications,
    notifications,
    closeModal,
    anchorEl,
    openFiltersNotifications,
  } = notificationStore

  const toggleAllNotificationsToRead = async () => {
    try {
      await notificationStore.readAllNotifications()
      await getQuantityUnreadNotifications()
    } catch (error) {
      console.error(error)
    }
  }

  const getQuantityUnreadNotifications = async () => {
    try {
      const quantity = await countUnreadNotifications()
      setQuantityUnreadNotifications(quantity)
    } catch (error) {
      console.error(error)
    }
  }

  const openModalListaEspera = (item) => {
    atendimentoStore.openModalListaEspera = true
    atendimentoStore.agendamentoListaEspera = item?.agendamento
    notificationStore.closeModal()
    history.replace('/atendimento')
  }

  const getAccessToken = async () => {
    const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY)
    setAccessToken(accessToken)
  }

  useEffect(() => {
    if (selectedTabIndex === 0) {
      notificationStore.paramsNotifications.visualizado = false
    } else {
      notificationStore.paramsNotifications.visualizado = null
    }
    notificationStore.findAllNotifications(true)
  }, [selectedTabIndex])

  useEffect(() => {
    getQuantityUnreadNotifications()
    getAccessToken()
  }, [])

  return (
    <>
        <Popover
          open={openModalNotifications}
          anchorEl={anchorEl}
          transition
          placement='bottom-end'
          keepMounted={false}
          style={{ zIndex: 999 }}
          onClose={closeModal}
          className={classes.popover}
        >
                <Paper className={classes.containerPaper}>
                  <header className={classes.header}>
                    <div>
                      <NotificationIcon />
                      <h1>Notificações</h1>
                    </div>
                    <Button kind='transparent' shape='circle' onClick={closeModal}>
                      <CloseIcon size='24' color='#000' />
                    </Button>
                  </header>
                  <div className={classes.content}>
                    <TabSelector
                      tabsOptions={[
                        {
                          label: 'Não lidas',
                          roundedTag: true,
                          tagColor: '#219A97',
                          tagText: quantityUnreadNotifications,
                        },
                        { label: 'Tudo' },
                      ]}
                      baseColor='#F2F2F2'
                      selectedColor='#FFF'
                      onSelect={index => setSelectedTabIndex(index)}
                      selectedTabIndex={selectedTabIndex}
                    />
                    <div className={classes.filters}>
                      <Button
                        kind='secondary'
                        size='mini'
                        bgColor='#4C5463'
                        onClick={() => notificationStore.toggleFiltersNotifications()}
                        ref={node => setAnchorElFilter(node)}
                        aria-owns={anchorElFilter ? 'menu-notifications-filters' : undefined}
                        aria-haspopup='true'
                      >
                        <FilterIcon color='#FFF' />
                        Filtrar
                      </Button>
                      {selectedTabIndex === 0 && (
                        <Button kind='transparent' size='mini' onClick={toggleAllNotificationsToRead}>
                          <CheckmarkCircleIcon color='#505050' size='16' />
                          Marcar todos como lido
                        </Button>
                      )}
                    </div>
                    <Scroll
                      loadMore={() => notificationStore.loadMoreNotifications()}
                      hasMore={notificationStore.hasMore}
                      pageStart={0}
                      initialLoad={false}
                      className={classes.scrollContainer}
                    >
                      <div className={classes.wrapperCards}>
                        {notifications.length === 0 && <h1>Não há notificações</h1>}
                        {notifications.map((item, index) => (
                          <CardNotification
                            key={index}
                            item={item}
                            accessToken={accessToken}
                            openModalListaEspera={() => openModalListaEspera(item)}
                            refetch={() => {
                              notificationStore.findAllNotifications(true)
                              getQuantityUnreadNotifications()
                            }}
                            />
                          ))}
                      </div>
                    </Scroll>
                  </div>
                </Paper>
              {/* </Grow> */}
            {/* )} */}
        </Popover>
      {openFiltersNotifications && openModalNotifications && (
        <FilterTypesNotification open={openFiltersNotifications} anchorEl={anchorElFilter} />
      )}
    </>
  )
})

const styles = {
  containerPaper: {
    width: '560px',
    padding: '24px',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    height: '75vh'
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > button': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '14px',
      gap: '4px',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    height: '67vh'
  },
  wrapperCards: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    '& > h1': {
      fontSize: '16px',
      color: '#505050',
      margin: 0,
      paddingTop: '16px',
      textAlign: 'center',
    },
  },
  scrollContainer: {
    height: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '8px',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
    '& h1': {
      fontSize: 24,
      fontWeight: 600,
      margin: 0,
    },
  },
  popover:{
    '& .MuiPopover-paper-183':{
      height: '75vh',
      minWidth: '560px',
      borderRadius: '8px',
      border: 'none',
      overflow: 'hidden'
    },
  }
}

const NotificationModalWithStyles = withStyles(styles)(NotificationModal)
export default inject('notificationStore', 'atendimentoStore')(NotificationModalWithStyles)
