import moment from "moment";
import React from "react";

const Consultas = ({ dados }) => {

  return (
    <>
      <div style={styles.titulo}>Assinatura de presença</div>
      <div style={styles.headerColumn}>
        <div >
          <span style={styles.span}>Paciente:</span> {dados?.dadosRelatorio?.sujeitoAtencao?.nome}
        </div>
        <div>
          <span style={styles.span}>Telefone:</span> {dados?.dadosRelatorio?.sujeitoAtencao?.telefone}
        </div>
        <div >
          <span style={styles.span}>Convênio:</span> {dados?.dadosRelatorio?.sujeitoAtencao?.convenio}
        </div>
        <div >
        <span style={styles.span}> Hora inicio:</span> {dados?.dadosRelatorio?.horaInicio}
        </div>
      </div>
      <div style={styles.headerColumn}>
        <div >
          <span style={styles.span}>Profissional:</span> {dados?.dadosRelatorio?.profissionalSaude?.nome}
        </div>
        <div >
          <span style={styles.span}>Data:</span> {moment(dados?.dadosRelatorio?.data).format("DD/MM/YYYY")}
        </div>
      </div>
      <div style={styles.containerAssinatura}>
        <div style={styles.titulo}>Assinatura</div>
        <div className={styles.assinatura}>
          <img height={70} src={dados?.dadosRelatorio?.assinaturaPresenca} alt="assinatura" />
        </div>
      </div>
    </>
  );
};

const styles = {
  headerColumn: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: "10px",
    margin: "5px"
  },
  containerAssinatura: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    flexDirection:"column",
    borderBottom:"1px solid #505050",
    alignItems:"center",
  },
  span: {
    fontWeight: 700
  },
  titulo: {
    marginLeft: "0px",
    marginBottom: "10px",
    marginTop: "5px",
    paddingBottom: "5px",
    textAlign: "center",
    fontWeight: 700,
  },
  assinatura:{
    textAlign: "center",
    fontWeight: 700,
  }

};

export default Consultas;
