import React from 'react';
import { render } from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import './app/config/moment';

import "./app/assets";
import Routes from "./routes";

import stores from './app/stores/Root.store';

import { initializeFirebase } from './push-notification';
import { initializeGmt } from './app/config/config';

initializeFirebase();
initializeGmt();

const app = document.getElementById('app');
window.stores = stores;

window._digisac = {
  id: "0e270c3e-0cc8-4407-b45c-f97e1f2a4431",
  account: "4209728P16052024032926",
  autoOpen: 0,
  hideWhenOffline: 0,
  d: "apphealth",
  ts: new Date().getTime(),
  ref: encodeURIComponent(document.URL)
}

const ttChatLoaderS = document.createElement('script');
ttChatLoaderS.src = "https://webchat.digisac.app/embedded.js";
document.body.appendChild(ttChatLoaderS);

render(
  <Provider {...stores}>
    <HashRouter>
      <Routes />
    </HashRouter>
  </Provider>,
  app
);

if (process.env.NODE_ENV !== 'production') {
  require('mobx-logger').enableLogging({
    action: true,
    reaction: false,
    transaction: true,
    compute: false,
  });
}
