import moment from "moment";
import classNames from "classnames";
import { inject } from "mobx-react";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import styles from "./ConsultasPagesStyle";
import Table from "../../../components/Table/Table";
import PrintIcon from "../../../components/Icon/Print";
import AddIcon from "../../../components/Icon/AddIcon";
import { columnsConsultas, situacoes } from "./Constants";
import ButtonLong from "../../../components/Button/ButtonLong";
import { TextFieldSearch } from "../../../components/TextField";
import PageTitle from "../../../components/PageTitle/PageTitle";
import PopperCheck from "../../../components/Popper/PopperCheck";
import PeriodDate from "../../../components/PeriodDate/PeriodDate";
import * as AgendamentoService from "../../../services/AgendamentoService";
import { findAllProfissionalSaude } from "../../../services/ProfissionalSaudeService";
import ImpressaoHtml from "../../../components/Impressao/ImpressaoHtml";
import Consultas from "../../../template/pdf/sujeito-atencao/Consultas";
import { Button } from "../../../components/ui/Buttons";
import ModalAssinaturaPresenca from "./components/ModalAssinaturaPresenca";
import AssinaturaPresenca from "../../../template/pdf/sujeito-atencao/AssinaturaPresenca"
import { findBase64ByAgendamentoId } from "../../../services/AssinaturaPresencaService";

const primeiroDiaDoMes = moment()
  .startOf("month")
  .format("YYYY-MM-DD");
const ultimoDiaDoMes = moment()
  .endOf("month")
  .format("YYYY-MM-DD");

const filterAgendamentoDefault = {
  status: situacoes.map(item => item.value),
  dataInicio: primeiroDiaDoMes,
  dataFim: ultimoDiaDoMes,
  profissionalSaude: null,
  pageableDTO: {
    sortDir: "DESC",
    sortField: "data",
  },
};

const ConsultasPage = ({
  sujeitoAtencaoStore,
  atendimentoStore,
  configuracaoImpressaoStore,
  usuarioStore,
  history,
  classes,
  match: { params },
}) => {
  const [
    atendimentosSujeitoSelecionado,
    setAtendimentosSujeitoSelecionado,
  ] = useState([]);
  const [filterAgendamento, setFilterAgendamento] = useState(
    filterAgendamentoDefault
  );
  const [status, setStatus] = useState(situacoes);
  const [loading, setLoading] = useState(false);
  const [isPrintMustache, setIsPrintMustache] = useState(false);
  const [dadosRelatorio, setDadosRelatorio] = useState(null);
  const [openModalAssinatura,setOpenModalAssinatura]=useState(false)
  const [dadosAgendamento,setDadosAgendamento]=useState(null)
  const [isLoading,setIsLoading]=useState(false)
  const [isPrint,setIsPrint]=useState(false)

  const ref = useRef(null);

  useEffect(() => {
    configuracaoImpressaoStore.getConfig('OUTROS')
    loadAgendamentos();
  }, []);

  useEffect(() => {
    const { state } = history?.location || null
    if (state?.assinaturaPresenca) {
      if (state.presencaAssinada) {
        loadBase64ToCanvas(ref, state.base64)
      }
      setOpenModalAssinatura(true)
    }
  }, [history]);


  useEffect(() => {
    !filterAgendamento.dataInicio && !filterAgendamento.dataFim && handleFiltrar();
  }, [filterAgendamento.dataInicio]);


  const loadBase64ToCanvas = (canvas, base64String) => {
    const ctx = canvas.getContext("2d");
    const image = new Image();

    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
    };

    image.src = base64String;
  }


  const loadAgendamentos = async () => {
    try {
      setLoading(true);
      const { dataFim, dataInicio, profissionalSaude } = filterAgendamento;
      const status =
        filterAgendamento.status.length > 0 ? filterAgendamento.status : null;

      const filters = {
        sujeitoAtencaoId: params.id,
        ...(dataFim && { dataFim }),
        ...(dataInicio && { dataInicio }),
        ...(profissionalSaude?.id && { profissinalId: profissionalSaude?.id }),
        ...(status && { status }),
        pageableDTO: filterAgendamento.pageableDTO,
      };

      const response = await AgendamentoService.findAgendamentosByIdSujeitoAtencaoFilters(
        filters
      );
      setAtendimentosSujeitoSelecionado(response || []);
    } catch {
      sujeitoAtencaoStore.openNotification(
        "Erro ao carregar agendamentos do paciente",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClickNovo = async () => {
    const { objView } = sujeitoAtencaoStore;
    const { dadosConvenio, id, telefonePrincipal, telefonePrincipalDDI } = objView || {};
    const { convenio, numeroCarteira, validadeCarteira } = dadosConvenio || {};
    const { id: idConvenio, descricao } = convenio || {};

    history.push("/atendimento");
    await atendimentoStore.loadAgendamentoTipo();
    atendimentoStore.objView = {
      ...atendimentoStore.objView,
      data: moment().format("YYYY-MM-DD"),
      tipo: atendimentoStore.tipoPadrao(),
      situacao: "AGENDADO",
      horaInicio: moment().format("HH:mm"),
      horaFim: atendimentoStore.configVigente?.duracao && moment()
        .add(atendimentoStore.configVigente.duracao, "minutes")
        .format("HH:mm"),
      sujeitoAtencao: {
        ...objView,
        id: id,
        telefone: telefonePrincipal,
        telefoneDDI: telefonePrincipalDDI,
      },
      convenio: idConvenio && {
        ...convenio,
        value: idConvenio,
        label: descricao
      },
      numeroCarteira,
      validadeCarteira,
    };
    atendimentoStore.open = true;
  };

  const handleChangeInputDate = (dataInicio, dataFim) => {
    setFilterAgendamento({
      ...filterAgendamento,
      dataInicio: moment(dataInicio).format("YYYY-MM-DD"),
      dataFim: moment(dataFim).format("YYYY-MM-DD"),
    });
  };

  const handleChangeFiltersData = (value) => {
    setFilterAgendamento({
      ...filterAgendamento,
      dataInicio: moment(value.dataInicial).format("YYYY-MM-DD"),
      dataFim: moment(value.dataFinal).format("YYYY-MM-DD"),
    });
  };

  const handleFiltrar = () => {
    setAtendimentosSujeitoSelecionado([]);
    loadAgendamentos(filterAgendamento);
  };

  const handleVerTodos = () => {
    setFilterAgendamento({
      ...filterAgendamento,
      dataInicio: null,
      dataFim: null,
    })
  };

  const handleClickOrdenar = (value) => {
    const { sortField, sortDir } = filterAgendamento;

    const sortDirField =
      sortField !== value ? "ASC" : sortDir === "ASC" ? "DESC" : "ASC";

    setFilterAgendamento({
      ...filterAgendamento,
      pageableDTO: {
        sortDir: sortDirField,
        sortField,
      },
    });
    loadAgendamentos();
  };

  const changeCheckbox = (item, posicao) => {
    let situacao = status;
    situacao[posicao] = {
      ...item,
      checked: !item.checked,
    };

    const statusChecked = situacao
      .filter((item) => item.checked)
      .map((item) => item.value);

    setStatus([...situacao]);
    setFilterAgendamento({
      ...filterAgendamento,
      status: statusChecked,
    });
  };

  const getQuantidadeStatus = () => {
    const statusChecked = status.filter((item) => item.checked);
    const quantidadeStatusChecked = statusChecked.length;

    return quantidadeStatusChecked === 9
      ? "Todos"
      : `${quantidadeStatusChecked} selecionados`;
  };

  const handleChangeProfissional = (profissionalSaude) => {
    setFilterAgendamento({
      ...filterAgendamento,
      profissionalSaude
    })

  };


  const handleProfissionalSaudeLoadOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return handleLoadMoreOptions({
      search,
      loadedOptions,
      data: { page },
    });
  };

  const handleLoadMoreOptions = async ({ search, data }) => {
    let searchDTO = {
      pageSize: 30
    };

    if (data.searchDTO) {
      searchDTO = {
        ...data.searchDTO,
      };
    }

    const response = await findAllProfissionalSaude({
      pageNumber: data.page,
      search,
      ...searchDTO,
    });

    const { content, last } = response.data.data.findAllProfissionalSaude;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: data.page + 1,
      },
    };
  };

  const handleImprimir = async () => {
    const unidadeLogada = await usuarioStore.getUnidadeAtual();
    const dados = atendimentosSujeitoSelecionado.map(item => {
      return {
        situacao: item?.situacao || "",
        data: item.data,
        horaInicio: item.horaInicio,
        horaFim: item.horaFim,
        sala: item.sala?.nome || "",
        profissionalSaudeNome: item?.profissionalSaude?.nome || "",
        solicitante: item?.solicitante?.nome || "",
        assinaturaPresenca: item?.assinaturaPresenca,
      }
    })
    setDadosRelatorio({
      unidade: unidadeLogada.nome,
      dadosRelatorio: dados,
    });
    handlePrintListaAgendamento();
  };

  const handlePrintListaAgendamento = () => {
    setIsPrintMustache(true);
  };

  const handlePrintAssinaturaPresenca = () => {
    setIsPrint(true);
  };
  const handleCloseModal = () => {
    setOpenModalAssinatura(false)
  }

  const handleClickTable = (e) => {
    try {
      setIsLoading(true)
      atendimentoStore.loadAgendamento(e)
      setOpenModalAssinatura(true)
      setDadosAgendamento({ idAgendamento: e })
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const handlePrint = async (e) => {
    const { objView } = atendimentoStore;
    const unidadeLogada = await usuarioStore.getUnidadeAtual();
    const dados = atendimentosSujeitoSelecionado.find(item => item.id === e)
    const image = await findBase64ByAgendamentoId({agendamentoId:e})
    const sujeitoAtencaoDados = {
      sujeitoAtencao: {
        nome: objView?.sujeitoAtencao?.nome,
        telefone: objView?.sujeitoAtencao?.contato?.telefonePrincipal,
        convenio: objView?.sujeitoAtencao?.dadosConvenio?.convenio?.descricao
      },
    }

    setDadosRelatorio({
      unidade: unidadeLogada.nome,
      dadosRelatorio: {
        ...dados, 
        ...sujeitoAtencaoDados,
        assinaturaPresenca: `data:image/png;base64,${image}`
      },
    });
    handlePrintAssinaturaPresenca();
  }

  return (

    <div className={classes.tableContainerConsultas}>
      <PageTitle title="Paciente - Consultas" />
      <div className={classes.contentHeaderConsultas}>
        <div className={classes.titleHeaderConsultas}>
          Confira as consultas agendadas
        </div>
        <div className={classes.contentButtonHeaderConsultas}>
          <ButtonLong colorCustom="yellow" onClick={handleImprimir} noShadow>
            Imprimir agenda
          </ButtonLong>
          <ButtonLong colorCustom="green" onClick={handleClickNovo} noShadow>
            Nova consulta
          </ButtonLong>
        </div>
      </div>

      <div className={classes.contentFiltersConsultas}>
        <div className={classNames(classes.field, classes.fieldProfissional)}>
          <span className={classes.tituloFiltros}>Profissional da saúde:</span>
          <TextFieldSearch
            placeholder=""
            loadOptions={handleProfissionalSaudeLoadOptions}
            withPaginate
            value={filterAgendamento.profissionalSaude}
            onChange={handleChangeProfissional}
            debounceTimeout={300}
            getOptionLabel={option => option.nome}
            additional={{
              page: 0,
            }}
            classNotched={classes.notchedOutlineSearch}
            classInput={classes.inputSearch}
          />
        </div>
        <div className={classes.field}>
          <span className={classes.tituloFiltros}>Data:</span>
          <PeriodDate
            filter={{
              dataInicial: filterAgendamento.dataInicio,
              dataFinal: filterAgendamento.dataFim,
            }}
            setFilter={handleChangeFiltersData}
            onChangeDate={handleChangeInputDate}
            hiddenButtonAplicar
          />
        </div>
        <div className={classes.field}>
          <span className={classes.tituloFiltros}>Status:</span>
          <PopperCheck
            ref={ref}
            options={status}
            changeCheckbox={changeCheckbox}
            label={getQuantidadeStatus()}
          />
        </div>
        <div className={classes.contentButtonVerTodos}>
          <Tooltip title="Ver todos agendamentos" placement="top">
            <ButtonLong colorCustom="transparencyHover" onClick={handleVerTodos} noShadow>
              Ver todos
            </ButtonLong>
          </Tooltip>
        </div>
        <div className={classes.contentButtonFilter}>
          <ButtonLong colorCustom="gray" onClick={handleFiltrar} noShadow>
            Filtrar
          </ButtonLong>
        </div>
      </div>
      <div className={classes.contentTableConsultas}>
        <Table
          dados={atendimentosSujeitoSelecionado}
          columns={columnsConsultas}
          ordenarTabela={{
            sortField: filterAgendamento.pageableDTO.sortField,
            sortDir: filterAgendamento.pageableDTO.sortDir,
          }}
          comOrdenacao
          handleClickOrdenar={handleClickOrdenar}
          clickable
          handleClick={(e) => handleClickTable(e)}
        />
        {loading && (
          <div className={classes.contentLoading}>
            <CircularProgress />
          </div>
        )}
      </div>
      <div className={classes.contentButtonCircleConsultas}>
        <Button
          shape='circle'
          bgColor='#F9BE73'
          onClick={handleImprimir}
        >
          <PrintIcon />
        </Button>
        <Button
          shape='circle'
          bgColor='#26ACA9'
          onClick={handleClickNovo}
        >
          <AddIcon />
        </Button>
      </div>
      {isPrintMustache && <ImpressaoHtml
        isPrintMustache={isPrintMustache}
        sujeitoAtencaoId={params.id}
        handlePrintMustache={() => { setIsPrintMustache(false) }}
        htmlStringComponent={
          <Consultas
            dados={dadosRelatorio}
          />
        }
      />}
      {isPrint && <ImpressaoHtml
        isPrintMustache={isPrint}
        sujeitoAtencaoId={params.id}
        handlePrintMustache={() => { setIsPrint(false) }}
        htmlStringComponent={
          <AssinaturaPresenca
            dados={dadosRelatorio}
          />
        }
      />}
      {
        openModalAssinatura &&
        <ModalAssinaturaPresenca
          openModalAssinaturaPresenca={openModalAssinatura}
          handleClose={handleCloseModal}
          dadosAgendamento={dadosAgendamento}
          handleImprimir={(e) => handlePrint(e)}
        />
      }
    </div>
  );
};

const ConsultasPageWithStyles = withStyles(styles)(ConsultasPage);
export default inject(
  "sujeitoAtencaoStore",
  "atendimentoStore",
  "configuracaoImpressaoStore",
  "usuarioStore",
)(ConsultasPageWithStyles);
