
const styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: '16px',
    justifyContent: 'space-between',
    height: '100%'
  },
  header: {
    display: 'flex',
    padding: '8px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center'
  },
  dialog: {
    width: '699px',
    height: '536px',
    overflow: 'hidden',
    padding:'12px'
  },
  icons: {
    display: "flex",
    gap: "10px"
  },
  title: {
    fontSize: '24px',
    fontWeight: '700',
    color: '#505050'
  },
  contentDadosPaciente: {
    display: "flex",
    flexDirection: "column"
  },
  inputInfos: {
    color: "#505050",
    borderRadius: '8px',
    background: '#f2f2f2'
  },
  label: {
    fontSize: '12px',
    color: '#505050',
    fontWeight: '500'
  },
  contentDados: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '16px',
    justifyContent: 'center',
    height: '145px'
  },
  dadosPaciente: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '600px'
  },
  contentLabelAssine: {
    display: "flex",
    flexDirection: 'column',
  },
  labelAssine: {
    color: '#505050',
    display: 'flex',
    fontSize: '15px',
    fontWeight: '500',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  circularProgress: {
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonSave: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent:"space-between"
  },
  contentButtons:{
    display:"flex",
    flexDirection:"row",
  },
  buttonLimpar:{
    width: '192px',
    backgroundColor:"#707C97"
  },
  buttonSalvar:{
    width: '276px',
  }
}
export default styles