import { inject } from "mobx-react";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import { Button } from "../../../../../components/ui/Buttons";
import { orcamentoDefault } from "../../../../../stores/Orcamento.store";
import ModalOrcamentoOdontologico from "../Modal/Orcamento/ModalOrcamentoOdontologico";
import { getProfissionalSaudeLogado } from "../../../../../services/UsuarioService";
import { tabsProcedimentos } from "../../../../../stores/Odontograma.store";

const Orcamento = observer(
  ({
    classes,
    history,
    odontogramaStore,
    prontuarioStore,
    sujeitoAtencaoStore,
    orcamentoStore,
    tabSelected,
  }) => {
    const { sujeitoAtencaoSelected, agendamentoSelected } = prontuarioStore;

    const [openModalOrcamento, setOpenModalOrcamento] = useState(false);

    const handleOpenOrcamentos = () => {
      sujeitoAtencaoStore.reset();
      sujeitoAtencaoStore.changeId(sujeitoAtencaoSelected.id);
      history.push(
        `/sujeito-atencao/edit/${sujeitoAtencaoSelected.id}/orcamentos`
      );
    };

    const handleOpenModalOrcamento = async () => {
      const { dadosConvenio } = sujeitoAtencaoSelected || {};
      const { convenio } = dadosConvenio || {};

      const profissionalLogado = await getProfissionalSaudeLogado();

      orcamentoStore.orcamento = {
        ...orcamentoDefault,
        ...(convenio?.id && {
          convenio: {
            ...convenio,
            label: convenio?.descricao,
            value: convenio?.id,
          },
        }),
        profissionalSaude: profissionalLogado,
      };
      setOpenModalOrcamento(true);
    };

    return (
      <div className={classes.content}>
        <Button bgColor="#707C97" onClick={handleOpenOrcamentos}>
          Ver orçamentos
        </Button>
        <Button bgColor="#5462E0" onClick={handleOpenModalOrcamento}>
          Emitir orçamento
        </Button>
        {openModalOrcamento && (
          <ModalOrcamentoOdontologico
            openModalOrcamento={openModalOrcamento}
            setOpenModalOrcamento={setOpenModalOrcamento}
            sujeitoAtencaoSelected={sujeitoAtencaoSelected}
            loadList={() =>
              odontogramaStore.findAllProcedimentoOdontologicoOrcado(
                { sortDir: "DESC", sortField: "dataHoraLancamento" },
                tabSelected === tabsProcedimentos.COM_ORCAMENTO
              )
            }
            agendamentoSelected={agendamentoSelected}
          />
        )}
      </div>
    );
  }
);

const styles = {
  content: {
    display: "flex",
    gap: "8px",
  },
};

const OrcamentoWithStyles = withStyles(styles)(Orcamento);
export default inject(
  "odontogramaStore",
  "extratoStore",
  "prontuarioStore",
  "sujeitoAtencaoStore",
  "orcamentoStore",
  "unidadeStore"
)(OrcamentoWithStyles);
