import React from "react";
import { action, observable } from "mobx";
import moment from "moment";
import Api from "../../config/api";
import string from "../../utils/string";
import { applyCurrencyMask } from "../../utils/CurrencyMask";
import BaseStore from "../Base.store";
import getValorTipoConvenio from "../../utils/getValorTipoConvenio";
import { findAgendamentoTipoByAtivo } from "../../services/AgendamentoTipoService";
import getStatusFinanceiro from "../../utils/getStatusFinanceiro";
import { modalConfirmacaoDefault } from "../Atendimento.store";
import { createTitulo, FindRelatorioTicketMedioByUnidade, linkFaturamentoAgendamento, linkNotaAgendamento, pesquisaVoucherAssociado, vincularVoucherAgendamento } from "../../services/FinanceiroService";
import { faturarControleSessao } from "../../services/ControleSessaoService";
import SuccessIcon from "../../components/Icon/SuccessIcon";

export const TituloTipo = {
  DESPESA: "DESPESA",
  RECEITA: "RECEITA"
};

export const TituloTipoRepeticao = {
  NAO_REPETIR: "NAO_REPETIR",
  PARCELAR: "PARCELAR",
  REPETIR: "REPETIR"
};

export const TituloPeriodicidade = {
  ANUALMENTE: "ANUALMENTE",
  DIARIAMENTE: "DIARIAMENTE",
  MENSALMENTE: "MENSALMENTE",
  QUINZENALMENTE: "QUINZENALMENTE",
  SEMANALMENTE: "SEMANALMENTE"
};

export const tabsParcelamento = {
  NAO_REPETIR: 0,
  PARCELAR: 1,
  REPETIR: 2,
};

export const tiposDesconto = [
  { id: 0, value: "VALOR" },
  { id: 1, value: "PERCENTIL" },
];

export const tiposRepeticao = ["NAO_REPETIR", "PARCELAR", "REPETIR"];

export const defaultTitulo = {
  id: null,
  nome: "",
  valor: null,
  dataVencimento: null,
  dataPagamento: null,
  procedimentos: [],
  tipo: null,
  tipoDesconto: null,
  desconto: "",
  convenio: null,
  sujeitoAtencao: null,
  profissionalSaude: null,
  centroCusto: "",
  categoriaFinanceira: "",
  contaBancaria: "",
  dataPagamentoEntrada: "",
  totalParcelas: "",
  periodicidade: "",
  quantidade: "",
  valorEntrada: "",
  procedimentosControleSessao: {},
  codigoTransicao: "",
};

const defaultSearchDTO = {
  search: "",
  dataInicial: moment()
    .startOf("month")
    .format("YYYY-MM-DD"),
  dataFinal: moment()
    .endOf("month")
    .format("YYYY-MM-DD"),
  convenioId: null
};

const defaultModalState = {
  pago: false,
  tipoRepeticao: TituloTipoRepeticao.NAO_REPETIR,
  valorParcela: null,
  tituloParcelaList: [],
  tituloParcelaExcluidaList: [],
};

const defaultTotais = {
  totalReceita: 0,
  totalDespesa: 0,
  totalTicketMedio:0,
  total: 0,
  totalReceitaFormatted: "R$ 0,00",
  totalDespesaFormatted: "R$ 0,00",
  totalTicketMedioFormatted: "R$ 0,00",
  totalFormatted: "R$ 0,00"
};

const modalConfirmarExcluirDefault = {
  open: false,
  buttons: {
    labelPrimaryButton: "Cancelar",
    handlePrimaryButton: () => { },
    labelSecondButton: "Continuar",
    handleSecondButton: () => { },
    disabledSecondButton: false,
  }
}

const defaultTituloParcela = {
  id: null,
  valor: null,
  dataVencimento: null,
  dataPagamento: null,
  formaPagamento: null,
  tipoDesconto: null,
  desconto: null,
  subtotal: null,
  contaBancaria: null,
  nome: "",
  chavePublica: "",
};

export const defaultErrors = {
  nome: false,
  dataVencimento: false,
  valor: false,
  titulo: false,
  totalParcelas: false,
  parcelas: false,
  periodicidade: false,
  quantidade: false,
};

export default class ExtratoStore extends BaseStore {
  usuarioStore = null;
  filtroHeaderStore = null;
  @observable currentPage = null;
  @observable totalElements = 0;
  @observable numberOfElements = 0;
  @observable editMode = false;
  @observable expanded = true;
  @observable loading = true;
  @observable saving = false;
  @observable opening = false;
  @observable open = false;
  @observable printingRecibo = false;
  @observable dadosParaImpressaoRecibo = [];
  @observable profissionaisSaudeList = [];
  @observable centroCustoList = [];
  @observable categoriaList = [];
  @observable formasPagamento = [];
  @observable contaBancariaList = [];
  @observable errors = defaultErrors;
  @observable totais = {
    ...defaultTotais
  };
  @observable modalState = {
    ...defaultModalState
  };
  @observable tituloList = [];
  @observable titulo = {
    ...defaultTitulo
  };
  @observable searchDTO = {
    ...defaultSearchDTO
  };
  @observable relatorioCsv = "";

  @observable tipoConsultaAgendamento = null;

  @observable tabs = null;

  @observable listTab = [];

  @observable tiposFinanceiro = [{ id: "RECEITA", tipo: "RECEITA" }, { id: "DESPESA", tipo: "DESPESA" }]

  @observable loadingButtonActionModal = false;

  @observable dadosTituloPrint = null;
  @observable isPrintMustacheModal = false;
  @observable tabSelectedParcelamento = tabsParcelamento.NAO_REPETIR;
  @observable filterBy = 'DATA_VENCIMENTO';
  @observable defaultTabControleSessao = false
  @observable procedimentos = [];
  @observable agendamento = null;
  @observable tituloParcela = defaultTituloParcela;
  @observable openModalSalvoSucesso = false;
  @observable isAgendamento = false;
  @observable loadingModalParcela = false;
  @observable onlyReadParcelas = false;
  @observable isTituloVinculaAgendamento = false;
  @observable modalConfirmarExcluir = modalConfirmarExcluirDefault;
  @observable vouchersAssociado = null;
  @observable loadingVouchers = false;
  @observable valorTicketMedio = 0;


  constructor(rootStore) {
    super();
    this.atendimentoStore = rootStore.atendimentoStore;
  };

  @action async findAll(searchDTO = {}, pageableDTO = { sortField: "dataVencimento", sortDir: "ASC" }) {
    const usuarioLogado = JSON.parse(localStorage[`_immortal|usuarioLogado`] || null);
    try {
      let searchDTOInput = {};
      if (this.filterBy === 'DATA_PAGAMENTO') {
        const dataInicialPagamento = this.searchDTO.dataInicial;
        const dataFinalPagamento = this.searchDTO.dataFinal;
        searchDTOInput = {
          ...this.searchDTO,
          pageableDTO: {
            ...pageableDTO,
            sortField: 'dataPagamento',
          },
          ...searchDTO,
          dataInicialPagamento,
          dataFinalPagamento,
        };

        delete searchDTOInput.dataInicial;
        delete searchDTOInput.dataFinal;
      } else {
        searchDTOInput = {
          ...this.searchDTO,
          pageableDTO: {
            ...pageableDTO,
            sortField: 'dataVencimento'
          },
          ...searchDTO
        };

        delete searchDTOInput.dataInicialPagamento;
        delete searchDTOInput.dataFinalPagamento;
      }
      this.loading = true;

      searchDTOInput = {
        ...searchDTOInput,
        pageableDTO
      };

      if (usuarioLogado?.authorities.some(a => a.authority === 'ROLE_TITULO_PARCELA_READ_OUTROS_PROFISSIONAIS')) {
        this.tituloList = await this.findAllOutrosProfissionais(searchDTOInput);
        this.relatorioCsv = 'relatorioTituloParcelaOutrousProfissionaisCsv';
      } else {
        this.tituloList = await this.findAllByProfissionalSaude(searchDTOInput);
        this.relatorioCsv = 'relatorioTituloParcelaCsv'
      }

      let saldo = 0;
      const calculaSaldo = titulo => {
        if (titulo.tipo === TituloTipo.DESPESA) {
          saldo = saldo - titulo.valor;
        }

        if (titulo.tipo === TituloTipo.RECEITA) {
          saldo = saldo + titulo.valor;
        }
        return {
          ...titulo,
          saldo
        };
      };

      if (this.tituloList) {
        this.tituloList = this.tituloList.map(calculaSaldo);
        this.updateTotais(this.tituloList);
      }

    } catch (error) {
      this.totais = {
        ...defaultTotais
      };
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async findAllByProfissionalSaude(searchDTO) {
    const response = await Api.post("", {
      query: `
      query ($search: String, $dataInicial: LocalDate, $dataFinal: LocalDate, $dataInicialPagamento: LocalDate, $dataFinalPagamento: LocalDate, $centroCustoId: Long, $sujeitoAtencaoId: UUID, $categoriaFinanceiroId: Long, $pageableDTO: PageableDTOInput, $formasPagamentoId: [FormaPagamentoInput], $convenioId: Long, $contaBancariaId: Long, $tipo: TituloTipo, $status: [StatusTituloParcela],  $procedimentoId: UUID, $tipoConsultaId: UUID) {
        findAllTituloParcelaProfissionalSaudeLogado(search: $search, dataInicial: $dataInicial, dataFinal: $dataFinal, dataInicialPagamento: $dataInicialPagamento, dataFinalPagamento: $dataFinalPagamento, centroCustoId: $centroCustoId, sujeitoAtencaoId: $sujeitoAtencaoId, categoriaFinanceiroId: $categoriaFinanceiroId, formasPagamento: $formasPagamentoId, pageableDTO: $pageableDTO, convenioId: $convenioId, contaBancariaId: $contaBancariaId, tipo: $tipo, status: $status, procedimentoId: $procedimentoId, tipoConsultaId: $tipoConsultaId) {
          id
          tipo
          tipoRepeticao
          numeroParcela
          totalParcelas
          dataVencimento
          dataPagamento
          tipoDesconto
          desconto
          excluido
          nome
          agendamento {
            voucherPrever {
              id
            }
          }
          codigoTransicao
          convenio {
            descricao
          }
          formaPagamento {
            nome
          }
          procedimentos {
            id
            nome
          }
          profissionalSaude{
            nome
          }
          sujeitoAtencao{
            nome
          }
          categoriaFinanceira {
            nome
          }
          valor
          contaBancaria {
            nome
          }
        }
      }
                `,
      variables: {
        ...searchDTO
      }

    });

    return response?.data?.data?.findAllTituloParcelaProfissionalSaudeLogado || [];
  }

  async findAllOutrosProfissionais(searchDTO) {
    const response = await Api.post("", {
      query: `
          query ($search: String, $dataInicial: LocalDate, $dataFinal: LocalDate, $dataInicialPagamento: LocalDate, $dataFinalPagamento: LocalDate, $profissionalSaudeId: Long, $centroCustoId: Long, $sujeitoAtencaoId: UUID, $categoriaFinanceiroId: Long, $pageableDTO: PageableDTOInput, $formasPagamentoId: [FormaPagamentoInput], $convenioId: Long, $contaBancariaId: Long, $tipo: TituloTipo, $status: [StatusTituloParcela], $procedimentoId: UUID, $tipoConsultaId: UUID) {
            findAllTituloParcela(search: $search, dataInicial: $dataInicial, dataFinal: $dataFinal, dataInicialPagamento: $dataInicialPagamento, dataFinalPagamento: $dataFinalPagamento, profissionalSaudeId: $profissionalSaudeId, centroCustoId: $centroCustoId, sujeitoAtencaoId: $sujeitoAtencaoId, categoriaFinanceiroId: $categoriaFinanceiroId, formasPagamento: $formasPagamentoId, pageableDTO: $pageableDTO, convenioId: $convenioId, contaBancariaId: $contaBancariaId, tipo: $tipo, status: $status, procedimentoId: $procedimentoId, tipoConsultaId: $tipoConsultaId) {
              id
              tipo
              tipoRepeticao
              numeroParcela
              totalParcelas
              dataVencimento
              dataPagamento
              tipoDesconto
              desconto
              excluido
              nome
              agendamento {
                voucherPrever {
                  id
                }
              }
              codigoTransicao
              convenio {
                descricao
              }
              formaPagamento {
                nome
              }
              procedimentos {
                id
                nome
              }
              profissionalSaude{
                nome
              }
              sujeitoAtencao{
                nome
              }
              categoriaFinanceira {
                nome
              }
              valor
              contaBancaria {
                nome
              }
            }
          }
                `,
      variables: {
        ...searchDTO
      }

    });

    return response?.data?.data?.findAllTituloParcela || [];
  }

  @action findValueTicketMedioByUnidade= async(searchDTO)=>{
    const ticketMedioValor= await FindRelatorioTicketMedioByUnidade(searchDTO)
    return ticketMedioValor
  }

  updateTotais = async (tituloList) =>{
    let totalReceita = 0;
    let totalDespesa = 0;

    tituloList.forEach(titulo => {
      const discountValue = titulo?.tipoDesconto === 'VALOR' ? titulo.desconto : (titulo.valor * titulo.desconto) / 100;
      if (titulo.tipo === TituloTipo.DESPESA) {
        totalDespesa = totalDespesa + (titulo.valor - (discountValue || 0));
      }

      if (titulo.tipo === TituloTipo.RECEITA) {
        totalReceita = totalReceita + (titulo.valor - (discountValue || 0));
      }
    });

    this.totais={
      totalDespesa,
      totalReceita,
      total:  totalReceita - totalDespesa,
    }

    this.totais.totalDespesaFormatted = applyCurrencyMask(this.totais.totalDespesa);
    this.totais.totalReceitaFormatted = applyCurrencyMask(this.totais.totalReceita);
    this.totais.totalFormatted = applyCurrencyMask(this.totais.total);
  }

  @action updateTabelaTituloParcela() {
    if (this.titulo.id) {
      return;
    }

    this.modalState.tituloParcelaList = [];
    this.modalState.valorParcela = null;

    const {
      valorEntrada,
      dataVencimento,
      periodicidade,
      totalParcelas,
      quantidade,
      subtotal
    } = this.titulo;
    const { tipoRepeticao } = this.modalState;

    const subtotalString = typeof subtotal !== 'string' && subtotal ? subtotal.toString() : subtotal;

    const hasDataVencimento = dataVencimento instanceof moment;
    const hasPeriodicidade = !!(periodicidade && periodicidade.id);
    const hasValidUpdateRepetir =
      !string.isEmpty(subtotalString) &&
      hasPeriodicidade &&
      parseInt(quantidade) &&
      hasDataVencimento;
    const hasValidUpdateParcelar =
      !string.isEmpty(subtotalString) &&
      totalParcelas?.value &&
      hasDataVencimento;

    if (
      tipoRepeticao === TituloTipoRepeticao.REPETIR &&
      hasValidUpdateRepetir
    ) {

      const repetirQuantidade = parseInt(quantidade);
      let dataVencimentoAtual = moment(dataVencimento);
      const list = [];

      for (let i = 1; i <= repetirQuantidade; i++) {
        const first = i === 1;
        if (!first && periodicidade.value === TituloPeriodicidade.ANUALMENTE) {
          dataVencimentoAtual = dataVencimentoAtual.add(1, "years");
        }
        if (!first && periodicidade.value === TituloPeriodicidade.DIARIAMENTE) {
          dataVencimentoAtual = dataVencimentoAtual.add(1, "days");
        }
        if (!first && periodicidade.value === TituloPeriodicidade.MENSALMENTE) {
          dataVencimentoAtual = dataVencimentoAtual.add(1, "months");
        }
        if (
          !first &&
          periodicidade.value === TituloPeriodicidade.SEMANALMENTE
        ) {
          dataVencimentoAtual = dataVencimentoAtual.add(1, "weeks");
        }
        if (
          !first &&
          periodicidade.value === TituloPeriodicidade.QUINZENALMENTE
        ) {
          dataVencimentoAtual = dataVencimentoAtual.add(15, "days");
        }

        list.push({
          numeroParcela: i,
          dataVencimento: dataVencimentoAtual.format("YYYY-MM-DD"),
          valor: typeof subtotal === 'string' ? string.currencyMaskToFloat(subtotal) : subtotal
        });
      }

      this.modalState.tituloParcelaList = this.getParcelasComStatus([...list]);
      return;
    }

    if (
      tipoRepeticao === TituloTipoRepeticao.PARCELAR &&
      hasValidUpdateParcelar
    ) {
      const repetir = parseInt(totalParcelas?.value);
      let dataVencimentoAtual = moment(dataVencimento);
      const list = [];

      let parcelaInicial = string.isEmpty(valorEntrada) ? 1 : 0;
      let totalParaParcelar = typeof subtotal === 'string' ? string.currencyMaskToFloat(subtotal) : subtotal;

      if (parcelaInicial === 0) {
        totalParaParcelar =
          totalParaParcelar - string.currencyMaskToFloat(valorEntrada);
      }

      const valorParcela = totalParaParcelar / repetir;
      this.modalState.valorParcela = parseFloat(valorParcela)

      for (let i = parcelaInicial; i <= repetir; i++) {
        const first = i === parcelaInicial;
        if (!first) {
          dataVencimentoAtual = dataVencimentoAtual.add(1, "months");
        }

        list.push({
          numeroParcela: i,
          dataVencimento: dataVencimentoAtual.format("YYYY-MM-DD"),
          valor:
            i === 0 ? string.currencyMaskToFloat(valorEntrada) : valorParcela
        });
      }

      this.modalState.tituloParcelaList = this.getParcelasComStatus([...list]);
    }
  }

  @action async create(titulo, tipo) {

    try {
      if (this.saving) {
        return;
      }

      if (this.contemErros(titulo)) {
        throw Error('Preencha os dados');
      }

      const { idOpened, agendamentoRecorrencia } = this.atendimentoStore;

      const tituloDados = {
        nome: titulo.nome,
        valor: typeof titulo.valor === "string" ? string.currencyMaskToFloat(titulo.valor) : titulo.valor,
        dataVencimento: moment(titulo.dataVencimento).format("YYYY-MM-DD"),
        dataPagamento: titulo.dataPagamento ? moment(titulo.dataPagamento).format("YYYY-MM-DD") : undefined,
        tipo: titulo.tipo,
        tipoDesconto: titulo.tipoDesconto?.value,
        tipoRepeticao: this.modalState.tipoRepeticao,
        desconto: (titulo.desconto && string.removeMask(String(titulo.desconto), titulo.tipoDesconto?.value === "VALOR" ? "R$" : "%")) || null,
        codigoTransicao: titulo.codigoTransicao,
      };

      if (tipo === "agendamento") {
        const id = idOpened;

        tituloDados.agendamento = {
          id
        }
      } else if (tipo === "recorrencia") {
        const recorrencia = idOpened && agendamentoRecorrencia && {
          id: agendamentoRecorrencia.id
        };

        tituloDados.agendamento = {
          id: idOpened,
          agendamentoRecorrencia: recorrencia
        }
      };

      if (titulo.sujeitoAtencao && titulo.sujeitoAtencao.id) {
        tituloDados.sujeitoAtencao = {
          id: titulo.sujeitoAtencao.id
        };
      }

      if (titulo.convenio && titulo.convenio.id && typeof titulo.convenio.id === "number") {
        tituloDados.convenio = {
          id: titulo.convenio.id
        };
      }

      if (titulo.profissionalSaude && titulo.profissionalSaude.id) {
        tituloDados.profissionalSaude = {
          id: titulo.profissionalSaude.id
        };
      }

      if (titulo?.agendamentoTipo) {
        const { agendamentoTipo } = titulo;
        tituloDados.agendamentoTipos = {
          tituloAgendamentoTipoId: {
            agendamentoTipo: {
              id: agendamentoTipo?.id,
              descricao: agendamentoTipo?.descricao
            }
          },
          quantidade: agendamentoTipo?.totalQuantity || 1,
        }
      }

      if (titulo.procedimentos && titulo.procedimentos.length > 0) {
        tituloDados.tituloProcedimentos = titulo.procedimentos.map((procedimento) => {
          return {
            procedimento: {
              id: procedimento?.id,
              nome: procedimento?.nome
            },
            quantidade: procedimento?.totalQuantity,
          }
        });

      }

      if (titulo.orcamento) {
        tituloDados.orcamento = {
          id: titulo.orcamento.id
        }
      }

      if (titulo.formaPagamento && titulo.formaPagamento.id) {
        tituloDados.formaPagamento = {
          id: titulo.formaPagamento.id
        };
      }

      if (titulo.centroCusto && titulo.centroCusto.id) {
        tituloDados.centroCusto = {
          id: titulo.centroCusto.id
        };
      }

      if (titulo.categoriaFinanceira && titulo.categoriaFinanceira.id) {
        tituloDados.categoriaFinanceira = {
          id: titulo.categoriaFinanceira.id
        };
      }

      if (titulo.contaBancaria && titulo.contaBancaria.id) {
        tituloDados.contaBancaria = {
          id: titulo.contaBancaria.id
        };
      }

      if (!string.isEmpty(titulo.valorEntrada)) {
        tituloDados.valorEntrada = string.currencyMaskToFloat(
          titulo.valorEntrada
        );
      }

      if (titulo.totalParcelas?.value) {
        tituloDados.totalParcelas = parseInt(titulo.totalParcelas?.value, 10);
      }

      if (!titulo?.voucherPrever?.id && this.vouchersAssociado?.length > 0) {
        tituloDados.agendamento = {
          ...tituloDados.agendamento,
          voucherPrever: { codigoExterno: this.vouchersAssociado[0]?.id }
        }
      }


      if (TituloTipoRepeticao.REPETIR === this.modalState.tipoRepeticao) {
        tituloDados.totalParcelas = parseInt(titulo.quantidade, 10);
        tituloDados.periodicidade =
          titulo.periodicidade && titulo.periodicidade.value
            ? titulo.periodicidade.value
            : null;
        tituloDados.tipoRepeticao = this.modalState.tipoRepeticao;
      }

      if (
        TituloTipoRepeticao.PARCELAR === tituloDados.tipoRepeticao &&
        !tituloDados.totalParcelas
      ) {
        throw new Error("Preencha o número de parcelas");
      }

      if (
        TituloTipoRepeticao.REPETIR === tituloDados.tipoRepeticao &&
        !tituloDados.totalParcelas
      ) {
        throw new Error("Preencha a quantidade");
      }

      if (
        TituloTipoRepeticao.REPETIR === tituloDados.tipoRepeticao &&
        string.isEmpty(tituloDados.periodicidade)
      ) {
        throw new Error("Selecione a periodicidade");
      }

      const unidadeAtual = await this.usuarioStore.getUnidadeAtual();

      const unidadeId = unidadeAtual?.id || null;
      const variables = {
        titulo: {
          ...tituloDados,
          unidade: {
            id: unidadeId
          },
        },
        ...(titulo.controleSessaoId && { controleSessaoId: titulo.controleSessaoId })
      };
      this.saving = true;
      let response = null;
      if (titulo.controleSessaoId) {
        const tituloControleSessao = {
          ...variables,
          titulo: {
            ...variables.titulo,
            tituloProcedimentos: [],
          }
        }
        if (titulo?.sessao?.id) {
          const query = titulo.sessao?.agendamentoTipo ? 'faturarControleSessaoAgendamentoTipo' : 'faturarControleSessaoProcedimento'
          const field = titulo.sessao?.agendamentoTipo ? 'controleSessaoAgendamentoTipoId' : 'controleSessaoProcedimentoId'
          const sessaoVariables = {
            ...variables,
            [field]: titulo?.sessao?.id,
          }
          delete sessaoVariables.controleSessaoId
          response = await faturarControleSessao({ variables: sessaoVariables, query, field })
        } else {
          response = await faturarControleSessao({ variables: tituloControleSessao, query: 'faturarControleSessao', field: 'controleSessaoId' })
        }
      } else {
        response = await createTitulo(variables)
        const { id, voucherPrever } = variables?.titulo?.agendamento || {};
        if (voucherPrever?.codigoExterno && response?.id) {
          await vincularVoucherAgendamento({ agendamentoId: id, codigoExterno: voucherPrever.codigoExterno })
        }
      }

      this.atendimentoStore.dadosModalConfirmacao = {
        open: true,
        title: "Pagamento salvo com sucesso!",
        color: "green",
        icon: <SuccessIcon />,
        buttons: null,
        children: null,
      };

      const timeoutId = setTimeout(() => {
        this.atendimentoStore.dadosModalConfirmacao = modalConfirmacaoDefault;
        clearTimeout(timeoutId);
      }, 3000);

      return response;

    } catch (error) {
      throw error;
    } finally {
      this.saving = false;
    }
  }

  getNomeProcedimentos = (procedimentos) => {
    return procedimentos.map(element => element.nomeProcedimento).join(', ');
  };

  @action async cadastrarReceitaBySujeito(tipo, sujeitoAtencao, profissionalSaude, tipoConsulta, procedimentos, idAgendamento, isModalSala = false) {
    const { convenioAgendamentoTipo } = sujeitoAtencao?.dadosConvenio?.convenio || {};

    let valor = 0;

    if (!isModalSala) {
      valor = convenioAgendamentoTipo ? await getValorTipoConvenio(tipoConsulta?.value, convenioAgendamentoTipo) : 0;
      this.tipoConsultaAgendamento = tipoConsulta?.value;
    }

    const valorConsultaEProcedimentos = procedimentos.reduce((previousValue, currentValue) => previousValue + this.getValorProcedimento(currentValue.procedimento, sujeitoAtencao.dadosConvenio?.convenio?.id), valor)

    this.titulo = {
      ...defaultTitulo,
      tipo,

      sujeitoAtencao:
        sujeitoAtencao && {
          ...sujeitoAtencao,
          value: sujeitoAtencao.id,
          label: sujeitoAtencao.nome
        },

      convenio:
        sujeitoAtencao?.dadosConvenio?.convenio && {
          ...sujeitoAtencao.dadosConvenio.convenio,
          value: sujeitoAtencao.dadosConvenio.convenio.id,
          label: sujeitoAtencao.dadosConvenio.convenio.descricao,
        },

      valor: applyCurrencyMask(valorConsultaEProcedimentos),

      nome: isModalSala ? this.getNomeProcedimentos(procedimentos) : tipoConsulta?.label,

      profissionalSaude:
        profissionalSaude && {
          ...profissionalSaude,
          value: profissionalSaude.id,
          label: profissionalSaude.nome
        },
      procedimentos: procedimentos.map(procedimento => procedimento.procedimento) || [],
      subtotal: valorConsultaEProcedimentos,
      agendamento: {
        id: idAgendamento
      }
    };

    this.isAgendamento = true;
    this.getTabs();
    this.open = true;
    this.expanded = true;
    this.editMode = true;
  }

  @action async updateTituloParcela(titulo) {
    try {
      if (this.saving) {
        return;
      }

      if (this.contemErros(titulo, "tituloParcela")) {
        throw Error('Preencha os dados');
      }

      const tituloDados = {
        id: titulo.id,
        contaBancaria: titulo.contaBancaria ? {
          id: titulo.contaBancaria.id
        } : null,
        dataVencimento: moment(titulo.dataVencimento).format("YYYY-MM-DD"),
        dataPagamento: titulo.dataPagamento ? moment(titulo.dataPagamento).format("YYYY-MM-DD") : undefined,
        tipo: titulo.tipo,
        convenio: titulo.convenio ? { id: titulo.convenio?.value } : undefined,
        categoriaFinanceira: titulo.categoriaFinanceira ? { id: titulo.categoriaFinanceira?.value } : undefined,
        excluido: titulo?.excluido ? titulo?.excluido : false,
        nome: titulo.nome,
        valor: typeof titulo.valor === "string" ? string.currencyMaskToFloat(titulo.valor) : titulo.valor,
        tipoDesconto: titulo.tipoDesconto?.value,
        desconto: titulo.desconto ? string.removeMask(String(titulo.desconto), titulo.tipoDesconto?.value === "VALOR" ? "R$" : "%") : 0,
      };

      if (titulo.agendamento?.id && titulo.sujeitoAtencao?.id) {
        tituloDados.agendamento = {
          id: titulo.agendamento?.id
        }
      };

      const hasFormaPagamento =
        !!(titulo.formaPagamento && titulo.formaPagamento.id);

      if (hasFormaPagamento) {
        tituloDados.formaPagamento = {
          id: titulo.formaPagamento.id
        };
      }

      const unidadeAtual = await this.usuarioStore.getUnidadeAtual();

      const unidadeId = unidadeAtual?.id || null;

      const query = `
        mutation ($tituloParcela: TituloParcelaInput) {
            updateTituloParcela(tituloParcela: $tituloParcela) {
              id
            }
        }
      `;
      const variables = {
        tituloParcela: {
          ...tituloDados,
          unidade: {
            id: unidadeId
          }
        }
      };

      if (titulo.numeroParcela === 0 && titulo?.excluido) {
        this.titulo.valorEntrada = 0;
      };

      this.saving = true;
      await Api.post("", {
        query,
        variables
      });

      if (this.titulo?.titulo?.id || this.titulo.id) {
        this.findByIdTituloIdParcelas(this.titulo?.titulo?.id || this.titulo.id)
      };

    } catch (error) {
      throw error;
    } finally {
      this.saving = false;
    }
  }

  @action async update(titulo) {
    try {
      if (this.saving) {
        return;
      }

      if (this.contemErros(titulo)) {
        throw Error('Preencha os dados');
      }

      const tituloDados = {
        id: titulo.titulo?.id ? titulo.titulo?.id : titulo.id,
        valor: typeof titulo.valor === "string" ? string.currencyMaskToFloat(titulo.valor) : titulo.valor,
        valorEntrada: string.removeMask(String(titulo.valorEntrada), "R$") || null,
        sujeitoAtencao: titulo?.sujeitoAtencao?.id ? {
          id: titulo.sujeitoAtencao.id
        } : null,
        agendamento: (titulo?.agendamento?.id || titulo?.titulo?.agendamento?.id) ? {
          id: titulo?.agendamento?.id || titulo?.titulo?.agendamento?.id
        } : null,
        profissionalSaude: titulo.profissionalSaude?.id ? {
          id: titulo.profissionalSaude.id
        } : null,
        centroCusto: titulo.centroCusto?.id ? {
          id: titulo.centroCusto.id
        } : null,
        convenio: titulo.convenio?.id ? { id: titulo.convenio?.value } : null,
        categoriaFinanceira: titulo.categoriaFinanceira?.id ? { id: titulo.categoriaFinanceira?.value } : undefined,

        tipoDesconto: titulo.tipoDesconto?.value,
        desconto: titulo.desconto ? string.removeMask(String(titulo.desconto), titulo.tipoDesconto?.value === "VALOR" ? "R$" : "%") : 0,
        contaBancaria: titulo.contaBancaria?.id ? { id: titulo.contaBancaria?.id } : null,
        formaPagamento: titulo.formaPagamento?.id ? { id: titulo.formaPagamento?.id } : null,
        tipoRepeticao: titulo.tipoRepeticao || this.modalState.tipoRepeticao,
        periodicidade: titulo.periodicidade?.id ? titulo.periodicidade.value : null,
        dataVencimento: moment(titulo.dataVencimento).format("YYYY-MM-DD"),
        dataPagamento: titulo?.dataPagamento ? moment(titulo.dataPagamento).format("YYYY-MM-DD") : null,
        tipo: titulo.tipo,
        nome: titulo.nome,
        totalParcelas: 1,
        codigoTransicao: titulo.codigoTransicao,
      };

      if (titulo?.agendamentoTipos && titulo?.agendamentoTipos.length > 0) {
        tituloDados.agendamentoTipos = titulo.agendamentoTipos.map((item) => {
          return {
            tituloAgendamentoTipoId: {
              agendamentoTipo: {
                id: item?.id,
                descricao: item?.descricao
              }
            },
            quantidade: item?.totalQuantity || 1,
          }
        });
      }

      if (titulo.procedimentos && titulo.procedimentos.length > 0) {
        tituloDados.tituloProcedimentos = titulo.procedimentos.map((procedimento) => {
          return {
            procedimento: {
              id: procedimento?.id,
              nome: procedimento?.nome
            },
            quantidade: procedimento?.totalQuantity || 1,
          }
        });
      }

      if (this.modalState.tipoRepeticao !== "NAO_REPETIR") {
        tituloDados.totalParcelas = this.modalState.tipoRepeticao === "PARCELAR" ? titulo.totalParcelas?.value : parseInt(titulo.quantidade)
      }

      if (titulo.agendamento?.id) {
        tituloDados.agendamento = {
          id: titulo.agendamento?.id,
        }
      };

      const unidadeAtual = await this.usuarioStore.getUnidadeAtual();
      const unidadeId = unidadeAtual?.id || null;

      const query = `
      mutation ($titulo: TituloInput) {
        updateTitulo(titulo: $titulo) {
          id
        }
      }
      `;
      const variables = {
        titulo: {
          ...tituloDados,
          unidade: {
            id: unidadeId
          }
        }
      };


      this.saving = true;
      await Api.post("", {
        query,
        variables
      });
    } catch (error) {
      throw error;
    } finally {
      this.saving = false;
    }
  }

  @action contemErros(titulo, tipoTitulo) {
    if (!titulo) {
      this.openNotification('Por favor, envie os dados do título.', 'error');
      return true;
    }

    this.errors = {
      nome: false,
      dataVencimento: false,
      valor: false,
      titulo: false,
    };

    let error = false;

    const hasDataVencimento = titulo.dataVencimento;

    if (string.isEmpty(titulo.nome)) {
      error = true;
      this.errors.nome = true;
    }

    if (!titulo?.valor || string.isEmpty(String(titulo?.valor).replace("R$", ""))) {
      error = true;
      this.errors.valor = true;
    }
    if (string.isEmpty(titulo.tipo)) {
      error = true;
      this.errors.tipo = true;
    }
    if (!titulo.dataVencimento && !hasDataVencimento) {
      error = true;
      this.errors.dataVencimento = true;
    }

    if (tipoTitulo !== "tituloParcela") {
      if (this.tabSelectedParcelamento === tabsParcelamento.REPETIR && !titulo?.quantidade) {
        error = true;
        this.errors.quantidade = true;
      }
      if (this.tabSelectedParcelamento === tabsParcelamento.PARCELAR && !titulo?.totalParcelas) {
        error = true;
        this.errors.totalParcelas = true;
      }

      if (this.tabSelectedParcelamento === tabsParcelamento.REPETIR && !titulo?.periodicidade) {
        error = true;
        this.errors.periodicidade = true;
      }
    }
    if (error) {
      this.openNotification(`Por favor, corrija os campos indicados.`, 'error');
      this.atendimentoStore.showAlertMessage({
        message: `Por favor, corrija os campos indicados.`,
        isOpen: true,
        variant: 'error'
      });
      return true;
    }

    return false;
  }

  clearErrors = () => {
    this.errors = {
      nome: false,
      dataVencimento: false,
      valor: false,
      titulo: false,
    };
  }

  @action async edit(id) {
    this.open = true;
    this.isAgendamento = false;
    this.editMode = false;
    this.expanded = false;
    this.onlyReadParcelas = false;
    await this.loadById(id);
  }

  @action async openNew(tipo) {
    this.tipoConsultaAgendamento = await this.agendamentoTipoPadrao();
    this.expanded = true;
    this.editMode = true;
    this.titulo = {
      ...defaultTitulo,
      tipo
    };
    this.getTabs();
    this.open = true;
    this.isAgendamento = false;
  }

  @action async preencherReceitaFinanceiraProcedimento(dados) {
    const convenioProcedimentos = dados.procedimentoRealizado?.procedimento?.convenioProcedimentos;
    const convenioSujeitoId = dados.sujeitoAtencao?.dadosConvenio?.convenio?.id;

    let convenioSujeito;
    if (convenioSujeitoId) {
      convenioSujeito = convenioProcedimentos.find(procedimento => procedimento.convenio.id === convenioSujeitoId)
    }

    this.titulo = {
      ...defaultTitulo,
      tipo: 'RECEITA',

      sujeitoAtencao: dados?.sujeitoAtencao && {
        ...dados.sujeitoAtencao,
        value: dados.sujeitoAtencao.id,
        label: dados.sujeitoAtencao.nome
      },

      convenio: dados.sujeitoAtencao.dadosConvenio?.convenio && {
        ...dados.sujeitoAtencao.dadosConvenio.convenio,
        value: dados.sujeitoAtencao.dadosConvenio.convenio.id,
        label: dados.sujeitoAtencao.dadosConvenio.convenio.descricao,
      },

      valor: convenioSujeito?.valor && String(convenioSujeito.valor),

      nome: dados.procedimentoRealizado?.procedimento?.nome,

      profissionalSaude: dados.procedimentoRealizado.profissionalSaude && {
        ...dados.procedimentoRealizado.profissionalSaude,
        value: dados.procedimentoRealizado.profissionalSaude?.id,
        label: dados.procedimentoRealizado.profissionalSaude?.nome
      },
    };
    this.open = true;
    this.isAgendamento = false;
    this.expanded = true;
  }

  agendamentoTipoPadrao = async () => {
    const listaTipo = await findAgendamentoTipoByAtivo();
    const { findAllAgendamentoTipo } = listaTipo.data.data;
    if (!findAllAgendamentoTipo) return;
    const tipoConsulta = findAllAgendamentoTipo.find(item => item.codigo === "CONSULTA");
    return tipoConsulta?.id;
  }

  @action async loadById(id) {
    try {
      this.opening = true;
      const response = await Api.post("", {
        query: `
        query ($id: Long) {
          findByIdTituloParcela(id: $id) {
            id
            chavePublica
            contaBancaria {
              id
              nome
            }
            formaPagamento {
              id
              nome
            }
            titulo {
              id
              dataPagamento
              dataVencimento
              dataHoraLancamento
              nome
              periodicidade
              tipo
              tipoRepeticao
              totalParcelas
              valor
              desconto
              tipoDesconto
              valorEntrada
              codigoTransicao
              controleSessao {
                id
                nomeSessao
                controleSessaoAgendamentoTipos {
                  id
                }
              }
              convenio {
                id
                descricao
                convenioAgendamentoTipo {
                  agendamentoTipo {
                    id
                  }
                  valor
                }
              }
              sujeitoAtencao {
                id
                nome
                cpf
                documento
              }
              profissionalSaude {
                id
                nome
                cpf
                numeroConselho
                ufConselho
                conselhoProfissionalSaude {
                  sigla
                }
              }
              procedimentos {
                id
                nome
                convenioProcedimentos {
                  valor
                  convenio {
                    id
                  }
                }
              }
              agendamentoTipos {
                quantidade
                tituloAgendamentoTipoId {
                  agendamentoTipo {
                    id
                    nome:descricao
                  }
                }
              }
              tituloProcedimentos {
                quantidade
                procedimento{
                  id
                  nome
                  convenioProcedimentos {
                    valor
                    convenio {
                      id
                    }
                  }
                }
              }
              agendamento {
                id
                tipo {
                  id
                }
              }
              categoriaFinanceira {
                id
                nome
              }
              centroCusto {
                id
                nome
              }
              parcelas {
                id
                valor
                totalParcelas
                dataVencimento
                dataPagamento
                desconto
                tipoDesconto
                excluido
                numeroParcela
                chavePublica
              }
            }
            unidade {
              id
              nome
              nomeFantasia
              telefonePrincipal
            }
            dataPagamento
            dataVencimento
            dataHoraLancamento
            nome
            numeroParcela
            periodicidade
            tipo
            tipoRepeticao
            totalParcelas
            valor
            desconto
            tipoDesconto
            excluido
          }
        }
        `,
        variables: {
          id
        }
      });

      const data = response.data.data.findByIdTituloParcela;
      const titulo = data.titulo;
      const parcelaEntrada = titulo.parcelas.length > 0 && titulo.parcelas.find(item => item.numeroParcela === 0);
      this.tipoConsultaAgendamento = data?.agendamento?.tipo?.id ? data?.agendamento?.tipo?.id : await this.agendamentoTipoPadrao();

      this.titulo = {
        ...data,
        valor: parseFloat(titulo.valor)
          .toFixed(2)
          .replace(".", ","),
        nome: titulo.nome,
        tipoDesconto: this.getTipoDescontoTransformObjeto(titulo.tipoDesconto),
        desconto: titulo.desconto,
        dataVencimento: moment(data.dataVencimento),
        dataPagamento: data.dataPagamento ? moment(data.dataPagamento) : null,
        dataPagamentoEntrada: parcelaEntrada?.dataPagamento,
        periodicidade: titulo.periodicidade && {
          id: titulo.periodicidade,
          label: string.capitalize(titulo.periodicidade),
          value: titulo.periodicidade,
        },
        categoriaFinanceira:
          titulo?.categoriaFinanceira?.id
            ? {
              ...titulo.categoriaFinanceira,
              value: titulo.categoriaFinanceira.id,
              label: titulo.categoriaFinanceira.nome
            }
            : null,
        sujeitoAtencao:
          titulo?.sujeitoAtencao?.id
            ? {
              ...titulo.sujeitoAtencao,
              value: titulo.sujeitoAtencao.id,
              label: titulo.sujeitoAtencao.nome
            }
            : null,
        profissionalSaude:
          titulo?.profissionalSaude?.id
            ? {
              ...titulo.profissionalSaude,
              value: titulo.profissionalSaude.id,
              label: titulo.profissionalSaude.nome
            }
            : null,
        centroCusto:
          titulo?.centroCusto?.id
            ? {
              ...titulo.centroCusto,
              value: titulo.centroCusto.id,
              label: titulo.centroCusto.nome
            }
            : null,
        contaBancaria:
          data.contaBancaria?.id
            ? {
              ...data.contaBancaria,
              value: data.contaBancaria.id,
              label: data.contaBancaria.nome
            }
            : null,
        formaPagamento:
          data.formaPagamento?.id
            ? {
              ...data.formaPagamento,
              value: data.formaPagamento.id,
              label: data.formaPagamento.nome
            }
            : null,
        convenio:
          titulo?.convenio?.id
            ? {
              ...titulo.convenio,
              value: titulo.convenio.id,
              label: titulo.convenio.descricao
            }
            : null,
        procedimentos: titulo?.tituloProcedimentos?.map(item => ({
          ...item.procedimento,
          totalQuantity: item.quantidade ? item.quantidade : 1,
        })),
        ...(titulo?.controleSessao?.controleSessaoAgendamentoTipos?.length > 0 && {
          agendamentoTipos: titulo?.agendamentoTipos?.map(item => ({
            ...item.tituloAgendamentoTipoId.agendamentoTipo,
            totalQuantity: item.quantidade ? item.quantidade : 1,
          })),
        }),
        ...(titulo?.controleSessao?.id && { controleSessaoId: titulo?.controleSessao?.id }),
        valorEntrada: titulo.valorEntrada,
        totalParcelas: { value: titulo.totalParcelas },
        quantidade: titulo.totalParcelas,
        codigoTransicao: titulo.codigoTransicao,
      };
      this.verificaDadosParaDescontoPreenchido();
      const valorComDesconto = string.calculaDesconto(data.valor, data.desconto, data.tipoDesconto)
      this.modalState.valorParcela = valorComDesconto;
      this.tabSelectedParcelamento = tabsParcelamento[titulo.tipoRepeticao];

      this.criarListaParcelas(titulo.tipoRepeticao, titulo.parcelas);
      this.modalState.tipoRepeticao = titulo.tipoRepeticao;

      this.getTabs();
      return data;
    } catch (error) {
      throw error;
    } finally {
      this.opening = false;
    }
  }

  criarListaParcelas(tipoRepeticao, parcelas) {
    const listaParcelasOrdenadaPorData = this.ordenarListaPorData(parcelas)

    if (tipoRepeticao === TituloTipoRepeticao.PARCELAR) {
      const listaParcelar = listaParcelasOrdenadaPorData.map((item) => {
        return ({
          id: item.id,
          numeroParcela: item.numeroParcela === 0 ? "E" : item.numeroParcela,
          valor: string.calculaDesconto(item.valor, item.desconto, item.tipoDesconto),
          dataVencimento: item.dataVencimento,
          dataPagamento: item.dataPagamento,
          excluido: item.excluido,
          chavePublica: item.chavePublica,
        })
      })

      this.modalState.tituloParcelaList = this.getParcelasComStatus(listaParcelar.filter(item => !item.excluido));
      this.modalState.tituloParcelaExcluidaList = listaParcelar.filter(item => item.excluido);
    } else {
      const listaRepetir = parcelas.map((item) => {
        return ({
          id: item.id,
          numeroParcela: item.numeroParcela,
          valor: string.calculaDesconto(item.valor, item.desconto, item.tipoDesconto),
          dataVencimento: item.dataVencimento,
          dataPagamento: item.dataPagamento,
          excluido: item.excluido,
          chavePublica: item.chavePublica,
        })
      });

      this.modalState.tituloParcelaList = this.getParcelasComStatus(listaRepetir.filter(item => !item.excluido));
      this.modalState.tituloParcelaExcluidaList = listaRepetir.filter(item => item.excluido);
    };
  }

  ordenarListaPorData(lista) {
    const listaParcelasOrdenadaPorData = lista.length > 0 ? lista.sort((a, b) => new moment(a.dataVencimento).format('YYYYMMDD') - new moment(b.dataVencimento).format('YYYYMMDD')) : [];
    return listaParcelasOrdenadaPorData
  }

  @action getTabs() {
    if (this.titulo.tipo === "RECEITA") {
      this.listTab = [
        { label: 'Informações' },
        {
          label: 'Procedimentos',
          roundedTag: !!this.titulo?.procedimentos?.length,
          tagColor: '#5462E0',
          tagText: this.titulo?.procedimentos?.length || ''
        },
        { label: 'Auditoria', isDisabled: !this.titulo.id }
      ]
      this.tabs = {
        INFORMACOES: 0,
        PROCEDIMENTOS: 1,
        AUDITORIA: 2,
      }
    } else {
      this.listTab = [{ label: 'Informações' }, { label: 'Auditoria', isDisabled: !this.titulo.id }];
      this.tabs = {
        INFORMACOES: 0,
        AUDITORIA: 1,
      };
    }
  }

  @action closeModal() {
    this.open = false;
    this.isAgendamento = false;
    this.editMode = false;
    this.titulo = {
      ...defaultTitulo
    };
    this.modalState = {
      ...defaultModalState
    };
  }

  @action async findAllSujeitoAtencao(searchDTO = {}) {
    try {
      const defaultSearchDTO = {
        pageSize: 30,
        pageNumber: 0,
        sortDir: "ASC",
        sortField: "nome",
        ativo: true
      };

      const response = await Api.post("", {
        query: `
          query ($searchDTO: SearchSujeitoAtencaoDTOInput) {
            findAllSujeitoAtencao(searchDTO: $searchDTO) {
              last
              totalElements
              numberOfElements
              content {
                id
                nome
                profissionalSaude {
                  id
                  nome
                }
                dadosConvenio {
                  convenio {
                    id
                    descricao
                    convenioAgendamentoTipo {
                      agendamentoTipo {
                        id
                      }
                      valor
                    }
                  }
                }
              }
            }
          }
                `,
        variables: {
          searchDTO: {
            ...defaultSearchDTO,
            ...searchDTO
          }
        }
      });

      const { last, content } = response.data.data.findAllSujeitoAtencao;

      const customContent = content.map(c => {
        const profissionalSaude = c.profissionalSaude && {
          ...c.profissionalSaude,
          value: c.profissionalSaude.id,
          label: c.profissionalSaude.nome,
        };
        const convenio = c.dadosConvenio && c.dadosConvenio.convenio && {
          ...c.dadosConvenio.convenio,
          value: c.dadosConvenio.convenio.id,
          label: c.dadosConvenio.convenio.descricao
        };

        return ({
          ...c,
          value: c.id,
          label: c.nome,
          profissionalSaude,
          convenio
        })
      });

      return {
        last,
        content: customContent
      };
    } catch (error) {
      throw error;
    }
  }

  @action async findAllFormaPagamento(searchDTO = {}) {
    try {
      const defaultSearchDTO = {
        pageSize: 30,
        pageNumber: 0,
        sortDir: "ASC",
        sortField: "nome"
      };

      const response = await Api.post("", {
        query: `
          query ($searchDTO: SearchDTOInput) {
            findAllFormaPagamento(searchDTO: $searchDTO) {
              last
              totalElements
              numberOfElements
              content {
                id
                nome
              }
            }
          }
                `,
        variables: {
          searchDTO: {
            ...defaultSearchDTO,
            ...searchDTO
          }
        }
      });

      const { last, content } = response.data.data.findAllFormaPagamento;

      const customContent = content.map(c => ({
        ...c,
        value: c.id,
        label: c.nome
      }));

      return {
        last,
        content: customContent
      };
    } catch (error) {
      throw error;
    }
  }

  @action async findAllProfissionalSaude(searchDTO = {}) {
    try {
      const unidadeAtual = await this.usuarioStore.getUnidadeAtual();

      const unidadeId = unidadeAtual?.id || null;

      const defaultSearchDTO = {
        ativo: true,
        pageSize: 30,
        pageNumber: 0,
        sortDir: "ASC",
        sortField: "nome",
        unidadeId
      };

      const response = await Api.post("", {
        query: `
          query ($searchDTO: SearchProfissionalSaudeDTOInput) {
            findAllProfissionalSaude(searchDTO: $searchDTO) {
              last
              totalElements
              numberOfElements
              content {
                id
                nome
              }
            }
          }
                `,
        variables: {
          searchDTO: {
            ...defaultSearchDTO,
            ...searchDTO
          }
        }
      });

      const { last, content } = response.data.data.findAllProfissionalSaude;

      const customContent = content.map(c => ({
        ...c,
        value: c.id,
        label: c.nome
      }));

      return {
        last,
        content: customContent
      };
    } catch (error) {
      throw error;
    }
  }

  @action async findByIdProfissionalSaude(id) {
    try {
      const response = await Api.post("", {
        query: `
            query ($id: Long) {
              findByIdProfissionalSaude(id: $id) {
                    id
                    nome
                    numeroConselho
                    ufConselho
                    tipo
                }
            }
        `,
        variables: { id }
      })
      return response.data.data.findByIdProfissionalSaude

    } catch (error) {
      this.openNotification('Erro ao encontrar o profissional de saúde.', 'error');
    }
  }

  @action async findAllConvenios(searchDTO = {}) {
    try {
      const defaultSearchDTO = {
        pageSize: 30,
        pageNumber: 0,
        sortDir: "ASC",
        sortField: "descricao",
      };

      const response = await Api.post("", {
        query: `
          query ($searchDTO: SearchDTOInput) {
            findAllConvenio(searchDTO: $searchDTO) {
              last
              content {
                id
                descricao
              }
            }
          }
                `,
        variables: {
          searchDTO: {
            ...defaultSearchDTO,
            ...searchDTO,
            ativo: true,
          }
        }
      });


      return response.data.data.findAllConvenio;
    } catch (error) {
      throw error;
    }
  }

  @action async findAllProcedimentos(searchDTO = {}) {
    try {
      const defaultSearchDTO = {
        pageSize: 30,
        pageNumber: searchDTO.pageNumber || 0,
        sortDir: "ASC",
        sortField: "nome"
      };
      const { profissionalSaudeId, search } = searchDTO

      const response = await Api.post("", {
        query: `
        query ($nome: String, $ativo: Boolean, $convenioId: Long, $pageableDTO: PageableDTOInput, $profissionalSaudeId: Long) {
          findAllProcedimentos(nome: $nome, convenioId: $convenioId, pageableDTO: $pageableDTO, ativo: $ativo, profissionalSaudeId: $profissionalSaudeId) {
            last
            content {
              id
              nome
              ativo
              convenioProcedimentos {
                valor
                convenio {
                  id
                }
              }
            }
          }
        }
                `,
        variables: {
          nome: search || "",
          convenioId: this.titulo.convenio ? this.titulo.convenio.id : null,
          ativo: true,
          pageableDTO: {
            ...defaultSearchDTO,
          },
          ...(profissionalSaudeId && { profissionalSaudeId: profissionalSaudeId })
        }
      });

      const { last, content } = response.data.data.findAllProcedimentos;

      const customContent = content.map(procedimento => {

        return ({
          ...procedimento,
          value: procedimento.id,
          label: procedimento.nome
        })
      });

      return {
        last,
        content: customContent
      };
    } catch (error) {
      throw error;
    }
  }

  @action async findAllCentroCusto(searchDTO = {}) {
    try {
      const unidadeAtual = await this.usuarioStore.getUnidadeAtual();

      const unidadeId = unidadeAtual?.id || null;

      const defaultSearchDTO = {
        pageSize: 30,
        pageNumber: 0,
        sortDir: "ASC",
        sortField: "nome",
        unidadeId
      };

      const response = await Api.post("", {
        query: `
          query ($searchDTO: SearchDTOInput) {
            findAllCentroCusto(searchDTO: $searchDTO) {
              last
              totalElements
              numberOfElements
              content {
                id
                nome
              }
            }
          }
                `,
        variables: {
          searchDTO: {
            ...defaultSearchDTO,
            ...searchDTO
          }
        }
      });

      const { last, content } = response.data.data.findAllCentroCusto;

      const customContent = content.map(c => ({
        ...c,
        value: c.id,
        label: c.nome
      }));

      return {
        last,
        content: customContent
      };
    } catch (error) {
      throw error;
    }
  }

  @action async findAllConvenio(searchDTO = {}) {
    try {
      const defaultSearchDTO = {
        pageSize: 30,
        pageNumber: 0,
        sortDir: "ASC",
        sortField: "descricao"
      };

      const response = await Api.post("", {
        query: `
          query ($searchDTO: SearchDTOInput) {
            findAllConvenio(searchDTO: $searchDTO) {
              last
              totalElements
              numberOfElements
              content {
                id
                descricao
                convenioAgendamentoTipo {
                  agendamentoTipo {
                      id
                  }
                  valor
                }
                convenioProcedimentos {
                  procedimento {
                    id
                    nome
                  }
                  valor
                }
              }
            }
          }
                `,
        variables: {
          searchDTO: {
            ...defaultSearchDTO,
            ...searchDTO
          }
        }
      });

      const { last, content } = response.data.data.findAllConvenio;

      const customContent = content.map(c => ({
        ...c,
        value: c.id,
        label: c.descricao
      }));

      return {
        last,
        content: customContent
      };
    } catch (error) {
      throw error;
    }
  }

  @action async findAllContaBancaria(searchDTO = {}) {
    try {
      const defaultSearchDTO = {
        pageSize: 30,
        pageNumber: 0,
        sortDir: "ASC",
        sortField: "nome",
        ativo: "true",
      };

      const response = await Api.post("", {
        query: `
          query ($searchDTO: SearchDTOInput) {
            findAllContaBancaria(searchDTO: $searchDTO) {
              last
              totalElements
              numberOfElements
              content {
                id
                nome
              }
            }
          }
                `,
        variables: {
          searchDTO: {
            ...defaultSearchDTO,
            ...searchDTO
          }
        }
      });

      const { last, content } = response.data.data.findAllContaBancaria;
      this.contaBancariaList = content
      const customContent = content.map(c => ({
        ...c,
        value: c.id,
        label: c.nome
      }));

      return {
        last,
        content: customContent
      };
    } catch (error) {
      throw error;
    }
  }

  @action async findAllCategoriaFinanceira(searchDTO = {}) {
    try {
      const defaultSearchDTO = {
        pageSize: 30,
        pageNumber: 0,
        sortDir: "ASC",
        sortField: "nome",
        ativo: "true",
      };

      const response = await Api.post("", {
        query: `
          query ($searchDTO: SearchFinanceiroDTOInput) {
            findAllCategoriaFinanceira(searchDTO: $searchDTO) {
              last
              totalElements
              numberOfElements
              content {
                id
                nome
                tipo
                categoriasFinanceiras {
                  id
                  nome
                  ativo
                }
              }
            }
          }
                `,
        variables: {
          searchDTO: {
            ...defaultSearchDTO,
            ...searchDTO
          }
        }
      });

      const { last, content } = response.data.data.findAllCategoriaFinanceira;

      const list = [];
      content.forEach(categoria => {
        list.push({
          ...categoria,
          value: categoria.id,
          label: categoria.nome
        });

        if (categoria.categoriasFinanceiras instanceof Array) {
          categoria.categoriasFinanceiras.forEach(item => {
            item.ativo && list.push({
              ...item,
              value: item.id,
              label: `- ${item.nome}`
            });
          });
        }
      });

      return {
        last,
        content: list
      };
    } catch (error) {
      throw error;
    }
  }

  @action findAllPeriodicidades() {
    return Object.values(TituloPeriodicidade).map(p => ({
      id: p,
      label: string.capitalize(p),
      value: p
    }));
  }

  @action async carregaDadosFinanceiro() {
    const unidadeAtual = await this.usuarioStore.getUnidadeAtual();
    const unidadeId = unidadeAtual?.id || null;

    const response = await Api.post("", {
      query: `
        {
          findProfissionalSaudeByUnidadeComAgenda(
            unidadeId: ${unidadeId}, 
            pageableDTO: {
              sortDir: "ASC",
              sortField: "nome",
              pageSize: 999,
              pageNumber: 0
            }
          ) {
            id
            nome
            cns
            cpf
          }

          findAllCentroCusto(
            searchDTO: {
              pageNumber: 0,
              pageSize: 50,
              search: "",
              unidadeId: ${unidadeId}
            }
          ) {
            totalElements
            numberOfElements
            content {
              id
              nome
            }
          }
          findAllCategoriaFinanceira(
            searchDTO: {
              pageNumber: 0,
              pageSize: 50,
              search: "",
              unidadeId: ${unidadeId}
            }
          ) {
            totalElements
            numberOfElements
            content {
              id
              nome
            }
          }
          findAllFormaPagamento(searchDTO: {pageNumber: 0, pageSize: 20}){
            content{
              id
              nome
            }
          }
        }`
    });

    const {
      findProfissionalSaudeByUnidadeComAgenda,
      findAllCentroCusto,
      findAllCategoriaFinanceira,
      findAllFormaPagamento,
    } = response.data.data;

    this.profissionaisSaudeList = findProfissionalSaudeByUnidadeComAgenda;
    this.centroCustoList = findAllCentroCusto.content;
    this.categoriaList = findAllCategoriaFinanceira.content;
    this.formasPagamento = findAllFormaPagamento.content;

  };

  @action clearPesquisar = () => {
    this.currentPage = null;
    this.searchDTO.pageNumber = 0;
  };

  @action clearFilters = () => {
    this.searchDTO = defaultSearchDTO
  }

  @action consultaSujeitosDeAtencao = async ({ search = null, pageNumber }) => {
    const response = await Api.post('',
      {
        query: `
                query ($page: SearchSujeitoAtencaoDTOInput){
                  findAllSujeitoAtencao(searchDTO: $page){
                    last
                    content {
                      value: id
                      label: nome
                    }
                  }
                }`,
        variables: {
          page: {
            search: search,
            pageSize: 20,
            pageNumber,
            sortField: 'nome',
            sortDir: 'ASC'
          }
        }
      }
    );
    const { findAllSujeitoAtencao } = response?.data?.data;
    return findAllSujeitoAtencao;
  };

  @action resetSearchFiltros = () => {
    this.searchDTO = {
      ...this.searchDTO,
      profissionalSaudeId: null,
      tipo: null,
      convenioId: null,
      formasPagamentoId: [],
      sujeitoAtencaoId: null,
      categoriaFinanceiroId: null,
      contaBancariaId: null,
      pageableDTO: null,
      centroCustoId: null,
      status: [],
    };
  };

  createProcedimentosIdsArray = (data) => {
    const procedimentosIds = data.map(item => {
      return { id: item.procedimento.id }
    });
    return procedimentosIds;
  }

  @action changeTabSelectedParcelamento = (tabSelected) => {
    this.modalState.tipoRepeticao = tiposRepeticao[tabSelected];

    this.tabSelectedParcelamento = tabSelected;
    this.updateTabelaTituloParcela();
  };

  @action atualizarTotalComProcedimento = (procedimento, operation, quantidadeSessoes = 1) => {
    let valorAtual = 0;
    if (typeof this.titulo.valor === "string") {
      valorAtual = string.removeMask(this.titulo.valor, "R$");
    } else if (typeof this.titulo.valor === "number") {
      valorAtual = this.titulo.valor;
    } else {
      valorAtual = this.titulo?.valor ? parseFloat(this.titulo?.valor?.replace(",", "."))
        : 0;
    };

    let total = 0;
    const procedimentoValor = this.getValorProcedimento(procedimento);
    if (operation === "sum") {
      total = (procedimentoValor * quantidadeSessoes) + valorAtual;
    }

    if (operation === "sub") {
      total = valorAtual - (procedimentoValor * quantidadeSessoes);
    }
    this.titulo.valor = total.toFixed(2).replace(".", ",");
    if (this.titulo.tipoDesconto) {
      this.verificaDadosParaDescontoPreenchido();
    } else {
      this.titulo.subtotal = total.toFixed(2).replace(".", ",");
    }
  };

  @action getValorProcedimento = (procedimento, convenioId = null) => {
    const id = convenioId || this.titulo?.convenio?.id;

    if (id) {
      const convenioProcedimento = procedimento?.convenioProcedimentos?.filter(
        (item) => {
          return item.convenio.id === id;
        }
      );

      if (convenioProcedimento?.length > 0) {
        return convenioProcedimento[0].valor;
      } else {
        return 0;
      }
    }
  };

  @action getValorAgendamentoTipo = (agendamentoTipo, convenioId = null) => {
    const id = convenioId || this.titulo?.convenio?.id;

    if (id) {
      const convenioAgendamentoTipo = agendamentoTipo?.convenioAgendamentoTipo?.filter(
        (item) => {
          return item.convenio.id === id;
        }
      );

      if (convenioAgendamentoTipo?.length > 0) {
        return convenioAgendamentoTipo[0].valor;
      } else {
        return 0;
      }
    }
  };

  @action verificaDadosParaDescontoPreenchido = () => {
    const valor = string.removeMask(String(this.titulo.valor), "R$");

    this.titulo.subtotal = this.titulo.valor;
    if (this.titulo.valor && this.titulo.tipoDesconto && this.titulo.desconto) {
      if (this.titulo.tipoDesconto.value === "VALOR") {
        const descontoValor = typeof this.titulo.desconto === "string" ? string.removeMask(this.titulo.desconto, "R$") : this.titulo.desconto

        this.titulo.subtotal = applyCurrencyMask(valor - descontoValor);

      } else {
        const descontoPercentil = typeof this.titulo.desconto === "string" ? string.removeMask(this.titulo.desconto, "%") : this.titulo.desconto
        const valorDesconto = valor * (descontoPercentil / 100);

        this.titulo.subtotal = applyCurrencyMask(valor - valorDesconto);
      }
    }
  };

  @action handleChangeModal = async (field, event) => {
    if (
      field === "sujeitoAtencao" ||
      field === "profissionalSaude" ||
      field === "formaPagamento" ||
      field === "centroCusto" ||
      field === "periodicidade" ||
      field === "contaBancaria" ||
      field === "categoriaFinanceira" ||
      field === "dataPagamentoEntrada" ||
      field === "dataPagamento" ||
      field === "dataVencimento" ||
      field === "totalParcelas"
    ) {
      this.titulo[field] = event;
    } else if (field === "nome") {
      this.titulo[field] = event.target.value.substring(0, 255);
    } else if (field === "convenio") {
      this.selectConvenio(event);
    } else if (field === "tipoDesconto") {
      this.titulo[field] = event;
      this.titulo.desconto = "";
      this.verificaDadosParaDescontoPreenchido();
      this.updateTabelaTituloParcela();

    } else if (field === "desconto") {
      this.titulo[field] = event.target.value;
      this.verificaDadosParaDescontoPreenchido();
      this.updateTabelaTituloParcela();

    } else {
      this.titulo[field] = event.target.value;
    }

    if (field === "sujeitoAtencao" && !!event) {
      this.titulo.profissionalSaude = event.profissionalSaude;
      this.titulo.convenio = event.convenio;
      if (event.convenio) {

        if (event.convenio.convenioAgendamentoTipo.length > 0) {
          const valor = getValorTipoConvenio(
            this.tipoConsultaAgendamento,
            event.convenio.convenioAgendamentoTipo
          );
          this.titulo.valor = `R$${valor > 0 ? parseFloat(valor)
            .toFixed(2)
            .replace(".", ",") : ''}`;
          this.titulo.subtotal = this.titulo.valor
        }
      } else {
        this.titulo.valor = 0;
        this.titulo.subtotal = 0;
      }
    }
    if (field === "valor") {
      this.verificaDadosParaDescontoPreenchido();
      this.updateTabelaTituloParcela();
    }

    if (
      field === "dataVencimento" ||
      field === "periodicidade" ||
      field === "valorEntrada" ||
      field === "quantidade" ||
      field === "totalParcelas"
    ) {
      this.updateTabelaTituloParcela();
    }

    if (field === "valorEntrada" && event.target.value === "" && this.titulo.valorEntrada === "") {
      this.titulo.contaBancaria = "";
      this.titulo.dataPagamentoEntrada = "";
    };
  };

  @action selectProcedimento = (procedimento) => {
    this.titulo.procedimentos.push(procedimento);
  };

  selectConvenio = async (convenio) => {
    const convenioSelectedValue = convenio
      ? await getValorTipoConvenio(
        this.tipoConsultaAgendamento,
        convenio?.convenioAgendamentoTipo
      )
      : await getValorTipoConvenio(
        this.tipoConsultaAgendamento,
        this.titulo.convenio.convenioAgendamentoTipo
      );
    if (convenio && !this.titulo.convenio) {
      this.atualizarTotalComConvenio(parseFloat(convenioSelectedValue), "sum");
    }

    if (convenio && this.titulo.convenio && !this.isAgendamento) {
      const convenioCurrentValue = await getValorTipoConvenio(
        this.tipoConsultaAgendamento,
        this.titulo.convenio.convenioAgendamentoTipo
      );
      this.atualizarTotalComConvenio(parseFloat(convenioCurrentValue), "sub");
      this.atualizarTotalComConvenio(parseFloat(convenioSelectedValue), "sum");
    }
    if (!convenio) {
      this.titulo.valor = "R$";
      this.titulo.subtotal = this.titulo.valor;
    }

    if (
      convenio &&
      this.titulo.procedimentos &&
      this.titulo.procedimentos.length > 0 &&
      !this.isAgendamento
    ) {
      this.titulo.valor = convenioSelectedValue
        .toFixed(2)
        .replace(".", ",");

      this.titulo.procedimentos.forEach((procedimento) => {
        if (!procedimento.convenioProcedimentos) return;
        procedimento.convenioProcedimentos.forEach((item) => {
          if (item.convenio.id === convenio.id) {
            this.atualizarTotalComConvenio(item.valor, "sum");
          }
        });
      });
    }
    this.titulo.convenio = convenio;
  };

  getTipoDescontoTransformObjeto = (tipoDesconto) => {
    const findTipoDesconto = tiposDesconto.find(item => item.value === tipoDesconto);
    return findTipoDesconto;
  };

  atualizarTotalComConvenio = (value, operation) => {
    const valorAtual = this.titulo.valor && this.titulo.valor > 0
      ? parseFloat(string.removeMask(this.titulo.valor || "", "R$"))
      : 0;
    let total = 0;
    if (operation === "sum") {
      total = valorAtual + value;
    }

    if (operation === "sub") {
      total = valorAtual - value;
    }
    this.titulo.valor = `R$${total > 0 ? total.toFixed(2).replace(".", ",") : ''}`;
    this.titulo.subtotal = this.titulo.valor;
  };

  handleFormaPagamentoLoadOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return this.handleLoadMoreOptions({
      search,
      loadedOptions,
      data: { page },
      method: "findAllFormaPagamento",
    });
  };

  handleCentroCustoLoadOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return this.handleLoadMoreOptions({
      search,
      loadedOptions,
      data: {
        page,
        searchDTO: {
          ativo: true,
        },
      },
      method: "findAllCentroCusto",
    });
  };

  handleConvenioLoadOptions = (search, loadedOptions, { page }) => {
    return this.handleLoadMoreOptions({
      search,
      loadedOptions,
      data: {
        page,
        searchDTO: {
          ativo: true,
        },
      },
      method: "findAllConvenio",
    });
  };

  handleCategoriaLoadOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return this.handleLoadMoreOptions({
      search,
      loadedOptions,
      data: {
        page,
        searchDTO: {
          categoriaFinanceiraTipo: this.titulo.tipo,
        },
      },
      method: "findAllCategoriaFinanceira",
    });
  };

  handleContaBancariaLoadOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return this.handleLoadMoreOptions({
      search,
      loadedOptions,
      data: { page },
      method: "findAllContaBancaria",
    });
  };

  handleLoadMoreOptions = async ({ search, data, method }) => {
    let searchDTO = {};

    if (data.searchDTO) {
      searchDTO = {
        ...data.searchDTO,
      };
    }

    const response = await this[method]({
      pageNumber: data.page,
      search,
      ...searchDTO,
    });

    return {
      options: response.content,
      hasMore: !response.last,
      additional: {
        page: data.page + 1,
      },
    };
  };

  @action async preencherDadosPagamentoDoAgendamento(titulo = null) {
    const { idOpened } = this.atendimentoStore;

    if (!idOpened) return;

    const dadosTitulos = titulo ? [titulo] : await this.findTitulosByAgendamentoId(idOpened);
    const dadosTitulo = dadosTitulos?.length === 1 ? dadosTitulos[0] : dadosTitulos;

    if (Array.isArray(dadosTitulo)) {
      this.titulo = dadosTitulo;
      return;
    }

    let subtotal = "";

    if (dadosTitulo?.tipoDesconto) {
      if (dadosTitulo?.valor && dadosTitulo?.desconto) {
        if (dadosTitulo?.tipoDesconto === "VALOR") {
          const descontoValor = typeof dadosTitulo?.desconto === "string" ? string.removeMask(dadosTitulo?.desconto, "R$") : dadosTitulo?.desconto

          subtotal = applyCurrencyMask(dadosTitulo?.valor - descontoValor);

        } else {
          const descontoPercentil = typeof dadosTitulo?.desconto === "string" ? string.removeMask(dadosTitulo?.desconto, "%") : dadosTitulo?.desconto
          const valorDesconto = dadosTitulo?.valor * (descontoPercentil / 100);

          subtotal = applyCurrencyMask(dadosTitulo?.valor - valorDesconto);
        }
      }
    } else {
      subtotal = dadosTitulo?.valor || "";
    };

    const tituloAdaptado = dadosTitulo?.id && {
      ...dadosTitulo,
      tipoDesconto: tiposDesconto.find(item => item.value === dadosTitulo?.tipoDesconto),
      subtotal: subtotal,
      quantidade: dadosTitulo?.totalParcelas,
      periodicidade: dadosTitulo.periodicidade && { id: dadosTitulo.periodicidade, value: dadosTitulo.periodicidade, label: string.capitalize(dadosTitulo.periodicidade) },
      totalParcelas: { value: dadosTitulo.totalParcelas }
    };

    const valorParcela = dadosTitulo?.tipoRepeticao === "PARCELAR" && (string.removeMask(String(subtotal), "R$") - dadosTitulo.valorEntrada) / dadosTitulo.totalParcelas
    this.modalState = dadosTitulo?.tipoRepeticao ? {
      ...this.modalState,
      tipoRepeticao: dadosTitulo?.tipoRepeticao,
      valorParcela: valorParcela || "",
    }
      : this.modalState;
    this.tabSelectedParcelamento = dadosTitulo?.tipoRepeticao ? tiposRepeticao.indexOf(dadosTitulo.tipoRepeticao) : tabsParcelamento.NAO_REPETIR;
    this.titulo = dadosTitulo?.id ? tituloAdaptado : defaultTitulo;
    this.isAgendamento = true;
    this.atendimentoStore.convenioPagamento = dadosTitulo?.convenio;
    dadosTitulo?.id && this.criarListaParcelas(dadosTitulo?.tipoRepeticao, dadosTitulo.parcelas);
    this.getTabs();

    return dadosTitulos;
  };

  @action async findByIdTitulo(id) {
    try {
      this.opening = true;
      const response = await Api.post("", {
        query: `
        query ($id: Long) {
          findByIdTitulo(id: $id) {
            id
            nome
            tipoRepeticao
            totalParcelas
            valorEntrada
            codigoTransicao
            agendamento {
              id
            }
            convenio {
              value: id
              label: descricao
              id
              descricao
            }
            categoriaFinanceira {
              value: id
              label: nome
              id
              nome
            }
            centroCusto {
              value: id
              label: nome
              id
              nome
            }
            formaPagamento {
              value: id
              label: nome
              id
              nome
            }
            contaBancaria {
              value: id
              label: nome
              id
              nome
            }
            dataPagamento
            dataVencimento
            desconto
            periodicidade
            formaPagamento {
              value: id
              label: nome
              id
              nome
            }
            procedimentos {
              id
              descricao
              convenioProcedimentos {
                convenio {
                  id
                  descricao
                }
                valor
              }
            }
            profissionalSaude {
              value: id
              label: nome
              id
            }
            sujeitoAtencao {
              value: id
              label: nome
              id
            }
            parcelas {
              id
              valor
              desconto
              tipoDesconto
              excluido
              dataVencimento
              dataPagamento
              numeroParcela
              totalParcelas
              chavePublica
            }
            tipo
            tipoDesconto
            tipoRepeticao
            valor
          }
        }
        `,
        variables: {
          id
        }
      });

      return response.data.data.findByIdTitulo;
    } catch (e) {
      console.error(e)
    } finally {
      this.opening = false;
    }
  };

  @action async findByIdTituloParcela(id) {
    try {
      this.loadingModalParcela = true;
      const response = await Api.post("", {
        query: `
          query ($id: Long) {
            findByIdTituloParcela(id: $id) {
              id
              valor
              dataVencimento
              dataPagamento
              desconto
              tipoDesconto
              nome
              tipo
              excluido
              numeroParcela
              chavePublica
              codigoTransicao
              formaPagamento {
                id
                value:id
                label:nome
              }
              contaBancaria {
                id
                value:id
                label:nome
              }
            }
          }
        `,
        variables: {
          id
        }
      });

      const tituloParcela = response.data.data.findByIdTituloParcela;
      const subtotal = string.calculaDesconto(tituloParcela.valor, tituloParcela.desconto, tituloParcela.tipoDesconto);

      this.tituloParcela = {
        ...tituloParcela,
        tipoDesconto: tiposDesconto.find(item => item.value === tituloParcela.tipoDesconto),
        subtotal,
      };

      return response.data.data.findByIdTituloParcela;
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingModalParcela = false;
    }
  }

  getParcelasComStatus(parcelas) {
    const listaParcelasComStatus = parcelas.map(item => {
      const status = getStatusFinanceiro(item.dataVencimento, item.dataPagamento);

      return {
        ...item,
        status
      }
    });
    return listaParcelasComStatus;
  };

  @action async findByIdTituloIdParcelas(id) {
    try {
      const response = await Api.post("", {
        query: `
        query ($id: Long) {
          findByIdTitulo(id: $id) {
            id
            tipoRepeticao
            parcelas {
              id
              valor
              chavePublica
              dataVencimento
              dataPagamento
              desconto
              tipoDesconto
              numeroParcela
              nome
              tipo
              excluido
              codigoTransicao
              formaPagamento {
                id
                value:id
                label:nome
              }
              contaBancaria {
                id
                value:id
                label:nome
              }
            }
          }
        }
        `,
        variables: {
          id
        }
      });
      const dadosTitulo = response.data.data.findByIdTitulo;
      dadosTitulo && this.criarListaParcelas(dadosTitulo?.tipoRepeticao, dadosTitulo?.parcelas);

      return response.data.data.findByIdTitulo;
    } catch (e) {
      console.error(e)
    } finally {
      this.opening = false;
    }
  };

  @action recalculaParcelas() {
    if (!this.titulo.id) {
      return;
    }
    const titulosPago = this.modalState.tituloParcelaList.filter(item => item.status === "Pago")
      .map((item, index) => {
        return {
          ...item,
          numeroParcela: item.numeroParcela === "E" ? "E" : index
        }
      });

    const quantidadeParcelasPagas = titulosPago.some(item => item.numeroParcela === "E") ? titulosPago.length - 1 : titulosPago.length;

    const {
      valorEntrada,
      dataVencimento,
      totalParcelas,
      subtotal
    } = this.titulo;

    const hasValidUpdateParcelar =
      !string.isEmpty(String(subtotal)) &&
      (totalParcelas?.value) &&
      !!dataVencimento;

    if (hasValidUpdateParcelar) {
      const entradaParcela = this.modalState.tituloParcelaList.find(item => item.numeroParcela === "E");

      let totalParaParcelar = titulosPago.length > 0 ?
        string.currencyMaskToFloat(String(subtotal)) - this.getValorTotalPago(titulosPago)
        : string.currencyMaskToFloat(String(subtotal));

      if (entradaParcela && entradaParcela?.status !== "Pago") {
        totalParaParcelar = totalParaParcelar - string.removeMask(String(entradaParcela.valor), "R$");
      };

      const valorParcela = totalParaParcelar / (totalParcelas?.value - quantidadeParcelasPagas);

      this.modalState.valorParcela = parseFloat(valorParcela);

      let parcelas = titulosPago;

      for (let i = 1; i <= (parseInt(totalParcelas?.value) - quantidadeParcelasPagas); i++) {
        parcelas.push({
          numeroParcela: i + quantidadeParcelasPagas,
          dataVencimento: moment(dataVencimento).add(i, "months").format("YYYY-MM-DD"),
          valor: valorParcela,
        });
      }

      if (valorEntrada) {
        if (!entradaParcela?.dataPagamento && !entradaParcela?.excluido) {
          const entrada = {
            numeroParcela: "E",
            dataVencimento: moment(dataVencimento).format("YYYY-MM-DD"),
            valor: entradaParcela?.valor,
          }

          parcelas = [entrada, ...parcelas]
        }
      }

      this.modalState.tituloParcelaList = this.getParcelasComStatus(parcelas);
    }
  }

  @action recalculaRepetir() {
    if (!this.titulo.id) {
      return;
    }

    const list = this.modalState.tituloParcelaList.filter(item => item.status === "Pago");

    const {
      dataVencimento,
      periodicidade,
      quantidade,
      subtotal
    } = this.titulo;

    const hasPeriodicidade = periodicidade?.id;
    const hasValidUpdateRepetir =
      !string.isEmpty(String(subtotal)) &&
      hasPeriodicidade &&
      parseInt(quantidade) &&
      !!dataVencimento;

    if (hasValidUpdateRepetir) {
      const repetirQuantidade = list.length > 0 ? parseInt(quantidade) - list.length : parseInt(quantidade);

      let dataVencimentoAtual = moment(dataVencimento);

      for (let i = 1; i <= repetirQuantidade; i++) {
        const first = i === 1;
        if (!first && periodicidade.value === TituloPeriodicidade.ANUALMENTE) {
          dataVencimentoAtual = dataVencimentoAtual.add(1, "years");
        }
        if (!first && periodicidade.value === TituloPeriodicidade.DIARIAMENTE) {
          dataVencimentoAtual = dataVencimentoAtual.add(1, "days");
        }
        if (!first && periodicidade.value === TituloPeriodicidade.MENSALMENTE) {
          dataVencimentoAtual = dataVencimentoAtual.add(1, "months");
        }
        if (
          !first &&
          periodicidade.value === TituloPeriodicidade.SEMANALMENTE
        ) {
          dataVencimentoAtual = dataVencimentoAtual.add(1, "weeks");
        }
        if (
          !first &&
          periodicidade.value === TituloPeriodicidade.QUINZENALMENTE
        ) {
          dataVencimentoAtual = dataVencimentoAtual.add(15, "days");
        }

        list.push({
          numeroParcela: i,
          dataVencimento: dataVencimentoAtual.format("YYYY-MM-DD"),
          valor: string.currencyMaskToFloat(String(subtotal))
        });
      }

      this.modalState.tituloParcelaList = this.getParcelasComStatus([...list]);
    }
  };

  getValorTotalPago(lista) {
    const valorTotalParcelasPaga = lista.reduce((previousValue, currentValue) => previousValue + currentValue.valor, 0)

    return valorTotalParcelasPaga;
  };

  @action findTitulosByAgendamentoId = async (id) => {
    const response = await Api.post("", {
      query: `
      query ($agendamentoId: Long) {
          findTitulosByAgendamentoId(agendamentoId: $agendamentoId) {
            id
           
            nome
            tipoRepeticao
            totalParcelas
            valorEntrada
            ativo
            agendamento {
              id
              agendamentoRecorrencia {
                id
              }
            }
            convenio {
              value: id
              label: descricao
              id
              descricao
              convenioAgendamentoTipo {
                agendamentoTipo {
                    id
                }
                valor
              }
              convenioProcedimentos {
                procedimento {
                  id
                  nome
                }
                valor
              }
            }
            categoriaFinanceira {
              value: id
              label: nome
              id
              nome
            }
            centroCusto {
              value: id
              label: nome
              id
              nome
            }
            formaPagamento {
              value: id
              label: nome
              id
              nome
            }
            contaBancaria {
              value: id
              label: nome
              id
              nome
            }
            dataPagamento
            dataVencimento
            desconto
            periodicidade
            formaPagamento {
              value: id
              label: nome
              id
              nome
            }
            procedimentos {
              id
              nome
              convenioProcedimentos {
                convenio {
                  id
                  descricao
                }
                valor
              }
            }
            tituloProcedimentos {
              procedimento {
                id
                nome
              }
              quantidade
            }
            profissionalSaude {
              value: id
              label: nome
              id
            }
            sujeitoAtencao {
              value: id
              label: nome
              id
            }
            parcelas {
              id
              valor
              desconto
              tipoDesconto
              excluido
              dataVencimento
              dataPagamento
              numeroParcela
              totalParcelas
              chavePublica
            }
            tipo
            tipoDesconto
            tipoRepeticao
            valor
          }
        }
      `,
      variables: {
        agendamentoId: id
      }
    });

    const { findTitulosByAgendamentoId } = response?.data?.data || {};

    const titulos = findTitulosByAgendamentoId && findTitulosByAgendamentoId.length > 0 ? this.getTituloAtivo(findTitulosByAgendamentoId) : null;

    if (response?.data?.errors) {
      throw response.data.errors[0]
    };

    return titulos;
  };

  getTituloAtivo = (titulos) => {
    const tituloAtivo = titulos.filter(item => item.ativo)

    return tituloAtivo;
  };

  @action vincularTituloAgendamento = async (variables) => {
    const response = await Api.post("", {
      query: `
      mutation ($agendamentoId: Long, $tituloId: Long) {
        vincularTituloAgendamento(agendamentoId: $agendamentoId, tituloId: $tituloId) {
          id
          ativo
        }
      }
      `,
      variables: variables
    });

    if (response.data.errors) {
      throw response.data.errors[0]
    };

    return response.data.data.vincularTituloAgendamento
  };

  @action inativarTitulo = async (id) => {
    const response = await Api.post("", {
      query: `
        mutation ($tituloId: Long) {
          inativarTitulo(tituloId: $tituloId) {
            id
          }
        }
      `,
      variables: { tituloId: id }
    });

    if (response.data.errors) {
      throw response.data.errors[0]
    };

    return response.data.data.inativarTitulo
  };

  @action resetTitulo = () => {
    this.titulo = defaultTitulo;
    this.convenioPagamento = null;
    this.modalState = defaultModalState;
  };


  @action recalculaSubtotalParcela = () => {
    if (!this.tituloParcela.valor || !this.tituloParcela.tipoDesconto?.value || !this.tituloParcela.desconto) {
      return
    };

    const valorSemMascara = typeof this.tituloParcela.valor === "string" ? string.removeMask(this.tituloParcela.valor, "R$") : this.tituloParcela.valor;
    const descontoSemMascara = typeof this.tituloParcela.desconto === "string" ? string.removeMask(this.tituloParcela.desconto, "R$") : this.tituloParcela.desconto;

    this.tituloParcela.subtotal = string.calculaDesconto(valorSemMascara, descontoSemMascara, this.tituloParcela.tipoDesconto?.value)
  };

  @action calculaValoresModalFooter = () => {
    const valoresTotais = this.modalState.tituloParcelaList.reduce(
      (acumulador, parcela) => {
        if (!acumulador[parcela?.status]) {
          acumulador[parcela?.status] = 0;
        };

        if (!acumulador.Total) {
          acumulador.Total = 0
        };

        acumulador[parcela?.status] = acumulador[parcela?.status] + parcela?.valor;
        acumulador.Total = acumulador.Total + parcela?.valor;
        return acumulador;
      },
      {});

    return valoresTotais;
  };

  @action async preencherDadosOrcamentoOdontologico({ tipo, sujeitoAtencao, profissionalSaude, procedimentos, orcamento }) {
    const {
      id: orcamentoId,
      nome,
      convenio: convenioOrcamento,
      valor,
      desconto,
      totalParcelas,
      valorEntrada,
      subtotal,
    } = orcamento || {};
    const { dadosConvenio, id, nome: sujeitoAtencaoNome } = sujeitoAtencao || {};
    const { convenio: convenioSujeitoAtencao } = dadosConvenio || {};
    const convenio = convenioOrcamento || convenioSujeitoAtencao;
    this.titulo = {
      ...defaultTitulo,
      tipo,
      sujeitoAtencao: {
        ...sujeitoAtencao,
        value: id,
        label: sujeitoAtencaoNome,
      },
      convenio:
        convenio && {
          ...convenio,
          value: convenio.id,
          label: convenio.descricao,
        },
      valor,
      nome,
      profissionalSaude,
      procedimentos,
      subtotal,
      desconto: desconto?.valorDesconto || 0,
      totalParcelas,
      valorEntrada,
      tipoDesconto: desconto?.tipoDesconto || null,
      orcamento: {
        id: orcamentoId
      }
    };

    this.getTabs();
    this.open = true;
    this.editMode = true;
  }

  @action findVouchersAssociado = async (variables) => {
    try {
      this.loadingVouchers = true;
      const unidadeAtual = await this.usuarioStore.getUnidadeAtual();

      const { whitelabel } = unidadeAtual
      const isAngeplus = whitelabel?.nome === "ANGEPLUS";

      if (!isAngeplus) return;

      const response = await pesquisaVoucherAssociado(variables);
      this.vouchersAssociado = response?.voucherOrdenado || [];
    } catch (error) {
      console.error(error);
      this.vouchersAssociado = [];
    } finally {
      this.loadingVouchers = false;
    }
  }

  @action handleOpenAzulControleFaturamento = async ({ agendamentoId }) => {
    try {
      const urlAzulControle = await linkFaturamentoAgendamento({ agendamentoId });
      window.open(urlAzulControle)

    } catch (error) {
      this.atendimentoStore.showAlertMessage({
        isOpen: true,
        message: 'Erro ao acessar o financeiro',
        variant: "error"
      });
    };
  };

  @action linkNotaAgendamento = async ({agendamentoId}) => {
    try {
      const url = await linkNotaAgendamento({agendamentoId});
      return url;
    } catch(error) {
      this.atendimentoStore.showAlertMessage({
        isOpen: true,
        message: 'Erro ao acessar o financeiro',
        variant: "error"
      });
    };
  };
}
