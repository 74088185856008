import React, { useEffect, useState } from "react";
import CloseIcon from "../../../../components/Icon/Close";
import Dialog from "../../../../components/Dialog/Dialog";
import { Fab, withStyles } from "@material-ui/core";
import { Button } from "../../../../components/ui/Buttons";
import AltaIcon from "../../../../components/Icon/AltaIcon";
import { ReactSelect } from "../../../../components/Select/SelectSearch";
import { findAllTuss39TerminologiaMotivoEncerramento } from "../../../../services/InternacaoService";
import moment from "moment";
import CustomDateTimePicker from "../../../../components/CustomDateTimePicker";
import { inject } from "mobx-react";

const pageableDTO = {
  pageNumber: 0,
  pageSize: 999,
  sortDir: "ASC",
  sortField: "termo",
};

const ModalAltaPaciente = ({
  classes,
  open,
  handleClose,
  handleChange,
  dados,
  handleDarAlta,
  disabled,
  internacao,
  atendimentoStore,
}) => {
  const [motivosAlta, setMotivosAlta] = useState([]);
  const { leitosOcupados } = internacao;
  const { data } = atendimentoStore.objView || {}; 

  useEffect(() => {
    loadAllModelosAlta();
    if (!dados.dataAlta) {
      findDataAtual();
    }
  }, []);

  const findDataAtual = async () => {
    const dataAtual = moment();
    await handleChange(dataAtual, "dataAlta");
  };

  const loadAllModelosAlta = async () => {
    try {
      const variables = {
        pageableDTO,
      };

      const response = await findAllTuss39TerminologiaMotivoEncerramento(
        variables
      );
      setMotivosAlta(response.content);
    } catch (error) {
      console.error(error);
    }
  };

  const getDataMin = () => {
    const dataMaisAntiga = leitosOcupados.reduce((minDate, item) => {
      const dataAtual = moment(item.dataInicioOcupacao);
      return dataAtual.isBefore(minDate) ? dataAtual : minDate;
    }, moment(leitosOcupados[0].dataInicioOcupacao));
  
    return dataMaisAntiga.isValid() ? dataMaisAntiga : moment(data);
  };

  const disabledButton = disabled || moment(dados.dataAlta).isBefore(moment(getDataMin())) 

  return (
    <Dialog open={open} classes={{ paper: classes.Dialog }}>
      <div className={classes.headerModal}>
        <div className={classes.title}>Alta paciente </div>
        <Fab onClick={handleClose} size={"medium"} color={"secondary"}>
          <CloseIcon />
        </Fab>
      </div>
      <div>
        <AltaIcon width={"100"} height={"100"}></AltaIcon>
      </div>
      <div className={classes.contentInputs}>
        <div className={classes.inputData}>
          <label className={classes.label}>Data da Alta: </label>
          <CustomDateTimePicker
            onChange={(e) => handleChange(e, "dataAlta")}
            value={dados.dataAlta}
            invalidLabel=""
            invalidDateMessage=""
            disabled={disabled}
            minDate={getDataMin()}
          />
        </div>
        <div className={classes.contentTextField}>
          <label className={classes.label}>Motivo:</label>
          <ReactSelect
            className={classes.textField}
            placeholder="Selecione"
            value={dados.tuss39TerminologiaMotivoEncerramento}
            options={motivosAlta}
            getOptionLabel={(option) => option.termo}
            getOptionValue={(option) => option.id}
            onChange={(e) => handleChange(e, "tuss39TerminologiaMotivoEncerramento")}
            isDisabled={disabled}
          />
        </div>
      </div>
      <div className={classes.contentButton}>
        {!disabled && 
          <Button onClick={handleDarAlta} disabled={disabledButton}>
            Salvar
          </Button>}
      </div>
    </Dialog>
  );
};

const styles = {
  Dialog: {
    display: "flex",
    flexDirection: "column",
    width: "500px",
    height: "400px",
    padding: "16px",
    alignItems: "center",
    overflow: "hidden",
    justifyContent: "space-between",
  },
  headerModal: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    padding: "10px",
  },
  contentInputs: {
    display: "flex",
    flexDirection: "column",
    width: "360px",
    gap: "10px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "600",
  },
  inputData: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  },
  label: {
    color: "#505050",
    fontSize: "14px",
    minWidth: "100px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
  },
  dateTime: {
    width: "100%",
    fontFamily: "Poppins !important",
    fontSize: 12,
    "& input": {
      color: "#505050",
      fontWeight: 500,
    },
  },
  contentTextField: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    gap: "10px",
  },
  contentButton: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "flex-end",
  },
  textField: {
    width: "100%",
  },
};

const ModalAltaPacienteWithStyles = withStyles(styles)(ModalAltaPaciente);
export default inject("atendimentoStore")(ModalAltaPacienteWithStyles);
