import styled from "styled-components";
import { width } from "styled-system";

export const Form = styled.div`
     width: 100%;
    background-color: #fff;
    border-radius: 17px;
    box-sizing: border-box;
    margin-top: 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const styles = {
    contentPrescricao: {
        color: '#505050',
        display: 'flex',
        padding: '0.725rem 1.25rem',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    buttonEdit: {
        backgroundColor: '#26ACA9',
        minWidth: 20,
        minHeight: 20,
        width: 24,
        height: 24,
        color: 'white',
        alignContent: 'center',
        fontSize: 'medium',
        margin: 5,
        padding: 5
    },
    buttonRemove: {
        backgroundColor: '#FB7676',
        minWidth: 20,
        minHeight: 20,
        width: 24,
        height: 24,
        color: 'white',
        alignContent: 'center',
        fontSize: '18px',
        margin: 5
    },
    actions: {
        display: "flex",
        alignItems: "flex-start",
        marginTop: " -8px",
    },
    label: {
        whiteSpace: "pre-wrap",
        "@media (max-width: 900px)": {
            whiteSpace: "normal !important"
        }
    },
    wrapper: {
        width: '100%',
        display: 'flex',
        padding: '12px',
        background: '#f2f2f2',
        alignItems: 'center',
        alignContent: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: '10px'
    },
    buttonSalvar: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        padding: "5px",
    },
    button: {
        color: '#FFFFFF',
        border: '0',
        height: '48px',
        padding: '0 30px',
        background: '#00B0AE',
        borderRadius: '30px'
    },
    containerPrescricoes: {
        overflow: 'scroll',
        height: 'calc(100% - 284px)',
    },
    container: {
        height: '100%',
    },
    contentDrug: {
        padding: '10px'
    },
    containerMenu: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '33px',
        maxWidth: '300px'
    },
    wraperPrescricao: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        gap: '10px',
        justifyContent: 'space-between',
        maxWidth: '600px',
        width: '100%'
    },
    labelConduta: {
        fontWeight: 600
    },
    amount:{
        width:"50px",
    },
    conduta:{
        width: '210px',
        height: '122px' 
    }

};