import React from "react";
import { inject } from "mobx-react";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Search, Clear as ClearIcon } from "@material-ui/icons";
import Dialog from "../../../../components/Dialog/Dialog";
import { menuOpcoesPopper } from "../../Constants/Constants";
import InputSearch from "../../../../components/Input/Input";
import CloseIcon from "../../../../components/Icon/CloseIcon";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../../utils/getPageTitle";
import Auditoria from "../../../../components/Auditoria/Auditoria";
import PopperCustomMenu from "../../../../components/Popper/PopperCustomMenu";
import MultiToggleButtons from "../../../../components/Button/MultiToggleButtons";
import { Button } from "../../../../components/ui/Buttons";

const ModalConfiguracoesTabs = observer(
  ({
    classes,
    modalConfiguracoesStore,
    itemSelecionado,
    onClose,
    renderHeader,
    propsTabs,
    children,
    handleSave,
    propsPopperCustom,
    propsTabsAuditoria,
    loadingSave,
    hiddenPopper,
  }) => {
    const { titulo, showModal } = modalConfiguracoesStore;
    const modalEdit = itemSelecionado?.id;

    const getPageTitle = () => {
      const acao = modalEdit ? "Novo" : "Editar";

      return pageConfiguracoes(`${acao} ${titulo}`);
    };

    return (
      <>
        <PageTitle title={getPageTitle()} />
        <Dialog
          open={showModal}
          classes={{
            paper: classes.paper,
            paperScrollPaper: classes.paperScrollPaper
          }}
          scroll="paper"
          maxWidth="md"
          fullWidth
        >
          <div className={classes.tituloFechar}>
            <span className={classes.titulo}>
              {modalEdit ? `Editar ${titulo}` : `Novo ${titulo}`}
            </span>
            <div className={classes.buttonsHeader}>
              {modalEdit && !hiddenPopper && (
                <PopperCustomMenu
                  colorIcon="#fff"
                  menuOpcoes={menuOpcoesPopper({
                    functions: {
                      handleEdit: () =>
                        propsPopperCustom.handleEditar(itemSelecionado?.id),
                      handleDuplicar: () =>
                        propsPopperCustom.handleDuplicar(itemSelecionado?.id),
                      handleDesativar: () =>
                        propsPopperCustom.handleDesativar(itemSelecionado?.id),
                      handleAtivar: () =>
                        propsPopperCustom.handleAtivar(itemSelecionado?.id),
                    },
                    desativado: !itemSelecionado.ativo,
                    isEdit: false,
                  })}
                />
              )}
              <Button
                shape='circle'
                bgColor='#FB7676'
                onClick={onClose}
                className={classes.buttonActionClose}
              >
                <CloseIcon />
              </Button>
            </div>
          </div>
          {modalEdit && (
            <MultiToggleButtons
              options={["Informações", "Auditoria"]}
              tabSelected={propsTabsAuditoria.tabSelected}
              changeTabSelected={propsTabsAuditoria.changeTabAuditoriaSelected}
              classes={{ buttonsContainer: classes.buttonsTabAuditoria }}
            />
          )}
          {propsTabsAuditoria?.tabSelected === 0 ? (
            <>
              <div className={classes.header}>
                {renderHeader()}
                </div>
              <div
                className={classNames(
                  classes.content,
                  modalEdit && classes.contentEdit
                )}
              >
                <div className={classes.contentTabs}>
                  {propsTabs.options && <MultiToggleButtons
                    options={propsTabs.options}
                    tabSelected={propsTabs.tabSelected}
                    changeTabSelected={propsTabs.changeTabSelected}
                    classes={{ buttonsContainer: classes.buttonsTab }}
                    quantidades={propsTabs.quantidades}
                  />}
                  <InputSearch
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton
                          className={classes.iconButtonSearch}
                          onClick={propsTabs.handleClickSearch}
                        >
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    }
                    endAdornment={
                      propsTabs.valueSearch && (
                        <InputAdornment position="end">
                          <IconButton
                            className={classes.iconButtonSearch}
                            onClick={propsTabs.handleClickClear}
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                    classInputRoot={classes.inputSearch}
                    name={"search"}
                    placeholder="Pesquisar"
                    value={propsTabs.valueSearch}
                    onChange={propsTabs.onChangeSearch}
                    onKeyPress={propsTabs.onKeypressSearch}
                  />
                  {children}
                </div>
                <div className={classes.contentButtonSave}>

                  <Button
                    colorCustom="green"
                    onClick={handleSave}
                    noShadow
                    disabled={loadingSave}
                    loading={loadingSave}
                  >
                    Salvar
                  </Button>

                </div>
              </div>
            </>
          ) : (
            <div className={classes.contentAuditoria}>
              <Auditoria />
            </div>
          )}
        </Dialog>
      </>
    );
  }
);

const styles = {
  paper: {
    width: "745px",
    height: "650px",
    background: "#fff",
    overflow: "hidden"
  },
  paperScrollPaper: {
    maxHeight: '100%'
  },
  header: {
    padding: "10px 32px 16px 32px",
  },
  buttonsTabAuditoria: {
    margin: "0 32px",
  },
  buttonsHeader: {
    display: "flex",
    gap: "16px",
  },
  tituloFechar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  titulo: {
    fontWeight: 700,
    fontSize: "18px !important",
    color: "#333333",
  },
  titleField: {
    color: "#868686",
    fontSize: "12px",
  },
  textField: {
    height: "40px",
    width: "100%",
    "& input": {
      height: "23px",
      background: "#F2F2F2",
      border: "0.5px solid rgba(220, 220, 220, 0.2)",
      borderRadius: "8px",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 600,
      color: '#868686',
    },
    "& ::placeholder": {
      color: '#000'
    },
  },
  contentNome: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentStatus: {
    marginLeft: "24px",
  },
  content: {
    height: "calc(100% - 222px)",
    background: "#f9f9f9",
  },
  contentEdit: {
    flex: 1
  },
  contentTabs: {
    margin: "16px 32px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px 16px 0 16px",
    background: "#fff",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxShadow:
      "10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)",
    borderRadius: "10px",
    height: "calc(100% - 109px)",
  },
  iconButtonSearch: {
    padding: "4px",
  },
  inputSearch: {
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
    fontFamily: "Poppins",
    height: "38px",
    fontSize: "14px",
  },
  contentButtonSave: {
    justifyContent: "end",
    display: "flex",
    width: "100%",
    "& button": {
      width: "200px",
      marginRight: "32px",
    },
  },
  rowNome: {
    marginBottom: "4px",
    display: "flex",
  },
  notchedOutline: {
    "& fieldset": {
      border: "none",
    },
  },
  contentAuditoria: {
    height: "calc(100% - 137px)",
    padding: "16px 16px 0",
    overflow: "auto",
  },
};

const ModalConfiguracoesTabsWithStyles = withStyles(styles)(
  ModalConfiguracoesTabs
);
export default inject("modalConfiguracoesStore")(
  ModalConfiguracoesTabsWithStyles
);
