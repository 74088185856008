import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Switch, withStyles } from "@material-ui/core";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../../utils/getPageTitle";
import styles from "../LeitosStyle";
import ModalConfiguracoesTabs from "../../Components/Modal/ModalConfiguracoesTabs";
import { ReactSelect } from "../../../../components/Select/SelectSearch";
import { InputForm } from "../../../../components/Modal/Input";
import classNames from "classnames";
import ConvenioList from "./ConvenioList";
import { findAllConvenio, findAllTipoLeito, findAllTuss18, saveLeito } from "../../../../services/LeitoService";
import string from "../../../../utils/string";
import { TextFieldSearch } from "../../../../components/TextField";

const propsTabsModalDefault = {
  options: null,
  valueSearch: "",
  quantidades: [0],
};

const LeitoModal = observer(
  ({
    classes,
    auditoriaStore,
    onClose,
    leitoSelecionado,
    openNotification,
    loadLeitos,
  }) => {
    const [leitoSelected, setLeitoSelected] = useState(leitoSelecionado);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingList, setLoadingList] = useState(false);
    const [propsTabsModal, setPropsTabsModal] = useState(propsTabsModalDefault);
    const [pageNumber, setPageNumber] = useState(0);
    const [lastScroll, setLastScroll] = useState(false);
    const [convenios, setConvenios] = useState([]);
    const [tiposLeito, setTiposLeito] = useState([]);

    const { id, nome, tipoLeito, tuss18TaxasGases } = leitoSelected || {};

    useEffect(() => {
      auditoriaStore.auditoriaProps = {
        ...auditoriaStore.auditoriaProps,
        auditoria: "o leito",
        auditoriaTela: "leito",
      };
      loadConvenios({ isClear: true });
      loadTiposLeito();
    }, []);

    const isEdit = leitoSelecionado?.id;
    const getPageTitle = () => {
      const acao = isEdit ? "Editar" : "Novo";

      return pageConfiguracoes(`${acao} leito`);
    };

      const loadTiposLeito = async () => {
        const response = await findAllTipoLeito({
          pageableDTO: {
            sortDir: "ASC",
            sortField: "codigo",
          },
        });
        setTiposLeito(response);
      };

    const handleSave = async () => {
      try {
        setLoadingSave(true);

        if (!nome) {
          openNotification({
            message: "Favor preencher o nome",
            type: "error",
          });
          return;
        }

        const leitoConveniosSemMascara = leitoSelected.leitoConvenios.map(
          (item) => {
            return {
              ...item,
              valor:
                typeof item.valor === "string"
                  ? string.currencyMaskToFloat(item.valor)
                  : item.valor,
            };
          }
        );

        const variables = {
          leito: {
            ...(id && { id }),
            nome,
            ativo: leitoSelected?.id ? leitoSelected.ativo : true,
            ...(tipoLeito?.id && { tipoLeito: {id: tipoLeito.id} }),
            leitoConvenios: leitoConveniosSemMascara,
            ...(tuss18TaxasGases?.id && { tuss18TaxasGases: { id: tuss18TaxasGases.id } }),
          },
        };

        await saveLeito(variables);
        loadLeitos({ isClear: true });
        onClose();
        openNotification({
          message: "Leito salvo com sucesso",
          type: "success",
        });
      } catch (e) {
        openNotification({
          message: "Erro ao salvar o leito",
          type: "error",
        });
      } finally {
        setLoadingSave(false);
      }
    };

    const handleClickSearchModal = () => {
      loadConvenios({ isClear: true });
    };

    const handleClickClearModal = async () => {
      setPropsTabsModal(propsTabsModalDefault);
      loadConvenios({ isClear: true, isClearSearching: true });
    };

    const onChangeSearchModal = (e) => {
      let value = e.target.value;
      setPropsTabsModal({ ...propsTabsModal, valueSearch: value });
    };

    const onKeypressSearchModal = (e) => {
      if (e.key === "Enter" && propsTabsModal.valueSearch.length >= 3) {
        loadConvenios({ isClear: true });
      }
    };

    const loadConvenios = async (options) => {
      try {
        setLoadingList(true);

        const response = await findAllConvenio({
          searchDTO: {
            search: options?.isClearSearching ? "" : propsTabsModal.valueSearch,
            ativo: true,
          },
          pageableDTO: {
            pageNumber: options.isClear ? 0 : pageNumber,
            pageSize: 30,
            sortField: "descricao",
            sortDir: "ASC",
          },
        });

        const { content, last } = response;

        const listChecked = content.map((item) => {
          const convenioChecked = leitoSelected.leitoConvenios.find(
            (convenioItem) => convenioItem.convenio.id === item.id
          );

          return {
            convenio: {
              descricao: item.descricao,
              id: item.id,
            },
            checked: !!convenioChecked,
            valor: convenioChecked?.valor || 0,
          };
        });

        setLastScroll(last);
        setPageNumber(options.isClear ? 1 : pageNumber + 1);
        setConvenios(
          options.isClear ? listChecked : [...convenios, ...listChecked]
        );
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingList(false);
      }
    };

    const handleChangeCheckbox = (item) => {
      item.checked = !item.checked;
      let object = leitoSelected;

      if (item.checked) {
        object.leitoConvenios.push({
          convenio: {
            id: item.convenio.id,
          },
          valor: item.valor,
        });
      } else {
        const array = object.leitoConvenios.filter(
          (convenioItem) => convenioItem.convenio.id !== item.convenio.id
        );
        object.leitoConvenios = array;
      }

      setLeitoSelected((prevState) => ({
        ...prevState,
        leitoConvenios: object.leitoConvenios,
      }));
    };

    const handleChange = (event, itemLista) => {
      const { name, value } = event.target;

      if (itemLista) {
        itemLista.valor = value;
        const indexSelecionado = leitoSelected.leitoConvenios.findIndex(
          (item) => item.convenio.id === itemLista.convenio.id
        );

        if (indexSelecionado >= 0) {
          let object = leitoSelected;
          object.leitoConvenios[indexSelecionado].valor = itemLista.valor;
          setLeitoSelected(object);
        }
      }
    };

    const renderTabModal = () => {
      return (
        <ConvenioList
          leitoSelected={leitoSelected}
          lastScroll={lastScroll}
          loadConvenios={loadConvenios}
          convenios={convenios}
          handleChangeCheckbox={handleChangeCheckbox}
          handleChange={handleChange}
          loadingModal={loadingList}
        />
      );
    };

    const renderHeader = () => {
      const { id, nome, ativo, tipoLeito, tuss18TaxasGases } = leitoSelected || {};

      const handleChange = (field, value) => {
        setLeitoSelected({
          ...leitoSelected,
          [field]: value,
        });
      };

      const loadAllTuss18TaxasGases = async (search, loadedOptions, { page }) => {
          const pageableDTO = {
            pageNumber: page,
            pageSize: 10,
            sortDir: 'ASC',
            sortField: 'codigoTermo',
          }
          const variables = {
            pageableDTO,
            search,
          }
      
          try {
            const { content, last } = (
              await findAllTuss18(variables)
            )
            return {
              options: content,
              hasMore: !last,
              additional: {
                page: page + 1,
              },
            }
          } catch (error) {
            console.error(error)
          }
        }

      return (
        <div className={classes.containerHeader}>
          <div className={classes.row}>
            <div className={classes.contentFieldFullWidth}>
              <div className={classes.titleField}>Nome</div>
              <InputForm
                value={nome}
                onChange={(e) => handleChange("nome", e.target.value)}
                classes={{
                  input: classes.inputRetangular,
                }}
              />
            </div>
            {id && (
              <div
                className={classNames(classes.contentField, classes.fieldAtivo)}
              >
                <div className={classes.titleField}>Ativo</div>
                <Switch
                  checked={ativo}
                  color="primary"
                  onClick={() => handleChange("ativo", !ativo)}
                  classes={{
                    root: classes.switchRoot,
                    switchBase: classes.switchBase,
                  }}
                />
              </div>
            )}
          </div>
          <div className={classes.row}>
          <div className={classes.contentFieldTuss18}>
              <div className={classes.titleField}>Selecione código e descrição</div>
              <TextFieldSearch
                placeholder='Selecione'
                classNotched={classes.notchedOutline}
                classInput={classes.inputContainer}
                classIcons={classes.classIcons}
                loadOptions={loadAllTuss18TaxasGases}
                getOptionLabel={(option) => `${option.codigoTermo} - ${option.descricao}`}
                getOptionValue={(option) => option.id}
                value={tuss18TaxasGases}
                onChange={(e) => handleChange("tuss18TaxasGases", e)}
                withPaginate
                debounceTimeout={300}
                additional={{
                  page: 0,
                }}
              />
            </div>
            <div className={classes.contentField}>
              <div className={classes.titleField}>Tipo leito</div>
              <ReactSelect
                value={tipoLeito}
                options={tiposLeito}
                getOptionLabel={(option) => `${option.codigo} - ${option.descricao}`}
                getOptionValue={(option) => option.id}
                onChange={(e) => handleChange("tipoLeito", e)}
                classes={{
                  input: classes.inputRetangular,
                }}
              />
            </div>
          </div>
        </div>
      );
    };

    return (
      <>
        <PageTitle title={getPageTitle()} />
        <ModalConfiguracoesTabs
          itemSelecionado={leitoSelected}
          title={"leito"}
          renderHeader={renderHeader}
          loadingSave={loadingSave}
          classes={{
            content: leitoSelected?.id
              ? classes.contentModalEdit
              : classes.contentModal,
          }}
          onClose={onClose}
          handleSave={handleSave}
          loadingModal={loadingList}
          propsTabs={{
            ...propsTabsModal,
            handleClickSearch: handleClickSearchModal,
            handleClickClear: handleClickClearModal,
            onChangeSearch: onChangeSearchModal,
            onKeypressSearch: onKeypressSearchModal,
          }}
          hiddenPopper
          propsTabsAuditoria={{
            tabSelected: 0,
            changeTabAuditoriaSelected: () => {},
          }}
        >
          {renderTabModal()}
        </ModalConfiguracoesTabs>
      </>
    );
  }
);

const LeitoModalWithStyles = withStyles(styles)(LeitoModal);
export default inject(
  "auditoriaStore",
  "modalConfiguracoesStore"
)(LeitoModalWithStyles);
