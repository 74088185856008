import React from "react";
import {inject, observer} from "mobx-react";
import {
    Grid,
    Switch, 
} from "@material-ui/core";
import Scroll from '../../../components/InfiniteScroll/Scroll';

import {withStyles} from "@material-ui/core/styles/index";

import {
    findAllAgendamentoTipoPage,
    findByIdAgendamentoTipo,
    saveAgendamentoTipo,
    findAllTuss52TerminologiaTipoConsulta,
    findAllTuss22ProcedimentoEventoSaude,
    agendamentoTipoNomeJaExistente,
} from '../../../services/AgendamentoTipoService';
import Notification from "../../../components/Notification";
import { getUnidadeLogado } from "../../../services/UsuarioService";
import { listarConveniosTipoConsulta } from "../../../services/ConvenioService";
import string from "../../../utils/string";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../utils/getPageTitle";
import { tabs } from "../../../stores/ModalConfiguracoes.store";
import { findAllAgendamentoTipoAuditByAgendamentoTipoId, findAllAgendamentoTipoAuditByUnidade } from "../../../services/AuditoriaService";
import HeaderConfiguracoesButtonNovo from "../Components/HeaderConfiguracoesButtonNovo";
import ContentConfiguracoes from "../Components/ContentConfiguracoes";
import Table from "../../../components/Table/Table";
import { columnsConfiguracoes } from "../Constants/Constants";
import AddIcon from "../../../components/Icon/AddIcon";
import ModalConfiguracoesTabs from "../Components/Modal/ModalConfiguracoesTabs";
import ConvenioList from "../Components/Modal/Tabs/ConvenioList";
import ModalConfirmacao from "../../../components/Modal/ModalConfirmacao/ModalConfirmacao";
import WarningIcon from "../../../components/Icon/Warning";
import { TextField, TextFieldSearch } from '../../../components/TextField'
import RowHeaderModal from "./RowHeaderModal";
import ModalConfiguracoes from "../../../components/Modal/ModalConfiguracoes";
import { Add } from "@material-ui/icons";
import { Button } from "../../../components/ui/Buttons";

@inject(
    'sujeitoAtencaoStore',
    'atendimentoStore',
    'filtroHeaderStore',
    'agendamentoTipoStore',
    'googleAnalyticsStore',
    "usuarioStore",
    "modalConfiguracoesStore",
    "auditoriaStore"
)
@observer
class ConfiguracaoConsulta extends React.Component {
    state = {
        isLoading: false,
        agendamentoTipo: [],
        agendamentoTipoSelected: undefined,
        keyAgendamentoTipo: 0,
        loadingSave: false,
        modificacoesPage: 0,
        lastPage: false,
        sortField: 'descricao',
        sortDir: 'ASC',
        convenios: [],
        pageNumberModal: 0,
        lastModal: false,
        loadingModal: false,
        disableButton: false,
        listaModal: [],
        tabSelected: tabs.INFORMACOES,
        search: "",
        status: true,
        propsTabsModal: {
            valueSearch: "",
        },
        openAlertAgendamentoTipo: false,
        propsTabsAuditoria: {
            tabSelected: tabs.INFORMACOES
        },
        ordenarTabela: {
            sortField: "descricao",
            sortDir: "ASC",
        },
    };

    async componentDidMount() {
        const { modalConfiguracoesStore } = this.props;
        this.loadAgendamentoTipo();

        const paginaVisualizada = {
            page: window.location.hash, 
            title: 'Configurações - Configuração de Consulta'
        }
        this.props.googleAnalyticsStore.pageView(paginaVisualizada);

        modalConfiguracoesStore.titulo="consulta";
    }

    setAuditoriaTela = () => {
        const { auditoriaStore } = this.props;

        auditoriaStore.auditoriaProps={
            ...auditoriaStore.auditoriaProps,
            auditoria: "o tipo consulta",
            auditoriaTela: "agendamentoTipo",
        };

        auditoriaStore.load={
            query: findAllAgendamentoTipoAuditByUnidade, 
            variableName: "",
            variableId: "", 
        };
    };

    loadConvenios = async() => {
        const { propsTabsModal, pageNumberModal, agendamentoTipoSelected } = this.state;
        this.setState({ loadingModal: true });
        await listarConveniosTipoConsulta({
            search: propsTabsModal.valueSearch,
            pageNumber: pageNumberModal
        })
            .then(({ data: response }) => {
                const convenios = response.data.findAllConvenio.content.map(convenioItem => {
                    const convenioChecked = agendamentoTipoSelected?.convenioAgendamentoTipo?.find(item => item.convenio?.id === convenioItem.id) || false;
                    const valorNumber = convenioChecked?.valor || 0;

                    return {
                        convenio: {
                            descricao: convenioItem.descricao,
                            id: convenioItem.id,
                            checked: !!convenioChecked
                        },
                        valor: typeof convenioChecked?.valor === "number" ? String(valorNumber.toFixed(2).replace('.', ',')) : valorNumber
                    }
                });

                this.setState({
                    convenios: [...this.state.convenios, ...convenios],
                    lastModal: response.data.findAllConvenio.last
                })
            })
            .finally(() => {
                this.setState({ loadingModal: false })
            })
    }

    loadAgendamentoTipo = (options) => {
        if(options?.resetSearch){
            this.setState({
                modificacoesPage: 0
            })
        }
        const pageableDTO = {
            pageNumber: options?.resetSearch ? 0 : this.state.modificacoesPage,
            pageSize: 30,
            sortDir: options?.sortDir || this.state.sortDir,
            sortField: options?.sortField || this.state.sortField,
        }

        const nextPage = options?.resetSearch ? 1 : this.state.modificacoesPage + 1
        
        this.setState({isLoading: true});
        findAllAgendamentoTipoPage(pageableDTO, this.state.search, this.state.status)
            .then(({data: response}) => {
                const {content, last} = response.data.findAllAgendamentoTipoPage
                this.setState({
                    agendamentoTipo: 
                    options?.resetSearch ? content : [...this.state.agendamentoTipo, ...content],
                    lastPage: last,
                    modificacoesPage: nextPage,
                });
            })
            .catch(() => {
                this.props.agendamentoTipoStore.openNotification(
                    "Falha ao carregar lista com os tipos de consultas.",
                    "error"
                );
            })
            .finally(() => {
                this.setState({isLoading: false});
            });
    }

    handleDuplicar = async (id) => {
        this.editAgendamentoTipoById(id, true);
    }

    handleAtivar = async (id) => {
        const { modalConfiguracoesStore } = this.props;
        const response = await findByIdAgendamentoTipo(id);
        const selecionado = response.data.data.findByIdAgendamentoTipo;
        const selecionadoComId = {
            ...selecionado,
            id,
            ativo: true
        }

        delete selecionadoComId.tuss52TerminologiaTipoConsulta?.codigoTermoDescricao;
        delete selecionadoComId.tuss22ProcedimentoEventoSaude?.codigoTermoDescricao;
        await saveAgendamentoTipo(selecionadoComId);
        this.setState({
            agendamentoTipo: [],
            pageNumber: 0
        });
        modalConfiguracoesStore.showModal=false;
        this.loadAgendamentoTipo({ resetSearch: true });
    };

    handleInativar = async (id) => {
        const { modalConfiguracoesStore } = this.props;
        const response = await findByIdAgendamentoTipo(id);
        const selecionado = response.data.data.findByIdAgendamentoTipo;
        const selecionadoComId = {
            ...selecionado,
            id,
            ativo: false
        }
        delete selecionadoComId.tuss52TerminologiaTipoConsulta?.codigoTermoDescricao;
        delete selecionadoComId.tuss22ProcedimentoEventoSaude?.codigoTermoDescricao;
        await saveAgendamentoTipo(selecionadoComId);
        this.setState({
            agendamentoTipo: [],
            pageNumber: 0
        });
        modalConfiguracoesStore.showModal=false;
        this.loadAgendamentoTipo({ resetSearch: true });
    };

    handleClickRow = (id) => {
        this.editAgendamentoTipoById(id);
    };

    editAgendamentoTipoById = (id, isDuplicar=false) => {
        const { modalConfiguracoesStore } = this.props;
        this.setState({isLoading: false});
        
        findByIdAgendamentoTipo(id)
            .then(({data: response}) => {
                const agendamentoSelected = response.data.findByIdAgendamentoTipo;
                if (isDuplicar) {
                    agendamentoSelected.id=null;
                    agendamentoSelected.descricao=`${agendamentoSelected.descricao} - cópia`;
                };


                this.setState({
                    agendamentoTipoSelected: agendamentoSelected,
                }, () => { 
                    modalConfiguracoesStore.showModal = true;
                    this.loadConvenios() 
                })
            })
            .catch(() => {
                this.props.agendamentoTipoStore.openNotification(
                    "Falha ao carregar o tipo de consulta.",
                    "error"
                );
            })
            .finally(() => {
                this.setState({isLoading: false})
            });
    };

    save = async({isSaveMesmoNome=false}) => {
        this.setState({loadingSave: true});
        try {
            const { agendamentoTipoStore } = this.props;
            const { agendamentoTipoSelected } = this.state;
            if(!agendamentoTipoSelected.descricao || string.isEmpty(agendamentoTipoSelected.descricao)) {
                agendamentoTipoStore.openNotification(
                    "Digite o nome da consulta",
                    "error"
                );
                return
            }

            const existeMesmoNome = await this.verificaAgendamentoTipoComMesmoNome(agendamentoTipoSelected.descricao)

            if(!isSaveMesmoNome && existeMesmoNome) return;

            const listaAgendamentoTipo = agendamentoTipoSelected.convenioAgendamentoTipo.map(item => {
                const valorNumber = typeof item.valor === 'string' ?  string.currencyMaskToFloat(item.valor) : item.valor;
                
                return({
                    ...item,
                    valor: valorNumber
                })
            })

            const agendamentoTipo = {
                ...agendamentoTipoSelected,
                descricao: agendamentoTipoSelected.descricao || agendamentoTipoSelected.nome,
                convenioAgendamentoTipo: listaAgendamentoTipo
            }
            delete agendamentoTipo.nome 
            if(agendamentoTipo.descricao.toUpperCase() === "CONSULTA") {
                this.props.agendamentoTipoStore.openNotification(
                    "Já existe o agendamento do tipo Consulta.",
                    "warning"
                );
                this.setState({loadingSave: false});
                return
            }

            delete agendamentoTipo.tuss52TerminologiaTipoConsulta?.codigoTermoDescricao;
            delete agendamentoTipo.tuss22ProcedimentoEventoSaude?.codigoTermoDescricao;

            saveAgendamentoTipo(agendamentoTipo)
                .then(() => {
                    const { modalConfiguracoesStore } = this.props;

                    modalConfiguracoesStore.showModal = false;
                    this.setState({
                        agendamentoTipoSelected: undefined,
                        convenios: [],
                        pageNumberModal: 0,
                        propsTabsModal: {
                            valueSearch: "",
                        },
                    }, () => {
                        this.loadAgendamentoTipo({resetSearch: true});
                    });
                })
                .catch(() => {
                    this.props.agendamentoTipoStore.openNotification(
                        "Falha ao salvar o tipo de consulta",
                        "error"
                    );
                });
        } finally {
            this.setState({loadingSave: false});
        }
    }

    createAgendamentoTipo = async() => {
        const { modalConfiguracoesStore } = this.props;

        const unidade = await getUnidadeLogado();
        this.setState({
            agendamentoTipoSelected: {
                unidade: {id: unidade.id},
                ativo: true,
                convenioAgendamentoTipo: [],
            }
        }, () => {
            modalConfiguracoesStore.showModal = true;
            this.loadConvenios();
        });
    }

    handleClickOrdenar = async (value) => {
        const { ordenarTabela } = this.state;
        
        const sortDir =
        ordenarTabela.sortField !== value
            ? "ASC"
            : ordenarTabela.sortDir === "ASC"
                ? "DESC"
                : "ASC";

        const status = value === 'ativo' ? sortDir === 'ASC' : this.state.status;

        this.setState({            
            ordenarTabela: { 
              sortDir: sortDir,
              sortField: value,
            },
            status,
            agendamentoTipo: [],
            pageNumber: 0
        }, () => this.loadAgendamentoTipo({resetSearch: true, sortField: value, sortDir}));
    }

    onCloseModalTipoAgendamento = () => {
        const { modalConfiguracoesStore } = this.props;

        modalConfiguracoesStore.showModal = false;
        this.setState({
            agendamentoTipoSelected: undefined,
            convenios: [],
            pageNumberModal: 0,
            propsTabsModal: {
                valueSearch: "",
            },
        });
        this.setState({agendamentoTipoSelected: undefined});
    }

    handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.props.agendamentoTipoStore.closeNotification();
    };

    handleClickSearchModal = () => {
        this.setState({
            convenios: [],
            pageNumberModal: 0,
        }, () => {
            this.loadConvenios();
        });
    };

    onChangeSearchModal = (e) => {
        const { propsTabsModal } = this.state;

        let value = e.target.value;
        this.setState({ propsTabsModal: {
            ...propsTabsModal,
            valueSearch: value
        } })
    };

    handleClickClearModal = () => {
        this.setState({
            propsTabsModal: {
                valueSearch: "",
            },
            convenios: [],
            pageNumberModal: 0,
        }, () => {
            this.loadConvenios();
        });
    };

    onKeypressSearchModal = (e) => {
        const { propsTabsModal } = this.state;

        if (e.key === 'Enter' && propsTabsModal.valueSearch.length >= 3) {
            this.setState({
                convenios: [],
                pageNumberModal: 0,
            }, () => {
                this.loadConvenios();
            });
        };
    };

    loadMoreModal = () => {
        const { loadingModal, convenios, pageNumberModal } = this.state;
        if (loadingModal) {
            return;
        }

        this.setState({ pageNumberModal: convenios.length > 0 ? pageNumberModal + 1 : 0 });

        this.loadConvenios();
    };

    handleChangeCheckbox = (item) => {
        item.convenio.checked = !item.convenio.checked;
        let object = this.state.agendamentoTipoSelected;

        if (item.convenio.checked) {
            object.convenioAgendamentoTipo.push({
                convenio: {
                    id: item.convenio.id
                },
                valor: item.valor
            });

        } else {
            const array = object.convenioAgendamentoTipo.filter(convenioItem => convenioItem.convenio.id !== item.convenio.id);
            object.convenioAgendamentoTipo = array;
        }

        this.setState({ agendamentoTipoSelected: object })

    };

    handleChange = (event, itemLista) => {
        const { name, value } = event.target;
        const { agendamentoTipoSelected } = this.state;

        if (itemLista) {
            itemLista.valor = value;
            const indexSelecionado = agendamentoTipoSelected.convenioAgendamentoTipo.findIndex(item => item.convenio.id === itemLista.convenio.id);
            if (indexSelecionado >= 0) {
                let object = agendamentoTipoSelected;
                object.convenioAgendamentoTipo[indexSelecionado].valor = itemLista.valor;
                this.setState({agendamentoTipoSelected: object});
            }
            
        } else {
            this.setState(prevState => ({
                agendamentoTipoSelected: {
                    ...prevState.agendamentoTipoSelected,
                    [name]: value
                },
            }));
        }
    };

    handleChangeTipoConsulta = (option, field) => {
        this.setState({
            agendamentoTipoSelected:{
                ...this.state.agendamentoTipoSelected,
                [field]: option,
            }
        })
    };

    changeTabSelected = async(tabSelected) => {
        const { auditoriaStore } = this.props;
        const { agendamentoTipoSelected } = this.state;

        auditoriaStore.auditoriaProps={
          ...auditoriaStore.auditoriaProps,
          pageNumber: 0,
          lastPage: false,
          auditorias: [],
        }

        if(tabSelected === 1) {
            auditoriaStore.load={
                query: findAllAgendamentoTipoAuditByAgendamentoTipoId, 
                variableName: "agendamentoTipoId",
                variableId: agendamentoTipoSelected.id, 
            }
            await auditoriaStore.loadAuditItems();
        }

        this.setState({ tabSelected })
    };

    handleClickSearch = () => {
        this.setState({
            convenios: [],
            pageNumber: 0
        }, () => {
            this.loadAgendamentoTipo({resetSearch: true});
        });
        
    };

    handleClickClear = () => {
        this.setState({
            search: "",
            convenios: [],
            pageNumber: 0,
        }, () => {
            this.loadAgendamentoTipo({resetSearch: true});
        });
        
    };

    onChangeSearch = (e) => {
        let search = e.target.value;
        this.setState({ search })
    };

    onKeypressSearch = (e) => {
        if (e.key === 'Enter' && this.state.search.length >= 3) {
            this.setState({
                convenios: [],
                pageNumber: 0,
            }, () => {
                this.loadAgendamentoTipo({resetSearch: true});
            });
        }
    };

    setAuditoriaModal = () => {
        const { auditoriaStore } = this.props;
        const { agendamentoTipoSelected } = this.state;

        auditoriaStore.auditoriaProps={
            ...auditoriaStore.auditoriaProps,
            auditoria: "o tipo consulta",
            auditoriaTela: "agendamentoTipo",
        };

        auditoriaStore.load={
            query: findAllAgendamentoTipoAuditByAgendamentoTipoId, 
            variableName: "agendamentoTipoId",
            variableId: agendamentoTipoSelected.id,
        }
    };

    changeTabModalAuditoriaSelected = async(tabSelected) => {
        const { auditoriaStore } = this.props;
        const { agendamentoTipoSelected } = this.state;

        auditoriaStore.auditoriaProps={
            ...auditoriaStore.auditoriaProps,
            pageNumber: 0,
            lastPage: false,
            auditorias: [],
        };

        if (tabSelected === 1) {
            auditoriaStore.auditoriaProps={
                ...auditoriaStore.auditoriaProps,
                auditoria: "o tipo de consulta",
                auditoriaTela: "agendamentoTipo",
            };    

            auditoriaStore.load={
                query: findAllAgendamentoTipoAuditByAgendamentoTipoId, 
                variableName: "agendamentoTipoId",
                variableId: agendamentoTipoSelected.id,
            }
            await auditoriaStore.loadAuditItems();
          }

        this.setState({
            propsTabsAuditoria: {
                tabSelected: tabSelected
            }
        })
    };

    saveAgendamentoTipoComMesmoNome = () => {
        const { agendamentoTipoSelected } = this.state;

        this.setState({
            agendamentoTipoSelected: {
                ...agendamentoTipoSelected,
                descricao: `${agendamentoTipoSelected.descricao} - cópia`
            }
        }, () => {
            this.closeModalConfirmacao();
            this.save({isSaveMesmoNome: true});
        });
    };

    closeModalConfirmacao = () => {
        this.setState({ openAlertAgendamentoTipo: false })
    }

    voltarMudarNome = () => {
        const { modalConfiguracoesStore } = this.props;
        this.closeModalConfirmacao();
        modalConfiguracoesStore.showModal=true;
    };

    verificaAgendamentoTipoComMesmoNome = async(nomeAgendamentoTipo) => {
        const { agendamentoTipoSelected } = this.state;
        const existeMesmoNome = await agendamentoTipoNomeJaExistente(nomeAgendamentoTipo);
        this.setState({ 
            openAlertAgendamentoTipo: existeMesmoNome && !agendamentoTipoSelected.id,
        })

        return existeMesmoNome && !agendamentoTipoSelected.id;
    };

    loadAllTuss52 = async (search, loadedOptions, { page }) => {
        const pageableDTO = {
          pageNumber: page,
          pageSize: 10,
          sortDir: "ASC",
          sortField: "codigoTermo",
        };
        try {
          const { content, last } = (await findAllTuss52TerminologiaTipoConsulta(
            pageableDTO,
            true,
            search
          )).data.data.findAllTuss52TerminologiaTipoConsulta;
          return {
            options: content,
            hasMore: !last,
            additional: {
              page: page + 1,
            },
          };
        } catch {
          this.openNotification(
            "Erro ao carregar Tuss 52 - Tipos de consultas",
            "error"
          );
        }
      };

      loadAllTuss22 = async (search, loadedOptions, { page }) => {
        const pageableDTO = {
          pageNumber: page,
          pageSize: 10,
          sortDir: "ASC",
          sortField: "codigoTermo",
        };
        const searchDTO = {
          pageableDTO,
          search,
        };
    
        try {
          const { content, last } = (await findAllTuss22ProcedimentoEventoSaude(
            searchDTO
          )).data.data.findAllTuss22ProcedimentoEventoSaude;
          return {
            options: content,
            hasMore: !last,
            additional: {
              page: page + 1,
            },
          };
        } catch {
          this.openNotification(
            "Erro ao carregar Tuss 22 - Procedimentos e eventos em saúde ",
            "error"
          );
        }
      };

    renderHeader = () => {
        const { classes } = this.props;
        const { agendamentoTipoSelected } = this.state;

        return(
            <>
                <div className={classes.rowNome}>
                    <div className={classes.contentNome}>
                        <div className={classes.titleRenderHeader}>
                            Nome da consulta *Obrigatório
                        </div>
                        <TextField
                            name={"descricao"}
                            className={classes.textField}
                            classes={{
                            root: classes.notchedOutlineTextField,
                            }}
                            placeholder={`Digite o nome da consulta`}
                            value={agendamentoTipoSelected?.descricao}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className={classes.contentStatus}>
                        <div className={classes.titleRenderHeader}>Destaque</div>
                        <Switch
                            checked={agendamentoTipoSelected?.destaque}
                            color="primary"
                            onClick={this.handleChangeDestaque}
                        />
                    </div>
                    {agendamentoTipoSelected?.id && (
                        <div className={classes.contentStatus}>
                            <div className={classes.titleRenderHeader}>Status</div>
                            <Switch
                                checked={agendamentoTipoSelected?.ativo}
                                color="primary"
                                onClick={this.handleChangeStatus}
                            />
                        </div>
                    )}
                </div>
                <div className={classes.rowHeader}>
                    <div className={classes.contentFieldHeader}>
                        <div className={classes.titleRenderHeader}> Tuss 52 - Tipos de consulta </div>
                        <TextFieldSearch
                            name='tuss52TerminologiaTipoConsulta'
                            placeholder='Selecione'
                            classNotched={classes.notchedOutline}
                            className={classes.renderHeaderSelect}
                            loadOptions={this.loadAllTuss52}
                            withPaginate
                            value={agendamentoTipoSelected?.tuss52TerminologiaTipoConsulta}
                            onChange={e => this.handleChangeTipoConsulta(e, "tuss52TerminologiaTipoConsulta")}
                            getOptionLabel={option => option.codigoTermoDescricao}
                            debounceTimeout={300}
                            additional={{
                                page: 0,
                            }}
                        />
                    </div>
                    <div className={classes.contentFieldHeader}>
                        <div className={classes.titleRenderHeader}> Tuss 22 - Procedimentos e eventos em saúde </div>
                        <TextFieldSearch
                            name='tuss22ProcedimentoEventoSaude'
                            placeholder='Selecione'
                            classNotched={classes.notchedOutline}
                            className={classes.renderHeaderSelect}
                            loadOptions={this.loadAllTuss22}
                            withPaginate
                            value={agendamentoTipoSelected.tuss22ProcedimentoEventoSaude}
                            onChange={e => this.handleChangeTipoConsulta(e, "tuss22ProcedimentoEventoSaude")}
                            getOptionLabel={option => option.codigoTermoDescricao}
                            debounceTimeout={300}
                            additional={{
                                page: 0,
                            }}
                        />
                    </div>
                </div>
            </>
        )
    };

    changeTabSelectedModal = async(tabSelected) => {
        this.setState(
            {
              convenios: [],
              pageNumberModal: 0,
              lastModal: false,
              propsTabsModal: {
                valueSearch: "",
            }
            },
            () => this.loadListaModal(tabsModalSelected[tabSelected])
        );
    };

    handleChangeStatus = () => {
        const { agendamentoTipoSelected } = this.state;

        this.setState({
            agendamentoTipoSelected: {
                ...agendamentoTipoSelected,
                ativo: !agendamentoTipoSelected.ativo
            }
        });
    };

    handleChangeDestaque = () => {
        const { agendamentoTipoSelected } = this.state;

        this.setState({
            agendamentoTipoSelected: {
                ...agendamentoTipoSelected,
                destaque: !agendamentoTipoSelected.destaque
            }
        });
    };

    render() {
        const {isLoading, lastPage, agendamentoTipoSelected, loadingSave, agendamentoTipo, search, propsTabsModal, ordenarTabela} = this.state;
        const {classes, agendamentoTipoStore, modalConfiguracoesStore} = this.props;
        
        return (
            <>
                <Grid className={classes.content}>
                    <PageTitle title={pageConfiguracoes("Tipos de consulta")}/>
                    <HeaderConfiguracoesButtonNovo
                        title="Consultas"
                        labelButton="Nova Consulta"
                        handleClickNovo={this.createAgendamentoTipo}
                        search={{
                            handleChange: this.onChangeSearch,
                            value: search,
                            onKeypress: this.onKeypressSearch,
                            handleClickClear: this.handleClickClear,
                            onClickButtonSearch: this.handleClickSearch
                        }}
                    />
                    <div className={classes.contentList}>
                        <ContentConfiguracoes
                            setAuditoriaTela={this.setAuditoriaTela}
                        >
                            <div className={classes.table}>
                                <Scroll
                                    hasMore={!isLoading && !lastPage}
                                    pageStart={0}
                                    initialLoad={false}
                                    loadMore={() => this.loadAgendamentoTipo()}
                                >
                                    {
                                        agendamentoTipo.length > 0 && <Table
                                            dados={agendamentoTipo}
                                            columns={columnsConfiguracoes({
                                                isDescricao: true,
                                                headerNome: "Nome da consulta",
                                                functions: {
                                                    handleEditar: this.editAgendamentoTipoById,
                                                    handleDuplicar: this.handleDuplicar,
                                                    handleDesativar: this.handleInativar,
                                                    handleAtivar: this.handleAtivar,
                                                },
                                            })}
                                            clickable={true}
                                            handleClick={this.handleClickRow}
                                            comOrdenacao
                                            ordenarTabela={ordenarTabela}
                                            handleClickOrdenar={(value) => this.handleClickOrdenar(value)} 
                                        />
                                    }
                                </Scroll>
                            </div>
                        </ContentConfiguracoes>
                            <Button
                                shadow
                                shape='circle'
                                bgColor='#26ACA9'
                                onClick={this.createAgendamentoTipo}
                                className={classes.buttonCriar}
                            >
                                <AddIcon/>
                            </Button>
                    </div>
                    {
                        modalConfiguracoesStore.showModal &&
                        <ModalConfiguracoesTabs
                            itemSelecionado={agendamentoTipoSelected}
                            title={"consulta"}
                            renderHeader={this.renderHeader}
                            classes={{
                                content: agendamentoTipoSelected?.id ? classes.contentModalEdit : classes.contentModal
                            }}
                            onClose={this.onCloseModalTipoAgendamento}
                            handleSave={this.save}
                            loadingModal={this.state.loadingModal}
                            propsTabs={{
                                ...propsTabsModal,
                                changeTabSelected: this.changeTabSelectedModal,
                                handleClickSearch: this.handleClickSearchModal,
                                handleClickClear: this.handleClickClearModal,
                                onChangeSearch: this.onChangeSearchModal,
                                onKeypressSearch: this.onKeypressSearchModal,
                            }}
                            propsPopperCustom={{
                                handleEditar: this.handleEditar,
                                handleDuplicar: this.handleDuplicar,
                                handleDesativar: this.handleInativar,
                                handleAtivar: this.handleAtivar,
                            }}
                            propsTabsAuditoria={{
                                tabSelected: this.state.propsTabsAuditoria.tabSelected,
                                changeTabAuditoriaSelected: this.changeTabModalAuditoriaSelected,
                            }}
                            loadingSave={loadingSave}
                        >
                            <ConvenioList
                                listaModal={this.state.convenios}
                                handleChangeCheckbox={this.handleChangeCheckbox}
                                handleChange={this.handleChange}
                                loadMore={this.loadMoreModal}
                                lastScroll={this.state.lastModal}
                                loadingModal={this.state.loadingModal}
                                classes={{ content: classes.contentConvenioList }}
                            />
                        </ModalConfiguracoesTabs>
                    }
                    <ModalConfirmacao
                        open={this.state.openAlertAgendamentoTipo}
                        classes={{
                            paperAlert: classes.paperAlert
                        }}
                        icon={<WarningIcon />}
                        color={"yellow"}
                        title={"Já foi criado um tipo de consulta com esse nome"}
                        buttons={{
                            labelPrimaryButton: "Salvar assim mesmo",
                            handlePrimaryButton: () => this.saveAgendamentoTipoComMesmoNome(),
                            labelSecondButton: "Voltar e alterar nome",
                            handleSecondButton: () => this.voltarMudarNome(),
                            disabledPrimaryButton: loadingSave,
                            disabledSecondButton: loadingSave,
                        }}
                    />
                </Grid>

                
                    <Button
                        shadow
                        shape='circle'
                        bgColor='#26ACA9'
                        onClick={this.createAgendamentoTipo}
                        className={classes.buttonCriar}
                        
                    >
                        <Add/>
                    </Button>
               

                <Notification
                    close={this.handleClose}
                    isOpen={agendamentoTipoStore.notification.isOpen}
                    variant={agendamentoTipoStore.notification.variant}
                    message={agendamentoTipoStore.notification.message}
                />
            </>
        );
    }
}

const style = theme => ({
    header: {
        width: '100%',
        height: '80px',
        background: '#fff',
        display: 'flex',
        justifyContent: 'space-between'
    },
    wrapperHeader:{
        display: 'flex',
        zIndex: 3,
    },
    content: {
        background: "#F5F5F5",
        "& #buttonCircle": {
            boxShadow: 'none',
            background: "#fff",
            color: "#5F6368",
        },
    },
    contentModal: {
        height: "calc(100% - 238px) !important",
    },
    contentModalEdit: {
        height: "calc(100% - 278px)",
    },
    contentList: {
        display: 'flex',
        flexDirection: 'column',
        color: '#fff',
        fontWeight: 600,
        alignItems: 'center',
        padding: "24px 24px 0",
        gap: '16px',
        height: "calc(100% - 104px)",   
    },
    table: {
        "&>div": {
            height: 'calc(100vh - 240px)',
            overflow: 'auto',
        },
        "& thead th": {
            textAlign: "left",
            top: 0,
            position: "sticky",
            zIndex: 1,
        },
    },
    notFoundContainer: {
        width: '100%',
        display: "flex",
        justifyContent: "center",
    },
    buttonCriar: {
        position: 'absolute',
        bottom: 88,
        right: 37,
        zIndex: 100
    },
    paperAlert: {
        maxWidth: "373px",
    },
    rowHeader: {
        gap: "8px",
        marginTop: "8px",
        display: 'grid',
        gridTemplateColumns: '49.5% 48.75%'
    },
    titleRenderHeader: {
        color: "#868686",
        fontSize: "12px",
    },
    profile: {
        textAlign: 'end',
        paddingRight: 16,
        zIndex: 3,
    },
    titleHeader: {
        fontSize: "25px",
        fontWeight: "bold",
        color: theme.palette.primary.main,
        marginLeft: "10px"
    },
    wrapperTable:{
        width: '100%',
        height: 'calc(100vh - 128px)',
        overflow: 'auto'
    },
    botaoOrganizar: {
        color: 'white',
        width: 20,
        height: 20,
        marginLeft: 10,
    },
    selectStatus:{
        width: 200,
    },
    wrapperButtonAdd:{
        paddingRight: '3rem'
    },
    buttonNovo:{
        width: 200,
        height: 40,
        borderRadius: 87,
        boxShadow: 'none',
        background: '#F9BE73',
        color: '#fff',
        textTransform: 'capitalize',
        padding: '0 14px',
        marginLeft: 24,
        minWidth: 152,
        "@media (max-width: 1600px)": {
            width: 40,
            minWidth: 40,
            borderRadius: '100%',
        }
    },
    labelButtonNovo: {
        marginLeft: '5px',
        whiteSpace: 'nowrap',
        "@media (max-width: 1600px)": {
            display: 'none',
        }
    },
    colorIconOrdenacao: {
        filter: 'brightness(0.4)',
    },
    contentConvenioList: {
        height: 'calc(100% - 70px)',
    },
    contentFieldHeader: {
        width: "100%",
    },
    notchedOutline: {
        border: 'none',
    },
    renderHeaderSelect: {
        "&>div": {
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 600,
            color: '#868686',
            "&>div": {
                height: '38px',
                background: '#f2f2f2',
                borderRadius: '8px',
                border: '0.5px solid rgba(220, 220, 220, 0.2)',
            },
        },
        "& p": {
            color: "#868686",
            fontWeight: 600,
        },
    },
    rowNome: {
        marginBottom: "4px",
        display: "flex",
    },
    notchedOutlineTextField: {
        "& fieldset": {
            border: "none",
        },
    },
    contentNome: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    textField: {
        height: "40px",
        width: "100%",
        "& input": {
          height: "23px",
          background: "#F2F2F2",
          border: "0.5px solid rgba(220, 220, 220, 0.2)",
          borderRadius: "8px",
          fontFamily: "Poppins",
          fontSize: "14px",
          fontWeight: 600,
          color: '#868686',
        },
        "& ::placeholder": {
          color: '#000'
        },
    },
});

export default withStyles(style)(ConfiguracaoConsulta);
