import React, { useEffect, useState } from "react";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from "@material-ui/core/styles/index";
import {
  IconButton,
  TextField,
  InputAdornment,
  Paper,
  Fab,
  Popover,
} from '@material-ui/core'
import FiltrosBuscaPacientes from "../FiltroSearchFilter/FiltrosBuscaPacientes";
import FiltrosBuscaHorariosDisponiveis from "../FiltroSearchFilter/FiltrosBuscaHorariosDisponiveis";
import { permiteVerAgendaOutrosProfissionais } from "../../services/UsuarioService";
import FiltrosLancamentoFinanceiro from "../FiltroSearchFilter/FiltrosLancamentoFinanceiro";
import FilterIcon from "../Icon/FilterIcon";
import FiltrosAgendamentosSituacao from "../FiltroSearchFilter/FiltrosAgendamentosSituacao";
import TabSelector from "../TabSelector";
import { background } from "styled-system";

const styleInputSearch = () => ({
  root: {
    width: '100%',
    maxWidth: '436px',
    borderRadius: "8px !important",
    background: "#F2F2F2",
    justifyContent: 'center',
    fontWeight: 400,
    height: "38px",
    color: "#868686",
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  inputRoot: {
    color: "#868686",
    padding: "0 10px",
    fontWeight: 400,
    fontSize: '12px',
  },
  input: {
    padding: '5px 0 3px 0',
    fontFamily: 'Poppins',
    fontSize: '14px',
  },
  inputFocused: {
    borderColor: "#555"
  },
  paper: {
    padding: "24px",
    backgroundColor: "#FFF",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.2)",
    borderRadius: "20px",
    position: 'absolute',
    left: '255px',
    top: '80px',

    "& h1": {
      fontSize: "24px",
      fontWeight: 600,
    }
  },
  popper: {
    background: 'transparent',
    maxWidth: "688px",
    minHeight: "294px",
    zIndex: 1000,
    boxShadow: 'none',
    border: 'none'
  },
  popperFormated: {
    background: 'transparent',
    minWidth: "436px",
    minHeight: "294px",
    zIndex: 1000,
    boxShadow: 'none',
    border: 'none'
  },
  iconButtonSearch: {
    width: 30,
    height: 30,
    padding: 0,
  },
  tabSelector: {
    display: 'flex',
    justifyContent: 'space-between',
    color: "#b7b7b7",
    "& > h2": {
      fontSize: "14px",
      cursor: "pointer"
    }
  },
  tabSelecionada: {
    color: "#00B0AE",
  },
  Fab:{
    minHeight: 26,
    height: 32,
    width: 32,
    boxShadow: 'none',
  },
  contentDiv:{
    display: 'flex',
    justifyContent:'space-between',
    alignItems:'center',
  },
});

const InputSearch = (props) => {
  const {
    classes,
    classInputRoot,
    classInput,
    startAdornment,
    endAdornment,
    hideStartAdornment,
    hideEndAdornment,
    search,
    clearSearch,
    screen,
    searchHorariosDisponiveis,
    searchLancamentoFinanceiro,
    searchRelatorioAgendamentoPorSituacao,
    value,
    showIconClose,
    disabledButton,
    hiddenFilter,
    redefinirFiltros,
    ...rest
  } = props;

  const [openModalFiltrar, setOpenModalFiltrar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [placement, setPlacement] = useState(null);
  const [tabSelecionada, setTabSelecionada] = useState('BuscaPacientes')
  const [permiteVerProfissionais, setPermiteVerProfissionais] = useState(false)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  const getStartAdornment = startAdornment || (
    <InputAdornment position="start">
      <IconButton className={classes.iconButtonSearch} onClick={() => search()}>
        <SearchIcon className={classes.icon} />
      </IconButton>
    </InputAdornment>
  );

  useEffect(() => {
    (screen === "LancamentoFinanceiro" || screen === "AgendamentoPorSituacao") && setTabSelecionada(screen);

  }, []);

  const onClickOpenModalFiltrar = newPlacement => event => {
    setAnchorEl(event.currentTarget);
    setOpenModalFiltrar((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  }

  const onClickCloseModalFiltrar = () => {
    setOpenModalFiltrar(false)
  }

  const searchHorarios = () => {
    onClickCloseModalFiltrar();
    searchHorariosDisponiveis();
  }

  const searchPaciente = () => {
    onClickCloseModalFiltrar();
    search();
  }

  const searchLancamento = () => {
    onClickCloseModalFiltrar();
    searchLancamentoFinanceiro();
  }


  const searchAgendamentoPorSituacao = () => {
    onClickCloseModalFiltrar();
    searchRelatorioAgendamentoPorSituacao();
  };

  const getEndAdornment = () => {
    return (
      endAdornment || (
        <InputAdornment position="start">
          {(value || showIconClose) && <IconButton
            className={classes.iconButtonSearch}
            onClick={() => clearSearch()}
          >
            <CloseIcon class sName={classes.icon} />
          </IconButton>}
          {!hiddenFilter && <IconButton
            className={classes.iconButtonSearch}
            onClick={!!!openModalFiltrar ? onClickOpenModalFiltrar("bottom-end") : onClickCloseModalFiltrar}
            disabled={disabledButton}
          >
            <FilterIcon size={14} />
          </IconButton>}
        </InputAdornment>
      )
    );
  };

  const renderTabSelecionada = (tabSelecionada) => {
    switch (tabSelecionada) {
      case 'HorariosDisponiveis':
        return <FiltrosBuscaHorariosDisponiveis
          searchHorarios={searchHorarios}
          {...props}
        />
      case "LancamentoFinanceiro":
        const { classes, ...others } = props;

        return <FiltrosLancamentoFinanceiro
          searchLancamento={searchLancamento}
          {...others}
        />
      case "AgendamentoPorSituacao":
        return <FiltrosAgendamentosSituacao
          aplicarFiltro={searchAgendamentoPorSituacao}
          {...props}
        />
      default:
        return <FiltrosBuscaPacientes
          searchPaciente={searchPaciente}
          {...props} />
    }
  }


  const verificaVerOutrosProfissionais = async () => {
    setPermiteVerProfissionais(await permiteVerAgendaOutrosProfissionais());
  }

  useEffect(() => {
    verificaVerOutrosProfissionais();
  }, [])

  return (
    <div className={classes.content}>
      <TextField
        className={classes.root}
        id="input-with-icon-textfield"
        InputProps={{
          disableUnderline: true,
          classes: {
            root: `${classes.inputRoot} ${classInputRoot}`,
            input: `${classes.input} ${classInput}`,
            focused: classes.inputFocused,
          },
          startAdornment: hideStartAdornment ? null : getStartAdornment,
          endAdornment: hideEndAdornment ? null : getEndAdornment(),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        value={value}
        {...rest}
      />
      <Popover
        className={classes.popper}
        open={openModalFiltrar}
        anchorEl={anchorEl}
        placement={placement}
        onClose={onClickCloseModalFiltrar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'left',
          horizontal: 'left',
        }}
        PaperProps={{
          className: screen === 'atendimento' ? classes.popper : classes.popperFormated,
        }}
      >
          <Paper className={classes.paper} >
        <div className={classes.contentDiv}>
            <h1 className={classes.filtro}>Filtro</h1>
          <Fab size="small"  className={classes.Fab} onClick={onClickCloseModalFiltrar}>
            <CloseIcon fontSize={'small'}/>
          </Fab>
        </div>
            {screen === 'Atendimento' && permiteVerProfissionais && (
              <TabSelector
                selectedColor='#FFF'
                baseColor='#F2F2F2'
                onSelect={index => {
                  setSelectedTabIndex(index)
                  setTabSelecionada(index === 0 ? 'BuscaPacientes' : 'HorariosDisponiveis')
                }}
                selectedTabIndex={selectedTabIndex}
                tabsOptions={[
                  {
                    label: 'Pacientes',
                  },
                  { label: 'Horários disponíveis' },
                ]}
              />
            )}
            {renderTabSelecionada(tabSelecionada)}
          </Paper>
      </Popover>
    </div>
  );
};

export default withStyles(styleInputSearch)(InputSearch);