import React from "react";
import { inject, observer } from "mobx-react";
import { Tooltip, withStyles } from "@material-ui/core";
import {
    MeetingRoom as MeetingRoomIcon,
  } from "@material-ui/icons";
import { ReactSelect } from "../../components/Select/SelectSearch";
import {
    ProfissionalIcon,
    UnidadeIcon,
    IconUnidade,
} from "../../assets/img/svg";
import { TextFieldSearch } from "../../components/TextField";
import colors from "../Colors";
import localStorageService from "../../services/storage";
import { Button } from "../../components/ui/Buttons";
import ListIcon from "../../components/Icon/ListIcon";
import CanetaEditIcon from "../../components/Icon/CanetaEditIcon";

const styles = () => ({
    formControlSelect: {
        width: 'auto',
        marginRight: 20,
    },

    unidadeProfissional:{
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '12px',
        alignItems: 'center',
        '& > h1': {
            margin: 0,
            fontSize: '12px',
            color: '#868686',
        },
        '& > p': {
            margin: 0,
            fontSize: '12px',
            color: '#868686',
        }
    },

    reactSelect: {
        width: "200px",
        zIndex: 100,
        "&>div>div": {
            height: 32,
            borderRadius: '8px',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            display: 'flex',
            alignContent: 'center',
            background: '#f2f2f2',
        }
    },

    selectUnidadeProfissional:{
        border: '1px solid #f2f2f2',
        borderRadius: 50,
    },

    headerSelecaoAgendas: {
        height: '50px',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'start',
        '& svg': {
            color: '#868686',
            cursor: 'pointer',
            marginLeft: '5px'
        },
        '& h1': {
            margin: '6px 20px 0 20px',
            color: '#868686',
            fontSize: '16px',
        }
    },

    contentSelects: {
        display: 'flex',
        gap:'10px',
    },

    icon: {
        height: '16px',
        width: '16px',
        margin: '0px 12px 0px 9px',
    },
    
    iconUnidadeMessage:{
        height: '24px',
        width: '24px',
        margin: '0px 12px 0px 9px',
    },

    iconSala: {
        margin: '0px 12px 0px 9px',
    },
    notchedOutline: {
        border: '0',
    },
    classIcons: {
        fontSize: 24,
    },
    inputTextField: {
        color: colors.commons.fontColor,
        backgroundColor: colors.commons.gray11,
        borderRadius: '0.5rem',
        width: "200px",
        zIndex: 100,
    },
    wrapperListaEspera: {
        '& > button': {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            padding: '0.25rem 1rem',
            textWrap: 'nowrap',
            width:'40px',
            height:'40px'
        },
    },
    buttonCancelarEmMassa:{
        justifyContent:'end',
    },
    status: {
        display: "flex",
        alignItems: "center",
        gap: "8px"
    }
});

const optionsStatus = [
    { nome: "Ativo", value: true },
    { nome: "Inativo", value: false }
]

@inject('filtroHeaderStore', 'atendimentoStore', 'configuracaoHorarioAtendimentoStore', 'usuarioStore', 'configuracaoImpressaoStore', 'unidadeStore', 'socketStore', 'chatStore')
@observer
class FiltroHeader extends React.Component {
    state = {
        isMounted: false,
    };
    mounted = false;
    
    async componentDidMount() {
        this.mounted = true;
        const { filtroHeaderStore, screen, showSecretarioLogado } = this.props;
        
        const {showFiltroSalas} = await filtroHeaderStore.checkRoles(screen);
        await filtroHeaderStore.changeFiltros({ unidadeId: filtroHeaderStore.unidade?.id, profissionalSaudeId: filtroHeaderStore.profissionalSaude?.id, showSecretarioLogado });

        if(showFiltroSalas && !filtroHeaderStore.sala) {
            this.selecionarSala();
        }

        if (!this.mounted) {
            return;
        }
        
        this.setState({ isMounted: true });
    }
    
    componentWillUnmount() {
        this.mounted = false;
    }
    
    selecionarSala = () => {
        const { filtroHeaderStore, configuracaoHorarioAtendimentoStore } = this.props;

        if (filtroHeaderStore.salas.length === 2) {
            filtroHeaderStore.sala = filtroHeaderStore.salas.length === 2 && filtroHeaderStore.salas[1];
            this.handleChangeSala(filtroHeaderStore.sala);
        } else {
            filtroHeaderStore.sala = { nome: 'Selecione', id: '0' };
            configuracaoHorarioAtendimentoStore.horarioAtendimentoList = [];      
        }
    }

    handleUnidadeChange = async (unidade) => {
        const {screen, filtroHeaderStore, atendimentoStore, configuracaoHorarioAtendimentoStore, configuracaoImpressaoStore, usuarioStore } = this.props;
        filtroHeaderStore.unidade = unidade;

        await filtroHeaderStore.alterarUnidadeLogada(unidade?.id);
        await filtroHeaderStore.changeFiltros({ unidadeId: filtroHeaderStore.unidade?.id }, true);
        await usuarioStore.findProfissionalSaudeLogado();
        await this.setupSocketConnection();
        await this.props.unidadeStore.inicializaConfiguracaoUnidade();

        if(screen === 'AGENDAMENTO') {
            await this.props.unidadeStore.checkUsoTelefoneInternacional();
            await atendimentoStore.loadAgendamentoTipo();
            await atendimentoStore.initObjectView(atendimentoStore.selectedDate);
            if (filtroHeaderStore.sala) {
                filtroHeaderStore.sala = '0';
                atendimentoStore.configVigenteSala = null;
            }
        }

        if(screen === 'CONFIGURACAO_HORARIO_ATENDIMENTO') {
            configuracaoHorarioAtendimentoStore.resetSearchDTO();
            await configuracaoHorarioAtendimentoStore.findAll({pageNumber: 0});
        }

        if(screen === 'CONFIGURACAO_RECEITA') {
            configuracaoImpressaoStore.findAllConfiguracaoImpressao();

        }

        atendimentoStore.loadConvenios();
    };

    setupSocketConnection = async () => {
        const { socketStore, chatStore } = this.props;
        await socketStore.connect();
        await chatStore.enableChatListenners();
        await chatStore.getProfissionalSaudeAtual();
    }
    
    handleProfissionalSaudeChange = (profissionalSaude) => {
        const { screen, atendimentoStore, configuracaoHorarioAtendimentoStore, configuracaoImpressaoStore, filtroHeaderStore,loadMensagens } = this.props;
        filtroHeaderStore.profissionalSaude = profissionalSaude;
        this.setProfissionalSelectedLocalStorage(profissionalSaude);
        
        if(screen === 'AGENDAMENTO') {
            atendimentoStore.initObjectView(atendimentoStore.selectedDate);
        }
        if(screen === 'CONFIGURACAO_HORARIO_ATENDIMENTO') {
            configuracaoHorarioAtendimentoStore.resetSearchDTO();
            configuracaoHorarioAtendimentoStore.findAll({ pageNumber: 0 });
        }
        if(screen === 'CONFIGURACAO_RECEITA') {
            configuracaoImpressaoStore.findAllConfiguracaoImpressao();
        }
        if(screen === 'CONFIGURACAO_MENSAGENS_SISTEMA'){
            loadMensagens(profissionalSaude?.id)
        }
    };

    setProfissionalSelectedLocalStorage = (profissionalSaude) => {
        localStorageService.set('current_agenda_owner', profissionalSaude);
    }

    handleChangeSala = (sala) => {
        const { screen, atendimentoStore, configuracaoHorarioAtendimentoStore, filtroHeaderStore } = this.props;
        filtroHeaderStore.sala = sala;
        
        if(screen === 'AGENDAMENTO_SALA') {
            filtroHeaderStore.checkRoles('AGENDAMENTO');
            atendimentoStore.initObjectViewSala(atendimentoStore.selectedDate);
        }
        if(screen === 'CONFIGURACAO_HORARIO_SALAS') {
            configuracaoHorarioAtendimentoStore.resetSearchDTO();
            configuracaoHorarioAtendimentoStore.findAllConfiguracaoHorarioAtendimentoSala({ pageNumber: 0 });
        }
    };

    loadSalas= async (search, loadedOptions, { page }) => {
        try {
        const { content, last } = await this.props.filtroHeaderStore.listarSalas(page, search);
        return {
            options: content,
            hasMore: !last,
            additional: {
                page: page + 1,
            },
        };
        }catch(error) {
            console.error('load salas:', error)
        }
    };
    
    render() {
        const { isMounted } = this.state;
        const { classes, filtroHeaderStore, screen, messageUnidade ,showFiltroStatus,handleChangeStatus} = this.props;
        const { showFiltroProfissionalSaude, showFiltroSalas } = filtroHeaderStore;
        const showFiltroUnidades = filtroHeaderStore.unidades.length > 2;

        if (!isMounted) {
            return null;
        }

        return (
            <div className={classes.contentSelects}>
                {showFiltroStatus &&
                    <div className={classes.status}>
                        <Tooltip
                            title="Status"
                            placement="top"
                        >
                            <CanetaEditIcon size={23}/>
                        </Tooltip>
                        <ReactSelect
                            placeholder={'Selecione'}
                            className={classes.reactSelect}
                            options={optionsStatus}
                            getOptionLabel={(option) => option.nome}
                            getOptionValue={(option) => option.value}
                            onChange={handleChangeStatus}
                        />
                    </div>
                }   
                {showFiltroUnidades && screen !== 'AGENDAMENTO_SALA' && screen !== 'CONFIGURACAO_HORARIO_SALAS' && (
                    <div className={classes.unidadeProfissional}>
                        <Tooltip
                            title="Unidade"
                            placement="top"
                        >
                            <img
                                src={messageUnidade? IconUnidade:UnidadeIcon}
                                alt="Unidade"
                                className={messageUnidade? classes.iconUnidadeMessage :classes.icon}
                            />
                        </Tooltip>
                        <ReactSelect
                            className={classes.reactSelect}
                            placeholder={'Selecione'}
                            value={filtroHeaderStore.unidade}
                            options={filtroHeaderStore.unidades || []} 
                            getOptionLabel={(option) => option.nome}
                            getOptionValue={(option) => option.id}
                            onChange={this.handleUnidadeChange}
                        />
                    </div>
                )}
                
                {showFiltroProfissionalSaude && screen !== 'AGENDAMENTO_SALA' && (
                    <div className={classes.unidadeProfissional}>
                        <Tooltip
                            title="Profissional"
                            placement="top"
                        >
                            <img
                                src={ProfissionalIcon}
                                alt="Profissional"
                                className={classes.icon}
                            />
                        </Tooltip>
                        <ReactSelect
                            className={classes.reactSelect}
                            placeholder={'Selecione'}
                            value={filtroHeaderStore.profissionalSaude}
                            options={filtroHeaderStore.profissionaisSaude || []} 
                            getOptionLabel={(option) => `${option.nome}  ${option?.especialidade ? `- ${option?.especialidade}` : ''}`}
                            getOptionValue={(option) => option.id}
                            onChange={this.handleProfissionalSaudeChange}
                        />
                    </div>
                )}
                {showFiltroProfissionalSaude && screen === 'AGENDAMENTO' && (
                    <Tooltip title="Lista de espera">
                            <Button bgColor='#5462E0'  shape="circle" onClick={() => {
                                this.props.atendimentoStore.openModalListaEspera = true;
                            }} >
                                <ListIcon color='white' size={24}/>
                            </Button>
                    </Tooltip>
                )}
               
                
                {showFiltroSalas && (screen === 'AGENDAMENTO_SALA' || screen === 'CONFIGURACAO_HORARIO_SALAS') && (
                    <div className={classes.unidadeProfissional}>
                        <Tooltip
                            title="Sala"
                            placement="top"
                        >
                            <MeetingRoomIcon className={classes.iconSala}/>
                        </Tooltip>
                        <TextFieldSearch
                            placeholder="Selecione"
                            loadOptions={this.loadSalas}
                            classNotched={classes.notchedOutline}
                            classInput={classes.inputTextField}
                            classIcons={classes.classIcons}
                            getOptionLabel={(option) => option.nome}
                            getOptionValue={(option) => option.id}
                            value={filtroHeaderStore.sala}
                            onChange={this.handleChangeSala}
                            withPaginate
                            debounceTimeout={300}
                            additional={{
                            page: 0,
                            }}
                            menuPosition="fixed"
                            maxMenuHeight={200}
                        />
                    </div>
                )}
            </div>
        )
    }
}

export default withStyles(styles)(FiltroHeader);
