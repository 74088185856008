import Api from "../config/api";

export const findAllProduto = (variables) => Api.post("", {
    query: `
      query ($search: String, $ativo: Boolean, $pageableDTO: PageableDTOInput) {
        findAllProduto(search: $search, ativo: $ativo, pageableDTO: $pageableDTO) {
          last
          content {
            ativo
            id
            nome
            produtoConvenios {
              convenio {
                id
                descricao
              }
              valor
            }
            produtoProcedimentos {
              procedimento {
                id
                nome
              }
              quantidade
            }
            tuss19OrtesesProtesesMateriais {
              id
              descricao
            }
          }
        }
      }
    `,
    variables: variables
  });

export const findAllTuss19 = (variables) => Api.post("", {
  query: `
  query ($pageableDTO: PageableDTOInput, $search: String) {
    findAllTuss19(search: $search, pageableDTO: $pageableDTO) {
      last
      content {
        id
        descricao
        codigoTermo
        codigoTermoDescricao
      }
    }
  }
  `,
  variables: variables
});

export const saveProduto = async(produto) => {
  const method = produto?.id ? 'updateProduto' : 'createProduto';
  const response = await Api.post("", {
    query: `
      mutation ($produto: ProdutoInput){
        ${method}(produto:$produto){
          id
          nome
          ativo
          tuss19OrtesesProtesesMateriais{
            id
            descricao
          }
          produtoConvenios{
            convenio{
              id
            }
            valor
          }
          produtoProcedimentos{
            procedimento{
              id
            }
          }
        }
      }
    `,
    variables: produto
  });

  if(response.data.errors) {
    throw new Error(response.data.errors[0])
  }

  return response
}


export const findByIdProduto = async (produtoId) => {
  
  const response = await Api.post("", {
  query: `
    query($produtoId: UUID){
      findByIdProduto(produtoId:$produtoId){
        ativo
        nome
        tipo
        tuss19OrtesesProtesesMateriais{
          id
          descricao
          codigoTermo
          codigoTermoDescricao
        }
        produtoConvenios{
          convenio{
            id
          }
          valor
        }
        produtoProcedimentos{
          procedimento{
            id
          }
          quantidade
        }
      }
    }
  `,
  variables: { produtoId }

  }
  )

  return response.data.data.findByIdProduto
};

export const produtoNomeJaExistente = async search => {
  const response =  await Api.post("", {
  query: `
    {
      produtoNomeJaExistente(search: "${search}")
    }`
  })
  
  return response.data.data.produtoNomeJaExistente;
};

export const findAllUnidadeMedida = async (variables) => {
  const response = await Api.post("", {
    query: `query($search: String) {
    findAllUnidadeMedida(search: $search) {
      id 
      descricao
    }
  }`, variables
  })
  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  } else {
    return response.data.data.findAllUnidadeMedida
  }
}