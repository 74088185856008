import { Switch } from "@material-ui/core";
import string from "../../../utils/string";
import React, { useRef } from "react";
import PopperCustomMenu from "../../../components/Popper/PopperCustomMenu";
import { menuOpcoesPopper } from "../Constants/Constants";
import MoreIcon from "../../../components/Icon/More";

export const notificationDefault = {
  open: false,
  message: "",
  type: "",
};

export const pageableDTODefault = {
  pageNumber: 0,
  pageSize: 30,
  sortDir: "ASC",
  sortField: "nome",
};

export const columns = ({ functions }) => [
  {
    Header: "Nome",
    getValue: (leito) => leito.nome,
    props: { component: "th", scope: "row" },
    field: "nome",
  },
  {
    Header: "Tipo",
    getValue: (leito) => string.capitalize(leito?.tipoLeito?.descricao || ""),
    props: { component: "th", scope: "row" },
    field: "tipoLeito",
  },
  {
    Header: "Status",
    getValue: (leito) => {
      return <Switch checked={leito.ativo} color="primary" />;
    },
    props: { component: "th", scope: "row" },
  },
  {
    Header: "Ação",
    isClick: true,
    getValue: (row) => <PopperMenu row={row} functions={functions} />,
  },
];

const PopperMenu = ({ row, functions }) => {
  const ref = useRef(null);
  const handleCloseWithAction = (fn) => {
    return () => {
      ref?.current && ref.current.closePopper();
      fn(row.id);
    };
  };

  return (
    <PopperCustomMenu
      menuOpcoes={menuOpcoesPopper({
        functions: {
          handleEdit: handleCloseWithAction(functions?.handleEditar),
          handleDesativar: handleCloseWithAction(functions?.handleDesativar),
          handleAtivar: handleCloseWithAction(functions?.handleAtivar),
          handleDuplicar: handleCloseWithAction(functions?.handleDuplicar),          
        },
        desativado: !row.ativo,
        isEdit: true,
      })}
      ref={ref}
      iconButton={<MoreIcon color='#505050' />}
      colorButton={{
        background: '#fff',
      }}
    />
  );
};

export const leitoDefault = {
  id: "",
  nome: "",
  tipoLeito: "",
  ativo: true,
  leitoConvenios: [],
};
