import { CircularProgress, Switch, withStyles } from "@material-ui/core";
import React from "react";
import { inject, observer } from "mobx-react";
import Notification from "../../../components/Notification";
import styles from "./Produtos.style";
import {
  findAllProduto,
  findAllTuss19,
  findAllUnidadeMedida,
  findByIdProduto,
  produtoNomeJaExistente,
  saveProduto,
} from "../../../services/ProdutoService";
import { findAllConvenio } from "../../../services/ConvenioService";
import { findAllProcedimentos } from "../../../services/ProcedimentoService";
import string from "../../../utils/string";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../utils/getPageTitle";
import { tabs } from "../../../stores/ModalConfiguracoes.store";
import { findAllProdutoAuditByProdutoId, findAllProdutoAuditByUnidade } from "../../../services/AuditoriaService";
import HeaderConfiguracoesButtonNovo from "../Components/HeaderConfiguracoesButtonNovo";
import ContentConfiguracoes from "../Components/ContentConfiguracoes";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import Table from "../../../components/Table/Table";
import { columnsConfiguracoes } from "../Constants/Constants";
import AddIcon from "../../../components/Icon/AddIcon";
import ModalConfiguracoesTabs from "../Components/Modal/ModalConfiguracoesTabs";
import ProcedimentoList from "../Components/Modal/Tabs/ProcedimentoList";
import ConvenioList from "../Components/Modal/Tabs/ConvenioList";
import { TextField, TextFieldSearch } from "../../../components/TextField";
import ModalConfirmacao from "../../../components/Modal/ModalConfirmacao/ModalConfirmacao";
import WarningIcon from "../../../components/Icon/Warning";
import { Button } from "../../../components/ui/Buttons";
import { ReactSelect } from "../../../components/Select/SelectSearch";

export const tabsModal = {
  PROCEDIMENTO: 0,
  CONVENIO: 1,
};

@inject("auditoriaStore", "modalConfiguracoesStore")
@observer
class Produtos extends React.Component {
  state = {
    notification: {
      isOpen: false,
      message: "",
    },
    search: "",
    status: true,
    produtos: [],
    pageNumber: 0,
    last: false,
    procedimentos: [],
    convenios: [],
    pageNumberModal: 0,
    lastModal: false,
    ordenarTabela: {
      sortField: "nome",
      sortDir: "ASC",
    },
    tipoOpcao: "procedimentos",
    isLoading: false,
    produtoSelected: null,
    loadingSave: false,
    idProdutoSelecionado: "",
    tabSelected: tabs.INFORMACOES,
    propsTabsModal: {
      options: ["Procedimento", "Convênio"],
      tabSelected: tabsModal.PROCEDIMENTO,
      valueSearch: "",
      quantidades: [0, 0],
    },
    openAlertProduto: false,
    propsTabsAuditoria: {
      tabSelected: tabs.INFORMACOES
    },
    unidadeMedida: null,
    isOpme:false,
  };

  unidadesMedida = [];

  componentDidMount() {
    const { modalConfiguracoesStore, auditoriaStore } = this.props;
    this.loadProdutos();

    auditoriaStore.auditoriaProps={
      ...auditoriaStore.auditoriaProps,
      auditoria: "o produto",
      auditoriaTela: "produto",
    };

  modalConfiguracoesStore.titulo="produto";
  }

  loadProdutos = () => {
    this.setState({ isLoading: true });
    findAllProduto({
      search: this.state.search,
      ativo: this.state.status,
      pageableDTO: {
        pageNumber: this.state.pageNumber,
        pageSize: 30,
        sortDir: this.state.ordenarTabela.sortDir,
        sortField: this.state.ordenarTabela.sortField,
      },
    })
      .then((response) => {
        const { last, content } = response.data.data.findAllProduto;
        this.setState({
          produtos: [...this.state.produtos, ...content],
          last: last,
        });
      })
      .catch((error) => {
        const notification = {
          isOpen: true,
          message: "Erro ao carregar lista de produtos",
        };
        this.showAlertMessage(notification);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  loadConvenios = async () => {
    const { propsTabsModal, pageNumberModal, produtoSelected } = this.state;
    this.setState({ loadingModal: true });
    await findAllConvenio(
      propsTabsModal.valueSearch,
      {
        sortDir: "ASC",
        sortField: "descricao",
      },
      true,
      pageNumberModal,
      10
    )
      .then(({ data: response }) => {
        const convenios = response.data.findAllConvenio.content.map(
          (convenioItem) => {
            const convenioChecked = produtoSelected.convenios.find(
              (item) =>
                item.convenio?.id === convenioItem.id ||
                item.id === convenioItem.id
            );
            return {
              convenio: {
                descricao: convenioItem.descricao,
                id: convenioItem.id,
                checked: !!convenioChecked,
              },
              valor: convenioChecked?.valor || 0,
            };
          }
        );

        this.setState({
          convenios: [...this.state.convenios, ...convenios],
          lastModal: response.data.findAllConvenio.last,
        });
      })
      .catch((error) => {
        const notification = {
          isOpen: true,
          message: "Erro ao carregar a lista de convênios.",
        };
        this.showAlertMessage(notification);
      })
      .finally(() => {
        this.setState({ loadingModal: false });
      });
  };

  loadProcedimentos = async () => {
    const { propsTabsModal, pageNumberModal, produtoSelected } = this.state;
    this.setState({ loadingModal: true });
    await findAllProcedimentos({
      nome: propsTabsModal.valueSearch,
      ativo: true,
      pageableDTO: {
        pageNumber: pageNumberModal,
        pageSize: 10,
        sortDir: "ASC",
        sortField: "nome",
      },
    })
      .then(({ data: response }) => {
        const procedimentos = response.data.findAllProcedimentos.content.map(
          (procedimentoItem) => {
            const procedimentoChecked = produtoSelected.procedimentos.find(
              (item) =>
                item.procedimento?.id === procedimentoItem.id ||
                item.id === procedimentoItem.id
            );

            return {
              procedimentos: {
                nome: procedimentoItem.nome,
                descricao: procedimentoItem.descricao,
                id: procedimentoItem.id,
                checked: !!procedimentoChecked,
              },
              quantidade: procedimentoChecked?.quantidade,
            };
          }
        );

        this.setState({
          procedimentos: [...this.state.procedimentos, ...procedimentos],
          lastModal: response.data.findAllProcedimentos.last,
        });
      })
      .catch((error) => {
        const notification = {
          isOpen: true,
          message: "Erro ao carregar a lista de procedimentos",
        };
        this.showAlertMessage(notification);
      })
      .finally(() => {
        this.setState({ loadingModal: false });
      });
  };

  loadUnidadesMedida = async () => {
    try {
      const response = await findAllUnidadeMedida();
      this.unidadesMedida = response;

    } catch (error) {
      console.error(error);
    }
  };

  handleClickNovoProduto = () => {
    const { modalConfiguracoesStore } = this.props;
    const { propsTabsModal } = this.state;

    this.setState(
      {
        produtoSelected: {
          nome: "",
          ativo: true,
          procedimentos: [],
          convenios: [],
        },
        convenios: [],
        procedimentos: [],
        pageNumberModal: 0,
        propsTabsModal: {
          ...propsTabsModal,
          tabSelected: 0,
          valueSearch: "",
          quantidades: [0, 0]
        },
        tipoOpcao: "procedimentos"
      },
      () => {
        modalConfiguracoesStore.showModal = true;
        this.loadUnidadesMedida();
        this.loadProcedimentos()
      }
    );
  };

  handleClickRow = (id) => {
    this.handleEditar(id);
  };

  handleDuplicar = async (id) => {
    this.handleEditar(id, true);
  };

  handleAtivar = async (id) => {
    const selecionado = await findByIdProduto(id);
    const selecionadoAtivo = {
      ...selecionado,
      id,
      ativo: true,
    };

    delete selecionadoAtivo.tuss19OrtesesProtesesMateriais.codigoTermoDescricao;
    await saveProduto({ produto: selecionadoAtivo });
    this.setState({
      produtos: [],
      pageNumber: 0,
    });
    this.loadProdutos();
  };

  handleInativar = async (id) => {
    const selecionado = await findByIdProduto(id);
    const selecionadoInativo = {
      ...selecionado,
      id,
      ativo: false,
    };

    delete selecionadoInativo.tuss19OrtesesProtesesMateriais.codigoTermoDescricao;
    await saveProduto({ produto: selecionadoInativo });
    this.setState({
      produtos: [],
      pageNumber: 0,
    });
    this.loadProdutos();
  };

  handleEditar = async (id, isDuplicar=false) => {
    const { modalConfiguracoesStore } = this.props;

    const response = await findByIdProduto(id);
    const tuss19OrtesesProtesesMateriais = response.tuss19OrtesesProtesesMateriais?.id && {
      id: response.tuss19OrtesesProtesesMateriais.id,
      codigoTermoDescricao: {
        value: response.tuss19OrtesesProtesesMateriais.codigoTermoDescricao,
        label: response.tuss19OrtesesProtesesMateriais.codigoTermoDescricao,
      }
    };

    const convenios = response.produtoConvenios.map(item => {
      return({
        ...item,
        valor: item.valor ? String(item.valor.toFixed(2)).replace('.', ',') : item.valor
      })
    })

    const produtoSelecionadoComId = {
      id: id,
      ativo: response.ativo,
      nome: response.nome,
      convenios: convenios,
      procedimentos: response.produtoProcedimentos,
      tuss19OrtesesProtesesMateriais
    };

    if (isDuplicar) {
      produtoSelecionadoComId.id=null;
      produtoSelecionadoComId.nome=`${produtoSelecionadoComId.nome} - cópia`;
    };

    this.setState(
      {
        produtoSelected: produtoSelecionadoComId,
        propsTabsModal: {
          ...this.state.propsTabsModal,
          tabSelected: 0,
          valueSearch: "",
          quantidades: [ produtoSelecionadoComId.procedimentos.length, produtoSelecionadoComId.convenios.length ]
        },
        propsTabsAuditoria: {
          tabSelected: 0
        },
        convenios: [],
        procedimentos: [],
        pageNumberModal: 0,
        lastModal: false,
        tipoOpcao: "procedimentos"
      },
      () => {
        modalConfiguracoesStore.showModal = true;
        this.loadUnidadesMedida();
        this.loadProcedimentos()
      }
    );
  };

  onChangeSearch = (e) => {
    let search = e.target.value;
    this.setState({ search });
  };

  handleClickSearch = () => {
    this.setState({
      produtos: [],
      pageNumber: 0,
    });
    this.loadProdutos();
  };

  onKeypressSearch = (e) => {
    if (e.key === "Enter" && this.state.search.length >= 3) {
      this.setState({
        produtos: [],
        pageNumber: 0,
      });
      this.loadProdutos();
    }
  };

  handleClickClear = async () => {
    await this.setState({
      search: "",
      produtos: [],
      pageNumber: 0,
    });
    this.loadProdutos();
  };

  onCloseModal = () => {
    const { modalConfiguracoesStore } = this.props;

    modalConfiguracoesStore.showModal = false;
  };

  handleClickOrdenar = async (value) => {
    const { ordenarTabela } = this.state;

    const sortDir =
      ordenarTabela.sortField !== value
        ? "ASC"
        : ordenarTabela.sortDir === "ASC"
        ? "DESC"
        : "ASC";
      
    const status = value === 'ativo' ? sortDir === 'ASC' : this.state.status;

    this.setState(
      {
        ordenarTabela: {
          sortDir: sortDir,
          sortField: value,
        },
        status,
        produtos: [],
        pageNumber: 0,
      },
      () => this.loadProdutos()
    );
  };

  handleChange = (event, item, index) => {
    const { name, value } = event.target;
    const { produtoSelected, tipoOpcao } = this.state;

    const procedimentoOuConvenio = tipoOpcao === "procedimentos" ? "procedimento" : "convenio";
    const atributeItem = tipoOpcao === "convenios" ? "convenio" : tipoOpcao;

    if (item) {
      item[name] = name === "valor" ? value : string.numberMaskToFloat(value);

      const indexSelecionado = produtoSelected[tipoOpcao].findIndex((each) => each[procedimentoOuConvenio]?.id === item[atributeItem].id || each.id === item[atributeItem].id);
      if (indexSelecionado >= 0) {
        let object = produtoSelected;
        object[tipoOpcao][indexSelecionado][name] = name === "valor" ? string.currencyMaskToFloat(item[name]) : item[name];

        this.setState({
          produtoSelected: object,
        });
      }
    } else {
      this.setState((prevState) => ({
        produtoSelected: {
          ...prevState.produtoSelected,
          [name]: value,
        },
      }));
    }

    if(name === "valor" || name === "quantidade") {
      this.setState({
        [tipoOpcao]: [
          ...this.state[tipoOpcao].slice(0, index),
          {
            ...this.state[tipoOpcao][index],
            [name]: value,
          },
          ...this.state[tipoOpcao].slice(index+1, this.state[tipoOpcao].length)
        ]
      });
    }

  };

  handleChangeCheckbox = async (item, index) => {
    const { tipoOpcao, produtoSelected, propsTabsModal } = this.state;

    const tipo = tipoOpcao === "procedimentos" ? "procedimentos" : "convenio";

    item[tipo].checked = !item[tipo].checked;
    let object = produtoSelected;

    if (item[tipo].checked) {
      object[tipoOpcao].push({
        id: item[tipo].id,
      });
    } else {
      const removeItens = object[tipoOpcao].filter(
        (each) => each.id !== item[tipo].id
      );
      object[tipoOpcao] = removeItens;
    }

    const quantidades = tipoOpcao === "procedimentos" ?
      [ item.procedimentos.checked ? propsTabsModal.quantidades[0]+1 : propsTabsModal.quantidades[0]-1, propsTabsModal.quantidades[1] ]
      :
      [ propsTabsModal.quantidades[0], item.convenio.checked ? propsTabsModal.quantidades[1]+1 : propsTabsModal.quantidades[1]-1 ]

    this.setState({ 
      produtoSelected: object,
      [tipoOpcao]: [
        ...this.state[tipoOpcao].slice(0, index),
        {
          ...this.state[tipoOpcao][index],
          checked: !item[tipo].checked,
        },
        ...this.state[tipoOpcao].slice(index+1, this.state[tipoOpcao].length)
      ],
      propsTabsModal: {
        ...propsTabsModal,
        quantidades,
      }
    });
  };

  handleSave = async ({isSaveMesmoNome=false}) => {
    try {
      const { modalConfiguracoesStore } = this.props;
      const { produtoSelected, unidadeMedida, isOpme } = this.state;
      this.setState({
        loadingSave: true,
      });
  
      if (!produtoSelected.nome || string.isEmpty(produtoSelected.nome)) {
        const notification = {
          isOpen: true,
          message: "Digite o nome do produto.",
        };
        this.showAlertMessage(notification);
        return;
      }
  
      const existeMesmoNome = await this.verificaProdutoComMesmoNome(produtoSelected.nome)
  
      if(!isSaveMesmoNome && existeMesmoNome) return;
  
      const produtoConvenios = produtoSelected.convenios.map((item) => {
        return {
          convenio: {
            id: item.convenio?.id || item.id,
          },
          valor: typeof item.valor === 'string' ? string.removeMask(item.valor, "R$") : item.valor,
        };
      });
  
      const produtoProcedimentos = produtoSelected.procedimentos.map((item) => ({
        procedimento: {
          id: item.procedimento?.id || item.id,
        },
        quantidade: item.quantidade,
      }));
  
      let id = produtoSelected?.id;
      let tuss19OrtesesProtesesMateriais = produtoSelected.tuss19OrtesesProtesesMateriais && {
        id: produtoSelected.tuss19OrtesesProtesesMateriais.id,
      };
  
      await saveProduto({
        produto: {
          id,
          nome: produtoSelected.nome,
          ativo: produtoSelected.ativo,
          tuss19OrtesesProtesesMateriais,
          produtoConvenios: produtoConvenios,
          produtoProcedimentos: produtoProcedimentos,
          ...(isOpme && { tipo: "OPME" }),
          ...(unidadeMedida && { unidadeMedida }),
        },
      });
  
      this.setState({
        produtos: [],
        pageNumber: 0,
      });
      modalConfiguracoesStore.showModal = false;
      this.loadProdutos();

    } catch {
      const notification = {
        isOpen: true,
        message: "Erro ao salvar o produto",
      };
      this.showAlertMessage(notification);
    } finally {
      this.setState({ loadingSave: false });
    }
  };

  onChangeSearchModal = (e) => {
    const { propsTabsModal } = this.state;

    let value = e.target.value;
    this.setState({ propsTabsModal: {
      ...propsTabsModal,
      valueSearch: value
    } })
  };

  handleClickClearModal = () => {
    this.setState({
      propsTabsModal: {
        ...this.state.propsTabsModal,
        valueSearch: "",
      },
      convenios: [],
      procedimentos: [],
      pageNumberModal: 0,
      lastModal: false,
    }, () => {
      this.loadListaModal();
    });

  };

  onKeypressSearchModal = (e) => {
    const { propsTabsModal } = this.state;

    if (e.key === 'Enter' && propsTabsModal.valueSearch.length >= 3) {
      this.setState({
        convenios: [],
        procedimentos: [],
        pageNumberModal: 0,
        lastModal: false,
      }, () => {
        this.loadListaModal();
      });
    }
  };

  handleClickSearchModal = () => {
    this.setState({
      convenios: [],
      procedimentos: [],
      pageNumberModal: 0,
      lastModal: false,
    }, () => {
      this.loadListaModal();
    });
  }

  loadListaModal = () => {
    this.state.tipoOpcao === "procedimentos"
    ? this.loadProcedimentos()
    : this.loadConvenios();
  }

  loadMoreModal = () => {
    const {
      loadingModal,
      convenios,
      procedimentos,
      pageNumberModal,
      tipoOpcao,
    } = this.state;
    if (loadingModal) {
      return;
    }

    if (tipoOpcao === "procedimentos") {
      this.setState({
        pageNumberModal: procedimentos.length > 0 ? pageNumberModal + 1 : 0,
      });
      this.loadProcedimentos();
    } else {
      this.setState({
        pageNumberModal: convenios.length > 0 ? pageNumberModal + 1 : 0,
      });
      this.loadConvenios();
    }
  };

  handleChangeTuss = (value) => {
    if (value) {
      this.setState((prevState) => ({
        produtoSelected: {
          ...prevState.produtoSelected,
          tuss19OrtesesProtesesMateriais: {
            id: value.id,
            descricao: { value: value.descricao, label: value.descricao },
            codigoTermo: { value: value.codigoTermo, label: value.codigoTermo },
            codigoTermoDescricao: { value: value.codigoTermoDescricao, label: value.codigoTermoDescricao },
          },
          nome: value.codigoTermoDescricao,
        },
      }));
    } else {
      this.setState((prevState) => ({
        produtoSelected: {
          ...prevState.produtoSelected,
          tuss19OrtesesProtesesMateriais: null,
        },
      }));
    }
  };

  showAlertMessage = (notification) => {
    this.setState({ notification: notification });

    const timeoutId = setTimeout(() => {
      this.closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
    };
    this.setState({ notification: notification });
  };

  loadMore = () => {
    const { isLoading, produtos } = this.state;
    if (isLoading) {
        return;
    }

    this.setState({ pageNumber: produtos.length > 0 ? this.state.pageNumber + 1 : 0 });

    this.loadProdutos();
  };

  closeModalConfirmacao = () => {
    this.setState({ openAlertProduto: false })
  };

  voltarMudarNome = () => {
    const { modalConfiguracoesStore } = this.props;
    this.closeModalConfirmacao();
    modalConfiguracoesStore.showModal=true;
  };

  verificaProdutoComMesmoNome = async(nomeProduto) => {
    const { produtoSelected } = this.state;
    const existeMesmoNome = await produtoNomeJaExistente(nomeProduto);
    this.setState({ 
      openAlertProduto: existeMesmoNome && !produtoSelected.id,
      loadingSave: false,
    });

    return existeMesmoNome && !produtoSelected.id;
  };

  saveProdutoComMesmoNome = async() => {
    const { produtoSelected } = this.state;
    this.setState({
      produtoSelected: {
        ...produtoSelected,
        nome: `${produtoSelected.nome} - cópia`,
      }
    }, () => {
      this.closeModalConfirmacao();
      this.handleSave({isSaveMesmoNome: true});
    })
  };

  setAuditoriaTela = () => {
    const { auditoriaStore } = this.props;

    auditoriaStore.auditoriaProps={
        ...auditoriaStore.auditoriaProps,
        auditoria: "o tipo consulta",
        auditoriaTela: "agendamentoTipo",
    };

    auditoriaStore.load={
        query: findAllProdutoAuditByUnidade, 
        variableName: "",
        variableId: "", 
    };
  };

  changeTabSelectedModal = async(tabSelected) => {
    const { propsTabsModal } = this.state;

    this.setState(
      {
        procedimentos: [],
        convenios: [],
        pageNumberModal: 0,
        lastModal: false,
        tipoOpcao: tabSelected === 0 ? "procedimentos" : "convenios",
        propsTabsModal: {
          ...propsTabsModal,
          tabSelected: tabSelected,
          valueSearch: "",
      }
      },
      () => tabSelected === 0
        ? this.loadProcedimentos()
        : this.loadConvenios()
    );
  };

  handleCodigoTermoDescricaoLoadOptions = async (search, loadedOptions, { page }) => {  
    return this.handleLoadMoreOptionsDescricao({
      search: search,
      loadedOptions,
      data: { page },
      method: "tuss19OrtesesProtesesMateriais",
    });
  };

  handleLoadMoreOptionsDescricao = async ({ search, data, method }) => {
    let searchFilter = {
        pageableDTO: {
            sortField: "codigoTermo",
            sortDir: "ASC",
            pageNumber: data.page,
            pageSize: 20
        },
        ativo: true
    }
      
    if(search) {
        searchFilter.search = search;
    }

    const response = await findAllTuss19({...searchFilter});
    const { last, content } = response.data.data.findAllTuss19;
    const options = content.map((item) => ({
      ...item,
      value: item.id,
      label: item.codigoTermoDescricao,
    }));

    return {
        options: options,
        hasMore: !last,
        additional: {
        page: data.page + 1
      }
    };
  };

  handleChangeUnidadeMedida = (e) => {
    this.setState({ unidadeMedida: e });
  }

  onChangeSwitch = () => {
    const { isOpme } = this.state;
    this.setState({ isOpme: !isOpme });
  }


  renderHeader = () => {
    const { classes } = this.props;
    const { produtoSelected, isOpme } = this.state

    return(<>
     <div className={classes.row}>
          <div className={isOpme ? classes.contentNomeAdapted : classes.contentNome}>
            <div className={classes.wraperNome}>
              <div className={classes.titleRenderHeader}>
              Nome do produto *Obrigatório
              </div>
              <TextField
                name={"nome"}
                className={classes.textField}
                classes={{
                    root: classes.notchedOutlineTextField,
                }}
                placeholder={`Digite o nome do produto`}
                value={produtoSelected?.nome}
                onChange={this.handleChange}
              />
          </div>
          <div className={classes.contentSwitch}>
            <label className={classes.titleField}>OPME</label>
            <Switch
              onClick={this.onChangeSwitch}
              checked={this.state.isOpme}
              color={'primary'}
            />
          </div>
        </div>
        <div>
          {produtoSelected?.id && (
            <div className={classes.contentStatus}>
              <div className={classes.titleField}>Status</div>
              <Switch
                checked={produtoSelected?.ativo}
                color="primary"
                onClick={this.handleChangeStatus}
              />
            </div>
          )}
          </div>
      </div>
      <div className={classes.wrapperTextFields}>
        <div className={isOpme ? classes.textFieldCodigoAdapted : classes.textFieldCodigo}>
          <div className={classes.titleRenderHeader}>
            Selecione código e descrição
          </div>
          <TextFieldSearch
            placeholder='Pesquise por código ou descrição'
            className={classes.renderHeaderInput}
            classNotched={classes.notchedOutline}
            loadOptions={this.handleCodigoTermoDescricaoLoadOptions}
            withPaginate
            value={produtoSelected?.tuss19OrtesesProtesesMateriais?.codigoTermoDescricao}
            onChange={e => this.handleChangeTuss(e)}
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </div>
        {this.state.isOpme &&
          <div className={classes.textFieldUnidadeMedida}>
            <div className={classes.titleRenderHeader}>Unidade de Medida</div>
            <ReactSelect
              getOptionLabel={(option) => option.descricao}
              getOptionValue={(option) => option.id}
              options={this.unidadesMedida || []}
              value={this.state.unidadeMedida}
              onChange={e => this.handleChangeUnidadeMedida(e)}
              className={classes.unidadeMedida}
            />
          </div>
        }
      </div>
    </>)
  };

  changeTabModalAuditoriaSelected = async(tabSelected) => {
    const { auditoriaStore } = this.props;
    const { produtoSelected } = this.state;

    auditoriaStore.auditoriaProps={
        ...auditoriaStore.auditoriaProps,
        pageNumber: 0,
        lastPage: false,
        auditorias: [],
    };

    if (tabSelected === 1) {
        auditoriaStore.load={
            query: findAllProdutoAuditByProdutoId, 
            variableName: "produtoId",
            variableId: produtoSelected.id,
        }
        await auditoriaStore.loadAuditItems();
      }

    this.setState({
        propsTabsAuditoria: {
            tabSelected: tabSelected
        }
    })
  };

  handleChangeStatus = () => {
    const { produtoSelected } = this.state;
    this.setState({
      produtoSelected: {
        ...produtoSelected,
        ativo: !produtoSelected.ativo
      }
    });
  };

  render() {
    const { classes, modalConfiguracoesStore } = this.props;
    const { notification, search, produtos, last, isLoading, ordenarTabela, propsTabsModal, produtoSelected} = this.state;

    return (
      <>
        <div className={classes.content}>
          <PageTitle title={pageConfiguracoes("Produtos")} />
          <HeaderConfiguracoesButtonNovo
            title="Produtos"
            labelButton="Novo Produto"
            handleClickNovo={this.handleClickNovoProduto}
            search={{
              handleChange: this.onChangeSearch,
              value: search,
              onKeypress: this.onKeypressSearch,
              handleClickClear: this.handleClickClear,
              onClickButtonSearch: this.handleClickSearch
            }}
          />
          <div className={classes.contentList}>
            <ContentConfiguracoes
              quantidadeProcedimentos={produtos.length}
              setAuditoriaTela={this.setAuditoriaTela}
            >
              <div className={classes.table}>
                <Scroll
                  loadMore={this.loadMore}
                  hasMore={!last}
                  pageStart={0}
                  initialLoad={false}
                >
                  {
                    produtos.length > 0 && <Table
                      dados={produtos}
                      columns={columnsConfiguracoes({
                          headerNome: "Nome do produto",
                          functions: {
                              handleEditar: this.handleEditar,
                              handleDuplicar: this.handleDuplicar,
                              handleDesativar: this.handleInativar,
                              handleAtivar: this.handleAtivar,
                          },
                      })}
                      clickable={true}
                      handleClick={this.handleClickRow}
                      comOrdenacao
                      ordenarTabela={ordenarTabela}
                      handleClickOrdenar={(value) => this.handleClickOrdenar(value)} 
                    />
                  }
                  {
                    isLoading && (
                      <div className={classes.notFoundContainer}>
                        <CircularProgress/>
                      </div>
                    )
                  }
                </Scroll>
              </div>
            </ContentConfiguracoes>
            <Button
              shape='circle'
              bgColor='#26ACA9'
              onClick={this.handleClickNovoProduto}
              shadow
              className={classes.buttonCriar} 
            >
              <AddIcon/>
            </Button>
          </div>
        </div>
        {
          modalConfiguracoesStore.showModal && (
            <ModalConfiguracoesTabs
              itemSelecionado={produtoSelected}
              title={"Produto"}
              renderHeader={this.renderHeader}
              onClose={this.onCloseModal}
              handleSave={this.handleSave}
              loadingModal={this.state.loadingModal}
              loadingSave={this.state.loadingSave}
              classes={{
                content: produtoSelected?.id ? classes.contentModalEdit : classes.contentModal,
              }}
              propsTabs={{
                  ...propsTabsModal,
                  changeTabSelected: this.changeTabSelectedModal,
                  handleClickSearch: this.handleClickSearchModal,
                  handleClickClear: this.handleClickClearModal,
                  onChangeSearch: this.onChangeSearchModal,
                  onKeypressSearch: this.onKeypressSearchModal,
              }}
              propsPopperCustom={{
                  handleEditar: this.handleEditar,
                  handleDuplicar: this.handleDuplicar,
                  handleDesativar: this.handleInativar,
                  handleAtivar: this.handleAtivar,
              }}
              propsTabsAuditoria={{
                  tabSelected: this.state.propsTabsAuditoria.tabSelected,
                  changeTabAuditoriaSelected: this.changeTabModalAuditoriaSelected,
              }}
            >
              {
                propsTabsModal.tabSelected === 0 ?
                <ProcedimentoList
                  showQuantidade
                  hiddenValor
                  listaModal={this.state.procedimentos}
                  handleChangeCheckbox={this.handleChangeCheckbox}
                  handleChange={this.handleChange}
                  loadMore={this.loadMoreModal}
                  lastScroll={this.state.lastModal}
                  loadingModal={this.state.loadingModal}
                />
                :
                <ConvenioList
                  listaModal={this.state.convenios}
                  handleChangeCheckbox={this.handleChangeCheckbox}
                  handleChange={this.handleChange}
                  loadMore={this.loadMoreModal}
                  lastScroll={this.state.lastModal}
                  loadingModal={this.state.loadingModal}
                />
              }
            </ModalConfiguracoesTabs>
          )
        }
        <ModalConfirmacao
          open={this.state.openAlertProduto}
          classes={{
              paperAlert: classes.paperAlert
          }}
          icon={<WarningIcon />}
          color={"yellow"}
          title={" Já foi criado um produto com esse nome"}
          buttons={{
            labelPrimaryButton: "Salvar assim mesmo",
            handlePrimaryButton: () => this.saveProdutoComMesmoNome(),
            labelSecondButton: "Voltar e alterar nome",
            handleSecondButton: () => this.voltarMudarNome(),
            disabledPrimaryButton: this.state.loadingSave,
            disabledSecondButton: this.state.loadingSave,
          }}
        />
        <Notification
          close={this.closeAlertMessage}
          reset={this.closeAlertMessage}
          isOpen={notification.isOpen}
          variant={"error"}
          message={notification.message}
        />
      </>
    );
  }
}

export default withStyles(styles)(Produtos);
