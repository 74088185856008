import moment from "moment";

let string = {
    format(mask, value) {
        if (mask == null || value == null) return value;

        let formatted = '';
        let next = 0;
        let ignore = 0;
        for (let i = 0; i < mask.length; i++) {
            if (i - ignore >= value.length) break;
            if (mask[i] === '#') formatted += value[next++];
            else {
                formatted += mask[i];
                ignore++;
            }
        }
        return formatted;
    },

    removeSpecialChars(value) {
        if (value == null || value.length < 1) return value;
        return value.replace(/[^A-Z0-9]+/ig, '');
    },
    
    currencyMaskToFloat(value) {
        if (typeof value === 'string' && value.trim().length > 0) {
            const newValue = value.replace(/[^,0-9]+/ig, '').replace(',', '.');
            
            return parseFloat(newValue);
        }
        
        return 0;
    },

    validaCPF(cpf) {
        if(this.isEmpty(cpf)) {
            return false;
        }
        
        const CPFS_INVALIDOS = [
            '00000000000', '11111111111', '22222222222', '33333333333', '44444444444',
            '55555555555', '66666666666', '77777777777', '88888888888', '99999999999'];
        cpf = this.removeSpecialChars(cpf);
        if (CPFS_INVALIDOS.indexOf(cpf) > -1) return false;

        let soma = 0;
        for (let i = 1; i <= 9; i++)
            soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
        let resto = (soma * 10) % 11;

        if (resto === 10 || resto === 11)
            resto = 0;

        if (resto !== parseInt(cpf.substring(9, 10)))
            return false;

        soma = 0;
        for (let i = 1; i <= 10; i++)
            soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
        resto = (soma * 10) % 11;

        if (resto === 10 || resto === 11)
            resto = 0;

        return resto === parseInt(cpf.substring(10, 11));
    },
    
    validaTelefone(tel) {
        if(this.isEmpty(tel)) {
            return false;
        }
        
        const text = this.removeSpecialChars(tel);
        const length = text.trim().length;
        
        return length >= 10 && length <= 16;
    },

    validaTelefoneComDDI(tel, telDDI) {
        if(this.isEmpty(tel)) {
            return false;
        }
        
        if(telDDI === '55') {
            return this.validaTelefone(tel);
        }

        return true;
    },

    validaEmail(email) {
        // eslint-disable-next-line no-control-regex,no-useless-escape
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

        return expression.test(String(email).toLowerCase())
    },

    stringify(objFromJson) {

        if (Array.isArray(objFromJson)) {
            return objFromJson.map(item => this.stringify(item));

        } else if (typeof objFromJson !== "object") {
            // not an object, stringify using native function
            return JSON.stringify(objFromJson);
        }

        let props = Object
            .keys(objFromJson)
            .map(key => objFromJson[key] ? `${key}:${this.stringify(objFromJson[key])}` : '')
            .join(",");

        return `{${props}}`;
    },

    concatenarArrayProcedimentosEmString(array) {
        if (!array) {
            return ''
        };

        const string = array.map(item => item.procedimento?.nome || item.nome);
        return string.join(', ');
    },

    valorProcedimentoConsulta(agendamento) {
        const convenioId = agendamento?.convenio?.id;

        if (convenioId){
            const procedimentos = agendamento.procedimentos.length > 0 && agendamento.procedimentos.map(item => item.procedimento);

            if (!!procedimentos){
                const procedimentosConvenio = agendamento.convenio.convenioProcedimentos;

                const procedimentoAgendadoComConvenio = procedimentosConvenio.filter(
                  (procedimento) => {
                    return procedimentos.some(
                      (procedimentoAgendado) => procedimentoAgendado.id === procedimento.procedimento.id
                    );
                  }
                );
                
                const listaComValores = procedimentoAgendadoComConvenio && procedimentoAgendadoComConvenio.map(item => item.valor);
                const somaValores = listaComValores.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                return somaValores
            }

        } else {
            return
        }
    },
    
    capitalize(text) {
        text = text.toLowerCase();
        return text.charAt(0).toUpperCase() + text.slice(1);
    },
    capitalizeEachWord(string) {
        const words = string.toLowerCase().split(' ');
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);
        }
        return words.join(' ');
    },

    capitalizeSecondWord(string) {
        string = string.replace(/[_][a-z]/g, letter => letter.toUpperCase())
        return string.replaceAll('_', '')
    },

    stringToLowerCase(string, index = 0){
        return string.charAt(index) + string.slice(1).toLowerCase();
    },

    objToGraphQL(obj) {
        const newObj = this.removeEmpty(obj);

        const objString = JSON.stringify(newObj);
        const graphQLObject = objString.replace(/"([^(")"]+)":/g, "$1:");

        return graphQLObject;
    },

    isEmpty(text) {
        if(typeof text !== 'string') {
            return true;
        }
        
        if(text.trim().length === 0) {
            return true;
        }
        
        return false;
    },

    removeEmpty(obj) {

        const o = JSON.parse(JSON.stringify(obj)); // Clone source oect.

        Object.keys(o).forEach(key => {
            if (o[key] && typeof o[key] === 'object')
                o[key] = this.removeEmpty(o[key]);  // Recurse.
            else if (o[key] === undefined || o[key] === null)
                delete o[key]; // Delete undefined and null.
        });

        return o;
    },
    
    truncate(str, length, ending) {
        if (typeof str !== 'string') {
            return str;
        }
        if (length == null) {
            length = 100;
        }
        if (ending == null) {
            ending = '...';
        }
        if (str.length > length) {
            return str.substring(0, length - ending.length) + ending;
        }
        
        return str;
    },
    
    numberMaskToFloat(value) {
        if (typeof value === 'number') {
            return value;
        }
        return this.isEmpty(value) ? 0 : parseFloat(value.replace(',', '.'));
    },

    formatData(value){
        if(value) {
            let data = this.removeSpecialChars(value);

            return data.substring(6, 8) + '/' + data.substring(4, 6) + '/' + data.substring(0, 4);
        }

        return value;
    },

    getValueByPath(object, path) {
        let value = { ...object };
        const attributes = path.split('.').slice(1);
        attributes.forEach(i => { 
            value = value ? value[i] : null
        });
        return value;
    },

    adicionaSufixo(texto, sufixo) {
        return `${texto + sufixo}`
    },

    isMaxLength(text, maxLength){
        return text.length > maxLength
    },

    converteUUIDeID(id) {
        return id.toString().replaceAll("-", "")
    },

    removeUnderline(string) {
        return string.replaceAll("_"," ")
    },

    removeMask(value, sufix) {
        return parseFloat(value.replace(sufix, "")
            .replace('.', '')
            .replace(",", ".")
        )
    },

    verificaSenhaForte(value){
        const numero = /[0-9]/;
        const tamanho = value.length;
        const letraMinuscula = /[a-z]/;
        const letraMaiuscula = /[A-Z]/;
        const caractereEspecial = /\W|_/;

        const validacao = {
            numero: numero.test(value),
            tamanho: tamanho > 7,
            letraMinuscula: letraMinuscula.test(value),
            letraMaiuscula: letraMaiuscula.test(value),
            caractereEspecial: caractereEspecial.test(value),
        };

        return validacao;
    },

    verificaAniversarioProximoADataAtual(dataNascimento, quantidadeDias) {
        const anoAtual = moment().format("YYYY");
        const dataNascimentoModified = `${anoAtual}-${moment(dataNascimento).format("MM-DD")}`;

        const dataInicio = moment().subtract(quantidadeDias, 'days').format("YYYY-MM-DD");
        const dataFim = moment().add(quantidadeDias, 'days').format("YYYY-MM-DD");

        const isAniversarioProximo = moment(dataNascimentoModified).isSameOrAfter(dataInicio) && moment(dataNascimentoModified).isSameOrBefore(dataFim);
        const isAniversariante = moment(dataNascimento).format("MM-DD") === moment().format("MM-DD");

        return { isAniversarioProximo, isAniversariante }
    },

    cpfMask(value) {
        return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },

    calculaDesconto(valor, desconto, tipo){
        let valorDesconto = desconto;

        if(tipo === "PERCENTIL") {
            valorDesconto = valor * desconto/100
        }

        return desconto ? valor - valorDesconto : valor;
    },

    stripHtml(html) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    },

    transformDoisDigitosString(numero) {
        return (numero < 10) ? '0' + numero.toString() : numero.toString();
    },

    isUUID (value) {
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return uuidRegex.test(value);
    },

    currentFieldControleSessao(data) {
        const isProcedure = data?.controleSessaoProcedimentos?.length > 0;
        const field =  isProcedure ? 'controleSessaoProcedimentos' : 'controleSessaoAgendamentoTipos'
        const subField = isProcedure ? 'procedimento' : 'agendamentoTipo'
        return { field, subField }
    },

    stringIsLink(value) {
        const urlPattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        return urlPattern.test(value);
    },

    removeHTMLTags(value){
        return value.replace(/(<([^>]+)>)/ig, '');
    },

    isFilenameWithExtension(text) {
      var regex = /^[^\s]+\s?.+\.[a-zA-Z]+$/;
      return regex.test(text);
    },

    formatArrayStrings(array){
        const isEmail=(str)=> /@/.test(str)
        const trimStrig = (str) => str.trim()
        const arraySemEspacos= array.map((item) => trimStrig(item))
        const arrayfiltrado = arraySemEspacos.filter((item) => item !== "")
        const destinatariosOrdenados = arrayfiltrado.sort((a,b)=> isEmail(a) - isEmail(b))
        return destinatariosOrdenados
    },

    removeCpfMask(cpf) {
        return cpf.replace(/[\.\-]/g, "")
    }
};

export default string;