import Api from "../config/api";

export const findAllOrcamento = async (variables) => {
  const response = await Api.post("", {
    query: `
      query(
        $profissionalSaudeId: Long
        $sujeitoAtencaoId: UUID
        $search: String
        $procedimentoId: UUID
        $dataHoraLancamentoInicio: LocalDateTime
        $dataHoraLancamentoFim: LocalDateTime
        $pageableDTO: PageableDTOInput
        $status: [OrcamentoStatus]
        $tipo: OrcamentoTipo
      ) {
        findAllOrcamento(
          profissionalSaudeId: $profissionalSaudeId
          sujeitoAtencaoId: $sujeitoAtencaoId
          search: $search
          procedimentoId: $procedimentoId
          dataHoraLancamentoInicio: $dataHoraLancamentoInicio
          dataHoraLancamentoFim: $dataHoraLancamentoFim
          pageableDTO: $pageableDTO
          status: $status
          tipo: $tipo
        ) {
          last
          content {
            id
            dataHoraLancamento
            nome
            numero
            profissionalSaude {
              id
              nome
            }
            valorTotalLiquido
            status
            tipo
            faturado
          }
        }
      }    
    `,
    variables: variables,
  });

  if (!response.data.errors) {
    return response.data.data.findAllOrcamento;
  } else {
    throw response.data.errors[0];
  }
};

export const getProcedimentoOdontologico = async (variables) => {
  const response = await Api.post("", {
    query: `
    query ($search: String, $localOdontograma: [Long], $pageableDTO: PageableDTOInput, $tipoProcedimentoDTO: TipoProcedimentoDTOInput) {
      findAllProcedimentos(search: $search, localOdontograma: $localOdontograma, pageableDTO: $pageableDTO, tipoProcedimentoDTO: $tipoProcedimentoDTO) {
        last
        content {
          value:id
          label:codigoTermoDescricao
        }
      }
    }
    `,
    variables: {
      tipoProcedimentoDTO: {
        tipo: "ODONTOLOGICO",
      },
      ...variables,
    },
  });

  if (!response.data.errors) {
    return response.data.data.findAllProcedimentos;
  } else {
    throw response.data.errors[0];
  }
};

export const findProfissionalSaudeByUnidadeComAgendaPage = async (
  variables
) => {
  const response = await Api.post("", {
    query: `
    query($unidadeId: Long, $search: String, $pageableDTO: PageableDTOInput, $especialidadeMedica: Long) {
      findProfissionalSaudeByUnidadeComAgendaPage(unidadeId: $unidadeId, search: $search, pageableDTO: $pageableDTO, especialidadeMedica: $especialidadeMedica) {
        last
        content{
          value:id
          label:nome
          id
          nome
        }
      }
    }
    `,
    variables: variables,
  });

  if (!response.data.errors) {
    return response.data.data.findProfissionalSaudeByUnidadeComAgendaPage;
  } else {
    throw response.data.errors[0];
  }
};

export const updateStatusOrcamento = async (variables) => {
  const response = await Api.post("", {
    query: `
    mutation($orcamentoId: UUID, $status: OrcamentoStatus) {
      updateStatusOrcamento(orcamentoId: $orcamentoId, status: $status) {
        id
      }
    }
    `,
    variables: variables,
  });

  if (!response.data.errors) {
    return response.data.data.updateStatusOrcamento;
  } else {
    throw response.data.errors[0];
  }
};

export const createOrcamento = async (orcamento) => {
  const response = await Api.post("", {
    query: `
    mutation($orcamento: OrcamentoInput) {
      createOrcamento(orcamento: $orcamento) {
        id
      }
    }
    `,
    variables: {
      orcamento,
    },
  });

  if (!response.data.errors) {
    return response.data.data.createOrcamento;
  } else {
    throw response.data.errors[0];
  }
};

export const updateOrcamento = async (orcamento) => {
  const response = await Api.post("", {
    query: `
    mutation($orcamento: OrcamentoInput) {
      updateOrcamento(orcamento: $orcamento) {
        id
      }
    }
    `,
    variables: {
      orcamento,
    },
  });

  if (!response.data.errors) {
    return response.data.data.updateOrcamento;
  } else {
    throw response.data.errors[0];
  }
};

export const findOrcamentoById = async ({ id }) => {
  const response = await Api.post("", {
    query: `
      query($orcamentoId: UUID) {
        findOrcamentoById(orcamentoId: $orcamentoId) {
          convenio {
            id
            descricao
          }
          dataVencimento
          dataHoraLancamento
          desconto {
            valorDesconto
            tipoDesconto
          }
          descontoAprovado
          faturado
          nome
          observacao
          orcamentoProcedimentos {
            id
            procedimento {
              id
              nome
              descricao
              convenioProcedimentos {
                valor
                convenio {
                  id
                }
              }
            }
           procedimentoOdontologico {
              id
              dente {
                id
              }
              procedimento {
                id
                nome
                descricao
                convenioProcedimentos {
                  convenio {
                    id
                  }
                  valor
                }
              }
            }
            valorUnitario
            quantidade
          }
          profissionalSaude {
            id
            nome
          }
          tipo
          status
          tipoPagamento
          valor
          valorEntrada
          faturado
          numero
        }
      }
    `,
    variables: { orcamentoId: id },
  });

  if (!response.data.errors) {
    return response.data.data.findOrcamentoById;
  } else {
    throw response.data.errors[0];
  }
};

export const findOrcamentoByIdAgendamento = async (id) => {
  const response = await Api.post("", {
    query: `
    query($agendamentoId: Long ) {
      findOrcamentoByIdAgendamento(agendamentoId: $agendamentoId) {
        convenio {
          label:descricao
          value:id
          id
          descricao
        }
        dataVencimento
        id
        nome
        observacao
        status
        desconto {
          tipoDesconto
          valorDesconto
        }
        orcamentoProcedimentos {
            id
            espessura
            procedimento {
              id
              nome
              descricao
              convenioProcedimentos {
                valor
                convenio {
                  id
                }
              }
            }
           procedimentoOdontologico {
              id
              dente {
                id
                codigo
              }
              mucosaOral {
                id
                descricao
              }
              procedimento {
                id
                nome
                convenioProcedimentos {
                  convenio {
                    id
                  }
                  valor
                }
              }
            }
            valorUnitario
            quantidade
          }
        sujeitoAtencao {
          id
          nome
        }
        profissionalSaude {
          id
          nome
        }
        tipo
        tipoPagamento
        totalParcelas
        valor
        numero
        valorEntrada
      }
    }    
    `,
    variables: { orcamentoId: id },
  });

  if (!response.data.errors) {
    return response.data.data.findOrcamentoByIdAgendamento;
  } else {
    throw response.data.errors[0];
  }
};

export const getLinkFaturamentoOrcamento = async ({ orcamentoId }) => {
  const response = await Api.post("", {
    query: `
      query($orcamentoId: UUID) {
        linkFaturamentoOrcamento(orcamentoId: $orcamentoId)
      }
    `,
    variables: { orcamentoId },
  });

  if (!response.data.errors) {
    return response.data.data.linkFaturamentoOrcamento;
  } else {
    throw response.data.errors[0];
  }
};

export const findAllProcedimentos = async (variables) => {
  const response = await Api.post("", {
    query: `
    query FindAllProcedimentos($ativo: Boolean, $tiposProcedimentos: [TipoProcedimento], $nome: String, $pageableDTO: PageableDTOInput) {
      findAllProcedimentos(ativo: $ativo, tiposProcedimentos: $tiposProcedimentos, nome: $nome, pageableDTO: $pageableDTO) {
        last
        content {
          convenioProcedimentos {
            valor
            convenio {
              id
            }
          }
          nome
          id
          tiposProcedimentos
        }
      }
    }
    `,
    variables: variables,
  });

  if (!response.data.errors) {
    return response.data.data.findAllProcedimentos;
  } else {
    throw response.data.errors[0];
  }
};

export const findAllLocalPalmilha = async () => {
  const response = await Api.post("", {
    query: `
      query {
        findAllLocalPalmilha {
          id
          nome
          codigo
        }
      }
    `,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.findAllLocalPalmilha;
};

export const findOrcamentoOrtopedicoById = async (id) => {
  const response = await Api.post("", {
    query: `
    query($orcamentoId: UUID) {
      findOrcamentoById(orcamentoId: $orcamentoId) {
        id
        nome
        observacao
        status
        numero
        centimetrosChinelo
        numeroPalmilha
        orcamentoProcedimentos {
            id
            procedimento {
              id
              nome
              descricao
              tiposProcedimentos
              convenioProcedimentos {
                valor
                convenio {
                  id
                }
              }
            }
            valorUnitario
            quantidade
          }
        sujeitoAtencao {
          id
          nome
        }
        profissionalSaude {
          id
          nome
        }
        locaisPalmilhaPeDireito {
          espessura
          id
          codigo
          nome:nomeLocal
        }
        locaisPalmilhaPeEsquerdo {
          id
          codigo
          espessura
          nome:nomeLocal
        }
        tipo
        tipoPagamento
        totalParcelas
        valor
        valorEntrada
        numero
      }
    }    
    `,
    variables: { orcamentoId: id },
  });

  if (!response.data.errors) {
    return response.data.data.findOrcamentoById;
  } else {
    throw response.data.errors[0];
  }
};

export const findAllConvenioList = async () => {
  const response = await Api.post("", {
    query: `
      query {
        findAllConvenioList(searchDTO: {
          ativo: true,
          sortDir: "ASC",
          sortField: "descricao"
        }) {
          value: id
          label: descricao
          convenioProcedimentos {
            valor
            convenio {
              id
            }
          }
        }
      }
    `,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.findAllConvenioList;
};

export const urlPainel3dpe = async () => {
  const response = await Api.post("", {
    query: `
      {
        urlPainel3dpe
      }
    `,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.urlPainel3dpe;
};

export const orcamentosByAgendamento = async (variables) => {
  const response = await Api.post("", {
    query: `
    query($agendamentoId: Long, $pageableDTO: PageableDTOInput) {
      findAllOrcamento(agendamentoId: $agendamentoId, pageableDTO: $pageableDTO) {
        last
        content {
          id
          dataHoraLancamento
          valor
          status
          numero
        }
      }
    }
    `,
    variables: variables,
  });
  if (!response.data.errors) {
    return response.data.data.findAllOrcamento;
  } else {
    throw response.data.errors[0];
  }
};

export const sujeitoAtencaoOrcamentoHeader = async (id) => {
  const response = await Api.post("", {
    query: `
      query ($id: UUID){
					findByIdSujeitoAtencao(id: $id){
          id
          nome
          contato {
            telefonePrincipal
            email
          }
        }
      }
    `,
    variables: { id },
  });
  if (!response.data.errors) {
    return response.data.data.findByIdSujeitoAtencao;
  } else {
    throw response.data.errors[0];
  }
};

export const copiarOrcamento = async (orcamentoId) => {
  const response = await Api.post("", {
    query: `
    mutation CopiarOrcamento($orcamentoId: UUID) {
    copiarOrcamento(orcamentoId: $orcamentoId) {
      id
      convenio {
        id
        descricao
      }
      dataVencimento
      dataHoraLancamento
      desconto {
        valorDesconto
        tipoDesconto
      }
      descontoAprovado
      faturado
      nome
      observacao
      orcamentoProcedimentos {
        id
        procedimento {
          id
          nome
          descricao
          convenioProcedimentos {
            valor
            convenio {
              id
            }
          }
        }
        procedimentoOdontologico {
          id
          dente {
            id
          }
          procedimento {
            id
            nome
            descricao
            convenioProcedimentos {
              convenio {
                id
              }
              valor
            }
          }
        }
        valorUnitario
        quantidade
      }
      profissionalSaude {
        id
        nome
      }
      tipo
      status
      tipoPagamento
      valor
      valorEntrada
      faturado
      numero  
    }
  }`,
    variables: { orcamentoId },
  });
  if (!response.data.errors) {
    return response.data.data.copiarOrcamento;
  } else {
    throw response.data.errors[0];
  }
};

export const addHistoricoOrcamento = async (variables) => {
  const response = await Api.post("", {
    query: `
      mutation ($orcamentoId: UUID, $informacao: String) {
        addHistoricoOrcamento(orcamentoId: $orcamentoId, informacao: $informacao) {
          informacao
        }
      }
    `,
    variables: variables,
  });
  if (!response.data.errors) {
    return response.data.data.addHistoricoOrcamento;
  } else {
    throw response.data.errors[0];
  }
};

export const reprovarOrcamentoOdontologico = async (orcamentoId) => {
  const response = await Api.post("", {
    query: `
      mutation ($orcamentoId: UUID) {
        reprovarOrcamentoOdontologico(orcamentoId: $orcamentoId) {
          id
        }
      }
    `,
    variables: { orcamentoId },
  });
  if (!response.data.errors) {
    return response.data.data.reprovarOrcamentoOdontologico;
  } else {
    throw response.data.errors[0];
  }
};

export const getStatusOrcamentoPadraoOdontologico = async () => {
  const response = await Api.post("", {
    query: `
     query ($tipo: TipoStatusOrcamentoOdontologico, $ativo: Boolean, $pageableDTO: PageableDTOInput) {
      findAllStatusOrcamentoOdontologico(tipo: $tipo, ativo: $ativo, pageableDTO: $pageableDTO) {
        content {
          id
          nome
          tipo
        }
      }
    }
    `,
    variables: {
      tipo: "PENDENTE_APROVACAO",
      ativo: true,
      pageableDTO: {
        pageNumber: 0,
        pageSize: 10,
        sortDir: "ASC",
        sortField: "nome",
      },
    },
  });
  if (!response.data.errors) {
    return response.data.data.findAllStatusOrcamentoOdontologico.content[0];
  } else {
    throw response.data.errors[0];
  }
};

export const relatorioAllOrcamentoCsv = async (variables) => {
  const response = await Api.post("", {
    query: `
      query ($profissionalSaudeId: Long, $dataAgendamento: LocalDate, $tipo: OrcamentoTipo, $search: String, $sujeitoAtencaoId: UUID, $dataHoraLancamentoInicio: LocalDateTime, $dataHoraLancamentoFim: LocalDateTime, $statusOrcamentoOdontologicoId: UUID, $numero: Long) {
      relatorioAllOrcamentoCsv(profissionalSaudeId: $profissionalSaudeId, dataAgendamento: $dataAgendamento, tipo: $tipo, search: $search, sujeitoAtencaoId: $sujeitoAtencaoId, dataHoraLancamentoInicio: $dataHoraLancamentoInicio, dataHoraLancamentoFim: $dataHoraLancamentoFim, statusOrcamentoOdontologicoId: $statusOrcamentoOdontologicoId, numero: $numero)
    }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.relatorioAllOrcamentoCsv;
};

export const findAllOrcamentoList = async (variables) => {
  const response = await Api.post("", {
    query: `
      query(
        $profissionalSaudeId: Long
        $sujeitoAtencaoId: UUID
        $search: String
        $procedimentoId: UUID
        $dataHoraLancamentoInicio: LocalDateTime
        $dataHoraLancamentoFim: LocalDateTime
        $status: [OrcamentoStatus]
        $tipo: OrcamentoTipo
      ) {
        findAllOrcamentoNonPageable(
          profissionalSaudeId: $profissionalSaudeId
          sujeitoAtencaoId: $sujeitoAtencaoId
          search: $search
          procedimentoId: $procedimentoId
          dataHoraLancamentoInicio: $dataHoraLancamentoInicio
          dataHoraLancamentoFim: $dataHoraLancamentoFim
          status: $status
          tipo: $tipo
        ) {
          id
          dataHoraLancamento
          nome
          numero
          sujeitoAtencao {
            nome
          }
          profissionalSaude {
            id
            nome
          }
          valorTotalLiquido
          status
          tipo
          faturado
        }
      }    
    `,
    variables: variables,
  });

  if (!response.data.errors) {
    return response.data.data.findAllOrcamentoNonPageable;
  } else {
    throw response.data.errors[0];
  }
};