import React from "react";
import { normalizeProps } from "./utils/normalizeProps";

const AtencaoIcon = (props) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...normalizeProps(props)}
        >
            <path d="M25.4951 27H6.5049C4.9563 27 3.99522 25.3158 4.78304 23.9825L14.2781 7.91391C15.0523 6.60385 16.9477 6.60385 17.7219 7.9139L27.217 23.9825C28.0048 25.3158 27.0437 27 25.4951 27Z" fill="#E3922B" stroke="black" />
            <path d="M15 12V19C15 19.5523 15.4477 20 16 20C16.5523 20 17 19.5523 17 19V12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12Z" fill="black" stroke="black" />
            <circle cx="16" cy="23" r="2" fill="black" />
        </svg>
    );
};

export default AtencaoIcon;
