import React, { useState, useEffect } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles/index";
import { observer } from "mobx-react-lite";
import { Print as PrintIcon } from "@material-ui/icons";
import { findPacientesDuplicados, findPacienteByDocumento, relatorioPacientesDuplicadosCsv } from "../../../../services/RelatorioService";
import Table from "../../../../components/Table/Table";
import Scroll from "../../../../components/InfiniteScroll/Scroll";
import HeaderRelatorio from "../../HeaderRelatorio";
import Notification from "../../../../components/Notification";
import { inject } from "mobx-react";
import ImpressaoHtml from "../../../../components/Impressao/ImpressaoHtml";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { Button } from "../../../../components/ui/Buttons";
import RelatorioPacientesDuplicadosPdfDocument from "../../../../template/pdf/relatorio/PacientesDuplicados";
import MensagemListaVazia from "../../../../components/Mensagem/MensagemListaVazia";
import ArrowDownloadIcon from "../../../../components/Icon/ArrowDownload";
import styles from "./RelatorioPacientesDuplicadosStyles";
import { columns } from "../constants";
import ModalPacientesDuplicados from "./Modal/ModalPacientesDuplicados";
import ModalUnificacao from "../../../SujeitoAtencao/Manutencao/Unificacao/ModalUnificacao";
import AlertaUnificacao from "../../../SujeitoAtencao/Manutencao/Unificacao/AlertaUnificacao";
import { alertasUnificacao } from "../../../SujeitoAtencao/Manutencao/Unificacao/AlertasUnificacao";
import { base64Convert } from "../../../../utils/base64ToCsv";

const RelatorioPacientesDuplicados = observer((props) => {
  const { classes } = props;

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pacientesDuplicados, setPacientesDuplicados] = useState([]);
  const [lastScroll, setLastScroll] = useState(false);
  const [search, setSearch] = useState("");
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
  });
  const [printing, setPrinting] = useState(false);
  const [
    openModalVisualizarPaciente,
    setOpenModalVisualizarPaciente,
  ] = useState(false);
  const [pacientes, setPacientes] = useState([]);
  const [destino, setDestino] = useState(null);
  const [openModalUnificar, setOpenModalUnificar] = useState(false);
  const [openModalAlertaUnificacao, setOpenModalAlertaUnificacao] = useState(
    false
  );
  const [motivoUnificacao, setMotivoUnificacao] = useState("");
  const [dadosAlertaUnificacao, setDadosAlertaUnificacao] = useState({
    title: "",
    text: "",
    textStrong: "",
    icon: null,
    color: "",
    isButtons: true,
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const disabled = pacientesDuplicados.length === 0 || loadingButton;

  useEffect(() => {
    loadPacientesDuplicados({ isClearable: true });
  }, []);

  const loadPacientesDuplicados = async (options) => {
    try {
      setLoading(true);

      const response = await findPacientesDuplicados({
        search: options?.clearSearch ? "" : search,
        pageable: {
          pageSize: 30,
          pageNumber: options?.isClearable ? 0 : pageNumber,
          sortField: "total",
          sortDir: "ASC",
        },
      });
      const { content, last } = response;
      const listaPacientesDuplicados = options?.isClearable
        ? [...content]
        : [...pacientesDuplicados, ...content];
      setLastScroll(last);
      setPacientesDuplicados(listaPacientesDuplicados);
      setPageNumber(options?.isClearable ? 1 : pageNumber + 1);
    } catch (error) {
      const notification = {
        isOpen: true,
        message: "Erro ao carregar a lista de duplicados",
      };
      showAlertMessage(notification);
    } finally {
      setLoading(false);
    }
  };

  const onClickPesquisar = () => {
    loadPacientesDuplicados({ isClearable: true });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleVisualizar = async(pacienteDuplicado) => {
    try {
      const response = await findPacienteByDocumento({
        documento: pacienteDuplicado.documento,
        pageable : { sortDir: "ASC", sortField: "dataCriacao" },
      });

      const pacientes = response.map((item, index) => ({
        ...item,
        principal: index === 0,
      }));

      setDestino(pacientes[0]);
      setPacientes(pacientes);
      setOpenModalVisualizarPaciente(true);
     
    } catch (error) {
      console.error(error);
    }
  };

  const handleUnificar = () => {
    setDadosAlertaUnificacao(alertasUnificacao.preAviso);
    setOpenModalAlertaUnificacao(true);
  };

  const showAlertMessage = (notification) => {
    setNotification(notification);
    const timeoutId = setTimeout(() => {
      closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  const closeAlertMessage = () => {
    setNotification({
      isOpen: false,
      message: "",
    });
  };

  const printRelatorio = () => {
    setPrinting(true);
  };

  const handleDownloadCSV = async () => {
    try {
      setLoadingButton(true);
      const base64 = await relatorioPacientesDuplicadosCsv({
        search, 
        pageable: { 
          sortDir: "ASC",
          sortField: "total",
        }
      });
      console.log('base64', base64)
      if (base64 === "error") return;

      const blob = base64Convert(base64);
      const url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "relatorioPacientesDuplicados.csv");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };

  const handleCloseModalVisualizarPaciente = () => {
    setOpenModalVisualizarPaciente(false);
  };

  const handleCloseUnificar = () => {
    setOpenModalUnificar(false);
  };

  const handleCancelarUnificacao = () => {
    setOpenModalAlertaUnificacao(false);
  };

  const handleContinuarUnificao = () => {
    if (dadosAlertaUnificacao.nome === "preAviso") {
      setDadosAlertaUnificacao(alertasUnificacao.motivo);
    } else {
      if (!motivoUnificacao) {
        const notification = {
          isOpen: true,
          message: "Informe o motivo",
        };
        showAlertMessage(notification);
        return;
      }

      setOpenModalAlertaUnificacao(false);
      setOpenModalUnificar(true);
    }
  };

  const handleInformacoesAlertaUnificacao = (informacoes) => {
    setDadosAlertaUnificacao({
      title: informacoes.title,
      text: informacoes.text,
      textStrong: informacoes.textStrong,
      icon: informacoes.icon,
      color: informacoes.color,
      isButtons: informacoes.isButtons,
    });

    setOpenModalAlertaUnificacao(true);

    if (informacoes.nome === "sucess") {
      setTimeout(() => {
        setOpenModalAlertaUnificacao(false);
      }, 1000);
    }
  };

  const changeCheckbox = (e) => {
    const pacientesCadastroPrincipal = pacientes.map(item => {
      const principal = item.id === e.id

      return {
        ...item,
        principal,
      }
    });

    const destino = pacientesCadastroPrincipal.find(item => item.principal);

    setPacientes(pacientesCadastroPrincipal);
    setDestino(destino);
  };
  
  const handleUnificarPacientesDuplicados = async () => {
    loadPacientesDuplicados({ isClearable: true });
    handleCloseModalVisualizarPaciente();
  };

  return (
    <div className={classes.content}>
      <PageTitle title="Relatórios - Pacientes Duplicados" />
      <HeaderRelatorio
        search={onClickPesquisar}
        value={search}
        title="Pacientes duplicados"
        onChange={handleSearchChange}
        hiddenButtons
        hiddenFilter
        hiddenTotal
      />
      <div className={classes.tableContainerRelatorio}>
        <Scroll
          loadMore={loadPacientesDuplicados}
          hasMore={!lastScroll}
          pageStart={0}
          initialLoad={false}
        >
          <Table
            className={classes.table}
            dados={pacientesDuplicados}
            columns={columns({ handleVisualizar })}
            clickable={false}
          />
          {pacientesDuplicados.length === 0 && !loading && (
            <MensagemListaVazia />
          )}
          {loading && (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ marginTop: 20 }}
            >
              <CircularProgress size={30} />
            </Grid>
          )}
        </Scroll>
      </div>
      <div className={classes.buttonsDownloadPrint}>
        <Button
          shape="circle"
          bgColor="#F9BE73"
          onClick={handleDownloadCSV}
          disabled={disabled}
        >
          <ArrowDownloadIcon />
        </Button>
        <Button
          bgColor="#F9BE73"
          shape="circle"
          onClick={printRelatorio}
          disabled={disabled}
        >
          <PrintIcon />
        </Button>
      </div>

      {printing && (
        <ImpressaoHtml
          isPrintMustache={printing}
          handlePrintMustache={() => setPrinting(false)}
          htmlStringComponent={
            <RelatorioPacientesDuplicadosPdfDocument
              pacientesDuplicados={pacientesDuplicados}
              pesquisadoPor={search}
            />
          }
        />
      )}

      {openModalVisualizarPaciente && (
        <ModalPacientesDuplicados
          open={openModalVisualizarPaciente}
          handleClose={handleCloseModalVisualizarPaciente}
          pacientes={pacientes}
          handleUnificar={handleUnificar}
          changeCheckbox={changeCheckbox}
        />
      )}

      {openModalUnificar && (
        <ModalUnificacao
          open={openModalUnificar}
          handleClose={handleCloseUnificar}
          handleInformacoesAlertaUnificacao={handleInformacoesAlertaUnificacao}
          motivo={motivoUnificacao}
          destinoProps={destino}
          handleUnificarPacientesDuplicados={handleUnificarPacientesDuplicados}
        />
      )}

      {openModalAlertaUnificacao && (
        <AlertaUnificacao
          open={openModalAlertaUnificacao}
          title={dadosAlertaUnificacao.title}
          text={dadosAlertaUnificacao.text}
          textStrong={dadosAlertaUnificacao.textStrong}
          icon={dadosAlertaUnificacao.icon}
          color={dadosAlertaUnificacao.color}
          isButtons={dadosAlertaUnificacao.isButtons}
          handleCancelar={handleCancelarUnificacao}
          handleContinuar={handleContinuarUnificao}
          onChangeMotivo={(e) => setMotivoUnificacao(e.target.value)}
          valueInput={motivoUnificacao}
        />
      )}

      <Notification
        close={closeAlertMessage}
        reset={closeAlertMessage}
        isOpen={notification.isOpen}
        variant={"error"}
        message={notification.message}
      />
    </div>
  );
});

const RelatorioPacientesDuplicadosWithStyles = withStyles(styles)(
  RelatorioPacientesDuplicados
);
export default inject("relatorioStore")(RelatorioPacientesDuplicadosWithStyles);
