import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const AssinaturaPresencaIcon = props => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <rect width="24" height="24" rx="12" fill="#707C97" />
      <path d="M2 16.8462L2.48075 17.1235C3.14879 17.5089 3.99567 17.1044 4.11572 16.3426L4.3668 14.7492C4.47955 14.0336 5.47433 13.9452 5.71159 14.6296V14.6296C5.72605 14.6713 5.73651 14.7143 5.74282 14.758L6.04474 16.8482C6.14157 17.5186 6.5711 18.0944 7.18608 18.3782L7.50464 18.5252C8.14279 18.8198 8.88872 18.7577 9.46943 18.3618L10 18" stroke="#FFFDFD" stroke-width="1.2" />
      <path d="M15.6955 4.5715L18.6757 6.36217M15.6955 4.5715L10.5451 13.1432M15.6955 4.5715L16.5181 3.20248C16.6464 2.98885 16.8792 2.86009 17.1284 2.86483V2.86483C18.1204 2.88372 19.0275 3.42877 19.5099 4.2958V4.2958C19.6311 4.51359 19.6266 4.77952 19.4983 4.99315L18.6757 6.36217M18.6757 6.36217L13.5253 14.9338M13.5253 14.9338L10.4901 16.61L10.5451 13.1432M13.5253 14.9338L10.5451 13.1432" stroke="#FFFDFD" stroke-width="1.2" />
    </svg>
  );
};

export default AssinaturaPresencaIcon;