import React from 'react';
import { normalizeProps } from './utils/normalizeProps';

const CanetaEditIcon = props => {
  return (
    <svg 
    width="26" 
    height="25" 
    viewBox="0 0 26 25" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...normalizeProps(props)}
    >
        <path d="M24.0369 1.27558C22.3361 -0.425198 19.5786 -0.425192 17.8778 1.27559L9.71591 9.43747C9.6105 9.54288 9.53108 9.67138 9.48394 9.8128L7.08338 17.0145C6.96836 17.3595 7.05817 17.7399 7.31535 17.9971C7.57253 18.2543 7.95293 18.3441 8.29798 18.2291L15.4997 15.8285C15.6411 15.7814 15.7696 15.7019 15.875 15.5965L24.0369 7.43466C25.7377 5.73388 25.7377 2.97637 24.0369 1.27558ZM21.4122 12.7753C21.4289 13.0071 21.4375 13.2412 21.4375 13.4773C21.4375 18.7805 17.1384 23.0796 11.8352 23.0796C6.53202 23.0796 2.23295 18.7805 2.23295 13.4773C2.23295 8.17418 6.53202 3.87511 11.8352 3.87511C12.0712 3.87511 12.3053 3.88363 12.5371 3.90038L14.233 2.20449C13.4595 2.04079 12.6574 1.95467 11.8352 1.95467C5.47139 1.95467 0.3125 7.11354 0.3125 13.4773C0.3125 19.8411 5.47139 25 11.8352 25C18.199 25 23.3579 19.8411 23.3579 13.4773C23.3579 12.6551 23.2718 11.8529 23.108 11.0794L21.4122 12.7753Z" fill="#505050"/>
    </svg>
  );
};

export default CanetaEditIcon;