const styles = {
  content: {
    display: "grid",
    gridTemplateRows: "80px 1fr",
    position: "relative",
    overflow: "hidden",
    background: "#f5f5f5",

    "& thead th": {
      textAlign: "left",
      position: "sticky",
      top: 0,

      "&:first-child": {
        padding: "0 0 0 24px",
      },

      "&:last-child": {
        paddingRight: 0,
      },
    },
    "& tbody td": {
      padding: "0 0 0 24px",
      maxWidth: "120px",
      overflowWrap: "break-word",

      "& .status": {
        width: 16,
        height: 16,
        borderRadius: 100,
        display: "inline-block",
      },
      "& .true": {
        backgroundColor: "#00C1BF",
      },
      "& .false": {
        backgroundColor: "#FB7676",
      },
    },
  },
  buttonsDownloadPrint: {
    display: "flex",
    position: "absolute",
    bottom: "20px",
    right: "14px",
    width: "88px",
    justifyContent: "space-between",
  },
  tableContainerRelatorio: {
    overflow: "auto",
    margin: "16px",
    borderRadius: "16px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    background: "#fff",
    "&> div": {
      height: "100%",
      "&> div": {
        height: "100%",
      },
    },
  },
};

export default styles;
