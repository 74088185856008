import React, { useRef } from "react";
import PopperCustomMenu from "../../../../../components/Popper/PopperCustomMenu";
import PencilIcon from "../../../../../components/Icon/Pencil";
import TrashIcon from "../../../../../components/Icon/Trash";

const menuOpcoesPopper = (options) => {
  const optionsMenu = [
    {
      ativo: !options.isEdit,
      label: "Editar conduta",
      onClick: (e) => options?.functions?.handleEdit(e),
      icon: <PencilIcon size="24" color="#505050" />,
    },
    {
      ativo: options.isEdit,
      label: "Editar prescrição",
      onClick: (e) => options?.functions?.handleEditPrescricao(e),
      icon: <PencilIcon size="24" color="#505050" />,
    },
    {
      ativo: options.isEdit,
      label: "Excluir Prescrição",
      onClick: (e) => options?.functions?.handleRemovePrescricao(e),
      icon: <TrashIcon size="24" color="#505050" />,
    },
  ];
  return optionsMenu;
};


export const PopperMenu = ({ row, functions, isMedico }) => {

  const ref = useRef(null);
  const handleCloseWithAction = (fn) => {
    return () => {
      ref?.current && ref.current.closePopper()
      fn(row)
    }
  }


  return (
    <PopperCustomMenu
      menuOpcoes={menuOpcoesPopper({
        functions: {
          handleEdit: handleCloseWithAction(functions?.handleEdit),
          handleRemovePrescricao: handleCloseWithAction(functions?.handleRemovePrescricao),
          handleEditPrescricao: handleCloseWithAction(functions?.handleEditPrescricao)
        },
        isEdit: isMedico,
      })}
      ref={ref}
      colorButton={{
        background: 'transparent',
      }}
    />
  );
};

