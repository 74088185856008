import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { TextFieldSearchStyled } from "../../styles";
import { Button } from "../../../../../components/ui/Buttons";
import Table from "../../../../../components/Table/Table";
import { findAllProfissionalSaudeComAgenda } from "../../../../../services/ProfissionalSaudeService";
import { columnsEquipeMedica, equipeMedicaDefault } from "../../constants";
import { ReactSelect } from "../../../../../components/Select/SelectSearch";
import { findAllTuss35TerminologiaGrauParticipacao } from "../../../../../services/InternacaoService";

const pageableDto = {
  pageNumber: 0,
  pageSize: 30,
  sortDir: "ASC",
  sortField: "termo",
};

const EquipeMedica = ({ classes, handleField, dados, internacao }) => {
  const unidadeLogada = JSON.parse(localStorage["_immortal|unidadeLogada"]);

  const [novoProfissional, setNovoProfissional] = useState(equipeMedicaDefault);
  const [grausDeParticipacao, setGrausDeParticipacao] = useState([]);
  const [search, setSearch] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [indexProfissionalSelected, setIndexProfissionalSelected] = useState(null);

  const possuiProcedimentosNovoProfissional = novoProfissional?.procedimentos?.length > 0;

  const isDisabled = !possuiProcedimentosNovoProfissional ||
  !novoProfissional?.profissionalSaude ||
  !novoProfissional?.tuss35TerminologiaGrauParticipacao;

  const procedimentosInternacao = internacao.procedimentos.map(item => item.procedimento)

  useEffect(() => {
    fildAllGrausDePariticipacao();
  }, []);

  const loadAllProfissional = async (search, loadedOptions, { page }) => {
    const variables = {
      search,
      pageableDTO: {
        pageNumber: page,
        pageSize: 20,
        sortDir: "ASC",
        sortField: "nome",
      },
      ativo: true,
      unidadeId: unidadeLogada.id,
      externos: false,
    };

    const response = await findAllProfissionalSaudeComAgenda(variables);
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  const fildAllGrausDePariticipacao = async () => {
    try {
      const variables = {
        pageableDto: pageableDto,
        search: search,
      };
      const response = await findAllTuss35TerminologiaGrauParticipacao(
        variables
      );
      setGrausDeParticipacao(response.content);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddEquipe = (value, field) => {
    let dado = value;

    if(field === "procedimentos") {
        dado = [value]
    };

    setNovoProfissional((prevState) => {
      return {
        ...prevState,
        [field]: dado,
      };
    });
  };

  const handleAdicionar = () => {
    const profissionais = internacao?.equipeMedica || [];
    handleField("equipeMedica", [...profissionais, novoProfissional]);
    setNovoProfissional(equipeMedicaDefault);
  };

  const handleDelete = (_, index) => {
    const profissionais = internacao?.equipeMedica;
    const profissionaisSelecionados = profissionais.filter(
      (_, indexProfissional) => indexProfissional !== index
    );
    handleField("equipeMedica", profissionaisSelecionados);
  };

  const handleEdit = (data, index) => {
    setIndexProfissionalSelected(index);
    setNovoProfissional(data);
    setIsEdit(true);
  };

  const handleCancelarProfissionalEdit = () => {
    setNovoProfissional(equipeMedicaDefault);
    setIndexProfissionalSelected(null);
    setIsEdit(false);
  };

  const handleSalvarProfissionalEdit = () => {
    const dadosEquipeMedica = internacao.equipeMedica;

    dadosEquipeMedica[indexProfissionalSelected] = novoProfissional;

    handleField("equipeMedica", dadosEquipeMedica);
    setIsEdit(false);
    setNovoProfissional(equipeMedicaDefault);
    setIndexProfissionalSelected(null);
  };

  return (
    <div className={classes.content}>
      <div className={classes.wrapper}>
        <div className={classes.contentTextField}>
          <div className={classes.contentInput}>
            <label className={classes.label}>Selecione o profissional: </label>
            <TextFieldSearchStyled
              placeholder="Selecione"
              loadOptions={loadAllProfissional}
              getOptionLabel={(option) => option.nome}
              getOptionValue={(option) => option.id}
              value={novoProfissional?.profissionalSaude}
              onChange={(e) => handleAddEquipe(e, "profissionalSaude")}
              withPaginate
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
            />
          </div>

          <div className={classes.contentInput}>
            <label className={classes.label}>Grau de participação: </label>
            <ReactSelect
              placeholder={"Selecione"}
              value={novoProfissional?.tuss35TerminologiaGrauParticipacao}
              onChange={(e) => handleAddEquipe(e, "tuss35TerminologiaGrauParticipacao")}
              options={grausDeParticipacao}
              getOptionLabel={(option) => option.termo}
              getOptionValue={(option) => option.codigoTermo}
            />
          </div>

          <div className={classes.contentInput}>
            <label className={classes.label}>Procedimento: </label>
            <ReactSelect
              placeholder={"Selecione"}
              value={novoProfissional?.procedimentos[0] || null}
              onChange={(e) => handleAddEquipe(e, "procedimentos")}
              options={procedimentosInternacao}
              getOptionLabel={(option) => option.nome}
              getOptionValue={(option) => option.id}
            />
          </div>
        </div>
        {isEdit ? (
          <div className={classes.contentButton}>
            <Button
              bgColor="#FB7676"
              style={{ height: 40 }}
              padding={0}
              onClick={handleCancelarProfissionalEdit}
              disabled={isDisabled}
            >
              Cancelar
            </Button>
            <Button
              bgColor={"#00B0AE"}
              style={{ height: 40 }}
              padding={0}
              onClick={handleSalvarProfissionalEdit}
              disabled={isDisabled}
            >
              Salvar
            </Button>
          </div>
        ) : (
          <Button
            bgColor={"#707C97"}
            style={{ height: 40 }}
            padding={0}
            onClick={handleAdicionar}
            disabled={isDisabled}
          >
            Adicionar
          </Button>
        )}
      </div>
      <div className={classes.contentTable}>
        <Table
          columns={columnsEquipeMedica({ handleDelete, handleEdit })}
          dados={internacao.equipeMedica}
        />
      </div>
    </div>
  );
};

const styles = {
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginTop: "16px",
    height: "calc(100% - 132px)",
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
  },
  contentTextField: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  },
  label: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#868686",
  },
  contentInput: {
    display: "flex",
    flexDirection: "column",
    width: "230px",
  },
  contentButton: {
    display: 'flex',
    gap: '8px',
    marginBottom: '16px',
  },
  contentTable: {
    overflow: 'auto',
  }
};

export default withStyles(styles)(EquipeMedica);
