const AUTHORITIES = {
  "ROLE_ORCAMENTO_DESCONTO": "ROLE_ORCAMENTO_DESCONTO",
  "ROLE_ORCAMENTO_CREATE": "ROLE_ORCAMENTO_CREATE",
  "ROLE_ORCAMENTO_READ": "ROLE_ORCAMENTO_READ",
  "ROLE_ORCAMENTO_UPDATE": "ROLE_ORCAMENTO_UPDATE",
  "ROLE_STATUS_ORCAMENTO_ODONTOLOGICO_CREATE": "ROLE_STATUS_ORCAMENTO_ODONTOLOGICO_CREATE",
  "ROLE_STATUS_ORCAMENTO_ODONTOLOGICO_READ": "ROLE_STATUS_ORCAMENTO_ODONTOLOGICO_READ",
  "ROLE_STATUS_ORCAMENTO_ODONTOLOGICO_UPDATE": "ROLE_STATUS_ORCAMENTO_ODONTOLOGICO_UPDATE",
  "ROLE_GERENCIADOR_ORCAMENTO_CREATE": "ROLE_GERENCIADOR_ORCAMENTO_CREATE",
  "ROLE_GERENCIADOR_ORCAMENTO_READ": "ROLE_GERENCIADOR_ORCAMENTO_READ",
  "ROLE_GERENCIADOR_ORCAMENTO_UPDATE": "ROLE_GERENCIADOR_ORCAMENTO_UPDATE",
  "ROLE_PROFISSIONAL_SAUDE_CREATE": "ROLE_PROFISSIONAL_SAUDE_CREATE",
  "ROLE_AGENDAMENTO_CREATE": "ROLE_AGENDAMENTO_CREATE",
  "ROLE_SUJEITO_ATENCAO_CREATE": "ROLE_SUJEITO_ATENCAO_CREATE",
  "ROLE_CONFIGURACAO_HORARIO_ATENDIMENTO_CREATE": "ROLE_CONFIGURACAO_HORARIO_ATENDIMENTO_CREATE",
  "ROLE_CONVENIO_CREATE": "ROLE_CONVENIO_CREATE",
  "ROLE_CATEGORIA_FINANCEIRA_CREATE": "ROLE_CATEGORIA_FINANCEIRA_CREATE",
  "ROLE_CONTA_BANCARIA_CREATE": "ROLE_CONTA_BANCARIA_CREATE",
  "ROLE_CENTRO_CUSTO_CREATE": "ROLE_CENTRO_CUSTO_CREATE",
  "ROLE_TITULO_CREATE": "ROLE_TITULO_CREATE",
  "ROLE_TITULO_PARCELA_CREATE": "ROLE_TITULO_PARCELA_CREATE",
  "ROLE_ENTRADA_PRONTUARIO_CREATE": "ROLE_ENTRADA_PRONTUARIO_CREATE",
  "ROLE_CADASTRO_MEDICAMENTO_CREATE": "ROLE_CADASTRO_MEDICAMENTO_CREATE",
  "ROLE_GUIA_SERVICO_CREATE": "ROLE_GUIA_SERVICO_CREATE",
  "ROLE_CONFIGURACAO_IMPRESSAO_CREATE": "ROLE_CONFIGURACAO_IMPRESSAO_CREATE",
  "ROLE_SALA_CREATE": "ROLE_SALA_CREATE",
  "ROLE_PROCEDIMENTO_CREATE": "ROLE_PROCEDIMENTO_CREATE",
  "ROLE_PROFISSIONAL_SAUDE_READ": "ROLE_PROFISSIONAL_SAUDE_READ",
  "ROLE_AGENDAMENTO_READ": "ROLE_AGENDAMENTO_READ",
  "ROLE_SUJEITO_ATENCAO_READ": "ROLE_SUJEITO_ATENCAO_READ",
  "ROLE_CONFIGURACAO_HORARIO_ATENDIMENTO_READ": "ROLE_CONFIGURACAO_HORARIO_ATENDIMENTO_READ",
  "ROLE_CONVENIO_READ": "ROLE_CONVENIO_READ",
  "ROLE_CATEGORIA_FINANCEIRA_READ": "ROLE_CATEGORIA_FINANCEIRA_READ",
  "ROLE_CONTA_BANCARIA_READ": "ROLE_CONTA_BANCARIA_READ",
  "ROLE_CENTRO_CUSTO_READ": "ROLE_CENTRO_CUSTO_READ",
  "ROLE_TITULO_READ": "ROLE_TITULO_READ",
  "ROLE_TITULO_PARCELA_READ": "ROLE_TITULO_PARCELA_READ",
  "ROLE_ENTRADA_PRONTUARIO_READ": "ROLE_ENTRADA_PRONTUARIO_READ",
  "ROLE_CADASTRO_MEDICAMENTO_READ": "ROLE_CADASTRO_MEDICAMENTO_READ",
  "ROLE_GUIA_SERVICO_READ": "ROLE_GUIA_SERVICO_READ",
  "ROLE_CONFIGURACAO_IMPRESSAO_READ": "ROLE_CONFIGURACAO_IMPRESSAO_READ",
  "ROLE_SALA_READ": "ROLE_SALA_READ",
  "ROLE_PROCEDIMENTO_READ": "ROLE_PROCEDIMENTO_READ",
  "ROLE_PROFISSIONAL_SAUDE_UPDATE": "ROLE_PROFISSIONAL_SAUDE_UPDATE",
  "ROLE_AGENDAMENTO_UPDATE": "ROLE_AGENDAMENTO_UPDATE",
  "ROLE_SUJEITO_ATENCAO_UPDATE": "ROLE_SUJEITO_ATENCAO_UPDATE",
  "ROLE_CONFIGURACAO_HORARIO_ATENDIMENTO_UPDATE": "ROLE_CONFIGURACAO_HORARIO_ATENDIMENTO_UPDATE",
  "ROLE_CONVENIO_UPDATE": "ROLE_CONVENIO_UPDATE",
  "ROLE_CATEGORIA_FINANCEIRA_UPDATE": "ROLE_CATEGORIA_FINANCEIRA_UPDATE",
  "ROLE_CONTA_BANCARIA_UPDATE": "ROLE_CONTA_BANCARIA_UPDATE",
  "ROLE_CENTRO_CUSTO_UPDATE": "ROLE_CENTRO_CUSTO_UPDATE",
  "ROLE_TITULO_UPDATE": "ROLE_TITULO_UPDATE",
  "ROLE_TITULO_PARCELA_UPDATE": "ROLE_TITULO_PARCELA_UPDATE",
  "ROLE_ENTRADA_PRONTUARIO_UPDATE": "ROLE_ENTRADA_PRONTUARIO_UPDATE",
  "ROLE_CADASTRO_MEDICAMENTO_UPDATE": "ROLE_CADASTRO_MEDICAMENTO_UPDATE",
  "ROLE_CONFIGURACAO_IMPRESSAO_UPDATE": "ROLE_CONFIGURACAO_IMPRESSAO_UPDATE",
  "ROLE_UNIDADE_UPDATE": "ROLE_UNIDADE_UPDATE",
  "ROLE_SALA_UPDATE": "ROLE_SALA_UPDATE",
  "ROLE_PROCEDIMENTO_UPDATE": "ROLE_PROCEDIMENTO_UPDATE",
  "ROLE_AGENDAMENTO_DELETE": "ROLE_AGENDAMENTO_DELETE",
  "ROLE_CONVENIO_DELETE": "ROLE_CONVENIO_DELETE",
  "ROLE_AGENDAMENTO_READ_OUTROS_PROFISSIONAIS": "ROLE_AGENDAMENTO_READ_OUTROS_PROFISSIONAIS",
  "ROLE_CONFIGURACAO_HORARIO_ATENDIMENTO_READ_OUTROS_PROFISSIONAIS": "ROLE_CONFIGURACAO_HORARIO_ATENDIMENTO_READ_OUTROS_PROFISSIONAIS",
  "ROLE_ENTRADA_PRONTUARIO_OUTROS_PROFISSIONAIS_SAUDE_READ": "ROLE_ENTRADA_PRONTUARIO_OUTROS_PROFISSIONAIS_SAUDE_READ",
  "ROLE_TITULO_READ_OUTROS_PROFISSIONAIS": "ROLE_TITULO_READ_OUTROS_PROFISSIONAIS",
  "ROLE_TITULO_PARCELA_READ_OUTROS_PROFISSIONAIS": "ROLE_TITULO_PARCELA_READ_OUTROS_PROFISSIONAIS",
  "ROLE_ATENDIMENTO": "ROLE_ATENDIMENTO",
  "ROLE_SUJEITO_ATENCAO_UNIFICAR": "ROLE_SUJEITO_ATENCAO_UNIFICAR",
  "ROLE_AGENDAMENTO_RELATORIO": "ROLE_AGENDAMENTO_RELATORIO",
  "ROLE_SUJEITO_ATENCAO_RELATORIO": "ROLE_SUJEITO_ATENCAO_RELATORIO",
  "ROLE_PROFISSIONAL_SAUDE_UPDATE_OUTROS": "ROLE_PROFISSIONAL_SAUDE_UPDATE_OUTROS",
  "ROLE_DOCUMENTO_CREATE": "ROLE_DOCUMENTO_CREATE",
  "ROLE_DOCUMENTO_READ": "ROLE_DOCUMENTO_READ",
  "ROLE_DOCUMENTO_UPDATE": "ROLE_DOCUMENTO_UPDATE",
  "ROLE_DOCUMENTO_MODELO_CREATE": "ROLE_DOCUMENTO_MODELO_CREATE",
  "ROLE_DOCUMENTO_MODELO_READ": "ROLE_DOCUMENTO_MODELO_READ",
  "ROLE_DOCUMENTO_MODELO_UPDATE": "ROLE_DOCUMENTO_MODELO_UPDATE",
  "ROLE_CHAT_CREATE": "ROLE_CHAT_CREATE",
  "ROLE_CHAT_READ": "ROLE_CHAT_READ",
  "ROLE_CHAT_UPDATE": "ROLE_CHAT_UPDATE",
  "ROLE_MEDICAMENTO_CREATE": "ROLE_MEDICAMENTO_CREATE",
  "ROLE_MEDICAMENTO_READ": "ROLE_MEDICAMENTO_READ",
  "ROLE_MEDICAMENTO_UPDATE": "ROLE_MEDICAMENTO_UPDATE",
  "ROLE_PRODUTO_CREATE": "ROLE_PRODUTO_CREATE",
  "ROLE_PRODUTO_READ": "ROLE_PRODUTO_READ",
  "ROLE_PRODUTO_UPDATE": "ROLE_PRODUTO_UPDATE",
  "ROLE_ANAMNESE_CREATE": "ROLE_ANAMNESE_CREATE",
  "ROLE_ANAMNESE_READ": "ROLE_ANAMNESE_READ",
  "ROLE_ANAMNESE_UPDATE": "ROLE_ANAMNESE_UPDATE",
  "ROLE_ANAMNESE_MODELO_CREATE": "ROLE_ANAMNESE_MODELO_CREATE",
  "ROLE_ANAMNESE_MODELO_READ": "ROLE_ANAMNESE_MODELO_READ",
  "ROLE_ANAMNESE_MODELO_UPDATE": "ROLE_ANAMNESE_MODELO_UPDATE",
  "ROLE_AGENDAMENTO_TIPO_CREATE": "ROLE_AGENDAMENTO_TIPO_CREATE",
  "ROLE_AGENDAMENTO_TIPO_READ": "ROLE_AGENDAMENTO_TIPO_READ",
  "ROLE_AGENDAMENTO_TIPO_UPDATE": "ROLE_AGENDAMENTO_TIPO_UPDATE",
  "ROLE_FINANCEIRO_GUIA_CONSULTA_CREATE": "ROLE_FINANCEIRO_GUIA_CONSULTA_CREATE",
  "ROLE_FINANCEIRO_GUIA_CONSULTA_READ": "ROLE_FINANCEIRO_GUIA_CONSULTA_READ",
  "ROLE_FINANCEIRO_GUIA_CONSULTA_UPDATE": "ROLE_FINANCEIRO_GUIA_CONSULTA_UPDATE",
  "ROLE_FINANCEIRO_GUIA_SERVICO_CREATE": "ROLE_FINANCEIRO_GUIA_SERVICO_CREATE",
  "ROLE_FINANCEIRO_GUIA_SERVICO_READ": "ROLE_FINANCEIRO_GUIA_SERVICO_READ",
  "ROLE_FINANCEIRO_GUIA_SERVICO_UPDATE": "ROLE_FINANCEIRO_GUIA_SERVICO_UPDATE",
  "ROLE_FINANCEIRO_GUIA_SERVICO_LOTE_CREATE": "ROLE_FINANCEIRO_GUIA_SERVICO_LOTE_CREATE",
  "ROLE_FINANCEIRO_GUIA_SERVICO_LOTE_READ": "ROLE_FINANCEIRO_GUIA_SERVICO_LOTE_READ",
  "ROLE_FINANCEIRO_GUIA_SERVICO_LOTE_UPDATE": "ROLE_FINANCEIRO_GUIA_SERVICO_LOTE_UPDATE",
  "ROLE_GUIA_SERVICO_MODELO_CREATE": "ROLE_GUIA_SERVICO_MODELO_CREATE",
  "ROLE_GUIA_SERVICO_MODELO_READ": "ROLE_GUIA_SERVICO_MODELO_READ",
  "ROLE_GUIA_SERVICO_MODELO_UPDATE": "ROLE_GUIA_SERVICO_MODELO_UPDATE",
  "ROLE_RECEITA_CREATE": "ROLE_RECEITA_CREATE",
  "ROLE_RECEITA_READ": "ROLE_RECEITA_READ",
  "ROLE_RECEITA_UPDATE": "ROLE_RECEITA_UPDATE",
  "ROLE_RECEITA_MODELO_CREATE": "ROLE_RECEITA_MODELO_CREATE",
  "ROLE_RECEITA_MODELO_READ": "ROLE_RECEITA_MODELO_READ",
  "ROLE_RECEITA_MODELO_UPDATE": "ROLE_RECEITA_MODELO_UPDATE",
  "ROLE_AVALIACAO_ANTROPOMETRICA_CREATE": "ROLE_AVALIACAO_ANTROPOMETRICA_CREATE",
  "ROLE_AVALIACAO_ANTROPOMETRICA_READ": "ROLE_AVALIACAO_ANTROPOMETRICA_READ",
  "ROLE_AVALIACAO_ANTROPOMETRICA_UPDATE": "ROLE_AVALIACAO_ANTROPOMETRICA_UPDATE",
  "ROLE_AFERICAO_VITAL_CREATE": "ROLE_AFERICAO_VITAL_CREATE",
  "ROLE_AFERICAO_VITAL_READ": "ROLE_AFERICAO_VITAL_READ",
  "ROLE_AFERICAO_VITAL_UPDATE": "ROLE_AFERICAO_VITAL_UPDATE",
  "ROLE_DASHBOARD_READ": "ROLE_DASHBOARD_READ",
  "ROLE_CONFIGURACAO_IMPRESSAO_READ_OUTROS_PROFISSIONAIS": "ROLE_CONFIGURACAO_IMPRESSAO_READ_OUTROS_PROFISSIONAIS",
  "ROLE_AGENDAMENTO_TELEMEDICINA": "ROLE_AGENDAMENTO_TELEMEDICINA",
  "ROLE_DOCUMENTO_MODELO_READ_OUTROS_PROFISSIONAIS": "ROLE_DOCUMENTO_MODELO_READ_OUTROS_PROFISSIONAIS",
  "ROLE_CONTROLE_SESSAO_CREATE": "ROLE_CONTROLE_SESSAO_CREATE",
  "ROLE_CONTROLE_SESSAO_READ": "ROLE_CONTROLE_SESSAO_READ",
  "ROLE_CONTROLE_SESSAO_UPDATE": "ROLE_CONTROLE_SESSAO_UPDATE",
  "ROLE_AGENDAMENTO_UPDATE_STATUS": "ROLE_AGENDAMENTO_UPDATE_STATUS",
  "ROLE_PROFISSIONAL_SAUDE_RELATORIO": "ROLE_PROFISSIONAL_SAUDE_RELATORIO",
  "ROLE_AGENDAMENTO_RECORRENCIA_CREATE": "ROLE_AGENDAMENTO_RECORRENCIA_CREATE",
  "ROLE_AGENDAMENTO_RECORRENCIA_READ": "ROLE_AGENDAMENTO_RECORRENCIA_READ",
  "ROLE_AGENDAMENTO_RECORRENCIA_UPDATE": "ROLE_AGENDAMENTO_RECORRENCIA_UPDATE",
  "ROLE_GUIA_INTERNACAO_MODELO_CREATE": "ROLE_GUIA_INTERNACAO_MODELO_CREATE",
  "ROLE_GUIA_INTERNACAO_MODELO_READ": "ROLE_GUIA_INTERNACAO_MODELO_READ",
  "ROLE_GUIA_INTERNACAO_MODELO_UPDATE": "ROLE_GUIA_INTERNACAO_MODELO_UPDATE",
  "ROLE_GUIA_INTERNACAO_CREATE": "ROLE_GUIA_INTERNACAO_CREATE",
  "ROLE_GUIA_INTERNACAO_READ": "ROLE_GUIA_INTERNACAO_READ",
  "ROLE_GUIA_INTERNACAO_UPDATE": "ROLE_GUIA_INTERNACAO_UPDATE",
  "ROLE_PERIODO_ATENDIMENTO_MODIFICADO_UPDATE": "ROLE_PERIODO_ATENDIMENTO_MODIFICADO_UPDATE"
};
export default AUTHORITIES;