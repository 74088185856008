import React, { forwardRef } from 'react';
import moment from "moment";
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import {inject, observer} from "mobx-react";
import {withStyles} from "@material-ui/core/styles/index";
import GridItem from '../GridItem';
import {Dates} from "../../utils";
import string from '../../utils/string';
import Colors from "../../template/Colors";
import DialogTitle from '../Dialog/DialogTitle';
import GracefulImage from '../Image/GracefulImage';
import ButtonWhatsApp from '../Button/ButtonWhatsApp';
import {buildUrlFotoPerfil} from "../../config/config";
import { goToWhatsApp } from '../../utils/goToWhatsApp';
import TelefoneFormatado from '../Input/TelefoneFormatado';
import {applyPhoneMask, applyInternationalPhoneMask} from "../../utils/PhoneMask";
import {consultarInadimplencia, findByIdSujeitoAtencaoAtendimento} from "../../services/SujeitoAtencaoService";
import { getDadosMensagem } from '../../pages/Atendimento/utils/getDadosMensagem';
import PersonEditIcon from '../Icon/PersonEdit';
import {  CircularProgress, Tooltip } from '@material-ui/core';
import ObservacaoEditIcon from '../Icon/ObservacaoIconEdit';
import VoucherIcon from '../Icon/VoucherIcon';
import { UNIDADE_LOGADA_KEY } from '../../services/storage';
import DocumentDismiss from '../Icon/DocumentDismiss';
import DocumentError from '../Icon/DocumentError';
import DocumentCheckmark from '../Icon/DocumentCheckmark';
import ModalMensagemWhatsapp from '../../pages/Atendimento/ModalMensagemWhatsapp';
import { findAllDadosMensagemSelecionada } from '../../services/MensagensService';
import { Description } from '@material-ui/icons';
import { Button } from '../ui/Buttons';
import AssinaturaPresencaIcon from '../Icon/AssinaturaPresencaIcon';
import OrcamentoEmitidoIcon from '../Icon/OrcamentoEmitidoIcon';

const profileDefault = require("../../assets/img/svg/profile-default.svg");

const styles = (theme) => ({
    root: {
        padding: 0,
    },
    title: {
        color: theme.palette.primary.main
    },

    boxInfosSujeitoAtencao: {
        background: '#FFFFFF',
        padding: '0px 0px 0px 20px',
        position: 'relative',
        height: '160px',
    },
    boxInfosSujeitoAtencaoRecize: {
        background: '#FFFFFF',
        padding: '0px 0px 0px 20px',
        position: 'relative',
        height: '105px',
    },
    spaceTop: {
        flexBasis: '12%'
    },
    image: {
        justifyContent: 'center'
    },
    inline: {
        display: 'inline-flex'
    },
    columnItems: {
        display: 'flex', 
        flexDirection: 'column'
    },
    inlineItems: {
        display: 'flex',
        flexDirection: 'row',
        gap: '4px'
    },
    imageMini: {
        borderRadius: '30px',
        maxWidth: '60px',
    },
    marginLeft: {
        marginLeft: '8px'
    },
    paperListTitle: {
        fontSize: "18px",
    },
    gridPadding:{
        paddingLeft: 5
    },
    phoneHover: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    contentNome: {
        gap: '8px',
        display: 'flex',
        marginBottom: '4px',
        fontSize: '18px',
        alignItems: 'center',

        '& svg': {
            cursor: 'pointer'
        }
    },
    observationTooltip: {
        wordBreak: 'break-word',
        maxHeight: '25px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 600,
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
    },
    containerVoucher: {
        display: 'flex', 
        alignItems: 'center', 
        backgroundColor:'#00908B', 
        borderRadius: '16px', 
        padding: '2px 6px', 
        gap: '4px',
        color: 'white', 
        fontWeight: '700'
    }
});

const StyledDialogTitle = withStyles({
    root: {
        paddingTop: '10px',
        width: '100%',
        textAlign: 'left'
    },
    title: {
        fontWeight: 700,
        color: Colors.commons.padrao,
        fontSize: '18px',
        margin: '8px 0',
    }
})(DialogTitle);

const situacoesContrato = {
    ADIMPLENTE: {
        title: 'Paciente adimplente',
        color: '#00B0AE',
        icon: <DocumentCheckmark size={12}/>,
    },
    INADIMPLENTE_PARCIAL: {
        title: 'Paciente inadimplente em um ou mais contratos.',
        color: '#FBBF6D',
        icon: <DocumentError size={12}/>,
    },
    INADIMPLENTE: {
        title: 'Paciente inadimplente em todos contratos',
        color: '#FB7676',
        icon: <DocumentDismiss size={12}/>,
    },
}

@inject("atendimentoStore", "unidadeStore", "extratoStore","filtroHeaderStore","sujeitoAtencaoStore")
@observer
class InfosSujeitoAtencaoModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: props.id,
            sujeitoAtencao: {
                nome: '',
                dataNascimento: '',
                dadosConvenio: {
                    convenio: {
                        descricao: ''
                    }
                },
                contato: {
                    telefonePrincipal: '',
                    telefonePrincipalDDI: '',
                    telefoneSecundario:'',
					telefoneSecundarioDDI:'',
                },
                fotoPerfil: '',
                nomeSocialOuNome:''
            },
            convenio: '',
            loadingVouchers: false,
            situacaoContrato: '',
            openModalMensagemWhatsap:false,
            dadosMensagem:null,
            profissionalSaude :null,
        };
    }

    async componentDidMount() {
        this.consultaSujeitoAtencao();
        await this.props.atendimentoStore.getConfiguracaoUnidadeLogada();
        this.props.atendimentoStore.utilizaContratoAzulControle && this.consultarInadimplencia();
        
    }

    possuiDocumentosAssinatura = () => {
        const { objView } = this.props
        if (objView?.documentosAssinadosCliente?.length > 0) {
            if (objView?.documentosAssinadosCliente?.some((item) => item.situacao === "Unsigned")) {
                return "#F9BE73"
            }
        }else{
            return "#219A97"
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            this.setState({id: this.props.id}, () => {
                this.consultaSujeitoAtencao();
            });
        };
    };

    consultarInadimplencia = async() => {
        const { id } = this.props;

        const situacaoContrato = await consultarInadimplencia(id);
        this.setState({ situacaoContrato });
    };

    async consultaSujeitoAtencao() {
        await findByIdSujeitoAtencaoAtendimento(this.state.id)
            .then((res) => {
                const sujeito = res.data.data.findByIdSujeitoAtencao;

                this.setState({
                    sujeitoAtencao: sujeito,
                    convenio: this.props.convenio ? this.props.convenio : sujeito?.dadosConvenio?.convenio?.descricao
                });
            });
        await this.loadVouchersSujeitoAtencao();
    }

    async loadVouchersSujeitoAtencao() {
        const {extratoStore} = this.props;
        const {sujeitoAtencao} = this.state;
        if (!sujeitoAtencao?.id) return;
        this.setState({loadingVouchers: true});
        try {
            await extratoStore.findVouchersAssociado({
                sujeitoAtencaoId: sujeitoAtencao?.id,
                status: "EMITIDO",
              });
        } catch (error) {
            console.error(error);
        } finally {
            this.setState({loadingVouchers: false});
        }
    }
    

    handleClickWhatsapp = async (telefone, telefoneDDI) => {
        const{forwardWhattsapp,atendimentoStore, unidadeStore}=this.props
        const { objView } = atendimentoStore;
        const configuracaoUnidade = unidadeStore.configuracaoUnidade
        const{utilizaMensagemPersonalizada,horasPermiteConfirmarAgendamento, utilizaEnderecoMensagemAgendamento}=configuracaoUnidade;
        
        if(forwardWhattsapp && atendimentoStore.idOpened && !atendimentoStore.isModalSala && utilizaMensagemPersonalizada){
            this.setState({openModalMensagemWhatsap: true})
            this.setState({profissionalSaude: objView.profissionalSaude.id})
            let message = await findAllDadosMensagemSelecionada(objView.id);

            this.setState({
                dadosMensagem: {
                    ...message,
                    telefone: telefone,
                    telefoneDDI: telefoneDDI
                }
            })
        }else{
            let message = "";
            const dadosMensagem = {
                nomePaciente: objView.sujeitoAtencao?.nomeSocialOuNome,
                nomeFantasiaUnidade: objView.unidade?.nomeFantasia,
                nomeProfissional: objView.profissionalSaude?.nome,
                horaInicio: objView.horaInicio || " - ",
                data: moment(objView.data).format("DD/MM/YYYY"),
                chavePublica: objView.chavePublica,
                isAgendamentoVizi: objView.paciente?.id,
                remoto: objView.remoto,
                horasPermiteAgendamento:horasPermiteConfirmarAgendamento,
                withEndereco: utilizaEnderecoMensagemAgendamento,
                endereco: objView.unidade?.endereco,
            };
            message = getDadosMensagem(dadosMensagem);
            return goToWhatsApp(telefone,telefoneDDI,message)
        };
        if(objView.id){
            atendimentoStore.increaseWhatsappMessageCounter(objView.id);
        }
    }

    handleCloseModalMensagemWhatsapp=()=>{
        this.setState({openModalMensagemWhatsap:false})
    }

    render() {
        const {classes, atendimentoStore, title, extratoStore,hiddenIcons,recize,isModalAssinatura,sujeitoAtencaoStore} = this.props;
        const {sujeitoAtencao, convenio, loadingVouchers, situacaoContrato} = this.state;
        const { utilizaTelefoneInternacional } = this.props.unidadeStore.unidade;
        const {accessToken, objView, utilizaContratoAzulControle,isLoadingUnidadeConfig,loadingAgendamento , sujeitosAtencaoLoading} = atendimentoStore;
        const municipio = sujeitoAtencao?.endereco?.municipio;
        const quantityVouchers = extratoStore?.vouchersAssociado?.length;
        const isPrever = JSON.parse(localStorage.getItem('_immortal|' + UNIDADE_LOGADA_KEY))?.rede?.whitelabel?.nome === 'ANGEPLUS'
        const { 
            nomeSocialOuNome , 
            contato, 
            dataNascimento, 
            nomeResponsavel, 
            documentoResponsavel, 
            fotoPerfil,
            documento, 
            documentoTipo 
        } = this.state.sujeitoAtencao || {};
        const { loadingSujeitoAtencao } = sujeitoAtencaoStore

        const situacaoContratoAzulControle = situacaoContrato && situacoesContrato[situacaoContrato];
        const loadingPerfilSujeitoAtencao= loadingVouchers || isLoadingUnidadeConfig || loadingAgendamento || sujeitosAtencaoLoading || loadingSujeitoAtencao

        return (
            <div classes={ classes.boxInfosSujeitoAtencao}>
                {loadingPerfilSujeitoAtencao && isModalAssinatura?
                     <CircularProgress/>
                     :
                    <div>
                    <StyledDialogTitle className={classes.paperListTitle}>
                        {title}
                    </StyledDialogTitle>
                    <Grid
                        container
                        direction={"col"}
                        xs={12}
                        className={classes.spaceTop}>
                        <Grid
                            container
                            direction={"row"}
                            xs={2}
                            className={classes.image}>
                            <GracefulImage src={fotoPerfil ? buildUrlFotoPerfil(fotoPerfil, accessToken) : profileDefault}
                                           alt={'Imagem Perfil'} height="60"
                                           className={classes.imageMini}
                                           placeholderColor={'#00ACA9'}/>
                        </Grid>
                        <Grid
                            container
                            direction={"row"}
                            xs={10}>
                    
                           { isModalAssinatura && <Nome>{string.truncate(nomeSocialOuNome, 30)}</Nome>}
                            {!hiddenIcons && <GridItem classes={{ grid: classes.gridPadding }} className={classes.contentNome}>
                                <Nome>{string.truncate(nomeSocialOuNome, 30)}</Nome>
                                <Tooltip title="Editar perfil" placement="top">
                                    <PersonEditIcon
                                        color='white'
                                        size={24}
                                        onClick={this.props.handleClickEditSujeitoAtencao}
                                    />
                                </Tooltip>
                                <Tooltip title="Documentos" placement="top">
                                    <Button
                                     shape='circle'
                                     bgColor={this.possuiDocumentosAssinatura()}
                                     onClick={this.props.handleClickDocumentoSujeitoAtencao}
                                     padding={0}
                                     style={{height: 24, width: 24}}
                                     >
                                    <Description
                                        color='#FFF'
                                        style={{fontSize:"15px"}}
                                    />
                                    </Button>
                                </Tooltip>
                                { objView?.id && objView?.possuiOrcamento && (
                                    <Tooltip title={'Orcamento'}>
                                        <Button
                                        shape='circle'
                                        bgColor='#219A97'
                                        onClick={this.props.handleClickOrcamentosSujeitoAtencao}
                                        padding={0}
                                        style={{height: 24, width: 24, display: "flex" }}
                                        >
                                            <OrcamentoEmitidoIcon />
                                        </Button>
                                    </Tooltip>)
                                }
                                {sujeitoAtencao?.observacao && (
                                    <Tooltip
                                        title={sujeitoAtencao?.observacao}
                                        placement="top"
                                        classes={{tooltip: classes.observationTooltip}}
                                    >
                                        <ObservacaoEditIcon color='white' onClick={this.props.handleClickEditObservacao} />
                                    </Tooltip>
                                )}
                                {objView?.id &&
                                    <Tooltip
                                        title={"Assinatura de presença"}
                                        placement="top"
                                        classes={{ tooltip: classes.observationTooltip }}
                                    >
                                        <AssinaturaPresencaIcon color='white' onClick={this.props.handleClickAssinaturaPresenca} />
                                    </Tooltip>
                                }
                                {utilizaContratoAzulControle && situacaoContratoAzulControle && (
                                    <Tooltip
                                        title={situacaoContratoAzulControle?.title}
                                        placement="top"
                                    >
                                        <SituacaoContrato color={situacaoContratoAzulControle?.color}>
                                            { situacaoContratoAzulControle?.icon }
                                        </SituacaoContrato>
                                    </Tooltip>
                                )}
                                {isPrever && (
                                    <Tooltip
                                        title={quantityVouchers <= 1 ? `${quantityVouchers} Voucher disponível` : `${quantityVouchers} Vouchers disponíveis`}
                                        placement="top"
                                    >
                                        <div className={classes.containerVoucher}>
                                            <VoucherIcon color='white' onClick={this.props.handleClickEditObservacao} />
                                            {loadingVouchers ? <CircularProgress size={12} color='white' /> : <span>{quantityVouchers}</span>}
                                        </div>
                                    </Tooltip>
                                )}
                            </GridItem>
                            }
                            <GridItem className={classes.inline} classes={{grid: classes.gridPadding}}>
                    
                                {contato?.telefonePrincipal && !loadingPerfilSujeitoAtencao &&
                                    (<>
                                        <Label>Tel.:</Label>
                                        <Value
                                            onClick={
                                                utilizaTelefoneInternacional ?
                                                () => this.handleClickWhatsapp(contato?.telefonePrincipal, contato?.telefonePrincipalDDI) :
                                                null
                                            }
                                            className={utilizaTelefoneInternacional ? classes.phoneHover : null}
                                        >
                                            <TelefoneFormatado
                                                telefone={contato?.telefonePrincipal}
                                                telefoneDDI={contato?.telefonePrincipalDDI}
                                            />
                                        </Value>
                                        {!utilizaTelefoneInternacional && (
                                            <ButtonWhatsApp
                                            messageSent={objView?.mensagemWhatsappEnviada}
                                            labelTooltip='Mensagem enviada, deseja enviar novamente?'
                                            onClick={() => this.handleClickWhatsapp(contato?.telefonePrincipal, '55')}
                                            />
                                        )}
                    
                                    </>)
                                }
                                {
                                    contato?.telefoneSecundario &&
                                    (<>
                                        <Label className={classes.marginLeft}>Tel.:</Label>
                                        <Value
                                            onClick={
                                                utilizaTelefoneInternacional ?
                                                () => this.handleClickWhatsapp(contato?.telefoneSecundario, contato?.telefoneSecundarioDDI) :
                                                null
                                            }
                                            className={utilizaTelefoneInternacional ? classes.phoneHover : null}
                                        >
                                            {utilizaTelefoneInternacional ?
                                                applyInternationalPhoneMask(contato?.telefoneSecundario, contato?.telefoneSecundarioDDI) :
                                                applyPhoneMask(contato?.telefoneSecundario)
                                            }
                                        </Value>
                                        {!utilizaTelefoneInternacional && (
                                            <ButtonWhatsApp
                                            messageSent={objView?.mensagemWhatsappEnviada}
                                            labelTooltip='Mensagem enviada, deseja enviar novamente?'
                                            onClick={() => this.handleClickWhatsapp(contato?.telefoneSecundario, '55')}
                                            />
                                        )}
                                    </>)
                                }
                            </GridItem>
                            <GridItem className={classes.inline} classes={{grid: classes.gridPadding}}>
                                {
                                    municipio?.nome &&
                                    (<>
                                        <Label>Município:</Label>
                                        <Value>{municipio?.nome ? `${municipio?.nome} - ${municipio?.uf}` : ''}</Value>
                                    </>)
                                }
                                {
                                    dataNascimento &&
                                    (<>
                                        <Label className={classes.marginLeft}>Idade:</Label>
                                        <Value>{dataNascimento ? Dates.calculaIdade(dataNascimento) : ''}</Value>
                                    </>)
                                }
                            </GridItem>
                            {!(nomeResponsavel || documentoResponsavel) && (
                                <GridItem className={classes.inline} classes={{grid: classes.gridPadding}}>
                                    {
                                        documento &&
                                        <>
                                            <Label>Doc.:</Label>
                                            <Documento>{documentoTipo?.descricao === "CPF" ? string.cpfMask(documento) : documento}</Documento>
                                        </>
                                    }
                                    { convenio &&
                                        <>
                                            <Label className={classes.marginLeft}>Convênio:</Label>
                                            <Convenio>{ convenio }</Convenio>
                                        </>
                                    }
                                </GridItem>
                            )}
                            {(nomeResponsavel || documentoResponsavel) && (
                                <GridItem
                                    className={nomeResponsavel && documentoResponsavel ? classes.columnItems : classes.inlineItems}
                                    classes={{grid: classes.gridPadding}}
                                >
                                    {nomeResponsavel && (
                                        <div className={classes.inline}>
                                            <Label>Responsável:</Label>
                                            <Value>{string.truncate(nomeResponsavel, 30)}</Value>
                                        </div>
                                    )}
                                    {documentoResponsavel && (
                                        <div className={classes.inline}>
                                            <Label>CPF Responsável:</Label>
                                            <Documento>{string.cpfMask(documentoResponsavel)}</Documento>
                                        </div>
                                    )}
                                </GridItem>
                            )}
                        </Grid>
                    </Grid>
                    {
                        <ModalMensagemWhatsapp
                        open={this.state.openModalMensagemWhatsap}
                        close={this.handleCloseModalMensagemWhatsapp}
                        dadosMensagem={this.state.dadosMensagem}
                        profissionalSaudeSelecionado={this.state.profissionalSaude}
                        />
                    }
                </div>
                
            }
            </div>
        );
    }
}


const Nome = styled.div`
    font-size: 14px;
    color: #505050;
    height: 21px;
    font-family: Poppins!important;
    font-weight: 700

`;

const Label = styled.div`
    font-size: 12px;
    color: #505050;
    height: 20px;
    margin-right: 5px;
    font-family: Poppins!important;
`;

const Value = styled.div`
    font-size: 12px;
    color: #505050;
    font-family: Poppins!important;
    font-weight: 700;
`;

const Convenio = styled(Value)`
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Documento = styled(Value)`
    white-space: nowrap;
`;

const SituacaoContrato = styled.div`
    background: ${props => props.color};
    border-radius: 100px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
`;

export default withStyles(styles)(InfosSujeitoAtencaoModal)