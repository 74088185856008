const styles = {
  container: {
    "& thead th": {
      textAlign: "left",
      top: 0,
      position: "sticky",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    padding: "24px 24px 0 24px",
    backgroundColor: "#F5F5F5",
    gap: "16px",
    height: "calc(100% - 104px)",
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    borderRadius: "16px",
    border: "solid 1px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#FFF",
  },
  scrollContainer: {
    height: "100%",
  },
  notFoundContainer: {
    marginTop: "20px",
  },
  dialogPaper: {
    maxWidth: "540px",
    maxHeight: "407px",
    height: "100%",
    overflow: "hidden",
  },
  modalHeader: {
    minHeight: 64,
    padding: "0px 32px",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    justifyContent: "space-between",
  },
  titleModal: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "27px",
    color: "#26ACA9",
  },
  containerModal: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: "16px 0",
    gap: "8px",
  },
  contentModal: {
    height: "100%",
  },
  toggleButtons: {
    margin: "0 32px",
  },
  containerHeader: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    gap: "8px",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 32px 0",
  },
  inputRetangular: {
    borderRadius: "8px",
  },
  contentField: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
  },
  contentFieldFullWidth: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  switchRoot: {
    marginLeft: "-10px",
  },
  switchBase: {
    height: "fit-content",
  },
  titleField: {
    color: "#868686",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    marginBottom: "4px",
  },
  fieldUf: {
    width: "30%",
  },
  fieldAtivo: {
    width: "40px",
  },
  notchedOutline: {
    border: "0",
  },
  inputContainer: {
    width: "calc( 100% - 20px )",
    background: "#F2F2F2",
    color: "#505050",
    borderRadius: "8px",
    height: "23px",
    padding: "4px 8px",

    "& > div": {
      "& > p": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
  },
  contentFieldTuss18: {
    width: "75%",
  },
};

export default styles;
