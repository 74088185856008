import moment from "moment";
import string from "../../../../utils/string";
import { situacoes } from "../../../../utils/constants/situacoes";

const primeiroDiaMesAtual = moment()
  .startOf("month")
  .format("YYYY-MM-DD");
const ultimoDiaMesAtual = moment()
  .endOf("month")
  .format("YYYY-MM-DD");

export const columns = [
  {
    Header: "Nome do paciente",
    align: "left",
    getValue: (agendamento) => {
      return agendamento.sujeitoAtencaoNome;
    },
    field: "sujeitoAtencaoNome",
  },
  {
    Header: "Tipo de consulta",
    align: "left",
    getValue: (agendamento) => {
      return agendamento.tipo;
    },
    field: "tipo",
  },
  {
    Header: "Profissional",
    align: "left",
    getValue: (agendamento) => {
      return agendamento.profissionalSaudeNome;
    },
    field: "profissionalSaudeNome",
  },
  {
    Header: "Situações",
    align: "left",
    getValue: (agendamento) => (string.capitalize(agendamento.situacao) || ""),
    field: "situacao",
  },
  {
    Header: "Quantidade",
    align: "left",
    getValue: (agendamento) => {
      return agendamento.quantidade;
    },
    field: "quantidade",
  },
];
export const situacoesOptionsDefault = [
  {
    nome: "Todas as situações",
    value: "TODAS_SITUACOES",
    checked: false,
  },
  {
    nome: "Agendado",
    value: "AGENDADO",
    checked: true,
  },
  {
    nome: "Aguardando",
    value: "AGUARDANDO",
    checked: false,
  },
  {
    nome: "Cancelado",
    value: "CANCELADO",
    checked: false,
  },
  {
    nome: "Excluído",
    value: "EXCLUIDO",
    checked: false,
  },
  {
    nome: "Confirmado",
    value: "CONFIRMADO",
    checked: false,
  },
];

export const filtersDefault = {
  dataInicio: primeiroDiaMesAtual,
  dataFim: ultimoDiaMesAtual,
  profissional: null,
  tipoAgendamento: null,
  status: situacoes,
};
