import React from "react";
import { Switch, withStyles } from "@material-ui/core";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { CircularProgress } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MultiToggleButtons from "../../components/Button/MultiToggleButtons";
import Auditoria from "../../components/Auditoria/Auditoria";
import PageTitle from "../../components/PageTitle/PageTitle";
import Dialog from "../../components/Dialog/Dialog";
import styles from "./styles";
import ButtonLong from "../../components/Button/ButtonLong";
import Notification from "../../components/Notification";

const ModalFinanceiro = observer((props) => {
  const {
    classes,
    auditoriaStore,
    open,
    pageTitle,
    handleClose,
    modalTitle,
    opening,
    handleSave,
    componentInformacoes,
    loadingSave,
    tabsProps,
    status,
  } = props;

  const renderTab = () => {
    return tabsProps?.tabSelected === 0 ? componentInformacoes : <Auditoria />;
  };

  return (
    <>
      <PageTitle title={pageTitle} />
      <Dialog
        classes={{
          paper: classes.dialogPaper,
        }}
        open={open}
        fullWidth
        onClose={handleClose}
      >
        <div className={classes.modalHeader}>
          <div className={classes.contentTitle}>
            <div className={classes.titleModal}> {modalTitle} </div>
            {status?.show && (
              <Switch
                onChange={() => status.handleStatus()}
                checked={status.ativo}
                color="primary"
              />
            )}
          </div>
          <div className={classes.buttonClose} onClick={handleClose}>
            <CloseIcon className={classes.closeIcon} />
          </div>
        </div>
        {opening ? (
          <div className={classes.notFoundContainer}>
            <CircularProgress />
          </div>
        ) : (
          <div className={classes.contentModal}>
            {tabsProps ? (
              <>
                <MultiToggleButtons
                  {...tabsProps}
                  classes={{ buttonsContainer: classes.toggleButtons }}
                />
                <div className={classes.contentTab}>{renderTab()}</div>
              </>
            )
            : componentInformacoes
          }
          </div>
        )}
        {(!tabsProps || tabsProps?.tabSelected === 0) && (
          <div className={classes.footer}>
            <div onClick={handleClose} className={classes.buttonCancelar}>
              Cancelar
            </div>
            <ButtonLong
              colorCustom="green"
              noShadow
              onClick={handleSave}
              disabled={loadingSave}
            >
              Salvar
              {loadingSave && (
                <CircularProgress
                  color="inherit"
                  size={14}
                  style={{ marginLeft: 10 }}
                />
              )}
            </ButtonLong>
          </div>
        )}
        <Notification
          close={auditoriaStore.closeAlertMessage}
          reset={auditoriaStore.closeAlertMessage}
          isOpen={auditoriaStore.notification.isOpen}
          variant={"error"}
          message={auditoriaStore.notification.message}
        />
      </Dialog>
    </>
  );
});

const ModalFinanceiroWithStyles = withStyles(styles)(ModalFinanceiro);
export default inject(
  "extratoStore",
  "auditoriaStore"
)(ModalFinanceiroWithStyles);
