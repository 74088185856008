import { withStyles } from "@material-ui/core";
import { inject } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Typography, Checkbox } from "@material-ui/core";
import classnames from "classnames";
import PanelLeft from "../../../components/PanelLeft/PanelLeft";
import InputDefault from "../../../components/Input/InputDefault";
import ModelDocumentEditor from "../../../components/ModelDocumentEditor";
import Grid from "@material-ui/core/Grid";
import styles from "../../../assets/jss/pages/novoDocumentoStyle.js";
import { observer } from "mobx-react-lite";
import { campos } from "../../../stores/ModeloDocumento.store";

const InformacoesModeloDocumento = observer((props) => {
  const { classes, modeloDocumentoStore, child } = props;
  const {
    id,
    documentName,
    ativo,
    tipo,
    isLoading,
    camposSistemaResponseFiltrados,
  } = modeloDocumentoStore;
  const {
    camposSujeitoAtencao,
    camposProfissionalSaude,
    camposDoSistema,
    camposSujeitoAtencaoInternacao,
  } = camposSistemaResponseFiltrados;

  const [camposSistemaSujeitoUtilizados, setCamposSistemaSujeitoUtilizados] = useState([]);
  const [camposSistemaProfissionalUtilizados, setCamposSistemaProfissionalUtilizados] = useState([]);
  const [camposDoSistemaUtilizados, setCamposDoSistemaUtilizados] = useState([]);
  const [camposSistemaSujeitoInternacaoUtilizados, setCamposSistemaSujeitoInternacaoUtilizados] = useState([]);
  const [camposUsuario, setCamposUsuario] = useState([]);
  const [camposUsuarioShow, setCamposUsuarioShow] = useState([]);
  const [camposProfissionalSaudeShow, setCamposProfissionalSaudeShow] = useState([]);
  const [camposDoSistemaShow, setCamposDoSistemaShow] = useState([]);
  const [camposSujeitoAtencaoShow, setCamposSujeitoAtencaoShow] = useState([]);
  const [camposSujeitoAtencaoInternacaoShow, setCamposSujeitoAtencaoInternacaoShow] = useState([]);

  useEffect(() => {
    montarListaParaExibirNaTela();
  }, [
    camposSistemaResponseFiltrados,
    camposSistemaSujeitoUtilizados,
    camposSistemaProfissionalUtilizados,
    camposDoSistemaUtilizados,
    camposUsuario,
    camposSistemaSujeitoInternacaoUtilizados,
  ]);

  const handleChange = (event) => {
    const { name, value, checked } = event.target;

    modeloDocumentoStore[name] = checked || value;
  };

  const handleChangeTipo = (event) => {
    const { checked } = event.target;
    modeloDocumentoStore.tipo = checked ? "ASSINATURA" : "PROFISSIONAL_SAUDE";
  };

  const updateCampos = (tipo, value) => {
    const uniqueArray = [...new Set(value)];

    if (tipo === "entrada") {
        setCamposUsuario(uniqueArray);
      return;
    }

    if (tipo === "sujeitoAtencao") {
        setCamposSistemaSujeitoUtilizados(uniqueArray);
      return;
    }

    if (tipo === "profissionalSaude") {
        setCamposSistemaProfissionalUtilizados(uniqueArray);
    }
    if (tipo === "sistema") {
        setCamposDoSistemaUtilizados(uniqueArray)
    }
    if (tipo === "sujeitoAtencaoInternacao") {
      setCamposSistemaSujeitoInternacaoUtilizados(uniqueArray)
  }
  };

  const updateStatus = (status) => {
    modeloDocumentoStore.ativo = status;
  };

  const updateName = (name) => {
    modeloDocumentoStore.documentName = name;
  };

  const updateTipoAssinatura = (tipoAssinatura) => {
    modeloDocumentoStore.tipo = tipoAssinatura;
  };

  const montarListaParaExibirNaTela = () => {
    campos.forEach((item) => {
      switch (item.lista) {
        case "camposUsuario":
          setCamposUsuarioShow(camposUsuario.map(
            (el, i) => (
              <li
                style={{ marginBottom: "3px" }}
                key={i}
                onClick={() => handleUserCampos(el)}
              >
                {el
                  .replace(/[^a-z0-9]/gi, "")
                  .replace(/([A-Z])/g, " $1")
                  .trim()}
              </li>
              
            )
          ));
          break;

        case "camposSujeitoAtencao":
        setCamposSujeitoAtencaoShow(camposSujeitoAtencao.map(
            (el, i) => {
              const selected = camposSistemaSujeitoUtilizados.some((c) => c.includes(el.campo));
              return (
                <li
                  onClick={() => handleCamposDoSistema(el)}
                  className={classnames(
                    classes.camposSistemaItem,
                    selected ? classes.camposSistemaItemSelected : null
                  )}
                  key={i}
                >
                  
                  {el.descricao}
                </li>
                
              );
            }
          ));
          break;

        case "camposProfissionalSaude":          
          setCamposProfissionalSaudeShow(camposProfissionalSaude.map(
            (el, i) => {
              const selected =
                camposSistemaProfissionalUtilizados.filter(
                  (c) => c.search(el.campo) !== -1
                ).length !== 0;
              return (
                <li
                  onClick={() => handleCamposDoSistema(el)}
                  className={classnames(
                    classes.camposSistemaItem,
                    selected ? classes.camposSistemaItemSelected : null
                  )}
                  key={i}
                >
                  {el.descricao}
                </li>
              );
            }
          ));
          break;
          case "camposSujeitoAtencaoInternacao":
            setCamposSujeitoAtencaoInternacaoShow(camposSujeitoAtencaoInternacao.map(
              (el, i) => {
                const selected = camposSistemaSujeitoInternacaoUtilizados.some((c) => c.includes(el.campo));
                return (
                  <li
                    onClick={() => handleCamposDoSistema(el)}
                    className={classnames(
                      classes.camposSistemaItem,
                      selected ? classes.camposSistemaItemSelected : null
                    )}
                    key={i}
                  >
                    {el.descricao}
                  </li>
                  
                );
              }
            ));
            break;
        case "camposDoSistema":
        default:
          setCamposDoSistemaShow(camposDoSistema.map(
            (el, i) => {
              const selected =
                camposDoSistemaUtilizados.filter(
                  (c) => c.search(el.campo) !== -1
                ).length !== 0;
              return (
                <li
                  onClick={() => handleCamposDoSistema(el)}
                  className={classnames(
                    classes.camposSistemaItem,
                    selected ? classes.camposSistemaItemSelected : null
                  )}
                  key={i}
                >
                  {el.descricao}
                </li>
              );
            }
          ));

          break;
      }
    });
  };

  const handleUserCampos = (el) => {
    child.current.insertText(el, "USUARIO");
  };

  const handleCamposDoSistema = (el) => {
    const formated = "${" + el.entidade + "." + el.campo + "}";
    child.current.insertText(formated, "SISTEMA");
  };
  
  return (
    <div className={classes.contentInformacoes}>
      <PanelLeft className={classes.panelLeft}>
        <Grid
          className={classes.rootPanelLeft}
          item
          container
          direction={"column"}
          wrap={"nowrap"}
        >
          <Grid item xs={12} className={classes.status}>
            <Checkbox
              name="ativo"
              checked={ativo}
              color="primary"
              onChange={handleChange}
            />
            <Typography color="primary" component="label">
              Status
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.status}>
            <Checkbox
              name="tipo"
              checked={tipo === "ASSINATURA"}
              color="primary"
              onChange={handleChangeTipo}
            />
            <Typography color="primary" component="label">
              Assinatura online do paciente
            </Typography>
          </Grid>

          <Grid item>
            <Grid container direction={"column"}>
              <h3 style={{ color: "#969696", fontSize: "15px" }}>
                Campos de entrada
              </h3>
              <ul className={classes.camposEntrada}>{camposUsuarioShow}</ul>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container direction={"column"}>
              <h3 style={{ color: "#969696", fontSize: "15px" }}>
                Campos do sistema disponiveis
              </h3>
              <h4 style={{ color: "#969696" }}>Paciente</h4>
              <ul className={classes.camposSujeitoAtencao}>
                {isLoading ? <p>loading</p> : camposSujeitoAtencaoShow}
              </ul>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container direction={"column"}>
              <h4 style={{ color: "#969696" }}>Profissionais de saúde</h4>
              <ul className={classes.camposProfissionaisSaude}>
                {isLoading ? <p>loading</p> : camposProfissionalSaudeShow}
              </ul>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container direction={"column"}>
              <h4 style={{ color: "#969696" }}>Sistema</h4>
              <ul className={classes.camposProfissionaisSaude}>
                {isLoading ? <p>loading</p> : camposDoSistemaShow}
              </ul>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container direction={"column"}>
              <h4 style={{ color: "#969696" }}>Internação</h4>
              <ul className={classes.camposProfissionaisSaude}>
                {isLoading ? <p>loading</p> : camposSujeitoAtencaoInternacaoShow}
              </ul>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          className={classes.rootPanelLeft}
          item
          container
          direction={"column"}
          wrap={"nowrap"}
        >
          <Grid className={classes.tableBlockRoot} item>
            <Grid
              container
              justify={"center"}
              className={classes.checkboxOptions}
            />
          </Grid>
        </Grid>
      </PanelLeft>

      <Grid
        container
        direction={"column"}
        wrap={"nowrap"}
        className={classes.container}
      >
        <Grid item className={classes.content}>
          <Grid item>
            <Grid className={classes.search}>
              <InputDefault
                classInputRoot={classes.inputName}
                onChange={handleChange}
                name="documentName"
                value={documentName}
                placeholder="Nome"z
              />
            </Grid>

            <ModelDocumentEditor
              id={id}
              updateName={updateName}
              updateStatus={updateStatus}
              updateTipoAssinatura={updateTipoAssinatura}
              refChild={child}
              updateCampos={updateCampos}
              modeloDocumentoStore={modeloDocumentoStore}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
});

const InformacoesModeloDocumentoWithStyle = withStyles(styles)(
  InformacoesModeloDocumento
);
export default inject("modeloDocumentoStore")(
  InformacoesModeloDocumentoWithStyle
);
