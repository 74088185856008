import React from "react";
import moment from "moment";

const RelatorioProcedimentosPeriodo = ({ dadosRelatorio, filters }) => {
  const columnColor = (index) => {
    if (index % 2 !== 0) {
      return { backgroundColor: "#F2F2F2" };
    }
    return "";
  };

  const {
    dataInicio,
    dataFim,
    profissionalSaudeSolicitante,
    profissionalSaudeResponsavel,
    convenios,
    procedimentos,
    sujeitoAtencao,

  } = filters || {};


  return (
    <>
      <div style={styles.titulo}>Relatório de Procedimentos agendados</div>
      <div style={styles.subTitulo}>
        {sujeitoAtencao && (
          <div>
            Nome:
            <span> {sujeitoAtencao.nome}</span>
          </div>
        )}
        {dataInicio && (
          <div>
            Data inicio:
            <span> {moment(dataInicio).format("DD/MM/YYYY")}</span>
          </div>
        )}
        {dataFim && (
          <div>
            Data Fim:
            <span> {moment(dataFim).format("DD/MM/YYYY")}</span>
          </div>
        )}
        {profissionalSaudeSolicitante && (
          <div>
            Prof. Solicitante:
            <span> {profissionalSaudeSolicitante?.nome}</span>
          </div>
        )}
        {profissionalSaudeResponsavel && (
          <div>
            Prof. Responsável:
            <span> {profissionalSaudeResponsavel?.nome}</span>
          </div>
        )}
        {convenios && (
          <div>
            Convênio:
            <span> {convenios.descricao}</span>
          </div>
        )}
        {procedimentos && (
          <div>
            Procedimentos:
            <span> {procedimentos.nome}</span>
          </div>
        )}
      </div>
      <div style={styles.headerColumn}>
        <div style={styles.column}>Nome</div>
        <div style={styles.column}>Data</div>
        <div style={styles.column}>Profissional Saude:</div>
        <div style={styles.column}>Convênio</div>
        <div style={styles.column}>Procedimento</div>

      </div>
      <div>
        {dadosRelatorio.map((item, index) => {
          return (
            <div
              key={item.id}
              style={{ ...styles.containerColuna, ...columnColor(index) }}
            >
              <div style={styles.column}>{item.nomePaciente}</div>
              <div style={styles.column}>{item.dataAtendimento}</div>
              <div style={styles.column}>{item.nomeProfissionalSaude}</div>
              <div style={styles.column}>{item.convenio}</div>
              <div style={styles.column}>{item.procedimento}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const styles = {
  titulo: {
    marginLeft: "0px",
    marginBottom: "10px",
    marginTop: "5px",
    paddingBottom: "5px",
    textAlign: "center",
    fontWeight: 700,
  },
  subTitulo: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #505050",
    marginBottom: "15px",
    paddingBottom: "5px",
    fontWeight: 700,
    justifyContent: "center",
    gap: "8px",
  },

  headerColumn: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 700,
    width: "100%",
  },
  containerColuna: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  columnProcedimento: {
    width: "70%",
  },
  column: {
    width: "30%",
  },
};

export default RelatorioProcedimentosPeriodo;
