import React from 'react'
import { normalizeProps } from './utils/normalizeProps'

const PranchetaIcon = props => {
  return (
    <svg width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <rect width="100" height="100" rx="50" fill="#219A97" />
      <path d="M42.8511 19.2228H36L26.0483 19.1025C23.8205 19.0756 22 20.8742 22 23.1022V78C22 80.2091 23.7909 82 26 82H73.4423C75.6366 82 77.4212 80.2324 77.4422 78.0383L77.9618 23.7202C77.9829 21.5151 76.2154 19.7088 74.0104 19.6822L64 19.5612H57.1489" stroke="white" stroke-width="3" />
      <path d="M28.8 29.5L68.9384 29.6662V74.6471H30.2832V31M59.1212 13.8824V22.624C59.1212 23.1763 58.6735 23.624 58.1212 23.624H41.714C41.1617 23.624 40.714 23.1763 40.714 22.624V13.8824C40.714 13.3301 41.1617 12.8824 41.714 12.8824H58.1212C58.6735 12.8824 59.1212 13.3301 59.1212 13.8824Z" stroke="white" stroke-width="3" />
      <path d="M47.0213 12.3571V10.5745C47.0213 8.60034 48.6216 7 50.5957 7V7V7C52.5699 7 54.1702 8.60035 54.1702 10.5745V11.1667" stroke="white" stroke-width="3" />
      <ellipse cx="50" cy="49.8571" rx="12.5106" ry="13.3929" fill="#FC9191" />
      <path d="M49.0822 41.9917V46.8503V57.0661C49.0822 57.7112 49.6052 58.2342 50.2503 58.2342C50.8955 58.2342 51.4185 57.7112 51.4185 57.0661V41.9917C51.4185 41.3465 50.8955 40.8235 50.2503 40.8235C49.6052 40.8235 49.0822 41.3465 49.0822 41.9917Z" stroke="white" stroke-width="3" />
      <path d="M56.5548 47.9737H52.6374H44.0474C43.3077 47.9737 42.7081 48.5733 42.7081 49.313C42.7081 50.0527 43.3077 50.6523 44.0474 50.6523H56.5548C57.2944 50.6523 57.894 50.0527 57.894 49.313C57.894 48.5733 57.2944 47.9737 56.5548 47.9737Z" stroke="white" stroke-width="3" />
    </svg>
  )
}

export default PranchetaIcon
