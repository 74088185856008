import React from "react";
import moment from "moment";
import string from "../../../utils/string";

const AgendamentosPorTipoConsulta = ({ dadosRelatorio, filters }) => {
  const columnColor = (index) => {
    if (index % 2 !== 0) {
      return { backgroundColor: "#F2F2F2" };
    }
    return "";
  };

  const { dataInicio, dataFim, profissionalSaude, status } = filters || {};

  const situacoesName = status.map(situacao => situacao.name).join(", ");

  return (
    <>
      <div style={styles.titulo}>
        Relatório de Agendamentos por tipo consulta
      </div>
      <div style={styles.subTitulo}>
        {dataInicio && (
          <div>
            Data início:
            <span>{moment(dataInicio).format("DD/MM/YYYY")}</span>
          </div>
        )}
        {dataFim && (
          <div>
            Data fim:
            <span>{moment(dataFim).format("DD/MM/YYYY")}</span>
          </div>
        )}
        {profissionalSaude && (
          <div>
            Profissional:
            <span>{profissionalSaude?.nome}</span>
          </div>
        )}
        {status.length > 0 && (
          <div>
            Situações:
            <span>{situacoesName}</span>
          </div>
        )}
      </div>
      <div style={styles.headerColumn}>
        <div style={styles.column}>Nome do Paciente</div>
        <div style={styles.column}>Tipo consulta</div>
        <div style={styles.column}>Profissional</div>
        <div style={styles.column}>Situações</div>
        <div style={styles.column}>Quantidade</div>
      </div>
      <div>
        {dadosRelatorio.map((item, index) => {
          return (
            <div
              key={item.id}
              style={{ ...styles.containerColuna, ...columnColor(index) }}
            >
              <div style={styles.column}>{item.sujeitoAtencaoNome}</div>
              <div style={styles.column}>{item.tipo}</div>
              <div style={styles.column}>{item.profissionalSaudeNome}</div>
              <div style={styles.column}>{string.capitalize(item.situacao)}</div>
              <div style={styles.column}>{item.quantidade}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const styles = {
  titulo: {
    marginLeft: "0px",
    marginBottom: "10px",
    marginTop: "5px",
    paddingBottom: "5px",
    textAlign: "center",
    fontWeight: 700,
  },
  subTitulo: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #505050",
    marginBottom: "15px",
    paddingBottom: "5px",
    fontWeight: 700,
    justifyContent: "center",
    gap: "8px",
  },

  headerColumn: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 700,
    width: "100%",
  },
  containerColuna: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  column: {
    width: "100%",
  },
};

export default AgendamentosPorTipoConsulta;
