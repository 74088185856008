import { Tooltip, withStyles } from "@material-ui/core";
import React, { useRef } from "react";
import {
  Divider,
  InputAdornment,
  CircularProgress,
  Fab,
} from "@material-ui/core";
import { NearMe } from "@material-ui/icons";
import InputSearch from "../../../../components/Input/Input";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { inject } from "mobx-react";
import LocalStorage from "../../../../services/localStorage";
import { getProfissionalSaudeLogado } from "../../../../services/UsuarioService";
import { observer } from "mobx-react-lite";
import ProcedimentoIcon from "../../../../components/Icon/ProcedimentoIcon";
import DocumentTextIcon from "../../../../components/Icon/DocumentTextIcon";

const CaixaEntradaProntuario = observer(
  ({ classes, 
    prontuarioStore,
     scrollMessagesRef,
      eventoGA,
       messagesScrollToBottom ,
       hiddenAnexos,
       handleOpenModalProcedimentos,
       isDocument,
       handleAdicionarDocumento,
       idAgendamento
      }) => {
    const { sendingMessagemProntuario, mensagemProntuario } = prontuarioStore;

    const documentoFileInput = useRef();

    const createEntradaProntuarioTipoTexto = () => {
      
      
      eventoGA("Prontuario", "Entrada prontuario", "Adicionar texto");
      prontuarioStore
        .createEntradaProntuarioTipoTexto({ filter: {}, withLoading: false },
          {},
          idAgendamento
        )
        .then(() => {
          handleChangeMensagemProntuario({ target: { value: "" } });
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const handleChangeMensagemProntuario = (e) => {
            const { value } = e.target;

      getProfissionalSaudeLogado().then((response) => {
        LocalStorage.put(
          `${prontuarioStore.sujeitoAtencaoSelected.id}-${response?.id}`,
          value
        );
      });

      prontuarioStore.mensagemProntuario = value;
    };

    const calcMessagesDiffScroll = () => {
      const { current } = scrollMessagesRef;
      const { scrollHeight, scrollTop, clientHeight } = current;

      const diffScroll = scrollHeight - scrollTop - clientHeight;

      return diffScroll
    };

    const handleEnterMensagemProntuario = (e) => {
      if (e.key === "Enter" && e.shiftKey && e.ctrlKey) {
        e.preventDefault();
        createEntradaProntuarioTipoTexto();
      }

      const diffScroll = calcMessagesDiffScroll();

      if(e.key !== "Enter") return;

      if (diffScroll <= 20) {
        messagesScrollToBottom();
      } else if (        
        diffScroll > 20 &&
        e.target.clientHeight < 296
      ) {
        scrollMessagesRef.current.scrollTop += 19;
      }
    };

    const handlePressAdicionarDocumento = () => {
      eventoGA("Prontuario", "Entrada prontuario", "Adicionar documento");
      const fileInput = documentoFileInput && documentoFileInput.current;

      if (!fileInput instanceof HTMLElement) {
        return;
      }
      fileInput.value = "";
      fileInput.click();
    };

    const handleSelectDocumento = () => {
      const fileInput = documentoFileInput && documentoFileInput.current;

      if (!fileInput instanceof HTMLElement) {
        return;
      }

      const filesArray = Object.values(fileInput.files);
      prontuarioStore.openPreviewDocumento(filesArray);
    };

    const handlePaste = (e) => {
      const { items, files } = e.clipboardData || {};
      const hasFiles = files.length > 0;
      const hasImageItem = Array.from(items).some(x => /^image\//.test(x.type));
    
      if (!hasFiles && !hasImageItem) {
        return;
      }
      
      e.preventDefault();
      if (hasFiles) {
        handleFiles(files);
      } else {
        handleImageItem(items);
      }
    };
    
    const handleFiles = (files) => {
      const filesArray = Object.values(files);
      prontuarioStore.openPreviewDocumento(filesArray);
    };
    
    const handleImageItem = (items) => {
      const imageItem = Array.from(items).find(x => /^image\//.test(x.type));
      const blob = imageItem?.getAsFile() || null;
      prontuarioStore.openPreviewDocumento([blob]);
    };

    const renderAnexos = () => {
      return (
        <InputAdornment
          className={classes.inputMessageAdornment}
          position="start"
        >
          <Tooltip title="Anexar arquivo">
            <Fab
              className={classes.buttonAddInput}
              onClick={handlePressAdicionarDocumento}
              color={"primary"}
            >
              <AttachFileIcon />
            </Fab>
          </Tooltip>
          <input
            ref={documentoFileInput}
            type="file"
            multiple
            className={classes.documentoFileInput}
            onChange={handleSelectDocumento}
          />
        </InputAdornment>
      )
    }
    const renderDocument = () => {
      return (
        <InputAdornment
          className={classes.inputMessageAdornment}
          position="start"
        >
          <Tooltip title="Anexar arquivo">
            <Fab
              className={classes.buttonAddInput}
              onClick={handleAdicionarDocumento}
              color={"primary"}
            >
              <DocumentTextIcon />
            </Fab>
          </Tooltip>
          <input
            ref={documentoFileInput}
            type="file"
            multiple
            className={classes.documentoFileInput}
            onChange={handleSelectDocumento}
          />
        </InputAdornment>)
    }

    const renderAdornment = () => {
      return isDocument ? renderDocument() : renderAnexos()
    }


    return (
      <div className={classes.searchPaciente}>
        <Divider className={classes.divider} />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            createEntradaProntuarioTipoTexto();
          }}
          className={classes.formInput}
        >
          <InputSearch
            onPaste={handlePaste}
            onDrop={(e) => {
              e.preventDefault();
              handleFiles(e.dataTransfer.files);
            }}
            classInputRoot={classes.inputMessageSujeitoAtencaoRoot}
            classInput={classes.inputMessageSujeito}
            onChange={(e) => handleChangeMensagemProntuario(e)}
            value={mensagemProntuario}
            multiline
            onKeyDown={handleEnterMensagemProntuario}
            startAdornment={
              hiddenAnexos ? <div /> : renderAdornment()
            }
            endAdornment={
              <InputAdornment
                className={classes.inputMessageAdornment}
                position="end"
              >
                {sendingMessagemProntuario ? (
                  <CircularProgress color="primary" size={24} />
                ) : (
                  <Tooltip title="enviar">
                  <Fab
                    color={"primary"}
                    type="submit"
                    className={classes.buttonSendInput}
                  >
                    <NearMe
                      color={"primary"}
                      className={classes.buttonSendIcon}
                    />
                  </Fab>
                  </Tooltip>
                )}
              </InputAdornment>
            }
            placeholder="Digite a evolução aqui"
          />
        </form>

        <div />
      </div>
    );
  }
);

const styles = {
  inputMessageSujeitoAtencaoRoot: {
    border: "none",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "flex-end",
  },
  inputMessageSujeito: {
    padding: "15px 0",
    maxHeight: "calc(100vh - 474px) !important",
    fontSize: "medium",
    color: "#3e3e3e",
  },
  inputMessageAdornment: {
    height: 40,
    maxHeight: "none",
    alignItems: "flex-start",
  },
  buttonAddInput: {
    width: "45px",
    height: "45px",
    minHeight: "1px",
    position: "relative",
    top: "-8px",
    boxShadow: "none !important",
    marginRight: 10,
  },
  documentoFileInput: {
    display: "none",
  },
  buttonSendInput: {
    width: "45px",
    height: "45px",
    minHeight: "1px",
    position: "relative",
    top: "-8px",
    boxShadow: "none !important",
  },
  buttonSendIcon: {
    color: "white",
    width: 20,
    height: 20,
  },
};

const CaixaEntradaProntuarioWithStyles = withStyles(styles)(
  CaixaEntradaProntuario
);
export default inject("prontuarioStore")(CaixaEntradaProntuarioWithStyles);
