import moment from "moment";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { CircularProgress, withStyles } from "@material-ui/core";
import { columns } from "../Constants";
import styles from "./ListaProcedimentosStyles";
import {
  findByProcedimentoOdontologicoId,
  inativarProcedimentoOdontologico,
} from "../../../../../services/OdontogramaService";
import Table from "../../../../../components/Table/Table";
import ButtonLong from "../../../../../components/Button/ButtonLong";
import WarningIcon from "../../../../../components/Icon/WarningIcon";
import ModalEditarProcedimento from "../Modal/EditarProcedimento/ModalEditarProcedimento";
import ModalProcedimentosRealizados from "../../../../SujeitoAtencao/Manutencao/ModalProcedimentosRealizados";
import { configuracaoHorarioAtendimentoVigenteOutrosProfissionaisSaude } from "../../../../../services/ConfiguracaoHorarioAtendimentoService";
import { getProfissionalSaudeLogado } from "../../../../../services/UsuarioService";
import Orcamento from "../Orcamento/Orcamento";
import MultiToggleButtons from "../../../../../components/Button/MultiToggleButtons";
import Filters from "./Filters";
import { tabsProcedimentos } from "../../../../../stores/Odontograma.store";

const pageableDTODefault = {
  sortDir: "DESC",
  sortField: "dataHoraLancamento",
};

const ListaProcedimentos = observer(
  ({
    classes,
    odontogramaStore,
    prontuarioStore,
    atendimentoStore,
    usuarioStore,
    history,
  }) => {
    const {
      odontograma,
      listagemProcedimentos,
      modalEditarProcedimento,
      modalProcedimentosRealizados,
      tabSelectedListaProcedimentos,
    } = odontogramaStore;
    const { procedimentosOdontologicos, loading } = listagemProcedimentos;
    const { showModal } = modalEditarProcedimento;
    const { showModalProcedimentosRealizados } = modalProcedimentosRealizados;

    const [ordenarTabela, setOrdenarTabela] = useState(pageableDTODefault);
    const [loadingExcluir, setLoadingExcluir] = useState(false);
    
    const isProntuarioPaciente = history.location.pathname.includes('sujeito-atencao')

    const popperRef = useRef(null);

    useEffect(() => {
      odontograma?.id && loadProcedimentosOdontologicos();
    }, [odontograma, ordenarTabela]);

    useEffect(() => {
      loadProcedimentosOdontologicos();
    }, [tabSelectedListaProcedimentos]);

    const loadProcedimentosOdontologicos = async () => {
      try {
        if(!odontograma?.id) return
        odontogramaStore.modalProcedimento.procedimentos = [];

        await odontogramaStore.findAllProcedimentoOdontologicoOrcado(ordenarTabela, tabSelectedListaProcedimentos === tabsProcedimentos.COM_ORCAMENTO);
      } catch (e) {
        odontogramaStore.openNotification(
          "Erro ao carregar os procedimentos odontológicos",
          "error"
        );
      }
    };

    const openModal = (modal, showModal) => {
      odontogramaStore[modal][showModal] = true;
    };

    const handleAgendar = async (closePopper, id) => {
      try {
        atendimentoStore.isOdontograma = true;
        await odontogramaStore.getListaProcedimentoOdontologico(
          odontograma.id,
          id
        );
        const profissionalLogado = await getProfissionalSaudeLogado();
        const unidadeLogada = await usuarioStore.getUnidadeAtual();

        const responseDuracao = await configuracaoHorarioAtendimentoVigenteOutrosProfissionaisSaude(
          {
            profissionalSaudeId: profissionalLogado.id,
            unidadeId: unidadeLogada.id,
            dataInicial: moment().format("YYYY-MM-DD"),
            dataFinal: moment().format("YYYY-MM-DD"),
          }
        );

        const duracao = responseDuracao?.data?.data?.configuracaoHorarioAtendimentoVigenteOutrosProfissionaisSaude[0]?.duracao

        atendimentoStore.getListaDuracao(duracao);
        const procedimentoOdontologico = await findByProcedimentoOdontologicoId(
          id
        );
        atendimentoStore.open = true;
        atendimentoStore.objView = {
          sujeitoAtencao: prontuarioStore.sujeitoAtencaoSelected,
          data: moment(),
          situacao: "AGENDADO",
          tipo: null,
          horaInicio: moment().format("HH:mm"),
          horaFim: moment().add(duracao, 'minutes').format("HH:mm"),
          duracao: {
            label: `${duracao} min.`,
            value: duracao,
          },
          isConsultaRemota: false,
          observacao: procedimentoOdontologico.observacao,
          procedimentos: [
            {
              procedimento: {
                id: procedimentoOdontologico.procedimento.id,
                nome: procedimentoOdontologico.procedimento.nome,
              },
              procedimentoOdontologico: {
                id: procedimentoOdontologico.id,
              },
            },
          ],
        };

        return closePopper;
      } catch (e) {
        console.error(e);
      }
    };

    const handleEditar = async (closePopper, id) => {
      await odontogramaStore.findByProcedimentoOdontologicoId(id);
      openModal("modalEditarProcedimento", "showModal");
      return closePopper;
    };

    const handleDuplicar = async (closePopper, id) => {
      odontogramaStore.modalEditarProcedimento.isDuplicarProcedimento = true;
      await odontogramaStore.findByProcedimentoOdontologicoId(id);
      openModal("modalEditarProcedimento", "showModal");
      return closePopper;
    };

    const handleOpenModalExcluir = async (closePopper, id) => {
      odontogramaStore.modalConfirmacao = {
        open: true,
        icon: <WarningIcon />,
        color: "red",
        title:
          "Deseja excluir esse procedimento? Essa é uma ação irreversível ",
        buttons: {
          labelPrimaryButton: "Cancelar",
          handlePrimaryButton: () =>
            odontogramaStore.changeCloseModalConfirmacao(),
          labelSecondButton: "Excluir",
          handleSecondButton: () => handleExcluirProcedimento(id),
          colorCustomSecondButton: "red",
          colorCustomPrimaryButton: "transparency",
          disabledPrimaryButton: loadingExcluir,
          disabledSecondButton: loadingExcluir,
        },
      };
      return closePopper;
    };

    const handleExcluirProcedimento = async (id) => {
      try {
        setLoadingExcluir(true);
        await inativarProcedimentoOdontologico(id);

        odontogramaStore.changeCloseModalConfirmacao();
        loadProcedimentosOdontologicos();
        odontogramaStore.openNotification(
          "Procedimento excluído com sucesso!",
          "success"
        );
      } catch {
        odontogramaStore.openNotification(
          "Erro ao excluir o procedimento",
          "error"
        );
      } finally {
        setLoadingExcluir(false);
      }
    };

    const handleClickOrdenar = (value) => {
      const sortDir =
        ordenarTabela.sortField !== value
          ? "ASC"
          : ordenarTabela.sortDir === "ASC"
          ? "DESC"
          : "ASC";
      setOrdenarTabela({
        sortDir: sortDir,
        sortField: value,
      });
    };

    const showButton = (row) => {
      const { id, status } = row;
      const enabledStatus = status === "APROVADO" || status === "EM_ANDAMENTO";

      return (
        <ButtonLong
          colorCustom="green"
          onClick={() => handleRealizar(id)}
          disabled={isProntuarioPaciente || !enabledStatus }
          noShadow
        >
          Realizar
        </ButtonLong>
      );
    };

    const handleRealizar = async (id) => {
      const procedimentoOdontologico = await findByProcedimentoOdontologicoId(id);

      odontogramaStore.modalProcedimentosRealizados = {
        showModalProcedimentosRealizados: true,
        procedimentoRealizado: {
          agendamentoId: procedimentoOdontologico.agendamento?.id,
          agendamentoProcedimentoId: procedimentoOdontologico.agendamentoProcedimentoId,
          procedimento: {
            id: procedimentoOdontologico.procedimento?.id,
          },
          procedimentoOdontologico: {
            id: id,
          },
        },
      };
    };

    const handleCloseModalRealizarProcedimento = () => {
      odontogramaStore.handleCloseModalRealizarProcedimento();
    };

    const changeTabSelected = (tabSelected) => {
      odontogramaStore.tabSelectedListaProcedimentos = tabSelected;
    };

    return (
      <div className={classes.content}>
        <div className={classes.contentHeader}>
          <div className={classes.contentHeaderButton}>
            <header className={classes.title}> Procedimentos </header>
            <MultiToggleButtons
              options={["Sem orçamento", "Com orçamento"]}
              tabSelected={tabSelectedListaProcedimentos}
              changeTabSelected={changeTabSelected}
            />
          </div>
          <Orcamento history={history} tabSelected={tabSelectedListaProcedimentos}/>
        </div>
        {
          tabSelectedListaProcedimentos === tabsProcedimentos.COM_ORCAMENTO && (
           <Filters />
          )
        }
        <div className={classes.contentTable}>
          {!loading && procedimentosOdontologicos.length === 0 && (
            <div className={classes.notFoundContainer}>
              <h3>Nenhum item encontrado</h3>
            </div>
          )}
          {procedimentosOdontologicos.length > 0 && (
            <Table
              dados={procedimentosOdontologicos}
              columns={columns({
                popperRef: popperRef,
                functions: {
                  handleAgendar: handleAgendar,
                  handleEditar: handleEditar,
                  handleDuplicar: handleDuplicar,
                  handleExcluir: handleOpenModalExcluir,
                  showButton: showButton,
                },
                isTabComOrcamento: tabSelectedListaProcedimentos === tabsProcedimentos.COM_ORCAMENTO,
              })}
              comOrdenacao
              ordenarTabela={ordenarTabela}
              handleClickOrdenar={handleClickOrdenar}
              classes={{
                table: classes.table,
                tableHead: classes.tableHeader,
              }}
            />
          )}
          {loading && procedimentosOdontologicos.length === 0 && (
            <div className={classes.notFoundContainer}>
              <CircularProgress />
            </div>
          )}
        </div>
        {showModal && <ModalEditarProcedimento />}
        {showModalProcedimentosRealizados && (
          <ModalProcedimentosRealizados
            handleClose={handleCloseModalRealizarProcedimento}
            idAgendamento={prontuarioStore?.agendamentoSelected?.id}
            idSujeitoAtencao={prontuarioStore?.sujeitoAtencaoSelected?.id}
            showModal={showModalProcedimentosRealizados}
            isOdontograma
            isAtendimentoProntuario={true}
            loadProcedimentosOdontologicos={loadProcedimentosOdontologicos}
            pageTitle="Atendimento - Odontograma Realizar procedimentos"
          />
        )}
      </div>
    );
  }
);

const ListaProcedimentosWithStyles = withStyles(styles)(ListaProcedimentos);
export default inject(
  "odontogramaStore",
  "prontuarioStore",
  "atendimentoStore",
  "usuarioStore"
)(ListaProcedimentosWithStyles);
