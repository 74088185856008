import React from "react";
import VisibilityIcon from "../../../components/Icon/VisibilityIcon";
import moment from "moment";
import { Button } from "../../../components/ui/Buttons";
import { Checkbox } from "@material-ui/core";

export const columns = ({ handleVisualizar }) => [
  {
    Header: "CPF",
    align: "left",
    getValue: (duplicado) => duplicado.documento,
  },
  {
    Header: "Quantidade",
    align: "left",
    getValue: (duplicado) => duplicado.total,
  },
  {
    Header: "Ações",
    align: "left",
    getValue: (duplicado) => (
      <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
        <Button
          bgColor="#F9BE73"
          shape="circle"
          onClick={() => handleVisualizar(duplicado)}
        >
          <VisibilityIcon />
        </Button>
      </div>
    ),
    style: { width: "100px", textAlign: "center" },
  },
];

export const columnsModal = ({ changeCheckbox }) => [
  {
    Header: "Nome",
    getValue: (pacienteDuplicado) => pacienteDuplicado.nome,
  },
  {
    Header: "Data nascimento",
    getValue: (pacienteDuplicado) =>
      pacienteDuplicado.dataNascimento
        ? moment(pacienteDuplicado.dataNascimento).format("DD/MM/YYYY")
        : "",
  },
  {
    Header: "Data criação",
    getValue: (pacienteDuplicado) =>
      moment(pacienteDuplicado.dataCriacao).format("DD/MM/YYYY HH:mm"),
  },
  {
    Header: "Cadastro principal",
    getValue: (pacienteDuplicado) => (
      <Checkbox
        checked={pacienteDuplicado.principal}
        color="primary"
        onChange={() => changeCheckbox(pacienteDuplicado)}
      />),
      style: { width: "80px", textAlign: "center" },
  },
];
