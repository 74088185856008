import { Tooltip, withStyles } from '@material-ui/core';
import React from 'react';
import { inject } from 'mobx-react';
import { Fab } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import Dialog from '../../../components/Dialog/Dialog';

import { findByIdSujeitoAtencaoAtendimento } from '../../../services/SujeitoAtencaoService';
import { findByIdProcedimentoRealizado,  createProcedimentoRealizadoInternacao } from '../../../services/ProcedimentosRealizadosService';
import ItemListArquivos from '../../../components/List/ItemListArquivos';
import Colors from '../../../template/Colors';
import { InputForm } from '../../../components/Input/InputDadosCadastraisPaciente';
import enviarArquivo from '../../../assets/img/svg/enviar-arquivo.svg';
import ItemListArquivosEnviar from '../../../components/List/ItemListArquivosEnviar';
import { saveProcedimentoRealizado } from '../../../services/ProcedimentosRealizadosService';
import localStorageService, { ACCESS_TOKEN_KEY } from '../../../services/storage';
import { buildUrlDownload } from "../../../config/config";
import { withRouter } from "react-router";
import { getProfissionalSaudeLogado } from '../../../services/UsuarioService';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { Button } from '../../../components/ui/Buttons';
import HeaderInfosPaciente from '../../../components/HeaderInfosPaciente';
import SuccessIcon from '../../../components/Icon/SuccessIcon';
import ModalConfirmacao from '../../../components/Modal/ModalConfirmacao/ModalConfirmacao';
import { adicionarAgendamentoProcedimento } from '../../../services/OdontogramaService';


@inject(
  'sujeitoAtencaoStore',
  'atendimentoStore',
  'googleAnalyticsStore',
  'extratoStore',
  "filtroHeaderStore",
  "prontuarioStore",
  "odontogramaStore",
  )
class ModalProcedimentosRealizados extends React.Component {
  state = {
    sujeitoAtencao: {
      nome: '',
      dataNascimento: '',
      dadosConvenio: {
        convenio: {
          descricao: '',
        },
      },
      contato: {
        telefonePrincipal: '',
      },
      fotoPerfil: '',
    },
    observacao: '',
    listaArquivos: [],
    observacoesTecnico: '',
    tipoProfissionalLogado: '',
    profissionalRealizouId: '',
    jaFoiRealizado: true,
    idProfissionalLogado: '',
    listaArquivosTecnico: [],
    nomeArquivoTecnico: '',
    descricaoArquivoTecnico: '',
    arquivoModificado: null,
    arquivo: null,
    fileDownloadUrlTecnico: '',
    nomeExtensaoDownload: '',
    abrirArquivo: '',
    salaId: '',
    procedimentoId: '',
    urlDownload: '',
    podeRealizar: false,
    procedimentoRealizado: {},
    loadingRealizando: false,
    openModalConfirmacao: false,
  };

  constructor(props) {
    super(props);
    this.documentoFileInput = React.createRef();
  }

  async componentDidMount() {
    const { idSujeitoAtencao, idProcedimentoRealizado, isAtendimentoProntuario} = this.props;
    const profissionalSaudeLogado = await getProfissionalSaudeLogado();
    if (isAtendimentoProntuario) {
      this.props.atendimentoStore.loadAgendamento(this.props.idAgendamento)
    }
    this.setState({idProfissionalLogado: profissionalSaudeLogado.id})

    this.tipoProfissional(profissionalSaudeLogado.tipo);

    this.infosSujeitoAtencao(idSujeitoAtencao);
    this.infosProcedimentoRealizado(idProcedimentoRealizado);
  }

  tipoProfissional = (tipoProfissional) => {
    switch (tipoProfissional) {
      case 1:
        this.setState({ tipoProfissionalLogado: 'medico', podeRealizar: true });
        break;
      case 2:
        this.setState({ tipoProfissionalLogado: 'secretario', podeRealizar: true });
        break;
      case 3:
        this.setState({ tipoProfissionalLogado: 'administrador', podeRealizar: true});
        break;
      case 4:
        this.setState({ tipoProfissionalLogado: 'tecnico', podeRealizar: true });
        break;
      default:
        break;
    }
  };

  infosSujeitoAtencao = async (id) => {
    await findByIdSujeitoAtencaoAtendimento(id).then((response) => {
      const sujeitoAtencao = response.data.data.findByIdSujeitoAtencao;
      this.setState({
        sujeitoAtencao: sujeitoAtencao,
      });
    });
  };

  infosProcedimentoRealizado = async (id) => {
    const { procedimentoSelecionado } = this.props.atendimentoStore;

    if(id) {
      return await findByIdProcedimentoRealizado(id).then((response) => {
        const procedimentoRealizado =
          response.data.data.findByIdProcedimentoRealizado;
        this.setState({
          observacoesTecnico: procedimentoRealizado.descricao,
          nomeProcedimento: procedimentoRealizado.procedimento.nome,
          observacao: procedimentoRealizado.descricao,
          listaArquivos:
            procedimentoRealizado.procedimentoRealizadoAmazonS3ObjetosAtivos,
          salaId: procedimentoRealizado?.sala?.id || null,
          procedimentoId: procedimentoRealizado.procedimento.id,
          urlDownload: procedimentoRealizado.urlDownload,
          profissionalRealizouId: procedimentoRealizado.profissionalSaude.id,
          jaFoiRealizado: true,
          procedimentoRealizado
        });
      });
    }else{
      this.setState({jaFoiRealizado: false})
    }

    if(procedimentoSelecionado) {
      this.setState({
        nomeProcedimento: procedimentoSelecionado.nome,
        observacao: procedimentoSelecionado.descricao,
        
      });
    }
  };

  baixarArquivo = async (item) => {
    const token = await localStorageService.get(ACCESS_TOKEN_KEY);
    const printData = buildUrlDownload(this.state.urlDownload + item?.entradaProntuarioAmazonS3Objeto?.id, token);
    window.open(printData, "_blank");
  };

  handleRemoveArquivo = async (item) => {
    const {prontuarioStore, idProcedimentoRealizado} = this.props;
    try{
      await excluirArquivoProcedimentoRealizado({
        entradaProntuarioAmazonS3ObjetId: item?.entradaProntuarioAmazonS3Objeto?.id
      });
      await this.infosProcedimentoRealizado(idProcedimentoRealizado);
      prontuarioStore.openNotification('Arquivo excluído com sucesso', 'success');
    } catch (e) {
      prontuarioStore.openNotification('Erro ao excluir arquivo', 'error');
      console.error(e);
    }
  };

  onObservacaoChange = (e) => {
    const { value } = e.target;
    this.setState({ observacoesTecnico: value });
  };

  handleClickSalvar = async () => {
    try{
    const { atendimentoStore, isModalSala, isProntuario, prontuarioStore, odontogramaStore, isAtendimentoProntuario, isOdontograma, idProcedimentoRealizado, idAgendamento } = this.props;
    const { modalProcedimentosRealizados } = odontogramaStore;
    const { procedimentoRealizado } = modalProcedimentosRealizados;
    const { isModalInternacao,objView}=atendimentoStore

    const { listaArquivosTecnico, observacoesTecnico } = this.state;
   
    const arquivosAdaptados = listaArquivosTecnico.map(item => item.arquivoAdaptado);
    const solicitante =  atendimentoStore.idSolicitanteDoProcedimento?.id && { id: atendimentoStore.idSolicitanteDoProcedimento?.id }
    const agendamentoProcedimentoWithControleSessaoProcedimento = atendimentoStore?.objView?.procedimentos?.find(item => item?.controleSessaoProcedimento !== null);

    const { controleSessaoProcedimento } = agendamentoProcedimentoWithControleSessaoProcedimento || {}
    let procedimentoRealizadoAdaptado = {
      procedimentoRealizado: {
        id: idProcedimentoRealizado,
        solicitante,
        descricao: observacoesTecnico,
        ...(controleSessaoProcedimento && {
          controleSessaoProcedimento: {
            id: controleSessaoProcedimento?.id
          }
        }),
        procedimento: {
          id: this.props.atendimentoStore.procedimentoSelecionado.id || this.state.procedimentoId || procedimentoRealizado.procedimento?.id,
        },
        ...(!!isOdontograma && {procedimentoOdontologico: {
          id: procedimentoRealizado.procedimentoOdontologico.id
        }}),
        sala: {
          id: !isModalSala ? (this.state.salaId || null) : (this.state.salaId || atendimentoStore.idSalaSelecionada || null) 
        },
        sujeitoAtencao: {
          id: this.props.idSujeitoAtencao,
        },
      },
      objetosAmazonS3DTO: arquivosAdaptados
    };

    this.setState({loadingRealizando: true})

      if (isModalInternacao) {
        await createProcedimentoRealizadoInternacao({ ...procedimentoRealizadoAdaptado, agendamentoId: objView.id })
      } else {
        await saveProcedimentoRealizado(procedimentoRealizadoAdaptado);
      }
      if (isProntuario ){
      await prontuarioStore.findAllEntradaProntuario();
    }

    const procedimentoSelecionado = atendimentoStore.objView.procedimentos.find(item => item.procedimento.id === (atendimentoStore.procedimentoSelecionado.id || this.state.procedimentoId));
    const indexProcedimentoSelecionado = atendimentoStore.objView.procedimentos.indexOf(procedimentoSelecionado);    
    !isOdontograma && atendimentoStore.objView.procedimentos.splice(indexProcedimentoSelecionado, 1, {...atendimentoStore.objView.procedimentos[indexProcedimentoSelecionado], realizado: true});
    await adicionarAgendamentoProcedimento({ 
      agendamento: {id: idAgendamento},
      agendamentoProcedimento: {
        procedimento: {
          id: this.props.atendimentoStore.procedimentoSelecionado.id || this.state.procedimentoId || procedimentoRealizado.procedimento?.id,
        },
        ...(!!isOdontograma && {procedimentoOdontologico: {
          id: procedimentoRealizado.procedimentoOdontologico.id
        }}),
        realizado: true,
      }
    });
     !!isOdontograma ? this.finalizaRealizarProcedimentoOdontologico() : this.setState({openModalConfirmacao: true})
  }catch(err){
    const {prontuarioStore}=this.props
    prontuarioStore.openNotification('Erro ao Salvar!', 'error');
    console.log('err', err)
      this.setState({openModalConfirmacao: false})
  } finally {
    this.setState({loadingRealizando: false})
  };
};

finalizaRealizarProcedimentoOdontologico = async() => {
  const{ loadProcedimentosOdontologicos } = this.props;
  await loadProcedimentosOdontologicos();
  this.handleClickCancelar()
};

  handleClickCancelar = () => {
    this.setState({
      listaArquivosTecnico: [],
      observacoesTecnico: '',
    });

    this.props.handleClose();
  };

  handleCloseModalConfirmacao = () => {
    const { handleClose, prontuarioStore, handleCloseSolicitacaoModal} = this.props;
    this.setState({ openModalConfirmacao: false });
    handleClose();
    if(handleCloseSolicitacaoModal && typeof handleCloseSolicitacaoModal === 'function'){
      handleCloseSolicitacaoModal();
    }
    prontuarioStore.openNotification('Procedimento realizado com sucesso!', 'success');
  };

  handleRealizarNovoProcedimento = () => {
    const { handleClose, handleOpenModalSolicitacao} = this.props;
    if(handleOpenModalSolicitacao && typeof handleOpenModalSolicitacao === 'function'){
      handleClose()
      handleOpenModalSolicitacao()
    }else{
      handleClose()
    }
  }

  handleClickEnviarArquivos = () => {
    const fileInput =
      this.documentoFileInput && this.documentoFileInput.current;

    if (!fileInput instanceof HTMLElement) {
      return;
    }
    fileInput.value = '';
    fileInput.click();
  };

  handleSelectDocumento = async (e) => {
    const fileInput =
      this.documentoFileInput && this.documentoFileInput.current;

    if (!fileInput instanceof HTMLElement) {
      return;
    }
    let url = '';
    const fileReader = new FileReader();
    fileReader.onloadend = function() {
      url = fileReader.result;
    };
    fileReader.readAsDataURL(fileInput.files[0]);

    const base64 = await this.getBase64FromFile(fileInput.files[0]);
    const arquivo = {
      arquivoAdaptado: {
        nome: fileInput.files[0].name.substr(
          0,
          fileInput.files[0].name.indexOf('.'),
        ),
        image: fileInput.files[0].type.indexOf('image') !== -1,
        extensao: fileInput.files[0].type.substring(
          fileInput.files[0].type.indexOf('/') + 1,
        ),
        base64: base64,
      },
      url: url,
    };
    this.setState({
      listaArquivosTecnico: [...this.state.listaArquivosTecnico, arquivo],
    });
  };

  getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => resolve(window.btoa(reader.result));
      reader.onerror = (error) => reject(error);
    });
  }

  onNomeChange = (e, index) => {
    const { listaArquivosTecnico } = this.state;
    const { value } = e.target;

    let arquivos = [...listaArquivosTecnico];
    let arquivo = { ...arquivos[index] };
    arquivo.arquivoAdaptado.nome = value;
    arquivos[index] = arquivo;
    this.setState({ listaArquivosTecnico: arquivos });

  };

  onDescricaoChange = (e, index) => {
    const { listaArquivosTecnico } = this.state;
    const { value } = e.target;

    let arquivos = [...listaArquivosTecnico];
    let arquivo = { ...arquivos[index] };
    arquivo.arquivoAdaptado.descricao = value;
    arquivos[index] = arquivo;
    this.setState({ listaArquivosTecnico: arquivos });
  };

  baixarArquivoTecnico = async (item) => {
    await this.setState({
      downloadArquivo: item.url,
      nomeExtensaoDownload: `${item.arquivoAdaptado.nome}.${item.arquivoAdaptado.extensao}`,
    });

    this.dofileDownload.click();
  };

  handleRemoveArquivoTecnico = (index) => {
    const { listaArquivosTecnico } = this.state;
    let arquivos = [...listaArquivosTecnico];
    arquivos.splice(index, 1);
    this.setState({ listaArquivosTecnico: arquivos });
  };

  handleClickEditSujeitoAtencao = () => {
		try {
			const { history, sujeitoAtencaoStore, idSujeitoAtencao } = this.props;
			if (idSujeitoAtencao) {
				sujeitoAtencaoStore.reset();
				sujeitoAtencaoStore.changeId(idSujeitoAtencao);
				return history.push(`/sujeito-atencao/edit/${idSujeitoAtencao}`);
			}
		} catch (error) {
			console.error(error);
		}
	};

  handleClickRealizarProcedimento = () => {
    const {handleOpenModalSolicitacao, handleClose} = this.props;
    if(handleOpenModalSolicitacao && typeof handleOpenModalSolicitacao === 'function'){
      handleClose()
      handleOpenModalSolicitacao()
    }else{
      handleClose()
    }
  };


  render() {
    const { classes, showModal, handleClose, loadingEnviarArquivos, pageTitle } = this.props;
    const {
      sujeitoAtencao,
      listaArquivos,                                                                                
      observacao,
      jaFoiRealizado,
      profissionalRealizouId,
      idProfissionalLogado,
      listaArquivosTecnico,
      openModalConfirmacao,
      podeRealizar,
      nomeProcedimento
    } = this.state;
    const isNomeProcedimentoGrande = nomeProcedimento && nomeProcedimento?.length > 45;

    const modalConfirmacaoProps = {
      colorCustomPrimaryButton: "green",
      colorCustomSecondButton: "transparencyHover",
      flexDirection: "column",
      labelSecondButton: "Ir para o prontuário",
      handleSecondButton: () => this.handleCloseModalConfirmacao(),
      labelPrimaryButton: "Realizar outro procedimento",
      handlePrimaryButton: () => this.handleRealizarNovoProcedimento(),
      disabledSecondButton: false,
    }
  
    return (
      <>
      <PageTitle title={pageTitle} />
      <Dialog
        classes={{
          scrollPaper: classes.scrollPaper,
          paper: classes.paper,
        }}
        maxWidth="md"
        open={showModal}
        fullWidth
      >
        <div className={classes.conteudoEsquerdo}>
          <HeaderInfosPaciente 
            sujeitoAtencao={sujeitoAtencao}
            handleClickEditSujeitoAtencao={() => this.handleClickEditSujeitoAtencao()}
          />
          <div className={classes.observacoes}>
            { isNomeProcedimentoGrande ? 
            <Tooltip title={nomeProcedimento} placement="top-start">
              <div className={classes.nomeProcedimento}>{nomeProcedimento}</div>
            </Tooltip>
            :
            <div className={classes.nomeProcedimento}>{nomeProcedimento}</div>
          }
            <div className={classes.spanObservacoes}>Observações:</div>

            {podeRealizar && (!jaFoiRealizado || profissionalRealizouId === idProfissionalLogado) ? (
              <div className={classes.boxObservacoesTecnico}>
                <InputForm
                  id="observacoes"
                  placeholder="Digite as observações do procedimento."
                  value={this.state.observacoesTecnico}
                  className={classes.inputObservacoes}
                  rowsMax={14}
                  rows={14}
                  multiline
                  onChange={(e) => this.onObservacaoChange(e)}
                />
                <div className={classes.buttons}>
                  <Button     
                  onClick={this.handleClickSalvar}
                  disabled={this.state.loadingRealizando}
                  >
                    Salvar
                  </Button>
                  <Button 
                  disabled={this.state.loadingRealizando}
                  onClick={this.handleClickCancelar}
                  kind='transparent'
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
                ) : (
                  <div className={classes.boxObservacoes}>{observacao}</div>
                )}
              </div>
        </div>
        <div className={classes.conteudoDireito}>
          <div className={classes.conteudoSemBotao}>
            <Fab className={classes.buttonClose} 
            disabled={this.state.loadingRealizando}
            onClick={handleClose}>
              <CloseIcon />
            </Fab>
            <div className={classes.spanArquivos}>Arquivos</div>
            <div className={classes.listaArquivosVisualizado}>
            {podeRealizar && jaFoiRealizado && profissionalRealizouId !== idProfissionalLogado ? (
              <>
                {listaArquivos.map((item) => {
                  return (
                    <ItemListArquivos
                      item={item}
                      urlDownload={this.state.urlDownload}
                      handleRemoveArquivo={() => this.handleRemoveArquivo(item)}
                      nomeArquivo={item.entradaProntuarioAmazonS3Objeto.nome}
                      baixarArquivo={() => this.baixarArquivo(item)}
                      observacao={item.descricao}
                    />
                  );
                })}
              </>
              ):(
                <div className={classes.listaArquivosTecnico}>
                  {listaArquivos.map((item) => {
                  return (
                    <ItemListArquivos
                      item={item}
                      urlDownload={this.state.urlDownload}
                      nomeArquivo={item.entradaProntuarioAmazonS3Objeto.nome}
                      handleRemoveArquivo={() => this.handleRemoveArquivo(item)}
                      baixarArquivo={() => this.baixarArquivo(item)}
                      observacao={item.descricao}
                    />
                  );
                })}
                {listaArquivosTecnico.map((item, index) => {
                  return (
                    <>
                      <ItemListArquivosEnviar
                        item={item}
                        nomeArquivo={item.arquivoAdaptado.nome}
                        descricao={item.arquivoAdaptado.descricao || ''}
                        onNomeChange={(e) => this.onNomeChange(e, index)}
                        onDescricaoChange={(e) =>
                          this.onDescricaoChange(e, index)
                        }
                        handleRemoveArquivo={() => this.handleRemoveArquivoTecnico(index)}
                        baixarArquivo={() => this.baixarArquivoTecnico(item)}
                      />
                    </>
                  );
                })}
                </div>
              )}
          </div>
          </div>
          {podeRealizar && (!jaFoiRealizado || profissionalRealizouId === idProfissionalLogado) ?  (
            <div
              className={classes.botaoEnviarArquivos}
              onClick={!loadingEnviarArquivos ? this.handleClickEnviarArquivos : undefined}
            >
              <img
                className={classes.enviarArquivoIcon}
                src={enviarArquivo}
                alt={'Enviar arquivo'}
              />
              <div className={classes.spanEnviarArquivos}>Enviar arquivos</div>
            </div>
            ): null}
          <input
            ref={this.documentoFileInput}
            type="file"
            className={classes.documentoFileInput}
            onChange={(e) => this.handleSelectDocumento(e)}
          />
          <a
            style={{ display: 'none' }}
            download={this.state.nomeExtensaoDownload}
            href={this.state.downloadArquivo}
            ref={(e) => (this.dofileDownload = e)}
          >
            download it
          </a>
          <a
            style={{ display: 'none' }}
            href={this.state.abrirArquivo}
            target="_blank"
            rel="noopener noreferrer"
            ref={(e) => (this.dofileAbrir = e)}
          >
            Download Pdf
          </a>
        </div>
      </Dialog>
      <ModalConfirmacao
        open={openModalConfirmacao}
        icon={<SuccessIcon color="white" />}
        title={'Procedimento realizado com sucesso!'}
        color={"green"}
        buttons={modalConfirmacaoProps}
        />
      </>
    );
  }
}

const styles = () => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 15,
    overflow: 'unset',
    height: 630,
  },
  conteudoEsquerdo: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px 0px 0px 10px',
    width: '60%',
    height: '100%',
  },
  image: {
    justifyContent: 'center',
  },
  imageMini:{
    borderRadius: '30px',
  },
  infoPaciente: {
    height: 160,
    padding: 20,
    boxShadow:
      '10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)',
  },
  tituloPaciente: {
    fontSize: '24px',
    fontWeight: 700,
    color: '#219A97',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  conteudoDireito: {
    width: '40%',
    background: '#219A97',
    borderRadius: '0px 10px 10px 0px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonClose: {
    color: '#219A97',
    height: 30,
    width: 35,
    position: 'absolute',
    right: '25px',
    top: '22px',
  },
  spanArquivos: {
    fontSize: 18,
    color: '#fff',
    margin: '25px 0 20px 23px',
    fontWeight: 600,
  },
  inline: {
    display: 'inline-flex',
  },
  observacoes: {
    padding: '0 1rem',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 240px)',
  },
  spanObservacoes: {
    color: '#868686',
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 4,
  },
  boxObservacoes: {
    background: '#F2F2F2',
    borderRadius: '13px',
    height: '100%',
    padding: '10px 18px',
  },
  boxObservacoesTecnico: {
    background: '#F2F2F2',
    borderRadius: '13px',
    height: 'calc(100% - 120px)',
  },
  nomeProcedimento: {
    color: '#33333',
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 16,
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',              
    textOverflow: 'ellipsis',
  },
  btnAddEditMini: {
		minHeight: 24,
		height: 24,
		width: 24,
		backgroundColor: "#868686",
		boxShadow: "none",
		color: "#DADADA",
		borderRadius: "50%",

		"& svg": {
			fontSize: "18px",
			color: "#FFFFFF",
		},
	},
  inputObservacoes: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  botaoEnviarArquivos: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#F9BE73',
    color: '#fff',
    margin: '10px 25px 15px',
    cursor: 'pointer',
    borderRadius: '50px',
    maxHeight: '42px',
    minHeight: 42,
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      background: Colors.commons.darkYellow,
    },
  },
  spanEnviarArquivos: {
    marginLeft: 10,
    fontSize: '18px',
  },
  enviarArquivoIcon: {
    height: 20,
    width: 20,
  },
  listaArquivosTecnico: {
    height: '88%',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '8%',
    '& button': {
      flex: 1,
    },
  },
  documentoFileInput: {
    display: 'none',
  },
  conteudoSemBotao: {
    height: 'calc(100% - 60px)',
  },
  listaArquivosVisualizado:{
    overflowY: 'auto',
    height: 'calc(100% - 85px)',
  },
});



export default withRouter(withStyles(styles)(ModalProcedimentosRealizados));