import DeleteIcon from "../../../../../components/Icon/DeleteIcon";
import { Button } from "../../../../../components/ui/Buttons";
import { ContentButtonsAcao } from "../../styles";
import React from "react";

export const columnsOutrasDespesas = ({
  handleDelete,
  handleEdit,
}) => {
  return [
    {
      Header: "Produto",
      align: "start",
      getValue: (data) => {
        return data?.produto?.nome || "";
      },
    },
    {
      Header: "Tipo",
      align: "start",
      getValue: (data) => {
        return data?.produto?.tipo || "";
      },
    },
    {
      Header: "Ação",
      align: "start",
      getValue: (data) => {
        return (
          <ContentButtonsAcao>
            <Button
              shape="circle"
              bgColor="#FB7676"
              onClick={() => handleDelete(data)}
              style={{ height: "30px", width: "30px" }}
            >
              <DeleteIcon size={14} />
            </Button>
          </ContentButtonsAcao>
        );
      },
    },
  ];
};