import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, withStyles } from "@material-ui/core";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import HeaderRelatorio from "../../HeaderRelatorio";
import Table from "../../../../components/Table/Table";
import { findAllMunicipio, relatorioUltimasConsultas, relatorioUltimasConsultasCsv } from "../../../../services/RelatorioService";
import { columns, filtersDefault, periodos } from "./constants";
import PrintIcon from "../../../../components/Icon/Print";
import { Button } from "../../../../components/ui/Buttons";
import ImpressaoHtml from "../../../../components/Impressao/ImpressaoHtml";
import UltimosAtendimentos from "../../../../template/pdf/relatorio/UltimosAtendimentos";
import { ReactSelect } from "../../../../components/Select/SelectSearch";
import Notification from "../../../../components/Notification";
import ArrowDownloadIcon from "../../../../components/Icon/ArrowDownload";
import { base64Convert } from "../../../../utils/base64ToCsv";
import { TextFieldSearch } from "../../../../components/TextField";
import { findAllProfissionalSaude } from "../../../../services/ProfissionalSaudeService";
import { findAllConvenio } from "../../../../services/OdontogramaService";
import Scroll from "../../../../components/InfiniteScroll/Scroll";
import ErrorCollector from "../../../../utils/ErrorCollector";
import MensagemListaVazia from "../../../../components/Mensagem/MensagemListaVazia";
import { inject } from "mobx-react";

const RelatorioUltimosAtendimentos = ({ classes, relatorioStore }) => {

  const [ultimosAtendimentos, setUltimosAtendimentos] = useState([]);
  const [ultimosAtendimentosPrint, setUltimosAtendimentosPrint] = useState([]);
  const [filters, setFilters] = useState(filtersDefault);
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [isPrintMustache, setIsPrintMustache] = useState(false);
  const [hasMore, setHasMore] = useState(false)
  const [pageNumber, setPageNumber] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [nomeSujeitoAtencao, setNomeSujeitoAtencao] = useState('')
  const [totalElements, setTotalElements] = useState(0);

  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
  });
  const { utilizaTelefoneInternacional, mostrarApenasProfissionaisAtivo } = relatorioStore.configuracaoUnidade || {};

  const { periodo, convenio, profissional, cidade } = filters || {};

  const loadProfissionaisSaude = async (search, loadedOptions, { page }) => {
    try {
      const { content, last } = (
        await findAllProfissionalSaude({
          pageNumber: page,
          search,
          ...(mostrarApenasProfissionaisAtivo && {ativo: mostrarApenasProfissionaisAtivo}),
        })
      ).data.data.findAllProfissionalSaude
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      console.error(error)
    }
  }

  const loadMunicipio = async (search, loadedOptions, { page }) => {
    const pageableDTO = {
      pageNumber: page,
      pageSize: 10,
      sortDir: "ASC",
      sortField: "nome",
    };
    const searchDTO = {
      ...pageableDTO,
      search,
    };
    try {
      const { content, last } = await findAllMunicipio(searchDTO);
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error(error);
    }

  };

  const loadConvenios = async (search, loadedOptions, { page }) => {
    const pageableDTO = {
      pageNumber: page,
      pageSize: 10,
      sortDir: "ASC",
      sortField: "id",
    };
    const searchDTO = {
      ...pageableDTO,
      ativo: true,
      search,
    };

    try {
      const { content, last } = await findAllConvenio(searchDTO);
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error(error);
    }
  };

  const loadUltimosAtendimentos = async (options) => {
    if (isLoading) {
      return
    }

    const {
      convenio,
      profissional,
      cidade
    } = filters
    setIsLoading(true)

    const variables = {
      pageableDTO: {
        pageNumber: options?.isClearable ? 0 : pageNumber,
        pageSize: 30,
      },
      periodo: periodo?.value,
      convenioId: convenio?.id,
      profissionalId: profissional?.id,
      municipioId: cidade?.id,
      search: options?.clearNomeSujeitoAtencao ? "" : nomeSujeitoAtencao,
    }
    try {
      const response = await relatorioUltimasConsultas(variables)
      const { content, last, totalElements } = response || {}
      setUltimosAtendimentos(prevState => {
        return options?.isClearable ? content : [...prevState, ...content]
      })
      setTotalElements(totalElements)
      setHasMore(!last)
      setPageNumber(pageNumber + 1)
      return response
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }


  const onClickPesquisar = async () => {
    await loadUltimosAtendimentos({ isClearable: true });
  };

  const handleClickClearPesquisar = async () => {
    setNomeSujeitoAtencao("");
    loadUltimosAtendimentos({ isClearable: true, clearNomeSujeitoAtencao: true });
  };

  const handleSearchChange = (e) => {
    setNomeSujeitoAtencao(e.target.value);
  };

  const onKeypressSearch = async (e) => {
    if (e.key === "Enter") {
      await loadUltimosAtendimentos({ isClearable: true });
    }
  };

  const handleFilters = (value, field) => {
    setFilters({ ...filters, [field]: value });
  };

  const handlePrint = async() => {
    setLoadingPrint(true);

    const variables = {
      pageableDTO: {
        pageNumber: 0,
        pageSize: totalElements,
      },
      periodo: periodo?.value,
      convenioId: convenio?.id,
      profissionalId: profissional?.id,
      municipioId: cidade?.id,
      search: nomeSujeitoAtencao,
    }

    const response = await relatorioUltimasConsultas(variables);

    setUltimosAtendimentosPrint(response.content)
    setIsPrintMustache(true);
    setLoadingPrint(false);
  };

  const showAlertMessage = (notification) => {
    setNotification(notification);

    const timeoutId = setTimeout(() => {
      closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  const closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
    };
    setNotification(notification);
  };

  const relatorioCsv = async () => {
    const {
      convenio,
      profissional,
      cidade
    } = filters
    try {
      const relatorioCsv = await relatorioUltimasConsultasCsv({
        periodo: periodo?.value,
        convenioId: convenio?.id,
        profissionalId: profissional?.id,
        municipioId: cidade?.id,
        search: nomeSujeitoAtencao,
        ...(mostrarApenasProfissionaisAtivo && {ativo: mostrarApenasProfissionaisAtivo}),
      });

      return relatorioCsv;

    } catch (error) {
      showAlertMessage({
        isOpen: true,
        variant: "error",
        message: "Erro ao realizar o download"
      })
    }
  };

  const getCsv = async () => {
    const base64 = await relatorioCsv();
    if (base64 !== "error") {
      const blob = base64Convert(base64);
      const url = window.URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "relatorioAgendamentosRepasse.csv");
      document.body.appendChild(link);
      link.click();
    }
  }

  useEffect(() => {
    loadUltimosAtendimentos({ isClearable: true});

  }, []);

  const handleClickSearch = () => {
    if (filters.periodo) {
      loadUltimosAtendimentos({ isClearable: true });
    }
  }

  return (
    <div className={classes.content}>
      <PageTitle title="Relatório - Últimos atendimentos" />
      <HeaderRelatorio
        search={onClickPesquisar}
        clearSearch={handleClickClearPesquisar}
        value={nomeSujeitoAtencao}
        title="Últimos atendimentos"
        onChange={handleSearchChange}
        onKeyPress={onKeypressSearch}
        hiddenFilter
        hiddenButtons
        totalTitle="atendimentos"
        totalAgendamentos={totalElements}
      />
      <div className={classes.contentFiltros}>
        <div className={classes.filtros}>
          <div className={classes.campoFiltro}>
            <span className={classes.tituloFiltros}> Período de tempo: </span>
            <ReactSelect
              onChange={(e) => handleFilters(e, "periodo")}
              value={periodo}
              options={periodos}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.value}
            />
          </div>
          <div className={classes.campoFiltro}>
            <span className={classes.tituloFiltros}> Profissional: </span>
            <TextFieldSearch
              placeholder='Selecione'
              classNotched={classes.notchedOutline}
              classInput={classes.inputTextField}
              classIcons={classes.classIcons}
              classes={{ paper: classes.menuHeight }}
              loadOptions={loadProfissionaisSaude}
              getOptionLabel={option => option?.nome}
              getOptionValue={option => option?.id}
              value={filters.profissional}
              onChange={e => handleFilters(e, 'profissional')}
              withPaginate
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              menuPosition='fixed'
            />
          </div>
          <div className={classes.campoFiltro}>
            <span className={classes.tituloFiltros}> Cidade: </span>
            <TextFieldSearch
              placeholder="Selecione"
              classNotched={classes.notchedOutline}
              classInput={classes.inputTextField}
              classIcons={classes.classIcons}
              loadOptions={loadMunicipio}
              withPaginate
              value={filters.cidade}
              onChange={e => handleFilters(e, 'cidade')}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
            />
          </div>
          <div className={classes.campoFiltro}>
            <label className={classes.tituloFiltros}>Convênio</label>
            <TextFieldSearch
              classNotched={classes.notchedOutline}
              classInput={classes.inputTextField}
              classIcons={classes.classIcons}
              placeholder="Selecione"
              withPaginate
              loadOptions={loadConvenios}
              value={filters.convenio || ""}
              onChange={(e) => handleFilters(e, "convenio")}
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
            />
          </div>
        </div>
        <Button
          shape='pill'
          bgColor='#707C97'
          onClick={handleClickSearch}
          disabled={!filters.periodo || isLoading}
        >
          Filtrar
        </Button>
      </div>
      <ErrorCollector mensagem={'Não foi possível carregar o relatório!'}>
        <div className={classes.listaUltimosAtendimentos}>
          <Scroll
            loadMore={() => loadUltimosAtendimentos()}
            hasMore={hasMore}
            pageStart={0}
            initialLoad={false}

          >
            {ultimosAtendimentos.length === 0 && !isLoading && (
              <MensagemListaVazia/>
            )}
            {ultimosAtendimentos?.length > 0 && (
              <div>
                <Table
                  dados={ultimosAtendimentos}
                  columns={columns({utilizaTelefoneInternacional})} />
              </div>)}
            {isLoading && (
              <Grid
                container
                justify='center'
                alignItems='center'
                style={{ height: '100%'}}
              >
                <CircularProgress size={30} />
              </Grid>
            )}
          </Scroll>
        </div>
      </ErrorCollector>
      <div className={classes.contentButtons}>
        <Button
          shape="circle"
          bgColor="#F9BE73"
          onClick={handlePrint}
          disabled={ultimosAtendimentos.length === 0}
          isLoading={loadingPrint}
        >
          <PrintIcon />
        </Button>
        <Button
          shape="circle"
          bgColor="#F9BE73"
          onClick={getCsv}
          disabled={ultimosAtendimentos.length === 0}
          isLoading={loadingPrint}
        >
          <ArrowDownloadIcon />
        </Button>
      </div>
      {isPrintMustache && (
        <ImpressaoHtml
          isPrintMustache={isPrintMustache}
          handlePrintMustache={() => setIsPrintMustache(false)}
          htmlStringComponent={
            <UltimosAtendimentos
              dadosRelatorio={ultimosAtendimentosPrint || []}
              filters={filters}
              search={nomeSujeitoAtencao}
            />
          }
        />
      )}

      <Notification
        close={closeAlertMessage}
        reset={closeAlertMessage}
        isOpen={notification.isOpen}
        variant={"error"}
        message={notification.message}
      />
    </div>
  );
};

const styles = {
  content: {
    display: 'grid',
    gridTemplateRows: '80px 71px 1fr',
    position: 'relative',
    overflow: 'hidden',
    background: '#f5f5f5',

    '& thead th': {
      textAlign: 'left',
      position: 'sticky',
      top: 0,
    },
  },
  contentFiltros: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "13px 16px 8px 16px",
    boxShadow:
      "10px 10px 25px rgb(112 124 151 / 5%), 15px 15px 35px rgb(112 124 151 / 5%)",
    background: "#fff",
    marginTop: 1,
    justifyContent: "space-between",
  },
  filtros: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
    width: "100%",
  },
  listaUltimosAtendimentos: {
    overflow: 'auto',
    height: 'calc(100% - 32px)',
    margin: '16px',
    borderRadius: '16px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    background: '#fff',
    '&> div': {
      height: '100%',
      '&> div': {
        height: '100%',
      },
    },
  },
  tituloFiltros: {
    color: "#868686",
    fontSize: "12px !important",
  },
  campoFiltro: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "188px",
  },
  notchedOutline: {
    border: "0",
  },
  contentButtons: {
    display: 'flex',
    position: 'absolute',
    bottom: '20px',
    right: '14px',
    width: '88px',
    justifyContent: 'space-between',
  },
};

const stores = ["unidadeStore", "relatorioStore"];
const RelatorioUltimosAtendimentosWithStyles = withStyles(styles)(RelatorioUltimosAtendimentos);
export default inject(...stores)(RelatorioUltimosAtendimentosWithStyles);
