import React, { useEffect, useState } from "react";
import PageTitle from "../../../components/PageTitle/PageTitle";
import HeaderRelatorio from "../../Relatorios/HeaderRelatorio";
import { TextFieldSearch } from "../../../components/TextField";
import PrintIcon from "../../../components/Icon/Print";
import ImpressaoHtml from "../../../components/Impressao/ImpressaoHtml";
import Notification from "../../../components/Notification";
import { CircularProgress, Grid, withStyles } from "@material-ui/core";
import colors from "../../../template/Colors";
import { Button } from "../../../components/ui/Buttons";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import Table from "../../../components/Table/Table";
import { filtersDefault, getColumns, pageableDTODefault } from "./constants";
import InputDateForm from "../../../components/Input/InputDateForm";
import ArrowDownloadIcon from "../../../components/Icon/ArrowDownload";
import { findAllProfissionalSaude } from "../../../services/ProfissionalSaudeService";
import { inject } from "mobx-react";
import { relatorioProfissionalAvaliacao, relatorioProfissionalAvaliacaoCsv } from "../../../services/AvaliacaoService";
import moment from "moment";
import { base64Convert } from "../../../utils/base64ToCsv";
import AvaliacaoTemplate from "../../../template/pdf/relatorio/AvaliacaoTemplate";

const Avaliacao = ({ classes, relatorioStore }) => {

  const [isPrintMustache, setIsPrintMustache] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [avaliacoes, setAvaliacoes] = useState([])
  const [filters, setFilters] = useState(filtersDefault)
  const [pageableDTO, setPageableDTO] = useState(pageableDTODefault);
  const [last, setLast] = useState(false);
  const [dadosRelatorio, setDadosRelatorio] = useState(null)
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
  });

  const disabledButtonFilter = (!filters.dataInicial && !filters.dataFinal && !filters.profissional) || 
    (filters.dataInicial && !filters.dataFinal)

  useEffect(() => {
    loadAvaliacoes({ isClearable: true })
  }, [])

  const showAlertMessage = (notification) => {
    setNotification(notification);

    const timeoutId = setTimeout(() => {
      closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  const closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
    };
    setNotification(notification);
  };


  const loadAvaliacoes = async (options) => {

    try {

      setIsLoading(true);
      const { profissional, dataFinal, dataInicial } = filters
      const variables = {
        pageableDto: {
          ...pageableDTO,
          pageNumber: options?.isClearable ? 0 : pageableDTO.pageNumber,
        },
        ...(profissional?.id && {
          profissionalId: profissional?.id,
        }),
        ...(dataInicial && { dataInicio: moment(dataInicial).format("YYYY-MM-DD") }),
        ...(dataFinal && { dataFim: moment(dataFinal).format("YYYY-MM-DD") }),
      };

      const isValid = relatorioStore.verificaLimiteTrintaDias(dataInicial, dataFinal)

      if (!isValid) {
        relatorioStore.openNotification("O limite de sessenta dias foi excedido!", "error")
        return;
      };

      const response = await relatorioProfissionalAvaliacao(variables);
      const { content, last, totalElements: elements } = response;
      const page = options?.isClearable ? 0 : pageableDTO.pageNumber;

      setAvaliacoes(
        options?.isClearable
          ? [...content]
          : [...procedimentosRealizados, ...content]
      );
      setPageableDTO({
        ...pageableDTO,
        pageNumber: page + 1,
      });
      setLast(last);
    } catch (error) {
      showAlertMessage({
        isOpen: true,
        variant: "error",
        message: "Erro ao carregar relatório de avaliações",
      });
    } finally {
      setIsLoading(false);
    }

  }


  const handlePrint = async () => {
    const { dataInicial, dataFinal, profissional } = filters;

    const isValid = relatorioStore.verificaLimiteTrintaDias(dataInicial, dataFinal)

    if (!isValid) {
      showAlertMessage({
        isOpen: true,
        variant: "error",
        message: "O limite de sessenta dias foi excedido",
      });
      return;
    };

    setIsLoading(true)
    try {
      const variables = {
        pageableDto: {
          ...pageableDTO,
          pageNumber: 0,
        },
        ...(profissional?.id && {
          profissionalId: profissional?.id,
        }),
        ...(dataInicial && { dataInicio: moment(dataInicial).format("YYYY-MM-DD") }),
        ...(dataFinal && { dataFim: moment(dataFinal).format("YYYY-MM-DD") }),
      };

      const response = await relatorioProfissionalAvaliacao(variables)

      setDadosRelatorio(response.content)
    } catch (error) {
      console.error(error)
    } finally {
      setIsPrintMustache(true)
      setIsLoading(false)
    }
  }

  const handleDownloadCSV = async () => {
    try {
      const { dataInicial, dataFinal, profissional } = filters;

      const isValid = relatorioStore.verificaLimiteTrintaDias(dataInicial, dataFinal)
      if (!isValid) {
        showAlertMessage({
          isOpen: true,
          variant: "error",
          message: "O limite de sessenta dias foi excedido",
        });
        return;
      };

      const variables = {
        pageableDto: {
          ...pageableDTO,
          pageNumber: 0,
        },
        ...(profissional?.id && {
          profissionalId: profissional?.id,
        }),
        ...(dataInicial && { dataInicio: moment(dataInicial).format("YYYY-MM-DD") }),
        ...(dataFinal && { dataFim: moment(dataFinal).format("YYYY-MM-DD") }),
      };

      const base64 = await relatorioProfissionalAvaliacaoCsv(variables)

      if (base64 !== "error") {
        const blob = await base64Convert(base64);
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "relatorioAvaliacao.csv");
        document.body.appendChild(link);
        link.click();
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleClickSearch = () => {
    const { dataInicial, dataFinal } = filters;
    const datasPreenchidas = !!dataInicial && !!dataFinal;

    if (datasPreenchidas) {
      const dentroDoLimite = relatorioStore.verificaLimiteTrintaDias(dataInicial, dataFinal);

      if (!dentroDoLimite) {
        showAlertMessage({
          isOpen: true,
          variant: "error",
          message: "O limite de sessenta dias foi excedido",
        });
        return;
      }
    }

    loadAvaliacoes({ isClearable: true });
  }

  const handleChangeFilters = (value, name) => {
    setFilters({ ...filters, [name]: value })
  }

  const loadProfissionaisSaude = async (search, loadedOptions, { page }) => {
    try {
      const { mostrarApenasProfissionaisAtivo } = relatorioStore.configuracaoUnidade || {};
      const { content, last } = (
        await findAllProfissionalSaude({
          pageNumber: page,
          search,
          ativo: mostrarApenasProfissionaisAtivo,
        })
      ).data.data.findAllProfissionalSaude

      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      <div className={classes.content}>
        <PageTitle title='Avaliações' />
        <HeaderRelatorio
          title='Avaliações'
          classes={{
            paper: classes.paper,
          }}
          hiddenSearch
          hiddenButtons
          hiddenTotal
        />

        <div className={classes.contentFiltros}>
          <div className={classes.filtrosHeader}>
            <Grid item xs={3} className={classes.spacingConvenio}>
              <span className={classes.tituloFiltros}> Data Inicio: </span>
              <InputDateForm
                iconposition='end'
                openTo="day"
                views={['year', 'month']}
                value={filters.dataInicial || ''}
                onChange={e => handleChangeFilters(e, 'dataInicial')}
              />
            </Grid>
            <Grid item xs={3} className={classes.spacingConvenio}>
              <span className={classes.tituloFiltros}> Data Fim: </span>
              <InputDateForm
                iconposition='end'
                openTo="day"
                views={['year', 'month']}
                value={filters.dataFinal || ''}
                onChange={e => handleChangeFilters(e, 'dataFinal')}
                disabled={!filters.dataInicial}
              />
            </Grid>

            <div className={classes.wrapperFilters}>
              <span className={classes.tituloFiltros}> Profissional: </span>
              <TextFieldSearch
                placeholder='Selecione'
                loadOptions={loadProfissionaisSaude}
                getOptionLabel={option => option?.nome}
                getOptionValue={option => option?.id}
                value={filters.profissional}
                onChange={e => handleChangeFilters(e, 'profissional')}
                withPaginate
                debounceTimeout={300}
                additional={{
                  page: 0,
                }}
                menuPosition='fixed'
              />
            </div>
          </div>
          <Button
            shape='pill'
            bgColor='#707C97'
            onClick={handleClickSearch}
            disabled={isLoading || disabledButtonFilter}
          >
            Filtrar
          </Button>
        </div>

        <div className={classes.tableContent}>
          <Scroll
            loadMore={loadAvaliacoes}
            hasMore={!last}
            pageStart={0}
            initialLoad={false}
            className={classes.scrollContainer}
          >
            {avaliacoes?.length === 0 && !isLoading && (
              <div className={classes.notFoundContainer}>
                <h3>Nenhum item encontrado</h3>
              </div>
            )}
            {avaliacoes?.length > 0 &&
              <Table
                classes={{ table: classes.table }}
                dados={avaliacoes}
                columns={getColumns()}
                clickable={false}
              />
            }
            {isLoading && (
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ height: "100%", width: "100%", justifyContent: "center", alignItems: "center" }}
              >
                <CircularProgress size={30} />
              </Grid>
            )}
          </Scroll>
        </div>
        <div className={classes.buttonsDownloadPrint}>
          <Button
            id='exportCsv'
            shape='circle'
            bgColor='#F9BE73'
            onClick={handleDownloadCSV}
          >
            <ArrowDownloadIcon />
          </Button>

          <Button
            shape='circle'
            bgColor='#F9BE73'
            onClick={handlePrint}
            isLoading={isLoading}
          >
            <PrintIcon />
          </Button>
        </div>
        {isPrintMustache && (
          <ImpressaoHtml
            isPrintMustache={isPrintMustache}
            handlePrintMustache={() => setIsPrintMustache(false)}
            htmlStringComponent={<AvaliacaoTemplate dadosRelatorio={dadosRelatorio || []} filters={filters} />}
          />
        )}
        <Notification
          close={closeAlertMessage}
          reset={closeAlertMessage}
          isOpen={notification.isOpen}
          variant={"error"}
          message={notification.message}
        />
      </div>
    </div>
  )
}


const styles = theme => ({
  content: {
    display: 'grid',
    gridTemplateRows: '80px 71px 1fr',
    position: 'relative',
    overflow: 'hidden',
    background: '#f5f5f5',
    height: "100%",

    '& thead th': {
      textAlign: 'left !important',
      position: 'sticky',
      top: 0,
    },
  },

  header: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  title: {
    fontSize: "18px",
    fontWeight: "700",
  },

  filtros: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    padding: '13px 16px 8px 16px',
    boxShadow: '10px 10px 25px rgb(112 124 151 / 5%), 15px 15px 35px rgb(112 124 151 / 5%)',
    background: '#fff',
    zIndex: 1,
    marginTop: 1,
    justifyContent: 'space-between',
    width: '100%'
  },

  contentFiltros: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    padding: '13px 16px 8px 16px',
    boxShadow: '10px 10px 25px rgb(112 124 151 / 5%), 15px 15px 35px rgb(112 124 151 / 5%)',
    background: '#fff',
    zIndex: 1,
    marginTop: 1,
    justifyContent: 'space-between',
  },


  buttonFilter: {
    display: 'flex',
    paddingRight: "20px",

  },

  tituloFiltros: {
    color: colors.commons.gray7,
    fontSize: '12px !important',
  },

  buttonsDownloadPrint: {
    display: 'flex',
    position: 'absolute',
    bottom: '20px',
    right: '55px',
    width: '88px',
    justifyContent: 'space-between',
  },

  wrapperFilters: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
  },

  scrollContainer: {
    height: "100%",
    "&>div": {
      height: '100%'
    },
    background: "#fff",

  },


  table: {
    overflowY: "auto",
    maxHeight: "calc(100vh - 200px)",
    background: "#fff"
  },

  tableContent: {
    overflow: "auto",
    maxHeight: "calc(100vh - 249px)",
    margin: "16px",
    borderRadius: "16px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    "&> div": {
      height: "100%",
      "&> div": {
        height: "100%",
      },
    },
  },

  notFoundContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    background: '#fff',

    '& h3': {
      color: colors.commons.gray10,
      fontWeight: 'normal',
      fontSize: '1rem',
    },
  },


  filtrosHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
  },

  paper: {
    width: '252px',
    right: '-20px',
    left: 'auto',
  },

  spacingConvenio: {
    maxWidth: 175,
    paddingRight: 16,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },


})

const stores = ["relatorioStore"]
const AvaliacaoWithStyles = withStyles(styles)(Avaliacao)
export default inject(stores)(AvaliacaoWithStyles);