import moment from "moment"

export const getColumns = () => {

  const columns = [
    {
      Header: 'Profissional',
      align: 'start',
      getValue: data => {
        return data.profissional
      },
    },
    {
      Header: 'Quantidade',
      align: 'start',
      getValue: data => {
        return data?.countAvaliacoes
      },
    },
    {
      Header: 'Avaliação',
      align: 'start',
      getValue: data => {
        return data?.avaliacao
      },
    },

  ]
  return columns

}

export const pageableDTODefault = {
  pageNumber: 0,
  pageSize: 30,
  sortField: "profissional",
  sortDir: "ASC",
};

const primeiroDiaMesAtual = moment().startOf("month").format("YYYY-MM-DD");
const ultimoDiaMesAtual = moment().endOf("month").format("YYYY-MM-DD");

export const filtersDefault = {
  dataInicial: primeiroDiaMesAtual,
  dataFinal: ultimoDiaMesAtual,
  profissional: null
};