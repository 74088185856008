import React from "react";
import {
  ContainerTab,
  InputCEPFormStyled,
  InputPhoneFormStyled,
  Row,
  RowButtons,
  TextFieldSearchStyled,
} from "../../styles";
import Field from "../../components/Field";
import { InputForm } from "../../../../../components/Modal/Input";
import { findAllMunicipio } from "../../../../../services/InternacaoService";
import { consultaDeCep } from "../../../../../services/EnderecoService";
import { Button } from "../../../../../components/ui/Buttons";
import { InputDocumentos } from "../../../../../components/Input/InputDocumentos";
import { withStyles } from "@material-ui/core";

const Responsavel = ({ classes,internacao, handleField }) => {
  const { responsavel, sujeitoAtencao, acompanhante } = internacao;
  const { nome, endereco, contato, rg, documento } = responsavel || {};

  const { cep, nomeLogradouro, bairro, municipio, numero } = endereco || {};

  const { telefonePrincipal, telefoneSecundario } = contato || {};

  const handleFieldResponsavel = (value, field) => {
    const dadosResponsavel = internacao.responsavel;
    dadosResponsavel[field] = value;

    handleField("responsavel", dadosResponsavel);
  };

  const loadAllMunicipio = async (search, loadedOptions, { page }) => {
    const searchDTO = {
      search,
      pageNumber: page,
      pageSize: 20,
      sortDir: "ASC",
      sortField: "nome",
    };

    const response = await findAllMunicipio({ searchDTO });
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  const onCEPChange = async (e) => {
    const { value } = e.target;

    const cepSemMascara = value.replace(/\D/g, "");
    if (cepSemMascara.length === 8) {
      const response = await consultaDeCep(cepSemMascara);
      const responseConsultaDeCep = response?.data?.data?.consultaDeCep || {};
      if (responseConsultaDeCep) {
        const { logradouro, bairro, municipio } = responseConsultaDeCep;

        const dadosResponsavel = {
          ...internacao.responsavel,
          endereco: {
            nomeLogradouro: logradouro,
            bairro,
            municipio,
            uf: municipio?.estado?.uf,
          },
        };

        handleField("responsavel", dadosResponsavel);
      }
    }

    handleFieldResponsavel(value, "cep");
  };

  const handleFieldUpdate = (value, type, field) => {
    const dadosResponsavel = {
      ...internacao.responsavel,
      [type]: {
        ...internacao.responsavel[type],
        [field]: value,
      },
    };

    handleField("responsavel", dadosResponsavel);
  };

  const handleDadosPaciente = () => {
    const { nome, endereco, contato, documento, rg } = sujeitoAtencao || {};
    const { telefonePrincipal, telefoneSecundario } = contato;

    const dadosSujeitoAtencao = {
      nome,
      endereco,
      contato: {
        telefonePrincipal,
        telefoneSecundario
      },
      documento,
      rg,
      vinculo: "",
    };

    handleField("responsavel", dadosSujeitoAtencao);
  };

  const handleDadosAcompanhante = () => {
    handleField("responsavel", acompanhante);
  };

  return (
    <ContainerTab>
      <RowButtons>
        <Button bgColor="#707C97" width="220px" onClick={handleDadosPaciente}>
          Dados do paciente
        </Button>
        <Button
          bgColor="#707C97"
          width="220px"
          onClick={handleDadosAcompanhante}
        >
          Dados do acompanhante
        </Button>
      </RowButtons>
      <Row>
        <Field label="Nome">
          <InputForm
            value={nome}
            onChange={(e) => handleFieldResponsavel(e.target.value, "nome")}
          />
        </Field>
      </Row>
      <Row>
        <Field label="RG">
          <InputForm
            value={rg}
            onChange={(e) => handleFieldResponsavel(e.target.value, "rg")}
          />
        </Field>
        <Field label="CPF">
          <InputDocumentos
            placeholder="Documento"
            value={documento}
            onChange={(e) =>
              handleFieldResponsavel(e.target.value, "documento")
            }
            tipo={'CPF'}
            className={classes.inputDivMask}
            classes={{ input: classes.inputMask }}
          />
        </Field>
      </Row>
      <Row>
        <Field label="Telefone principal">
          <InputPhoneFormStyled
            value={telefonePrincipal}
            onChange={(e) =>
              handleFieldUpdate(e.target.value, "contato", "telefonePrincipal")
            }
          />
        </Field>
        <Field label="Telefone secundário">
          <InputPhoneFormStyled
            value={telefoneSecundario}
            onChange={(e) =>
              handleFieldUpdate(e.target.value, "contato", "telefoneSecundario")
            }
          />
        </Field>
      </Row>
      <Row>
        <Field label="CEP">
          <InputCEPFormStyled
            type="text"
            onChange={(e) => onCEPChange(e)}
            value={cep}
          />
        </Field>
        <Field label="Endereço">
          <InputForm
            value={nomeLogradouro}
            onChange={(e) =>
              handleFieldUpdate(e.target.value, "endereco", "nomeLogradouro")
            }
          />
        </Field>
        <Field label="Número">
          <InputForm
            value={numero}
            onChange={(e) =>
              handleFieldUpdate(e.target.value, "endereco", "numero")
            }
          />
        </Field>
      </Row>
      <Row>
        <Field label="Bairro">
          <InputForm
            value={bairro}
            onChange={(e) =>
              handleFieldUpdate(e.target.value, "endereco", "bairro")
            }
          />
        </Field>
        <Field label="Cidade">
          <TextFieldSearchStyled
            placeholder="Selecione"
            loadOptions={loadAllMunicipio}
            getOptionLabel={(option) => `${option.nome} - ${option.uf}`}
            getOptionValue={(option) => option.id}
            value={municipio}
            onChange={(e) => handleFieldUpdate(e, "endereco", "municipio")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </Field>
      </Row>
    </ContainerTab>
  );
};

const styles = {
  inputMask: {
    color: '#000',
    border: 'none',
    borderRadius: 0,
    fontSize: '14px',
  },
  inputDivMask: {
    background: '#F2F2F2',
    borderRadius: '8px',
    height: '32px',
    paddingLeft: '8px',
    width: 'calc(100% - 8px)',
  },
}

export default withStyles(styles)(Responsavel)
