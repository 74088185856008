import React from "react";
import moment from "moment";

const AvaliacaoTemplate = ({ dadosRelatorio, filters }) => {

  const { dataInicial, dataFinal, profissional } = filters;

  const columnColor = (index) => {
    if (index % 2 !== 0) {
      return { backgroundColor: "#F2F2F2" }

    }
    return ''
  }

  return (
    <>
      <div style={styles.titulo}>Relatório de avaliações</div>
      <div style={styles.subTitulo}>
        <div style={styles.dataInicio}>
          Data início:
          <span style={styles.subTituloSpan}>
            {dataInicial ? moment(dataInicial).format("DD/MM/YYYY") : "-"}
          </span>
        </div>
        <div>
          Data fim:
          <span style={styles.subTituloSpan}>
            {dataFinal ? moment(dataFinal).format("DD/MM/YYYY") : "-"}
          </span>
        </div>
        <div>
          Profissional saúde:
          <span style={styles.subTituloSpan}>
            {profissional?.id ? profissional?.nome : "-"}
          </span>
        </div>
      </div>
      <div style={styles.headerColumn}>
        <div style={styles.columnProcedimento}>Profissional</div>
        <div style={styles.column}>Quantidade</div>
        <div style={styles.column}>Avaliação</div>
      </div>

      {dadosRelatorio.map((item, index) => {
        return (
          <div key={index} style={{ ...styles.containerColuna, ...columnColor(index) }}>
            <div style={styles.columnProcedimento}>{item.profissional}</div>
            <div style={styles.column}>{item.countAvaliacoes}</div>
            <div style={styles.column}>{item.avaliacao}</div>
          </div>
        );
      })}
    </>
  );
};

const styles = {
  titulo: {
    marginLeft: "0px",
    marginBottom: "10px",
    marginTop: "5px",
    paddingBottom: "5px",
    textAlign: "center",
    fontWeight: 700,
  },
  subTitulo: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #505050",
    marginBottom: "15px",
    paddingBottom: "5px",
    fontWeight: 700,
    justifyContent: "space-between",
  },
  subTituloSpan: {
    marginLeft: "8px",
  },
  dataInicio: {
    marginRight: "16px",
  },
  headerColumn: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 700,
    width: "100%",
    justifyContent: "space-between"
  },
  containerColuna: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between"
  },
  columnProcedimento: {
    width: "70%",
    minWidth: "60%",
    marginBottom: "10px",
    marginRight: "3px",
    marginLeft: "10px"
  },
  column: {
    width: "20%",
    textAlign: "center",
    alignContent: "flex-end",
    justifyContent: "flex-end",
  },
  infosTotal: {
    display: "flex",
    justifyContent: "center",
  },
};

export default AvaliacaoTemplate;