import Api from '../config/api'

export const findAllControleSessao = async variables => {
  const response = await Api.post('', {
    query: `
    query($pageableDTO: PageableDTOInput, $sujeitoAtencaoId: UUID, $emAberto: Boolean, $vencido: Boolean, $ativo: Boolean) {
      findAllControleSessao(pageableDTO: $pageableDTO, sujeitoAtencaoId: $sujeitoAtencaoId, ativo: $ativo, emAberto: $emAberto, vencido: $vencido) {
        last
        totalElements
        content {
          ativo
          id
          observacao
          nomeSessao
          vencido
          dataVencimento
          titulo {
            id
            dataPagamento
            dataVencimento
          }
          sessoesAgendadas
          controleSessaoProcedimentos{
            id
            ordem
            ativo
            agendamento {
              id
              nome
              situacao
              profissionalSaude {
                id
                nome
              }
              procedimentos {
                id
                realizado
              }
            }
            procedimento{
              nome 
              id
              convenioProcedimentos {
                convenio {
                  id
                }
                valor
              }
            }
            sala {
              nome
              id
            }
            titulo {
              dataPagamento
              id 
              nome
            }
          }
          controleSessaoProcedimentosOrdenado{
            id
            ordem
            ativo
            agendamento {
              id
              nome
              situacao
              profissionalSaude {
                id
                nome
              }
              procedimentos {
                id
                realizado
              }
            }
            procedimento{
              nome
              id
              convenioProcedimentos {
                convenio {
                  id
                }
                valor
              }
            }
            sala {
              nome
              id
            }
            titulo {
              dataPagamento
              id
              nome
            }
          }
          sujeitoAtencao {
            id 
            nome
            profissionalSaude {
              id
              nome
            }
            dadosConvenio {
              convenio {
                id
                descricao
                convenioAgendamentoTipo {
                  agendamentoTipo {
                      id
                  }
                  valor
                }
              }
            }
          }
          controleSessaoAgendamentoTipos{
            id
            ordem
            ativo
            agendamento {
              id
              data
              situacao
              nome
              profissionalSaude {
                id
                nome
              }
              procedimentos {
                id
                realizado
              }
            }
            sala {
              nome
              id
            }
            titulo {
              dataPagamento
              id 
              nome
            }
            agendamentoTipo {
              id
              descricao
              convenioAgendamentoTipo{
                convenio {
                  id
                }
                valor
              }
            }
          }
          controleSessaoAgendamentoTiposOrdenado{
            id
            ordem
            ativo
            agendamento {
              id
              data
              situacao
              nome
              profissionalSaude {
                id
                nome
              }
              procedimentos {
                id
                realizado
              }
            }
            sala {
              nome
              id
            }
            titulo {
              dataPagamento
              id
              nome
            }
            agendamentoTipo {
              id
              descricao
              convenioAgendamentoTipo{
                convenio {
                  id
                }
                valor
              }
            }
          }
        }
      }
    }
    
  `,
    variables,
  })
  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }
  return response.data.data.findAllControleSessao
}

export const findControleSessaoById = async id => {
  const response = await Api.post('', {
    query: `
    query($controleSessaoId: UUID){
      findControleSessaoById(controleSessaoId: $controleSessaoId){
          ativo
          id
          nomeSessao
          observacao
          dataVencimento
          ultimaDataAgendada
          sujeitoAtencao {
            id
            nome
            profissionalSaude {
              id
              nome
            }
            dadosConvenio {
              convenio {
                id
                descricao
              }
            }
          }
          controleSessaoProcedimentos{
            ativo
            id
            ordem
            agendamento {
              id
              nome
              procedimentos {
                id
                realizado
              }
            }
            procedimento{
              nome 
              id
            }
            sala {
              nome
              id
            }
            
            titulo {
              dataPagamento
              id 
              nome
            }
          }
          controleSessaoAgendamentoTipos{
            id
            ativo
            ordem
            agendamento {
              id
              nome
              procedimentos {
                id
                realizado
              }
            }
            sala {
              nome
              id
            }
            titulo {
              dataPagamento
              id 
              nome
            }
            agendamentoTipo {
              id
              descricao
            }
          }
          titulo {
            id
            dataPagamento
            dataVencimento
            valor
          }
          saldoDisponivel
          agendados
        }
    }
    
  `,
    variables: {
      controleSessaoId: id,
    },
  })
  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }
  return response.data.data.findControleSessaoById
}

export const findAllControleSessaoProcedimento = async variables => {
  const response = await Api.post('', {
    query: `
    query($controleSessaoId: UUID, $pageableDTO: PageableDTOInput, $ativo: Boolean){
      findAllControleSessaoProcedimento(controleSessaoId: $controleSessaoId,  pageableDTO: $pageableDTO, ativo: $ativo){
        last
        totalElements
        content {
          ativo
          id
          ordem
          agendamento {
            id
            data
            titulos{
            id
            nome
            ativo
            }
            situacao
            nome
            horaInicio
            horaFim
            profissionalSaude {
              id
              nome
            }
            procedimentos {
              id
              realizado
            }
          }
          controleSessao {
            id
            nomeSessao
            observacao
            dataVencimento
            titulo {
              dataPagamento
              dataVencimento
              id 
              nome
            }
            controleSessaoProcedimentos{
              ativo
              id
              agendamento {
                id
                nome
                procedimentos {
                  id
                  realizado
                }
              }
              procedimento{
                nome 
                id
                convenioProcedimentos {
                  convenio {
                    id
                  }
                  valor
                }
              }
              sala {
                nome
                id
              }
              titulo {
                dataPagamento
                dataVencimento
                id 
                nome
              }
            }
            sujeitoAtencao {
              id
              nome
              profissionalSaude {
                id
                nome
              }
              dadosConvenio {
                convenio {
                  id
                  descricao
                }
              }
            }
          }

          titulo {
            dataPagamento
            dataVencimento
            id 
            nome
          }
          procedimento{
            nome 
            id
            descricao
            convenioProcedimentos {
              convenio {
                id
              }
              valor
            }
          }
        }
      }
    }`,
    variables,
  })
  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }
  return response.data.data.findAllControleSessaoProcedimento
}

export const findAllControleSessaoAgendamentoTipo = async variables => {
  const response = await Api.post('', {
    query: `
    query($controleSessaoId: UUID, $pageableDTO: PageableDTOInput, $ativo: Boolean){
      findAllControleSessaoAgendamentoTipo(controleSessaoId: $controleSessaoId,  pageableDTO: $pageableDTO, ativo: $ativo){
        last
        totalElements
        content {
          ativo
          id
          ordem
          agendamento {
            id
            data
            situacao
            nome
            horaInicio
            horaFim
            profissionalSaude {
              id
              nome
            }
            procedimentos {
              id
              realizado
            }
          }
          controleSessao {
            id
            nomeSessao
            observacao
            dataVencimento
            titulo {
              id
              dataPagamento
              dataVencimento
            }
            controleSessaoAgendamentoTipos{
              ativo
              id
              agendamento {
                id
                nome
                procedimentos {
                  id
                  realizado
                }
              }
              agendamentoTipo {
                id
                descricao
                convenioAgendamentoTipo{
                  convenio {
                    id
                  }
                  valor
                }
            }
              sala {
                nome
                id
              }
              titulo {
                dataPagamento
                dataVencimento
                id 
                nome
              }
            }
            sujeitoAtencao {
              id
              nome
              profissionalSaude {
                id
                nome
              }
              dadosConvenio {
                convenio {
                  id
                  descricao
                }
              }
            }
          }
          titulo {
            dataPagamento
            id 
            nome
          }
          agendamentoTipo {
            id
            descricao
            convenioAgendamentoTipo{
              convenio {
                id
              }
              valor
            }
          }
        }
      }
    }`,
    variables,
  })
  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }
  return response.data.data.findAllControleSessaoAgendamentoTipo
}

export const findAllAgendamentoTipo = async variables => {
  const response = await Api.post('', {
    query: `
      query ($pageableDTO: PageableDTOInput, $ativo: Boolean, $search: String) {
        findAllAgendamentoTipoPage(pageableDTO: $pageableDTO, ativo: $ativo, search: $search) {
              content {
                id
                descricao
                ativo
                codigo
              }
              last
            }
      }
  `,
    variables,
  })
  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }

  return response.data.data.findAllAgendamentoTipoPage
}

export const createControleSessao = async variables => {
  return await Api.post('', {
    query: `
      mutation($controleSessao: ControleSessaoInput){
        createControleSessao(controleSessao: $controleSessao){
          id
        }
      }`,
    variables,
  })
}

export const updateControleSessao = async variables => {
  return await Api.post('', {
    query: `
      mutation($controleSessao: ControleSessaoInput){
        updateControleSessao(controleSessao: $controleSessao){
          id
        }
      }`,
    variables,
  })
}

export const excluirControleSessaoProcedimentoConsulta = async ({ idsSessoes, type }) => {
  const query =
    type === 'PROCEDIMENTO'
      ? 'excluirControleSessaoProcedimento'
      : 'excluirControleSessaoAgendamentoTipo'
  const field =
    type === 'PROCEDIMENTO' ? 'controleSessaoProcedimentosId' : 'controleSessaoAgendamentoTipoId'
  const response = await Api.post('', {
    query: `
      mutation($${field}: [UUID]){
        ${query}(${field}: $${field}) {
          id
        }
      }`,
    variables: {
      [field]: idsSessoes,
    },
  })
  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }
  return response.data.data[query]
}

export const excluirControleSessao = async variables => {
  const response = await Api.post('', {
    query: `
      mutation($controleSessaoId: UUID){
        excluirControleSessao(controleSessaoId: $controleSessaoId) {
          id
        }
      }`,
    variables,
  })
  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }
  return response.data.data.excluirControleSessao
}

export const createAgendamentoAndControleSessao = async variables => {
  const response = await Api.post('', {
    query: `
      mutation($agendamento: AgendamentoInput, $controleSessaoAgendamentoTipo: ControleSessaoAgendamentoTipoInput, $controleSessaoProcedimento: ControleSessaoProcedimentoInput){
        createAgendamentoByControleSessao(agendamento: $agendamento, controleSessaoAgendamentoTipo: $controleSessaoAgendamentoTipo, controleSessaoProcedimento: $controleSessaoProcedimento){
          id
        }
      }`,
    variables,
  })
  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }
  return response.data.data.createAgendamentoByControleSessao
}

export const vincularAgendamentoByControleSessaoAgendamentoTipo = async variables => {
  const response = await Api.post('', {
    query: `
      mutation($agendamento: AgendamentoInput, $controleSessaoAgendamentoTipo: ControleSessaoAgendamentoTipoInput){
        vincularAgendamentoByControleSessaoAgendamentoTipo(agendamento: $agendamento, controleSessaoAgendamentoTipo: $controleSessaoAgendamentoTipo){
          id
        }
      }`,
    variables,
  })
  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }
  return response.data.data.vincularAgendamentoByControleSessaoAgendamentoTipo
}

export const faturarControleSessao = async ({ variables, query, field }) => {
  const response = await Api.post('', {
    query: `
      mutation($${field}: UUID, $titulo: TituloInput){
        ${query}(${field}: $${field}, titulo: $titulo){
          id
            id
            tipoRepeticao
            valorEntrada
            parcelas {
              tipo
              numeroParcela
              valor
              formaPagamento {
                id
              }
              desconto
              tipoDesconto
              dataVencimento
              dataPagamento
              contaBancaria {
                id
              }
              nome
            }
          }
      }`,
    variables,
  })
  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }
  return response.data.data[query]
}

export const duplicarControleSessao = async (controleSessaoId) => {
  const response = await Api.post('', {
    query: `
     mutation($controleSessaoId: UUID){
      duplicarControleSessao(controleSessaoId: $controleSessaoId) {
        id
      }
    }`,
    variables: { controleSessaoId },
  })
  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }
  return response.data.data.duplicarControleSessao;
}

export const calculaQuantidadeAgendamentoPeridoControleSessao = async (variables) => {
  const response = await Api.post('', {
    query: `
    query($periodo: PeriodoControleSessao, $dataLimite: LocalDate){
      calculaQuantidadeAgendamentoPeridoControleSessao(periodo: $periodo, dataLimite: $dataLimite)
    }`,
    variables,
  })
  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }
  return response.data.data.calculaQuantidadeAgendamentoPeridoControleSessao;
}