import React, { useState, useEffect } from "react";
import { inject } from "mobx-react";
import DrugForm from "../../../Receituario/DrugForm";
import { Tooltip, withStyles } from "@material-ui/core";
import { getRouteAdmByValue } from "../../../../../services/receituarioService";
import { getProfissionalSaudeLogado } from "../../../../../services/UsuarioService";
import { PopperMenu } from "./constants";
import ModalEditConduta from "../../components/ModalEditConduta";
import { Form, styles } from "./styles";
import string from "../../../../../utils/string";
import { findAllUnidadeMedida } from "../../../../../services/ProdutoService";

const recipeDefault = {
  drug: "",
  amount: "",
  ascription: "",
  routeAdm: "",
  useType: "",
  unidadeMedida: null,
};
const PrescricaoMedica = ({
  classes,
  internacao,
  receitaStore,
  idPrescricao,
  handleField,
  atendimentoStore,
}) => {
  const [lastIdRecipe, setLastIdRecipe] = useState(0);
  const [recipe, setRecipe] = useState({ ...recipeDefault });
  const [isMedico, setIsMedico] = useState(false);
  const [openModalEditConduta, setOpenModalEditConduta] = useState(false);
  const [condutaEnfermagem, setCondutaEnfermagem] = useState("");
  const [indexPrecricaoEditing, setIndexPrecricaoEditing] = useState(null);
  const [optionsUnidadesMedida, setOptionsUnidadesdMedida] = useState([]);

  const { internacaoPrescricaoItens } = internacao;

  useEffect(() => {
    getPermissoes();
    getoptionsUnidadesMedida()
  }, []);

  const getPermissoes = async () => {
    const usuario = await getProfissionalSaudeLogado();
    const isMedico = usuario?.tipo === 1;
    setIsMedico(isMedico);
  };

  const getoptionsUnidadesMedida = async() => {
    const response = await findAllUnidadeMedida();
    setOptionsUnidadesdMedida(response)
  };

  const handleChange = (name, value) => {
    let dado = value;
    if(name === "amount") {
      const apenasNumeros = value.replace(/[^0-9,]+/g, '');
      dado = apenasNumeros;
    }

    setRecipe((prevRecipe) => ({
      ...prevRecipe,
      [name]: dado,
    }));
  };

  const handleNewRecipe = () => {
    const newPrescricao = {
      ...recipe,
      id: lastIdRecipe,
      useType: recipe?.useType || "INTERNO",
    };

    const prescriçoesFeitas = internacaoPrescricaoItens || [];
    const prescricoes = [...prescriçoesFeitas, newPrescricao];
    handleField("internacaoPrescricaoItens", prescricoes);

    setLastIdRecipe((prevId) => prevId + 1);
    setRecipe({ ...recipeDefault });
  };

  const handleEditPrescricao = (index) => {
    const editPrescricao = internacaoPrescricaoItens.find(
      (item, i) => i === index
    );

    setRecipe(editPrescricao);
    handleRemovePrescricao(index);
  };

  const handleRemovePrescricao = (index) => {
    const prescricoes = internacaoPrescricaoItens.filter(
      (item,i) => i !== index
    );
    handleField("internacaoPrescricaoItens", prescricoes);
  };

  const handleEditCondutaEnfermagem = async(e) => {
    setIndexPrecricaoEditing(e);
    const prescricao = await internacaoPrescricaoItens.find((item,index)=> index === e)
    setCondutaEnfermagem(prescricao?.condutaEnfermagem)
    setOpenModalEditConduta(true);
  };


  const handleCloseModal = () => {
    setOpenModalEditConduta(false);
    setIndexPrecricaoEditing(null);
  };

  const handleSaveConduta = () => {
    try {
      if (!condutaEnfermagem) {
        const notification = {
          isOpen: true,
          message: "Favor preencher a conduta.",
          variant: "error",
        };
        atendimentoStore.showAlertMessage(notification);
        return;
      }

      const prescricoes = internacaoPrescricaoItens.map((item, i) => {
        if (i === indexPrecricaoEditing) {
          return {
            ...item,
            condutaEnfermagem,
          };
        } else {
          return item;
        }
      });
      handleField("internacaoPrescricaoItens", prescricoes);
      setOpenModalEditConduta(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeConduta = (e)=>{
    setCondutaEnfermagem(e)
  }

  return (
    <Form>
      <div className={classes.container}>
        <div className={classes.contentDrug}>
          <DrugForm
            key="new"
            recipe={recipe}
            onChange={handleChange}
            onBlur={handleNewRecipe}
            optionsUnidadesMedida={optionsUnidadesMedida}
            isInternacao
          />
        </div>
        <div className={classes.containerPrescricoes}>
          {internacaoPrescricaoItens?.map((item, index) => {
            return (
              <div className={classes.contentPrescricao} key={item?.id}>
                <div className={classes.wrapper}>
                  <div className={classes.wraperPrescricao}>
                    <div>
                      <div>{item?.drug?.nome}</div>
                      <div>{getRouteAdmByValue(item?.routeAdm)}</div>
                      <div>{item?.routeAdm}</div>
                      <div>{item?.ascription?.value || ""}</div>
                    </div>
                    <div className={classes.amount}>{item?.amount} {item?.unidadeMedida?.descricao || ""}</div>
                  </div>
                  <div className={classes.containerMenu}>
                    <div className={classes.conduta}>
                      <label className={classes.labelConduta}>
                        Conduta enfermagem:
                      </label>
                      <Tooltip title={item?.condutaEnfermagem || ""}>
                        <div>{string.truncate(item?.condutaEnfermagem, 100) || ""}</div>
                      </Tooltip>
                    </div>
                    <div>
                      {
                        <PopperMenu
                          colorButton={"#fff"}
                          row={index}
                          isMedico={isMedico}
                          functions={{
                            handleEdit: (e) => handleEditCondutaEnfermagem(e),
                            handleEditPrescricao: (e) =>
                              handleEditPrescricao(e),
                            handleRemovePrescricao: (e) =>
                              handleRemovePrescricao(e),
                          }}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {openModalEditConduta && (
        <ModalEditConduta
          index={indexPrecricaoEditing}
          open={openModalEditConduta}
          handleCloseModal={handleCloseModal}
          conduta={condutaEnfermagem}
          handleChangeConduta={(e) => handleChangeConduta(e)}
          handleSave={handleSaveConduta}
        />
      )}
    </Form>
  );
};

const stores = ["receitaStore", "atendimentoStore"];
const PrescricaoMedicaWithStyles = withStyles(styles)(PrescricaoMedica);
export default inject(...stores)(PrescricaoMedicaWithStyles);
