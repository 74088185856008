import { action, observable } from "mobx";
import { findAllDocumentoModeloCampoSistema } from "../services/ModeloDocumentoService";
import { getUnidadeLogado } from "../services/UsuarioService";
import { findProfissionalSaudeByUnidadeComAgenda } from "../services/ProfissionalSaudeService";

const page = {
  search: '',
  pageNumber: 0,
  pageSize: 20
};

export const campos = [
  { nomeCampo: 'sujeitoAtencao', lista: 'camposSujeitoAtencao' },
  { nomeCampo: 'profissionalSaude', lista: 'camposProfissionalSaude' },
  { nomeCampo: 'sistema', lista: 'camposDoSistema' },
  { nomeCampo: 'entrada', lista: 'camposUsuario' },
  { nomeCampo: 'sujeitoAtencaoInternacao', lista: 'camposSujeitoAtencaoInternacao' },
]

export default class ModeloDocumentoStore {
  usuarioStore = null;
  @observable id = "";
  @observable documentName = "";
  @observable ativo = true;
  @observable isLoading = false;
  @observable camposSistemaResponse = [];
  @observable camposSistemaResponseFiltrados = {
    camposSujeitoAtencao: [],
    camposProfissionalSaude: [],
    camposDoSistema: [],
    camposSujeitoAtencaoInternacao: [],
  };
  @observable profissionaisSaude = [];
  @observable profissionalSaudeSelected = null;
  @observable tipo = 'PROFISSIONAL_SAUDE';

  constructor(rootStore) {
    this.usuarioStore = rootStore.usuarioStore;
  }

  @action carregaCamposSistema = async () => {
    try {
      this.isLoading = true;
      const unidadeAtual = await getUnidadeLogado();
      const unidadeId = unidadeAtual && unidadeAtual.id ? unidadeAtual.id : null;
      const redeId =
        unidadeAtual && unidadeAtual.rede && unidadeAtual.rede.id
          ? unidadeAtual.rede.id
          : null;

      const response = await findAllDocumentoModeloCampoSistema({ ...page, redeId, unidadeId });
      this.camposSistemaResponse = response;
      this.filtraCampos();

    } catch (error) {
      throw error
    } finally {
      this.isLoading = false;
    }

  }

  filtraCampos = () => {
    let camposFiltradosPorEntidade = {}

    campos.forEach(item => {
      const listaFiltrada = this.camposSistemaResponse.filter(campo => campo.entidade === item.nomeCampo)

      camposFiltradosPorEntidade = {
        ...camposFiltradosPorEntidade,
        [item.lista]: listaFiltrada
      }
    });
    
    this.camposSistemaResponseFiltrados = camposFiltradosPorEntidade;
  };

  @action reset = () => {
    this.documentName = "";
    this.ativo = true;
  }

  @action getProfissionais = async () => {
    const pageableDTO = {
      sortDir: 'ASC',
      sortField: 'nome',
      pageNumber: 0,
      pageSize: 30,
    };
    const unidadeAtual = await this.usuarioStore.getUnidadeAtual();

    const response = await findProfissionalSaudeByUnidadeComAgenda({
      unidadeId: unidadeAtual.id || null,
      pageableDTO
    });

    this.profissionaisSaude = response.data.data.findProfissionalSaudeByUnidadeComAgenda;
  };

}
