import styled from "styled-components";
import Dialog from "../../../components/Dialog/Dialog";
import { InputCEPForm } from "../../../components/Input/InputCEPForm";
import { InputPhoneForm } from "../../../components/Modal/Input";
import { TextFieldSearch } from "../../../components/TextField";
import { FormControlLabel } from "@material-ui/core";
import InputMaskCurrency from "../../../components/Input/InputMaskCurrency";

export const DialogStyled = styled(Dialog)`
  & > div > div {
    overflow: hidden;
    height: 100%;
    padding: 16px;
    max-height: 613px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h1`
  color: #00b0ae;
  font-size: 18px;
`;

export const ContentButtons = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
`;

export const ContainerTab = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  overflow: auto;
  margin-top: 16px;
`;

export const InputCEPFormStyled = styled(InputCEPForm)`
  input {
    font-size: 14px;
    background: #f2f2f2;
    border: none;
  }
`;

export const InputPhoneFormStyled = styled(InputPhoneForm)`
  input {
    font-size: 14px;
    background: #f2f2f2;
    border: none;
  }
`;

export const TextFieldSearchStyled = styled(TextFieldSearch)`
  fieldset {
    border: none;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

export const RowButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  margin: 0;
  color: #868686;
  align-items: end;
  padding-bottom: 5px;
`;

export const ContainerProcedimentos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  height: calc(100% - 135px);
`;

export const InputMaskCurrencyStyled = styled(InputMaskCurrency)`
  background: #f2f2f2;
  border-radius: 8px;
  height: 32px;
`;

export const ContentButtonsAcao = styled.div`
  display: flex;
  gap: 8px;
`;

export const ContentTable = styled.div`
  overflow: auto;
`;
