import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "../../../components/Dialog/Dialog";
import { Fab, Grid, CircularProgress } from "@material-ui/core";
import {
  Close as CloseIcon,
  Description as DescriptionIcon,
  FileCopy as DuplicateIcon,
  Save as SaveIcon,
  Print as PrintIcon,
} from "@material-ui/icons";
import { inject } from "mobx-react";
import Colors from "../../../template/Colors";
import InputSelectComLabel from "../../../components/Input/InputSelectComLabel";
import {
  findAllAnamneseModelo,
  findAnamnesePadrao,
  saveAnamnese,
} from "../../../services/AnamneseService";
import TiposCampoAnamnese from "./TiposCampoAnamnese";
import ItemList from "../../../components/Modal/ItemList";
import Notification from "../../../components/Notification";
import AnamnesePdfDocument from "../../../template/pdf/anamnese/AnamnesePdfDocument";
import moment from "moment";
import InformacoesImportantesModal from "../InformacoesImportantes/InformacoesImportantesModal";
import { checkUserRole } from "../../../utils/checkUserRole";
import ImpressaoHtml from "../../../components/Impressao/ImpressaoHtml";
import PageTitle from "../../../components/PageTitle/PageTitle";
import HorizontalLineIcon from "../../../components/Icon/HorizontalLineIcon";

import { getUnidadeLogado } from '../../../services/UsuarioService';

const AnamneseModal = (props) => {
  const {
    classes,
    openModal,
    handleClose,
    sujeitoAtencao,
    anamneseSelecionado,
    configuracaoImpressaoStore,
    screen,
    handleMinimize,
  } = props;

  const [notification, setNotification] = useState({
    isOpen: false,
    variant: "",
    message: "",
  });
  const [loadingSave, setLoadingSave] = useState(false);
  const [modelosAnamneses, setModelosAnamneses] = useState([]);
  const [modeloAnamneseSelecionado, setModeloAnamneseSelecionado] = useState(
    null
  );
  const [listaPerguntas, setListaPerguntas] = useState([]);
  const [readOnly, setReadOnly] = useState(false);
  const [loadingModelos, setLoadingModelos] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [showDadosImportantes, setShowDadosImportantes] = useState(false);
  const [isPrintMustache, setIsPrintMustache] = useState(false);
  const [dadosParaImpressao, setDadosParaImpressao] = useState(null);
  const [unidadeId, setUnidadeId] = useState(null);

  const profissionalSaudeLogado = JSON.parse(localStorage[`_immortal|profissional_saude_logado`] || null);

  useEffect(() => {
    loadInitialData();
    verifyShowDadosImportantes();
    loadModelosAnamneses();
    const key = keyModeloLocalStorage(sujeitoAtencao.id)
    const localStorageModelo = getLocalStorage(key);

    const localStoragePerguntas = localStorageModelo?.perguntas || []

    const initialModeloAnamnese = () => {
      setListaPerguntas(
        localStoragePerguntas
      )
      setModeloAnamneseSelecionado(
        localStorageModelo
      )
    }
    if (anamneseSelecionado) {
      setReadOnly(true);
      setModeloAnamneseSelecionado(anamneseSelecionado);
      setListaPerguntas(anamneseSelecionado.perguntas);

    } else {

      !!localStorageModelo ? initialModeloAnamnese() : haveAnamnesePadrao()
    }

    configuracaoImpressaoStore.getConfig('ANAMNESE');

  }, []);

  useEffect(() => {
    if (!isPrintMustache && printing) {
      setPrinting(false);
      handleClose();
    }
  }, [isPrintMustache]);

  const loadInitialData = async () => {
    const { id: unidadeId } = await getUnidadeLogado();


    setUnidadeId(unidadeId);
  }

  const verifyShowDadosImportantes = () => {
    const roleEntradaProntuarioRead = checkUserRole('ROLE_ENTRADA_PRONTUARIO_READ');
    setShowDadosImportantes(roleEntradaProntuarioRead && sujeitoAtencao?.informacoesImportantes?.length > 0);
  };

  const loadModelosAnamneses = async () => {
    setLoadingModelos(true);
    const listaModelosAnamneses = await findAllAnamneseModelo({
      searchDTO: {
        search: "",
        ativo: true,
        unidadeId,
        profissionalSaudeId: profissionalSaudeLogado.id
      },
      pageableDTO: {
        pageNumber: 0,
        pageSize: 999,
        sortDir: "ASC",
        sortField: "nome",
      },
    });

    setModelosAnamneses(listaModelosAnamneses?.content);
    setLoadingModelos(false);
  };
  const keyModeloLocalStorage = (id) => {
    return 'modeloAnamnese|' + id
  }
  const validaValorResposta = (tipo, valorResposta) => {
    if (tipo === "DATE") {
      return moment(valorResposta).format("YYYY-MM-DD");
    }
    if (tipo === "TIME") {
      return moment(valorResposta, "HH:mm").format("HH:mm");
    }
    if (tipo === "DATETIME") {
      return moment(valorResposta).format("YYYY-MM-DDThh:mm:ss");
    }
    return valorResposta;
  };

  const verificaValorRespostaValida = () => {
    const respostaValida = modeloAnamneseSelecionado.perguntas.reduce(
      (valida, pergunta) => {
        if (
          pergunta.tipo === "RADIO" ||
          pergunta.tipo === "CHECKBOX" ||
          pergunta.tipo === "COMBOBOX"
        ) {
          const valorRespostaValida = pergunta.respostas.some(
            (item) => item.valorResposta
          );
          const getRespostaOutro = pergunta.respostas.find(
            (item) => item.valorResposta && item.nome === ""
          );
          const valorOutroValida = getRespostaOutro
            ? getRespostaOutro.outro
            : true;

          return valida || (valorRespostaValida && valorOutroValida);
        } else {
          return (
            valida ||
            (!!pergunta.respostas[0]?.valorResposta ||
              !!pergunta.respostas[0]?.valorConvertido)
          );
        }
      },
      false
    );

    return respostaValida;
  };
  const resetLocalStorage = (key) => {
    if (localStorage.hasOwnProperty(key)) {
      localStorage.removeItem(key)
    }
  }
  const getLocalStorage = (key) => {
    if (localStorage.hasOwnProperty(key)) {
      const responseLocalStorage = JSON.parse(localStorage.getItem(key))
      return responseLocalStorage
    }
    return null
  }
  const addPerguntasLocalStorage = (perguntas) => {
    const key = keyModeloLocalStorage(sujeitoAtencao.id)
    localStorage.setItem(key,
      JSON.stringify({ ...modeloAnamneseSelecionado, perguntas: perguntas }));
  }

  const onSave = async (acao) => {
    try {
      setLoadingSave(true);
      const verifica = verificaValorRespostaValida();
      let perguntas = listaPerguntas;
      let modelo = modeloAnamneseSelecionado;

      if (!verifica) {
        const notification = {
          isOpen: true,
          variant: "error",
          message: "Favor preencher todos os campos.",
        };

        showAlertMessage(notification);
        setLoadingSave(false)
        return;
      }

      perguntas.forEach((pergunta, indexPergunta) => {
        pergunta.respostas.forEach((resposta, indexResposta) => {
          resposta.valorResposta = validaValorResposta(
            pergunta.tipo,
            resposta.valorResposta
          );
          if ((pergunta.tipo === "RADIO" || pergunta.tipo === "CHECKBOX")) {
            perguntas[indexPergunta].respostas[indexResposta] = {
              id: resposta.id,
              nome: resposta.outro ? resposta.outro : resposta.nome,
              valorResposta: resposta.valorResposta || false
            }
          };

        })
      });

      modelo = {
        ...modeloAnamneseSelecionado,
        perguntas
      }
      const key = keyModeloLocalStorage(sujeitoAtencao.id)
      await saveAnamnese({
        sujeitoAtencao: { id: sujeitoAtencao.id },
        anamneseModelo: modelo,
      })
      acao === "imprime" ? printAnamnese() : handleClose();
      const notification = {
        isOpen: true,
        variant: "success",
        message: "Anamnese criado com sucesso.",
      };
      showAlertMessage(notification);
      resetLocalStorage(key)

    } catch (error) {
      setLoadingSave(false)
      const notification = {
        isOpen: true,
        variant: "error",
        message: "Erro ao criar a anamnese.",
      };
      showAlertMessage(notification);
    }
  };

  const handleAplicar = () => {
    if (!modeloAnamneseSelecionado) {
      const notification = {
        isOpen: true,
        variant: "error",
        message: "Selecione um modelo de anamnese.",
      };
      showAlertMessage(notification);

      return;
    }

    const perguntasOrdenadas = modeloAnamneseSelecionado.perguntas.sort((a, b) => a.order - b.order)

    selecionarPrimeiraResposta(modeloAnamneseSelecionado);

    setListaPerguntas(perguntasOrdenadas);
  };

  const selecionarPrimeiraResposta = (modeloSelecionado) => {
    const modelo = modeloSelecionado

    modelo.perguntas.forEach((item) => {
      item.tipo === "RADIO" &&
        item.respostas.forEach((item, index) => {
          item.valorResposta = index === 0;
        });
    });
    const key = keyModeloLocalStorage(sujeitoAtencao.id)
    localStorage.setItem(key,
      JSON.stringify(modelo));
    setModeloAnamneseSelecionado(modelo);
    setListaPerguntas(modelo.perguntas);
  };

  const showAlertMessage = (notification) => {
    setNotification(notification);

    const timeoutId = setTimeout(() => {
      closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  const closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      variant: "",
      message: "",
    };
    setNotification(notification);
  };

  const onChange = (posicaoPergunta, e, tipo) => {
    let perguntas = listaPerguntas;
    let value = '';
    if (tipo === "TEXT_AREA" || tipo === "INPUT" || tipo === "NUMBER") {
      value = e.target?.value
    } else {
      value = e;
    }

    perguntas[posicaoPergunta].respostas[0] = {
      valorResposta: value,
    };

    setListaPerguntas([...perguntas]);
    addPerguntasLocalStorage(perguntas);
  };

  const onChangeTipoComMultiplasRespostas = (
    e,
    tipo,
    posicaoResposta,
    posicaoPergunta
  ) => {
    let perguntas = listaPerguntas;
    if (tipo === "RADIO") {
      perguntas[posicaoPergunta].respostas.forEach((item, index) => {
        item.valorResposta = posicaoResposta === index;
      });
    }

    if (tipo === "CHECKBOX") {
      perguntas[posicaoPergunta].respostas.forEach((item, index) => {
        item.valorResposta = !!item.valorResposta;
      });
      perguntas[posicaoPergunta].respostas[
        posicaoResposta
      ].valorResposta = !perguntas[posicaoPergunta].respostas[
        posicaoResposta
      ].valorResposta;
    }

    if (tipo === "COMBOBOX") {
      perguntas[posicaoPergunta].respostas.forEach((item) => {
        item.valorResposta = (item?.id === e?.id) || false;
      });
    }
    setListaPerguntas([...perguntas]);
    addPerguntasLocalStorage(perguntas);
  };

  const onChangeOutro = (e, posicaoResposta, posicaoPergunta) => {
    if (
      listaPerguntas[posicaoPergunta].respostas[posicaoResposta].valorResposta
    ) {
      listaPerguntas[posicaoPergunta].respostas[posicaoResposta].outro =
        e.target.value;
    }

    setListaPerguntas([...listaPerguntas]);
    addPerguntasLocalStorage(listaPerguntas);
  };

  const handleSelectModeloAnamnese = (e) => {
    const key = keyModeloLocalStorage(sujeitoAtencao.id)
    setModeloAnamneseSelecionado(e);
    resetLocalStorage(key)
    localStorage.setItem(key, JSON.stringify(e));
  };

  const handleCopyRecipe = () => {
    setReadOnly(false);
    const convertePerguntas = modeloAnamneseSelecionado.perguntas.map(pergunta => {
      const perguntas = convertePropriedadeValorConvertidoEmResposta(pergunta);

      return perguntas;
    });

    let modeloAdaptado = modeloAnamneseSelecionado;
    modeloAdaptado.perguntas = convertePerguntas;

    setModeloAnamneseSelecionado(modeloAdaptado);
  };

  const convertePropriedadeValorConvertidoEmResposta = (pergunta) => {
    let perguntaAdaptada = pergunta;

    perguntaAdaptada.respostas.forEach((item, index) => {
      perguntaAdaptada.respostas[index].valorResposta = item?.valorConvertido
      delete item.valorConvertido
    })

    return perguntaAdaptada;
  }

  const handlePrint = () => {
    anamneseSelecionado?.id ? printAnamnese() : onSave("imprime");
  };

  const printAnamnese = () => {
    setPrinting(true);
    const dadosConvertidoModeloAnamneseSelecionado = modeloAnamneseSelecionado.perguntas.map(pergunta => {
      const perguntas = formataDados(pergunta);

      return perguntas;
    });

    const dadosParaImpressao = {
      id: modeloAnamneseSelecionado.id,
      nome: modeloAnamneseSelecionado.nome,
      perguntas: dadosConvertidoModeloAnamneseSelecionado
    }

    setDadosParaImpressao(dadosParaImpressao);

    setIsPrintMustache(true);
  };

  const formataDados = (pergunta) => {
    let dados = pergunta;
    const haveResposta = pergunta?.respostas?.length > 0;

    if (!dados.respostas) {
      dados.respostas = [];
    }

    if (pergunta.tipo === "DATE") {
      if (dados.respostas.length > 0) {
        dados.respostas[0].valorImpressao = haveResposta ?
          moment(dados.respostas[0]?.valorConvertido).format("DD/MM/YYYY") : "";
      }
      return dados;
    }

    if (pergunta.tipo === "DATETIME") {
      if (dados.respostas.length > 0) {
        dados.respostas[0].valorImpressao = haveResposta ?
          moment(dados.respostas[0]?.valorConvertido).format("DD/MM/YYYY hh:mm") : "";
      }
      return dados;
    }

    if (pergunta.tipo === "CHECKBOX" || pergunta.tipo === "RADIO") {
      dados.respostas.forEach((item, index) => {
        dados.respostas[index].nome = item.nome === "" && !item.valorConvertido ? "Outro" : item.nome;
        dados.respostas[index].valorImpressao = item?.valorConvertido || item.valorResposta;
      });
    }

    dados.respostas.forEach((item, index) => {
      dados.respostas[index].valorImpressao = item?.valorConvertido || item?.valorResposta || "";
    });

    return dados;
  };

  const tamanhoTipo = tipo => {
    if (tipo === "DATE" || tipo === "TIME" || tipo === "DATETIME") {
      return 4
    } else {
      return 12
    }
  };

  const selectPaperClass = () => {
    if (readOnly) {
      if (showDadosImportantes) {
        return classes.paperSemModelosComInfo;
      } else {
        return classes.paperSemModelos;
      }
    } else {
      if (showDadosImportantes) {
        return classes.paperFullSize;
      } else {
        return classes.paper;
      }
    }
  }

  const paperClass = selectPaperClass();

  const getPageTitle = () => {
    const acao = readOnly ? "Visualizar" : "Nova";

    return `${screen} - ${acao} anamnese`
  };

  const haveAnamnesePadrao = async () => {
    const anamnesePadrao = await findAnamnesePadrao()
    setListaPerguntas(anamnesePadrao?.perguntas)
    setModeloAnamneseSelecionado(anamnesePadrao)
  }
  const handleCloseAnamneseModal = () => {
    const key = keyModeloLocalStorage(sujeitoAtencao.id)
    resetLocalStorage(key)
    handleClose()

  }

  return (
    <>
      <PageTitle title={getPageTitle()} />
      <Dialog
        classes={{
          paper: paperClass
        }}
        open={openModal}
        fullWidth
      >
        {showDadosImportantes &&
          <div>
            <InformacoesImportantesModal />
          </div>
        }
        <div className={classes.anamneseModalContent}>
          <div className={classes.conteudoEsquerdo}>
            <div className={classes.header} style={readOnly ? { borderTopRightRadius: '15px' } : null}>
              <div className={classes.tituloButtons}>
                <div className={classes.titulo}> Anamnese </div>
                <div className={classes.buttons}>
                  {modeloAnamneseSelecionado?.id && readOnly && (
                    <Fab
                      className={classes.buttonCopy}
                      onClick={() => handleCopyRecipe()}
                    >
                      <DuplicateIcon color="primary" />
                    </Fab>
                  )}

                  {!readOnly && (
                    <Fab
                      className={classes.buttonActionSave}
                      onClick={onSave}
                      disabled={loadingSave}
                    >
                      {loadingSave ? <CircularProgress size={25} color={'#fff'} /> : <SaveIcon />}
                    </Fab>
                  )}
                  <Fab
                    className={classes.buttonActionPrint}
                    onClick={handlePrint}
                    disabled={loadingSave}
                  >
                    {loadingSave ? <CircularProgress size={25} color={'#fff'} /> : <PrintIcon />}
                  </Fab>
                  {!readOnly && (
                    <Fab
                      className={classes.buttonActionMinimize}
                      onClick={handleMinimize}
                    >
                      <HorizontalLineIcon color='white' />
                    </Fab>
                  )}
                  <Fab
                    className={classes.buttonActionClose}
                    onClick={handleCloseAnamneseModal}>
                    <CloseIcon />
                  </Fab>
                </div>
              </div>
              {readOnly ? (
                <Grid>
                  <InputSelectComLabel
                    label="Modelo"
                    value={modeloAnamneseSelecionado.nome}
                    readOnly
                  />
                </Grid>
              ) : (
                <Grid className={classes.modeloButton}>
                  <Grid item xs={10}>
                    <InputSelectComLabel
                      label="Selecione o modelo"
                      placeholder="Selecione"
                      isSelect
                      value={modeloAnamneseSelecionado}
                      onChange={(e) => handleSelectModeloAnamnese(e)}
                      options={modelosAnamneses || []}
                      getOptionLabel={(option) => option.nome}
                      getOptionValue={(option) => option.id}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.gridBotaoAplicar}>
                    <div className={classes.botaoAplicar} onClick={handleAplicar}>
                      <p className={classes.labelBotaoAplicar}>Aplicar</p>
                    </div>
                  </Grid>
                </Grid>
              )}
            </div>
            <Grid container direction="row" className={[classes.contentContainer, readOnly ? classes.readOnlyContainer : null]}>
              <div className={classes.content}>
                {listaPerguntas?.map((item, index) => {
                  return (
                    <Grid item xs={tamanhoTipo(item.tipo)} key={index}>
                      <TiposCampoAnamnese
                        readOnly={readOnly}
                        label={item.pergunta}
                        tipocampo={item.tipo}
                        respostas={item.respostas}
                        onChange={(e, tipo) => onChange(index, e, tipo)}
                        onChangeTipoComMultiplasRespostas={(e, tipo, posicaoResposta) =>
                          onChangeTipoComMultiplasRespostas(e, tipo, posicaoResposta, index)
                        }
                        onChangeOutro={(e, posicaoResposta) =>
                          onChangeOutro(e, posicaoResposta, index)
                        }
                        textLength={listaPerguntas[index]?.respostas[0]?.valorResposta?.length || 0}
                      />
                    </Grid>
                  );
                })}
              </div>
            </Grid>
          </div>
          {!readOnly && (
            <div className={classes.modelos}>
              <div className={classes.infoModelos}>
                <DescriptionIcon />
                <span className={classes.tituloModelos}> Modelos </span>
              </div>
              {loadingModelos ? (
                <div className={classes.notFoundContainer}>
                  <CircularProgress className={classes.cicularModelos} />
                </div>
              ) : (
                <div className={classes.listaModelos}>
                  {modelosAnamneses.map((item, index) => {
                    return (
                      <Grid
                        container
                        direction={"column"}
                        wrap={"nowrap"}
                        key={index}
                        onClick={() => selecionarPrimeiraResposta(item)}
                        className={classes.card}
                      >
                        <ItemList
                          size={12}
                          field={"Modelo:"}
                          value={item.nome}
                          classes={{
                            listItem: classes.listItemMargin,
                            itemTitle: classes.colorItemTitle,
                            itemValueSub: classes.colorItemValue,
                          }}
                        />
                        <ItemList
                          size={12}
                          field={"Qtde. perguntas:"}
                          value={`${item.perguntas.length} perguntas`}
                          classes={{
                            listItem: classes.listItemMargin,
                            itemTitle: classes.colorItemTitle,
                            itemValueSub: classes.colorItemValue,
                          }}
                        />
                      </Grid>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
        {isPrintMustache &&
          <ImpressaoHtml
            isPrintMustache={isPrintMustache}
            sujeitoAtencaoId={sujeitoAtencao.id}
            handlePrintMustache={() => setIsPrintMustache(false)}
            htmlStringComponent={<AnamnesePdfDocument
              dadosAnamnese={dadosParaImpressao}
              sujeitoAtencao={sujeitoAtencao}
            />}
          />}

        <Notification
          close={closeAlertMessage}
          reset={closeAlertMessage}
          isOpen={notification.isOpen}
          variant={notification.variant}
          message={notification.message}
        />
      </Dialog>
    </>
  );
};

const style = {
  paper: {
    display: "flex",
    flexDirection: "row",
    overflow: "unset",
    height: 560,
    background: Colors.commons.grayLight,
    borderRadius: 10,
    maxWidth: '960px'
  },
  paperFullSize: {
    display: "flex",
    flexDirection: "row",
    overflow: "unset",
    height: 560,
    background: "transparent",
    borderRadius: 10,
    maxWidth: "1172px",
  },
  paperSemModelosComInfo: {
    display: "flex",
    flexDirection: "row",
    overflow: "unset",
    height: 560,
    background: "transparent",
    borderRadius: 10,
    maxWidth: "962px"
  },
  paperSemModelos: {
    maxWidth: "744px",
    display: "flex",
    flexDirection: "row",
    overflow: "unset",
    height: 560,
    background: Colors.commons.grayLight,
    borderRadius: 10,
  },
  conteudoEsquerdo: {
    display: "flex",
    flexDirection: "column",
    width: " 100%",
  },
  anamneseModalContent: {
    display: 'flex',
    width: '100%',
    maxWidth: '960px'
  },
  header: {
    height: 100,
    background: Colors.commons.white,
    padding: "30px 35px 23px",
    display: "flex",
    flexDirection: "column",
    boxShadow:
      "10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05)",
    zIndex: 1,
    borderRadius: "15px 0 0 0",
  },
  contentContainer: {
    width: "100%",
    height: "100%",
    padding: "0px 17px 14px",
    borderBottomLeftRadius: "15px",
    background: "#E5E5E5",
    overflow: "hidden",
  },
  content: {
    backgroundColor: "#FFF",
    padding: '23px 35px 21px',
    width: "100%",
    height: "calc(100% - 34px - 14px)",
    borderRadius: "0 0 10px 10px",
    overflow: 'auto'
  },
  readOnlyHeader: {
    borderTopRightRadius: '15px'
  },
  readOnlyContainer: {
    borderBottomRightRadius: '15px'
  },
  container: {
    backgroundColor: "#FFF",
    width: "100%",
    height: "100%",
    borderRadius: "0 0 10px 10px"
  },
  titulo: {
    fontSize: 18,
    fontWeight: 700,
    color: Colors.commons.secondary,
    borderRadius: "10px 10px 0 0",
  },
  tituloButtons: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonActionSave: {
    background: Colors.commons.secondary,
    width: "35px",
    height: "35px",
    color: "white",
    marginLeft: 10,
    boxShadow: "none",
    "&:hover": {
      background: Colors.primary.dark,
    },
  },
  buttonActionClose: {
    background: Colors.error.main,
    width: "35px",
    height: "35px",
    color: "white",
    marginLeft: 10,
    boxShadow: "none",
    "&:hover": {
      background: Colors.error.dark,
    },
  },
  buttonActionPrint: {
    width: "35px",
    height: "35px",
    color: "white",
    marginLeft: 10,
    boxShadow: "none",
    background: Colors.commons.yellow,
    "&:hover": {
      background: "#e0a048",
    },
  },
  buttonActionMinimize: {
    backgroundColor: Colors.commons.gray10,
    width: '35px',
    height: '35px',
    color: 'white',
    marginLeft: 10,
    '&:hover': {
      background: Colors.commons.gray10Dark,
    },
  },
  buttonCopy: {
    width: "35px",
    height: "35px",
    boxShadow: "none",
    color: 'white',
  },
  modelos: {
    maxWidth: "275px",
    width: "100%",
    background: Colors.commons.secondary,
    borderRadius: "0 10px 10px 0",
    padding: "30px 28px 28px 27px",
    display: "flex",
    flexDirection: "column",
  },
  botaoAplicar: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: 35,
    color: "white",
    borderRadius: "100px",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    background: Colors.commons.secondary,
    "&:hover": {
      background: Colors.primary.dark,
    },
  },
  labelBotaoAplicar: {
    color: "white",
    fontWeight: 500,
  },
  modeloButton: {
    display: "flex",
  },
  gridBotaoAplicar: {
    marginLeft: "10px",
    alignItems: "end",
    display: "flex",
  },
  card: {
    background: Colors.commons.white,
    borderRadius: "10px",
    marginBottom: 8,
    padding: 8,
    cursor: "pointer",
  },
  listItemMargin: {
    marginBottom: "5px",
  },
  colorItemTitle: {
    color: Colors.commons.gray7,
  },
  colorItemValue: {
    color: Colors.commons.gray9,
    borderBottom: "none",
    fontWeight: 500,
    height: 21,
  },
  infoModelos: {
    display: "flex",
    background: "rgba(0, 0, 0, 0.5)",
    borderRadius: 87,
    padding: "4px 12px",
    color: Colors.commons.white,
    justifyContent: "center",
    maxWidth: "106px",
    alignItems: "center",
    height: "24px",
    marginBottom: "16px",
  },
  tituloModelos: {
    fontSize: "12px !important",
    marginLeft: 10,
  },
  listaModelos: {
    overflow: "auto",
  },
  cicularModelos: {
    color: "#fff",
  },
  notFoundContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",
    margin: 10,
  },
};

const AnamneseWithSyles = withStyles(style)(AnamneseModal);
export default inject("configuracaoImpressaoStore", "usuarioStore")(
  AnamneseWithSyles
);
