import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./adicionarDadosStyle";
import { TextField } from "../../../components/TextField";
import AddIcon from "../../../components/Icon/AddIcon";
import { Grid } from "@material-ui/core";
import { getUnidadeLogado } from "../../../services/UsuarioService";
import { FindAllCampoMensagemSistemaParaMensagemSistema } from "../../../services/MensagensService";


const AdicionarDados = (props) => {
  const {
    classes,
    onclose,
    formatCampos,
  } = props;

  useEffect(()=>{
    carregaCamposSistema();
  },[])

  const [camposSistemaResponse ,setCamposSistemaResponse]=useState([]);
  const [novoCampo, setNovoCampo]=useState("");
  const[isModelosDocumentos,setModelosDocumentos]=useState(false);
  const[isLoading, setIsLoading]=useState(false);

  const carregaCamposSistema = async() => {
    setIsLoading(true);
    try {
      const unidadeAtual = await getUnidadeLogado();
      const unidadeId = unidadeAtual && unidadeAtual.id ? unidadeAtual.id : null;
      const redeId =
        unidadeAtual && unidadeAtual.rede && unidadeAtual.rede.id
          ? unidadeAtual.rede.id
          : null;
      const response = await FindAllCampoMensagemSistemaParaMensagemSistema();
      setCamposSistemaResponse(response);
    }catch(error){
      throw error;
    }finally{
    }
    setIsLoading(false);
  }

 const  handleNovaMensagem=(event)=>{
  event.stopPropagation();
  setNovoCampo(event.target.value);
 }

  return (
    <div className={classes.headerContentAdicionarDados}>
      <div className={classes.headerAdicionarDados}>
        <div className={classes.title}>
          Adicionar Dados
        </div>
        <div onClick={onclose} className={classes.buttonClose}>
          <CloseIcon />
        </div>
      </div>
      {isModelosDocumentos &&
        <div className={classes.boxInfosAdicionarDados}>
          <h3 className={classes.titleCriarCampo}>Criar Campo</h3>

          <div className={classes.contentTextAdd}>
            <TextField
              placeholder={"Campo de texto"}
              InputProps={{
                classes: {
                  input: classes.InputTextField,
                },
              }}
              value={novoCampo}
              onChange={event => handleNovaMensagem(event)}

            >
            </TextField>
            <div className={classes.addIcon} onClick={() => handleNovaMensagem(novoCampo)} >
              <AddIcon />
            </div>
          </div>
        </div>}
      <Grid className={classes.containerGrid}>
        {!isLoading &&
          camposSistemaResponse.map((item, i) => {
            return (
              <div className={classes.camposContainer} onClick={() => formatCampos(item)} key={item.id}>
                {item.descricao}
              </div>
            )
          })
        }
      </Grid>
    </div>
  );
};

export default withStyles(styles)(AdicionarDados);
