import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import styles from "../../assets/jss/pages/profissionalSaudeStyle";
import Header from "../../template/Header/Header";
import Footer from "../../components/Footer/Footer";
import PanelLeft from "../../components/PanelLeft/PanelLeft";
import PerfilDadosPrincipais from "./DadosPrincipais/PerfilDadosPrincipais";
import PerfilDadosPublicos from "./DadosPublicos/PerfilDadosPublicos";
import AvaliacaoVizi from "./AvaliacaoVizi/AvaliacaoVizi";
import UnidadePerfilVizi from "./UnidadePerfilVizi/UnidadePerfilVizi";
import RecebimentosVizi from "./RecebimentosVizi/RecebimentosVizi";
import Profile from "../../template/Header/Profile";
import { Route } from "react-router-dom";
import PerfilSubMenu from "./PerfilSubMenu";
import PerfilUnidade from "./Unidade/PerfilUnidade";
import ImageProfile from "../../components/ImageProfile/ImageProfile";
import localStorageService, { ACCESS_TOKEN_KEY } from "../../services/storage";
import { buildUrlFotoPerfil } from "../../config/config";
import {
  findByUnidadeLogadaPerfilPublico,
  findByUsuarioLogadoPerfilPublico,
} from "../../services/PerfilPublicoService";
import DateFilter from "./components/DateFilter";
import moment from "moment";
import { checkUserRole } from "../../utils/checkUserRole";

@inject(
  "profissionalSaudeStore",
  "usuarioStore",
  "unidadeStore",
  "perfilPublicoStore"
)
@observer
class Perfil extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingImage: false,
      accessToken: null,
      unidadeUtilizaVIZI: false,
      canViewFinanceiro: false,
      canUpdateUnidade: false,
    };
    this.carregarDados();
  }

  async componentDidMount() {
    const { perfilPublicoStore } = this.props;

    const unidadeUtilizaVIZI = await checkUserRole("ROLE_UNIDADE_UPDATE");
    const canViewFinanceiro = await checkUserRole("ROLE_TITULO_PARCELA_CREATE");
    this.setState({ unidadeUtilizaVIZI, canViewFinanceiro });

    if (!(perfilPublicoStore.perfilPublicoProfissional?.id || perfilPublicoStore.perfilPublicoUnidade?.id)) {
      await this.loadViziPerfis();
    }
    
  }

  carregarDados = async () => {
    const { profissionalSaudeStore, unidadeStore, usuarioStore } = this.props;
    this.startLoadingInfos();
    try {
      await profissionalSaudeStore.carregaProfissionalSaude();
      await profissionalSaudeStore.carregarInformacoesUsuarioLogado();
      await usuarioStore.findProfissionalSaudeLogado();
      const profissionalSaudeAtual = await usuarioStore.getProfissionalSaudeAtual();
      await profissionalSaudeStore.findByIdProfissionalSaudePublico(
        profissionalSaudeAtual.id
      );
      profissionalSaudeStore.insereCartaoCredito();
      await unidadeStore.getUnidadeLogada();
      await unidadeStore.getUnidadePublicaLogada();
      const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);
      await this.loadViziPerfis();
      this.setState({ accessToken: accessToken });
    } catch (error) {
      console.log(error);
    }
    this.stopLoading();
  };

  loadViziPerfis = async () => {
    const { perfilPublicoStore } = this.props;
    try {
      const unidadeLogadaPerfilPublico = await findByUnidadeLogadaPerfilPublico();
      if (unidadeLogadaPerfilPublico) {
        perfilPublicoStore.perfilPublicoUnidade = {
          ...perfilPublicoStore.perfilPublicoUnidade,
          ...unidadeLogadaPerfilPublico,
        };
        perfilPublicoStore.checkPerfilUnidadeIsComplete();
      } else {
        perfilPublicoStore.initPerfilPublicoUnidadeDefault();
      }
      
    }catch (error) {
        console.error(error);
        perfilPublicoStore.initPerfilPublicoProfissionalDefault();
    }
    try{
      const profissionalLogadoPerfilPublico = await findByUsuarioLogadoPerfilPublico();
      if (profissionalLogadoPerfilPublico) {
        perfilPublicoStore.perfilPublicoProfissional = {
          ...perfilPublicoStore.perfilPublicoProfissional,
          ...profissionalLogadoPerfilPublico,
        };
        perfilPublicoStore.checkPerfilProfissionalIsComplete();
      } else {
        perfilPublicoStore.initPerfilPublicoProfissionalDefault();
      }

    } catch (error) {
      console.error(error);
      perfilPublicoStore.initPerfilPublicoProfissionalDefault();
    }
  };

  startLoadingInfos = () => {
    this.props.unidadeStore.isLoadingInfo = true;
    this.props.profissionalSaudeStore.isLoadingInfo = true;
  };

  stopLoading = () => {
    this.props.unidadeStore.isLoadingInfo = false;
    this.props.profissionalSaudeStore.isLoadingInfo = false;
  };

  update = async () => {
    const { location, unidadeStore, profissionalSaudeStore } = this.props;
    if (location.pathname === "/perfil/unidade") {
      await unidadeStore.updateUnidadeLogada();
    } else {
      await profissionalSaudeStore.updateProfissionalSaudeLogado();
    }
  };

  cancelar = () => {
    this.props.history.push({
      pathname: "/",
    });
  };

  handleSaveImage = (image) => {
    this.salvarImagem(image);
  };

  salvarImagem = async (image) => {
    this.setState({ loadingImage: true });
    await this.props.usuarioStore.salvarImagem(image);
    this.setState({ loadingImage: false });
  }

  applyFilterOnSelectDateRange = async () => {
    const { perfilPublicoStore } = this.props;
    perfilPublicoStore.perfilPublicoProfissionalLogadoAvaliacoes = [];
    await perfilPublicoStore.findAllAvaliacaoProfissionalLogado();
  };

  setAvaliacaoFilter = async (range) => {
    const { perfilPublicoStore } = this.props;
    perfilPublicoStore.avaliacaoFilter = {
      dataInicial: moment(range.dataInicial).format("YYYY-MM-DD"),
      dataFinal: moment(range.dataFinal).format("YYYY-MM-DD"),
    };
  };

  resetAvaliacaoPageable = () => {
    const { perfilPublicoStore } = this.props;
    perfilPublicoStore.avaliacaoPageable = {
      pageSize: 10,
      pageNumber: 0,
    };
  };

  setGetAllFlag = (value) => {
    this.props.perfilPublicoStore.withoutDateRange = value;
  };

  render() {
    const {
      classes,
      match,
      profissionalSaudeStore,
      usuarioStore,
      perfilPublicoStore,
    } = this.props;
    const { profissionalSaude } = this.props.profissionalSaudeStore;
    const {
      loadingImage,
      accessToken,
      unidadeUtilizaVIZI,
      canViewFinanceiro,
    } = this.state;

		const canViewRecebimentosUnidade = unidadeUtilizaVIZI && canViewFinanceiro;

    return (
      <div className={classes.root}>
        <PanelLeft style={{ padding: "0" }}>
          <Grid item>
            <Header>
              <Grid
                item
                container
                alignItems={"center"}
                justify={"center"}
                xs={12}
              >
                <h3 className={classes.titleHeader}>Perfil</h3>
              </Grid>
            </Header>
          </Grid>
          <PerfilSubMenu match={match} />
        </PanelLeft>
        <div className={classes.content}>
          <Header>
            <Grid container justify={"space-between"} alignItems={"center"}>
              <Grid item className={classes.headerPerfil}>
                {accessToken && (
                  <ImageProfile
                    image={buildUrlFotoPerfil(
                      usuarioStore.fotoPerfil ||
                        profissionalSaudeStore.profissionalSaude?.usuario
                          ?.fotoPerfil,
                      accessToken
                    )}
                    nome={profissionalSaudeStore.profissionalSaude.nome}
                    possuiAgenda={
                      profissionalSaudeStore.profissionalSaude.possuiAgenda
                    }
                    numeroConselho={`${profissionalSaudeStore.profissionalSaude
                      ?.conselhoProfissionalSaude?.sigla ||
                      ""} ${profissionalSaudeStore.profissionalSaude
                      .numeroConselho || ""} ${profissionalSaudeStore
                      .profissionalSaude?.ufConselho || ""}`}
                    telefonePrincipal={
                      profissionalSaudeStore.profissionalSaude.telefonePrincipal
                    }
                    onSave={this.handleSaveImage}
                    loading={loadingImage}
                  />
                )}
              </Grid>
              <Grid item className={classes.profilePerfil}>
                {this.props.location.pathname === "/perfil/avaliacoes" && (
                  <Grid>
                    <DateFilter
                      filter={perfilPublicoStore.avaliacaoFilter}
                      setFilter={this.setAvaliacaoFilter}
                      onApply={this.applyFilterOnSelectDateRange}
                      setGetAllFlag={(value) => this.setGetAllFlag(value)}
                      resetPage={this.resetAvaliacaoPageable}
                    />
                  </Grid>
                )}
                <Profile />
              </Grid>
            </Grid>
          </Header>
          <Route exact path="/perfil" component={PerfilDadosPrincipais} />
          <Route exact path="/perfil/unidade" component={PerfilUnidade} />
          <Route exact path="/perfil/publico" component={PerfilDadosPublicos} />
          <Route
            exact
            path="/perfil/unidade-vizi"
            component={UnidadePerfilVizi}
          />
          {profissionalSaude.utilizaVIZI && profissionalSaude.perfilPublico && (
            <Route exact path="/perfil/avaliacoes" component={AvaliacaoVizi} />
          )}
          {canViewFinanceiro && (
            <Route
              exact
              path="/perfil/recebimentos-profissional"
              component={RecebimentosVizi}
            />
          )}
          {canViewRecebimentosUnidade && (
            <Route
              exact
              path="/perfil/recebimentos-unidade"
              component={RecebimentosVizi}
            />
          )}
        </div>

        <Footer />
      </div>
    );
  }
}

export default withStyles(styles)(Perfil);
