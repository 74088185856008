import React, { useState } from "react";
import {
  ContainerProcedimentos,
  ContentButtons,
  Row,
  TextFieldSearchStyled,
} from "../../styles";
import Field from "../../components/Field";
import { InputForm } from "../../../../../components/Modal/Input";
import { findAllCid10Subcategoria } from "../../../../../services/InternacaoService";
import { Button } from "../../../../../components/ui/Buttons";
import Table from "../../../../../components/Table/Table";
import { cidDefault, columnsCids } from "../../constants";
import { withStyles } from "@material-ui/core";

const Cids = ({classes, internacao, handleField }) => {
  const { cids } = internacao || [];

  const [cidNovo, setCidNovo] = useState({
    ...cidDefault,
    sequencia: cids.length + 1,
  });
  const [isEdit, setIsEdit] = useState(false);

  const { cid, descricao, sequencia, diagnostico } = cidNovo || {};

  const handleCid = (value, field) => {
    setCidNovo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleAdicionaCid = () => {
    const dadosCids = cids;
    dadosCids.push(cidNovo);

    setCidNovo({
      cid: null,
      descricao: "",
      diagnostico: "",
      sequencia: cids.length + 1,
    });

    handleField("cids", dadosCids);
  };

  const handleCidSelect = (value) => {
    const { descricao, descricaoAbreviada } = value || {};

    if(value) {
      setCidNovo((prevState) => ({
        ...prevState,
        descricao,
        cid: value,
        diagnostico: descricaoAbreviada,
      }));

    } else {
      setCidNovo(null)
    };
  };

  const handleEdit = (data) => {
    setCidNovo(data);
    setIsEdit(true);
  };

  const resetCidNovo = () => {
    setCidNovo({
      ...cidDefault,
      sequencia: cids.length + 1,
    });
  };

  const handleCancelarCidEdit = () => {
    resetCidNovo();
    setIsEdit(false);
  };

  const handleSalvarCidEdit = () => {
    const dadosCid = cids;
    const index = dadosCid.findIndex(
      (procedimento) => procedimento.id === cidNovo.id
    );

    dadosCid[index] = cidNovo;

    handleField("cids", dadosCid);
    setIsEdit(false);
    resetCidNovo();
  };

  const handleDelete = (index) => {
    const dadosCids = cids;
    dadosCids.splice(index, 1);

    handleField("cids", dadosCids);
  };

  const loadAllCid = async (search, loadedOptions, { page }) => {
    const searchDTO = {
      search,
      pageNumber: page,
      pageSize: 20,
      sortDir: "ASC",
      sortField: "descricao",
      ativo: true,
    };

    const response = await findAllCid10Subcategoria({ searchDTO });
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <ContainerProcedimentos>
      <Row>
        <Field label="Sequencia" width="10%">
          <InputForm
            value={sequencia}
            onChange={(e) => handleCid(e.target.value, "sequencia")}
            disabled={true}
          />
        </Field>
        <Field label="Código CID" width="25%">
          <TextFieldSearchStyled
            placeholder="Selecione"
            loadOptions={loadAllCid}
            getOptionLabel={(option) => option.descricaoAbreviada}
            getOptionValue={(option) => option.id}
            value={cid}
            onChange={handleCidSelect}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </Field>
        <Field label="Descrição" width="25%">
          <InputForm
            value={descricao}
            onChange={(e) => handleCid(e.target.value, "descricao")}
          />
        </Field>
        <Field label="Diagnóstico" width="40%">
          <InputForm
            value={diagnostico}
            onChange={(e) => handleCid(e.target.value, "diagnostico")}
          />
        </Field>
        {isEdit ? (
          <ContentButtons>
            <Button
              bgColor="#FB7676"
              style={{ height: 40 }}
              padding={0}
              onClick={handleCancelarCidEdit}
            >
              Cancelar
            </Button>
            <Button
              bgColor={"#00B0AE"}
              style={{ height: 40 }}
              padding={0}
              onClick={handleSalvarCidEdit}
              disabled={!cid?.id}
            >
              Salvar
            </Button>
          </ContentButtons>
        ) : (
          <Button
            bgColor={"#707C97"}
            style={{ height: 40 }}
            padding={0}
            onClick={handleAdicionaCid}
            disabled={!cid?.id}
          >
            Adicionar
          </Button>
        )}
      </Row>
      <Table
        columns={columnsCids({ handleDelete, handleEdit })}
        dados={cids || []}
        classes={{
          table: classes.table,
          tableHead: classes.tableHeader,
          tabela: classes.rootTable
        }}
      />
    </ContainerProcedimentos>
  );
};

const styles = {
  table: {
    paddingTop: "4px",
    "& td": {
      padding: "0 16px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      color: "#868686",
    },
    width: '100%',
    maxHeight: "calc(100% - 200px)"
  },
  rootTable: {
    width: '100%',
    height: 'calc(100% - 55px)',
    overflow: 'scroll',
    maxWidth: '960px',
    overflowX: 'hidden'
  },
  tableHeader: {
    background: 'red',
    zIndex: 0,
    "& tr": {
      height: "40px",
    },
    "& th": {
      background: "#f2f2f2",
      padding: "0 16px",
      textAlign: "start",
      "&:first-child": {
        borderRadius: "8px 0 0 0",
      },
      "&:last-child": {
        borderRadius: "0 8px 0 0",
      },
    },
  },
}

export default withStyles(styles)(Cids);
