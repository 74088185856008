import React from "react";
import moment from "moment";
import string from "../../../utils/string";
import { calculaParcela, calculaSubtotal } from "../../../utils/calculaSubtotal";
import { applyCurrencyMask } from "../../../utils/CurrencyMask";

const OrcamentoPdf = ({ orcamento }) => {
  const columnColor = (index) => {
    if (index % 2 !== 0) {
      return { backgroundColor: "#F2F2F2" };
    }
    return "";
  };

  const {
    orcamentoProcedimentos,
    profissionalSaude,
    dataVencimento,
    sujeitoAtencao,
    totalParcelas,
    tipoPagamento,
    valorEntrada,
    observacao,
    desconto,
    convenio,
    valor,
    nome,
  } = orcamento || {};

  const valorParcelas = totalParcelas && calculaParcela(
    {
      valorEntrada,
      valorTotal: valor,
      totalParcelas: totalParcelas.value || totalParcelas
    }
  )

  const descontoAdaptado = {
    tipoDesconto: {
      value: desconto?.tipoDesconto?.value  || desconto?.tipoDesconto
    },
    valorDesconto: desconto?.valorDesconto
  }
  const subtotalOrcamento = calculaSubtotal({
    valorTotal: valor,
    desconto: descontoAdaptado?.valorDesconto,
    tipoDesconto: descontoAdaptado?.tipoDesconto,
  })


  const orcamentosChecked = orcamentoProcedimentos?.filter((item) => {
    return item.checked || true;
  });

  const totalParcelasAdaptado = totalParcelas && totalParcelas?.value ? totalParcelas.value : totalParcelas
  
  const tipoDesconto = desconto?.tipoDesconto?.value || desconto?.tipoDesconto
  const descontoValor = desconto && (tipoDesconto === "PERCENTIL" ) ? `${desconto?.valorDesconto}%` : `R$${desconto?.valorDesconto}`
  
  return (
    <>
      <div style={styles.titulo}>Orcamento {nome}</div>
      <div style={styles.tituloNome}>Paciente: {sujeitoAtencao.nome}</div>
      <div style={styles.tituloNome}>Profissional: {profissionalSaude.nome}</div>

      <div style={styles.header}>

        <div>
          <span style={styles.itemHeader}>Convênio:</span> <div>{convenio ? string.truncate(convenio.descricao, 15) : "--"}</div>
        </div>

        <div>
          <span style={styles.itemHeader}>Entrada:</span><div> R$ {valorEntrada ? valorEntrada.toFixed(2) : "00,00"}</div>
        </div>

        <div >
          <span style={styles.itemHeader}>Parcelas:</span> <div>{!isNaN(totalParcelasAdaptado) && totalParcelasAdaptado !== null ? `${totalParcelasAdaptado} x ${valorParcelas}` : '--'}</div>
        </div>

        <div>
          <span style={styles.itemHeader}>Desconto:</span> <div>{descontoValor ? descontoValor : "00,00"}</div>
        </div>

        <div>
          <span style={styles.itemHeader}>Pagamento:</span> <div>{tipoPagamento === "A_PRAZO" ? "A prazo" : "A vista"}</div>
        </div>

        <div >
          <span style={styles.itemHeader}>Subtotal:</span><div>  {subtotalOrcamento}</div>
        </div>

        <div>
          <span style={styles.itemHeader}>Data vencimento:</span> <div>{dataVencimento ? moment(dataVencimento).format("DD/MM/YYYY") : "--"}</div>
        </div>

      </div>
      <div style={styles.subTitulo}>
      </div>
      <div style={styles.headerColumn}>
        <div style={styles.columnProcedimento}>Procedimento</div>
        <div style={styles.columnQuantidade}>Quantidade</div>
        <div style={styles.columnValor}>Valor</div>
      </div>
      <div>
        {orcamentosChecked.map((item, index) => {
          return (
            <div
              key={item.id}
              style={{ ...styles.containerColuna, ...columnColor(index) }}
            >
              <div style={styles.columnProcedimento}>
                {item?.procedimento?.nome || item?.procedimentoOdontologico?.procedimento?.nome}
              </div>
              <div style={styles.columnQuantidade}>{item.quantidade}</div>
              <div style={styles.columnValor}>
                { applyCurrencyMask(item.valorUnitario) }
              </div>
            </div>
          );
        })}
        <div style={styles.total}> Total: R${valor.toFixed(2)}</div>
      </div>
      {observacao && <div style={styles.observacao}>Observação: {observacao}</div>}
    </>
  );
};

const styles = {
  titulo: {
    marginLeft: "0px",
    marginBottom: "10px",
    marginTop: "5px",
    paddingBottom: "5px",
    textAlign: "center",
    fontWeight: 700,
  },
  subTitulo: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #505050",
    marginBottom: "15px",
    paddingBottom: "5px",
    fontWeight: 700,
    justifyContent: "center",
    gap: "8px",
  },

  headerColumn: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 700,
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  containerColuna: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 0",
  },
  columnProcedimento: {

    display: "flex",
    width: "50%",
    justifyContent: "flex-start",
    paddingLeft: "5px",

  },
  column: {
    width: "30%",

  },
  columnQuantidade: {
    display: "flex",
    width: "25%",
    justifyContent: "center",
  },
  columnValor: {
    display: "flex",
    width: "25%",
    justifyContent: "flex-end",
    paddingRight: "5px",
  }, header: {
    textAlign: "center",
    display: "flex",
    gap: "20px",
    padding: "5px",
    justifyContent: "space-between"
  },
  tituloNome: {
    display: "flex",
    width: "100%",
    marginLeft: "0px",
    marginBottom: "10px",
    marginTop: "5px",
    paddingBottom: "5px",
    textAlign: "center",
  },
  itemHeader: {
    fontWeight: 600
  },
  observacao: {
    paddingTop: "20px",
    width: "100%",
    fontSize: "11px",
  },
  total: {
    display: "flex",
    justifyContent: "flex-start",
    fontWeight: 700,
    paddingTop: "5px"
  }


};

export default OrcamentoPdf;
