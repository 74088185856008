import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core";
import { CircularProgress, Checkbox } from "@material-ui/core";
import Scroll from "../../../../components/InfiniteScroll/Scroll";
import InputMaskCurrency from "../../../../components/Input/InputMaskCurrency";

const ConvenioList = ({
  classes,
  handleChange,
  loadingModal,
  convenios,
  lastScroll,
  loadConvenios,
  handleChangeCheckbox,
}) => {
  return (
    <div className={classes.content}>
      <Scroll
        loadMore={loadConvenios}
        hasMore={!lastScroll}
        pageStart={0}
        initialLoad={false}
        className={classes.scrollContainerModelos}
      >
        <div className={classes.titles}>
          <div className={classes.title}> Selecione o convênio </div>
          <div className={classes.title}>Valor</div>
        </div>
        {convenios.length > 0 &&
          convenios.map((itemLista, index) => (
            <div className={classes.linha} key={index}>
              <div className={classes.checkboxDescricao}>
                <Checkbox
                  color={"primary"}
                  onChange={() => handleChangeCheckbox(itemLista)}
                  checked={itemLista.checked}
                />
                <div className={classes.convenio}>
                  {itemLista.convenio?.descricao ??
                    itemLista.convenios.descricao}
                </div>
              </div>
              <div className={classes.contentValorVigencia}>
                <InputMaskCurrency
                  name={"valor"}
                  value={itemLista?.valor === 0 ? "" : itemLista?.valor}
                  className={classNames(
                    classes.inputValor,
                    !itemLista.checked && classes.inputValorDesabilitado
                  )}
                  onChange={(e) => handleChange(e, itemLista)}
                  disabled={!itemLista?.checked}
                />
              </div>
            </div>
          ))}
        {loadingModal && (
          <div className={classes.contentLoading}>
            <CircularProgress />
          </div>
        )}
      </Scroll>
    </div>
  );
};

const styles = {
  content: {
    height: 320,
    overflow: "auto",
  },
  titles: {
    display: "flex",
    justifyContent: "space-between",
    position: "sticky",
    top: 0,
    zIndex: 1,
    background: "#fff",
    width: "100%",
  },
  title: {
    fontSize: "10px",
    color: "#868686",
    marginRight: "7px",
  },
  convenio: {
    color: "#868686",
  },
  linha: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "4px",
  },
  inputValor: {
    background: "#F2F2F2",
    padding: "4px 4px 4px 8px",
    width: "90px",
    height: "30px",
    border: "1px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
  },
  checkboxDescricao: {
    display: "flex",
    "&>span": {
      color: "#868686",
    },
  },
  contentLoading: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  scrollContainerModelos: {
    height: "100%",
  },
  inputValorDesabilitado: {
    color: "#E5E5E5",
  },
  contentValorVigencia: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  dataVigencia: {
    width: "165px",
    height: "40px",
    borderRadius: "8px",
    background: "#f2f2f2",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textWrap: "nowrap",
    fontSize: "12px",
    color: "#505050",
    fontWeight: "500",
  },
  titleValorVigencia: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-start",
    gap: "100px",
  },
  titleSemValorVigencia: {
    gap: "100px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "30px",
  },
  config: {
    cursor: "pointer",
    height: "14px",
    padding: "0",
    background: "#ffffff",
    border: "none",
    boxShadow: "none",
  },
};

export default withStyles(styles)(ConvenioList);
