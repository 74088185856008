import React, { forwardRef } from "react";
import styled from "styled-components";

import moment from "moment";
import string from "../../../../utils/string";

const EtiquetaContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: center;
  align-items: center;
`;

const Field = styled.div`
  display: flex;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-content: center;
`;

const Text = styled.span`
  font-size: 12px !important;
  line-height: 1.1;
  font-family: Poppins;
  text-align: start;
`;

const BigText = styled.span`
  font-size: 18px !important;
  line-height: 1.1;
  font-family: Poppins;
  text-align: start;
`;
const MediumText = styled.span`
  font-size: 14px !important;
  line-height: 1.1;
  font-family: Poppins;
  text-align: start;
`;
const Label = styled.span`
  font-size: 10px !important;
  line-height: 1.1;
  font-family: Poppins;
  text-align: start;
  font-weight: 600;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Etiqueta = forwardRef(
  (
    { dadosAgendamento, etiquetaSize, etiquetaTipo, etiquetaSizeSelected },
    ref
  ) => {
    const {
      nome,
      documento,
      dataNascimento,
      convenio,
      procedimento,
      profissionalSaude,
      internacao,
      tipo,
      sexo,
      data,
    } = dadosAgendamento || {};

    const { leitoOcupadoAtual } = internacao || {};
    const { leito } = leitoOcupadoAtual || {};

    const { contentWidth, contentHeight } = etiquetaSize || {};

    const idade = moment().diff(moment(dataNascimento, "YYYY-MM-DD"), "years");
    const dataNascimentoFormated = moment(dataNascimento, "YYYY-MM-DD").format(
      "DD/MM/YYYY"
    );

    const leitoNome = leito?.nome || "";
    const procedimentosFormated = procedimento.replace(/[0-9-]/g, "");
    const length = etiquetaSize?.contentWidth <= 87 ? 40 : 55;
    const lengthNomeProfissional = etiquetaSize?.contentWidth <= 87 ? 30 : 40;

    const EtiquetaProcedimento = () => {
      return (
        <EtiquetaContent
          ref={ref}
          style={{
            width: `${contentWidth}mm`,
            height: `${contentHeight}mm`,
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Content>
            <Field>
              <Text style={{ justifyContent: "end" }}>
                <Label>Paciente:</Label>
                {string.truncate(nome, 40)}
              </Text>
            </Field>
            <Field>
              <Text style={{ gap: "10px" }}>
                <Label>Médico:</Label>
                {` ${string.truncate(
                  profissionalSaude,
                  lengthNomeProfissional
                )} ${moment(data).format("DD/MM/YYYY")}`}
              </Text>
            </Field>
            <Field style={{ gap: "10px" }}>
              <Text>
                <Label>Dt.Nasc:</Label>
                {dataNascimentoFormated}
              </Text>
              <Text>
                <Label>Idade:</Label>
                {idade}
              </Text>
              <Text>
                <Label>Sexo:</Label>
                {string.capitalize(sexo)}
              </Text>
            </Field>
            <Field>
              <Text>
                <Label>Convênio:</Label>
                {string.capitalize(convenio?.label)}{" "}
                <Text>
                  <Label>Leito:</Label>
                  {leitoNome}
                </Text>
              </Text>
            </Field>
            <Field>
              <Text>
                <Label>Cirurgia:</Label>
                {string.truncate(procedimentosFormated, length)}
              </Text>
            </Field>
          </Content>
        </EtiquetaContent>
      );
    };
    const EtiquetaAgendamento = () => {
      return (
        <EtiquetaContent
          ref={ref}
          style={{ width: `${contentWidth}mm`, height: `${contentHeight}mm` }}
        >
          <Field style={{ fontWeight: "bold", padding: "10px" }}>
            <BigText>{string.truncate(nome, 40)}</BigText>
          </Field>
          <Field style={{ gap: "24px" }}>
            <MediumText>{dataNascimentoFormated}</MediumText>
          </Field>
          <Field style={{ gap: "24px", fontWeight: "500" }}>
            <MediumText>{tipo?.label}</MediumText>
          </Field>
        </EtiquetaContent>
      );
    };

    const EtiquetaPaciente = () => {
      return (
        <EtiquetaContent
          ref={ref}
          style={{ width: `${contentWidth}mm`, height: `${contentHeight}mm` }}
        >
          <Field style={{ fontWeight: "bold", padding: "5px" }}>
            <BigText>{string.truncate(nome, 40)}</BigText>
          </Field>
          <Field style={{ gap: "24px" }}>
            <MediumText>{dataNascimentoFormated}</MediumText>
          </Field>
        </EtiquetaContent>
      );
    };

    const EtiquetaAcompanhante = () => {
      return (
        <EtiquetaContent
          ref={ref}
          style={{ width: `${contentWidth}mm`, height: `${contentHeight}mm` }}
        >
          <Field style={{ fontWeight: "bold", padding: "10px" }}>
            <BigText>ACOMPANHANTE</BigText>
          </Field>
          <Field style={{ gap: "24px" }}>
            <MediumText>Leito: {leitoNome}</MediumText>
          </Field>
        </EtiquetaContent>
      );
    };

    const EtiquetaPadrao = () => {
      return (
        <EtiquetaContent
          ref={ref}
          style={{ width: `${contentWidth}mm`, height: `${contentHeight}mm` }}
        >
          <Field style={{ fontWeight: "bold" }}>
            <Text>{string.truncate(nome, 40)}</Text>
          </Field>
          <Field style={{ gap: "24px" }}>
            <Text>{dataNascimentoFormated}</Text>
            <Text>Idade: {idade}</Text>
          </Field>
          <Field style={{ gap: "24px" }}>
            <Text>CPF: {documento}</Text>
            {convenio?.value && <Text>Convenio: {convenio?.label}</Text>}
          </Field>
          <Field style={{ gap: "24px" }}>
            <Text>Médico: {profissionalSaude}</Text>
          </Field>
          {leito?.id && (
            <Field style={{ gap: "24px" }}>
              <Text>Leito: {leitoNome || ""}</Text>
            </Field>
          )}
          <Field>
            <Text>{string.truncate(procedimento, 40)}</Text>
          </Field>
        </EtiquetaContent>
      );
    };

    const renderEtiqueta = () => {
      let componente;
      switch (etiquetaTipo) {
        case "AGENDAMENTO":
          componente = EtiquetaAgendamento();
          break;
        case "PROCEDIMENTO":
          componente = EtiquetaProcedimento();
          break;
        case "PACIENTE":
          componente = EtiquetaPaciente();
          break;
        case "ACOMPANHANTE":
          componente = EtiquetaAcompanhante();
          break;
        default:
          componente = EtiquetaPadrao();
      }

      return componente;
    };

    return <div>{renderEtiqueta()}</div>;
  }
);

export default Etiqueta;
