import Api from "../config/api";

export const findAllSala = (variables) => Api.post("", {
    query: `
      query ($search: String, $ativo: Boolean, $idProcedimento: UUID, $pageableDTO: PageableDTOInput) {
        findAllSala(search: $search, ativo: $ativo, idProcedimento: $idProcedimento, pageableDTO: $pageableDTO) {
          last
          content {
            id
            nome
            ativo
            internacao
            profissionaisSaude{
              id
              nome
            }
            procedimentos {
              id
              nome
              convenioProcedimentos {
                convenio {
                  id
                  descricao
                }
                procedimento {
                  id
                  nome
                }
                valor
              }
            }
          }
        }
      }`,
  variables: variables
});

export const findByIdSala = idSala => Api.post("", {
  query: `
    query ($idSala: UUID) {
      findByIdSala(idSala: $idSala) {
        nome
        ativo
        internacao
        procedimentos{
          id
          nome
        }
        profissionaisSaude{
          id
          nome
        }
      }
    }`,
  variables: { idSala }
});

export const saveSala = sala => {
  const method = sala.id ? 'updateSala' : 'createSala';
    return Api.post("", {
      query: `
        mutation ($sala: SalaInput) {
          ${method}(sala: $sala) {
            id
            nome
            unidade {
              id
            }
            procedimentos{
              id
              nome
              convenioProcedimentos{
                convenio{
                  id
                }
                procedimento{
                  id
                }
                valor
              }
            }
            profissionaisSaude{
              id
              nome
            }
          }
        }`,
    variables: { sala }
  });
};

export const salaNomeJaExistente = async search => {
  const response =  await Api.post("", {
  query: `
    {
      salaNomeJaExistente(search: "${search}")
    }`
  })
  
  return response.data.data.salaNomeJaExistente
};