import React, {Component} from "react";
import JoditEditor from "jodit-react";
import 'jodit';
import 'jodit/build/jodit.min.css';
import Api, {NestorApi} from "../../config/api";
import imageCompression from "browser-image-compression";
import {buildUrlImagemDocumentoTemporario} from "../../config/config";
import localStorageService, {ACCESS_TOKEN_KEY} from "../../services/storage";
import Notification from "../Notification";

class ModelEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      imagens: [],
      lastCursorPosition: null,
      notification: {
        isOpen: false,
        message: '',
        variant: ''
      }
    };
  }

  async componentDidMount() {
    const {isDuplicar, id} = this.props;

    if (id && !isDuplicar) {
      const id = parseInt(this.props.id);
      setTimeout(async () => {
        const response = await Api.post("", {
          query: `
                query ($documentId: Long){
                          findByIdDocumentoModelo(id: $documentId){
                            nome
                            modelo
                            ativo
                            tipo
                            profissionalSaude {
                              id
                              nome
                            }
                          }
                }`,
          variables: { documentId: id }
        });

        const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);
        const { modelo, nome, ativo, profissionalSaude, tipo } = response.data.data.findByIdDocumentoModelo;

        if(this.props.modeloDocumentoStore) {
          this.props.modeloDocumentoStore.profissionalSaudeSelected = profissionalSaude;
        }
        this.setState({
          value: modelo.toString().replaceAll('{accessToken}',accessToken.toString())
        }, () => {
          this.updateCampos();
        });
        this.props.updateName(nome);
        this.props.updateStatus(ativo);
        this.props.updateTipoAssinatura && this.props.updateTipoAssinatura(tipo);
      }, 1000);
    }

    if (isDuplicar && this.props.textoHtml) {
      this.setState({
        value: this.props.textoHtml
      }, () => {
        this.updateCampos();
      });
    }
  }

  openNotification = (message, variant = null) => {
    this.setState({
      notification:{
        isOpen:  true,
        message: message,
        variant: variant
      }
    });

  }

  closeNotification = () => {
    this.setState({
      notification: {
        isOpen: false
      }
    })
  }

  resetNotification = () => {
    this.setState({
      notification: {
        isOpen: false,
        message: '',
        variant: ''
      }
    });
  }

  updateCampos = (value) => {
    if(!value){
      return;
    }
    const modelo = value.toString("html");

    const camposUsuario = modelo.match(/#{(.*?)}/g);
    if (camposUsuario) {
      this.props.updateCampos('entrada', camposUsuario);
    }
    const camposSistemaSujeito = modelo.match(/\${sujeitoAtencao.(.*?)}/g);
    if (camposSistemaSujeito) {
      this.props.updateCampos('sujeitoAtencao', camposSistemaSujeito);
    } else {
      this.props.updateCampos('sujeitoAtencao', []);
    }
    const camposSistemaProfissionalSaude = modelo.match(/\${profissionalSaude.(.*?)}/g);
    if (camposSistemaProfissionalSaude) {
      this.props.updateCampos('profissionalSaude', camposSistemaProfissionalSaude);
    } else {
      this.props.updateCampos('profissionalSaude', []);
    }
    const camposDoSistema = modelo.match(/\${sistema.(.*?)}/g);
    if (camposDoSistema) {
      this.props.updateCampos('sistema', camposDoSistema);
    } else {
      this.props.updateCampos('sistema', []);
    }
    const camposSistemaSujeitoInternacao = modelo.match(/\${sujeitoAtencaoInternacao.(.*?)}/g);
    if (camposSistemaSujeitoInternacao) {
      this.props.updateCampos('sujeitoAtencaoInternacao', camposSistemaSujeitoInternacao);
    } else {
      this.props.updateCampos('sujeitoAtencaoInternacao', []);
    }
  }

  onChange = value => {
    this.updateCampos(value);

    this.setState({value: value});
  };

  insertText = (el, tipo) => {
    this.jodit.selection.setCursorIn(this.state.lastCursorPosition);
    this.jodit.selection.insertHTML(`${el}&nbsp`);
  };

  handleImage = async (image) => {
    try {
      const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);

      const responseNestor = await this.uploadNestor(image);

      if(responseNestor.data) {
        return {
          id: responseNestor.data?.id,
          url: buildUrlImagemDocumentoTemporario(responseNestor.data?.id, accessToken),
        };
      }
      
      this.openNotification("Não foi possivel realizar o upload da imagem", 'error');

      return null;
    }catch(error){
      console.error('erro',error);
      this.openNotification("Não foi possivel realizar o upload da imagem", 'error');
    }
  }

  uploadNestor= async(image) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };

    const response = await fetch(image);
    const blob = await response.blob();
    const compressedFile = await imageCompression(blob, options);

    const formData = new FormData();
    formData.append('file', compressedFile);

    return NestorApi.post('/resource/AppHealth', formData);
  }

  handleSubmitFromChild = () => {
    return this.state.value.toString("html");
  };

  saveCursorPosition = () => {
      this.setState({lastCursorPosition: this.jodit.selection.current()});
  }

  handleSubmitImagesFromChild = () => {
    return this.state.imagens;
  };

  jodit;
  setRef = jodit => this.jodit = jodit;

  config = {
    sourceEditor: "area",
    readonly: this.props.disabled || false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    language: "pt_br",
    uploader: {
      insertImageAsBase64URI: true,
      defaultHandlerSuccess: e => {
        e.files.forEach(async (file) => {
          let image= await this.handleImage(file);
          if(image){
            this.jodit.selection.insertImage(image.url,null,300);
          }
        });
      }
    },
    autofocus: true,
    colorPickerDefaultTab: 'text',
    toolbarAdaptive: false,
    buttons: ['source','bold','italic','underline','strikethrough', 'superscript', 'subscript','fontsize','ul','ol','align','brush','image','hr','table','|','undo','redo','fullsize'],
    // removeButtons: [...this.props.disabledButtons],
    events: {
      afterInit: (instance) => {
        this.jodit = instance;
      },
      afterInsertImage: (image) => {
        const idImage = image.src.substring(image.src.length-36);
        this.setState({imagens: [...this.state.imagens, {id: idImage}]});
      }
    },
    disablePlugins: ["inline-popup"]
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
        <div className={(classes.editor, classes.scrollEditor)}>
          <JoditEditor
              editorRef={this.setRef}
              value={value}
              config={this.config}
              tabIndex={1}
              onChange={newContent => this.onChange(newContent)}
              onBlur={this.saveCursorPosition}
          />
          <Notification
              close={() => this.closeNotification()}
              reset={() => this.resetNotification()}
              isOpen={this.state.notification.isOpen}
              variant={this.state.notification.variant}
              message={this.state.notification.message}
          />
        </div>
    );
  }
}

export default ModelEditor;
