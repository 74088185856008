import React from "react";
import moment from "moment";

const ProntuarioContent = (props) => {
  const { mensagens, sujeitoAtencao } = props;

  const idadeSujeito = sujeitoAtencao?.dataNascimento
        ? moment().diff(sujeitoAtencao.dataNascimento, "years")
        : null;

  return (
    <>
      <div style={styles.textBold}>
        {sujeitoAtencao.nome || ""}
        {idadeSujeito
            ? `- ${idadeSujeito} ano${idadeSujeito > 1 ? "s" : ""}`
            : null}
      </div>
      <div style={styles.itens}>
        {mensagens.map((item, index) => {
          let mensagemInativacao = "";
          const { dataInativacao, motivo, inativador } =
            item.entradaProntuarioInativacao || "";

          if (item.tipo === "ARQUIVO" || item.tipo === "IMAGEM") {
            return null;
          }

          if (!item.ativo) {
            const nomeInativador = inativador?.nome;
            const diaInativacao = moment(dataInativacao).format("DD/MM/YYYY");
            const horaInativacao = moment(dataInativacao).format("HH:mm");
            mensagemInativacao = ` - (Registro inativado por ${nomeInativador} em ${diaInativacao} às ${horaInativacao})`;
          }

          return (
            <div key={index} style={styles.text}>
              {moment(item.dataHoraLancamento.split("[")[0]).format(
                "DD/MM/YYYY HH:mm"
              )}
              {` - ${item.profissionalSaude.nome}: `}
              <span
                style={!item.ativo ? styles.textoRiscado : styles.tamanhoFont}
              >
                {item.texto}
              </span>
              {!item.ativo && `${mensagemInativacao}`}
              {!item.ativo && <span>- Motivo: {motivo}</span>}
              {item?.procedimentoRealizado?.descricao && (
                <div>Observação: {item?.procedimentoRealizado?.descricao}</div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

const styles = {
  itens: {
    marginBottom: '15px',
    fontSize: '14px',
    textAlign: 'justify',
  },

  text: {
    fontSize: '14px',
    marginBottom: '15px',
  },

  textoRiscado: {
    textDecoration: 'line-through',
    fontSize: '14px',
  },

  tamanhoFont: {
    fontSize: '14px',
  },

  textBold: {
    fontWeight: 700,
    fontSize: '14px',
    marginBottom: '15px',
  },
}

export default ProntuarioContent;
