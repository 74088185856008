import React, { useState } from "react";
import {
  ContainerProcedimentos,
} from "../../styles";
import { Button } from "../../../../../components/ui/Buttons";
import Table from "../../../../../components/Table/Table";
import { columnsOutrasDespesas } from "./constants";
import { withStyles } from "@material-ui/core";
import { findAllProduto } from "../../../../../services/InternacaoService";
import { ReactSelect } from "../../../../../components/Select/SelectSearch";
import { tissTipoDespesa } from "../../constants";
import { TextFieldSearch } from "../../../../../components/TextField";

const OutrasDespesas = ({ classes, internacao, handleField }) => {

  const [novoProduto, setNovoProduto] = useState(null)
  const [tipoDespesa,setTipoDespesa]=useState(null)

  const { internacaoProdutos } = internacao

  const handleAdicionarProduto = () => {
    const produtosSelecionados = internacaoProdutos || [];
    const list = [...produtosSelecionados, novoProduto]
    handleField("internacaoProdutos", list)
    setNovoProduto(null)
    setTipoDespesa(null)
  };

  const handleDelete = (data) => {
    const produtosSelecionados = internacaoProdutos;
    const index = produtosSelecionados.findIndex(
      (produto) => produto.id === data.id
    );

    const listaFiltrada = produtosSelecionados.filter((item, i) => i !== index)

    handleField("internacaoProdutos", listaFiltrada);
  };

  const loadAllProdutos = async (search, loadedOptions, { page }) => {
    const variables = {
      search,
      pageableDTO: {
        pageNumber: page,
        pageSize: 20,
        sortDir: "ASC",
        sortField: "nome",
      },
      tipo:tipoDespesa?.value
    };

    const response = await findAllProduto(variables);
    const { content, last } = response?.data?.data?.findAllProduto

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleChangeSearch = (e) => {
    setNovoProduto({
      id: null,
      produto: e
    })
  }

  const handleChangeTipo = (e) => {
    setTipoDespesa(e)
  }

  return (
    <ContainerProcedimentos>
      <div className={classes.content}>
        <div className={classes.contentSelect}>
          <div className={classes.wrapperSelects}>
            <div className={classes.selectTipo}>
              <label className={classes.label}>Tipo:</label>
              <ReactSelect
                options={tissTipoDespesa}
                placeholder={"Selecione"}
                value={tipoDespesa}
                onChange={handleChangeTipo}
                isClearable
              />
            </div>
            <div className={classes.selectProduto}>
              <label className={classes.label}>Produtos:</label>
              <TextFieldSearch
                key={tipoDespesa?.value}
                placeholder="Selecione"
                loadOptions={loadAllProdutos}
                getOptionLabel={(option) => option.nome}
                getOptionValue={(option) => option.id}
                value={novoProduto?.produto || ""}
                onChange={(e) => handleChangeSearch(e)}
                withPaginate
                debounceTimeout={300}
                additional={{
                  page: 0,
                }}
                classNotched={classes.notchedOutline}
              />
            </div>
          </div>
        </div>

        <Button
          bgColor={"#707C97"}
          style={{ height: 40 }}
          padding={0}
          onClick={handleAdicionarProduto}
          disabled={!novoProduto}
        >
          Adicionar
        </Button>

      </div>
      <div className={classes.contentTable}>
        <Table
          columns={columnsOutrasDespesas({ handleDelete })}
          dados={internacaoProdutos}
        />
      </div>
    </ContainerProcedimentos>
  );
};

const styles = {
  label: {
    color: '#868686',
    fontWeight: '500',
    fontSize: '14px'
  },
  content: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "flex-end",
  },
  contentSelect: {
    width: "calc(100% - 120px)"
  },
  wrapperSelects: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
  },
  selectTipo: {
    display: "flex",
    flexDirection: "column",
    width: "200px"
  },
  selectProduto: {
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 216px)"
  },
  notchedOutline: {
    border: '0',
  },
  contentTable: {
    overflow: 'auto',
  }
}

export default withStyles(styles)(OutrasDespesas);
