import React, { useState, useEffect } from 'react';
import { Button } from '../../../../components/ui/Buttons';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { findBase64ByAgendamentoId } from '../../../../services/AssinaturaPresencaService';

const styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
  },
  contentButton: {
   display:'none'
  }
}

const Assinatura = observer(({ assinaturaRef, isDisabled,objView ,sujeitoAtencaoStore}) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [ctx, setCtx] = useState(null);

  useEffect(() => {
    if (assinaturaRef.current) {
      const canvas = assinaturaRef.current;
      const context = canvas.getContext('2d');
      context.strokeStyle = 'black';
      context.lineWidth = 3;
      context.lineCap = 'round';
      setCtx(context);

      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    }
  }, [assinaturaRef]);

  useEffect(() => {
    const loadImage = async () => {
      if (objView?.assinaturaPresenca && assinaturaRef.current) {  
        const canvas = assinaturaRef.current;
        const ctx = canvas.getContext('2d');
        const img = new Image();
  
        try {
          img.src = await getImageCanvas(); 
        } catch (error) {
          console.error("Erro ao carregar a imagem Base64:", error);
          return;
        }
  
        img.onload = () => {
          if (!canvas) return; 
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        };
      }
    };
  
    loadImage(); 
  
    return () => {
      if (assinaturaRef.current) {
        const canvas = assinaturaRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
    };
  }, [assinaturaRef, objView]);
  

  const getImageCanvas = async ()=>{
    try {
      const image = await findBase64ByAgendamentoId({agendamentoId:objView?.id})
      return `data:image/png;base64,${image}`
    } catch (error) {
      sujeitoAtencaoStore.openNotification("Erro ao carregar assinatura","error")
    }
  }

  const startDrawing = (e) => {
    if (isDisabled) return;
    setIsDrawing(true);
    const { offsetX, offsetY } = getCanvasCoordinates(e);
    ctx.beginPath();
    ctx.moveTo(offsetX, offsetY);
  };

  const draw = (e) => {
    if (!isDrawing || isDisabled) return;
    const { offsetX, offsetY } = getCanvasCoordinates(e);
    ctx.lineTo(offsetX, offsetY);
    ctx.stroke();
  };

  const stopDrawing = () => {
    if (isDisabled) return;
    setIsDrawing(false);
    ctx.closePath();
  };

  const clearCanvas = () => {
    if (isDisabled) return;
    ctx.clearRect(0, 0, assinaturaRef.current.width, assinaturaRef.current.height);
  };

  const getCanvasCoordinates = (e) => {
    const rect = assinaturaRef.current.getBoundingClientRect();
    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    const clientY = e.touches ? e.touches[0].clientY : e.clientY;
    return {
      offsetX: clientX - rect.left,
      offsetY: clientY - rect.top,
    };
  };

  return (
    <div style={styles.content}>
      <canvas
        ref={assinaturaRef}
        style={{ 
          width: '100%',
           height: '200px',
            border: '1px solid #868686',
            borderRadius: '16px',
            background: '#f2f2f2',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            opacity: isDisabled ? 0.5 : 1,
           }}
        onMouseDown={!isDisabled && startDrawing}
        onMouseMove={!isDisabled && draw}
        onMouseUp={!isDisabled && stopDrawing}
        onMouseLeave={!isDisabled && stopDrawing}
        onTouchStart={startDrawing}
        onTouchMove={draw}
        onTouchEnd={stopDrawing}

        width={600}
        height={200}
      />
      <div style={styles.contentButton}>
        <Button onClick={clearCanvas} id={"canvas-clear"}>Limpar</Button>
      </div>
    </div>
  );
});

const stores=["sujeitoAtencaoStore"]
export default inject(stores)(Assinatura);