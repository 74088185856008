import React, { Suspense, lazy } from "react";
import debounce from "lodash.debounce";
import { inject, observer } from "mobx-react";
import { CircularProgress, withStyles } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import PrintIcon from "@material-ui/icons/Print";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from "../../components/Dialog/Dialog";
import ButtonTransferir from "../../components/Button/ButtonTransferir";
import ButtonConfirm from "../../components/Button/ButtonConfirm";
import ConfirmarHorarioAgendamentoModal from "../../components/Modal/ConfirmarHorarioAgendamentoModal";
import InfosConsultaAtendimentoModal from "../../components/Modal/InfosConsultaAtendimentoModal";
import { ReactSelect } from "../../components/Select/SelectSearch";
import { ReactSelectCreate } from "../../components/Select/SelectSearch";
import AtendimentoModalSujeitoList from "./AtendimentoModalSujeitoList";
import AtendimentoModalListaProcedimentos from "./AtendimentoModalListaProcedimentos";
import AtendimentoModalSujeitoAgendamentos from "./AtendimentoModalSujeitoAgendamentos";
import { withRouter } from "react-router";
import {
  InputForm,
  InputCPFForm,
  SelectForm,
  InputDateForm,
  InputTimeForm,
  InputPhoneForm
} from "../../components/Modal/Input";
import ItemGrid from "../../components/Modal/ItemGrid";
import moment from "moment";
import Notification from "../../components/Notification";
import InfosSujeitoAtencaoModal from "../../components/Modal/InfosSujeitoAtencaoModal";
import { Fab, Tooltip } from "@material-ui/core";
import String from "../../utils/string";
import {
  findByCPFAndTelefone,
  findByIdSujeitoAtencaoImpressao,
  findSujeitoAtencaoByDocumento,
} from "../../services/SujeitoAtencaoService";
import { findUnidadeLogada } from "../../services/UnidadeService";

import FichaSujeitoAtencaoPdfDocument from "../../template/pdf/sujeito-atencao/fichaSujeitoAtencao";
import TransferirAgendamentos from "../../components/Modal/Atendimento/TransferirAgendamentos/TransferirAgendamentos";
import RecorrenciaModal from "./RecorrenciaModal";
import MultiToggleButtons from "../../components/Button/MultiToggleButtons";
import { response } from "../../config/config";
import { findAllSala } from "../../services/SalaService";
import { findAllProcedimentoOdontologico, findAllProcedimentosAtendimento } from "../../services/ProcedimentoService";
import AfericaoVitalModal from "../Atendimento/AfericaoVital/AfericaoVitalModal";
import {Dates} from "../../utils";
import ImpressaoHtml from "../../components/Impressao/ImpressaoHtml";
import { TextFieldSearch } from '../../components/TextField'

import PhoneInput from 'react-phone-input-2';
import pt from 'react-phone-input-2/lang/pt.json'
import classNames from 'classnames';
import ConfirmEditRecorrencia from './ConfirmEditRecorrencia'
import { InputDocumentos } from "../../components/Input/InputDocumentos";
import getPaisAtuacao from "../../utils/getPaisAtuacao";
import validaDocumentoEspanha from "../../utils/validacaoDocumentos"
import string from "../../utils/string"
import ButtonWhatsapp from "../../components/Button/ButtonWhatsApp";
import { goToWhatsApp } from "../../utils/goToWhatsApp";
import PageTitle from "../../components/PageTitle/PageTitle";
import AtendimentoModalTabPagamento from "./ModalTabs/AtendimentoModalTabPagamento";
import TooltipCard from "../../components/TooltipCard";
import {
  styles,
  StyledItemGridIsConsultaRemota,
  StyledItemGridListaNegra,
  StyledWarning
} from "./AtendimentoModalStyles";
import  {getDadosMensagem, getMensagemTeleconsulta}  from "./utils/getDadosMensagem";
import VincularSujeitoAlertDialog from "./Prontuario/VincularSujeitoAlertDialog";
import ModalConfirmacao from "../../components/Modal/ModalConfirmacao/ModalConfirmacao";
import ButtonsTabPagamento from "./ModalTabs/ButtonsTabPagamento";
import Parcelamento from "../Financeiro/Lancamento/Parcelamento";
import { checkUserRole } from "../../utils/checkUserRole";

import { modalConfirmacaoDefault } from "../../stores/Atendimento.store";
import dates from "../../utils/dates";
import FinanceiroMenuIcon from "../../components/Icon/FinanceiroMenu";
import AfericaoVitalIcon from "../../components/Icon/AfericaoVitalIcon";
import AvaliacaoAntropometricaIcon from "../../components/Icon/AvaliacaoAntropometricaIcon";
import AvaliacaoAntropometricaModal from "./AvaliacaoAntropometrica/AvaliacaoAntropometricaModal";
import InfoIconFilled from "../../components/Icon/InfoIconFilled";
import { verificarAgendamentoRetorno } from "../../services/AgendamentoService";
import ListIcon from "../../components/Icon/ListIcon";
import WarningIcon from "../../components/Icon/WarningIcon";
import { getWhitelabelNome } from "../../services/WhitelabelService";
import DocumentOnePageIcon from "../../components/Icon/DocumentOnePage";
import AtendimentoModalEtiquetas from './AtendimentoModalEtiquetas';
import { getUnidadeLogado } from "../../services/UsuarioService";
import InternacaoModal from "./Internacao/InternacaoModal";
import InternacaoIcon from "../../components/Icon/IconInfo";
import { findSujeitoAtencaoInternacaoByAgendamentoId } from "../../services/InternacaoService";
import { internacaoDefault } from "./Internacao/constants";
import { findAllProfissionalSaudeEExterno } from "../../services/ProfissionalSaudeService";
import localStorageService, { CURRENT_AGENDA_OWNER, PROFISSIONAL_SAUDE_LOGADO_KEY } from "../../services/storage";
import ModalOrcamentosListByAgendamento from "../../components/Modal/ModalOrcamentosListByAgendamento/ModalOrcamentosListByAgendamento";
import GuiaConsultaIcon from "../../components/Icon/GuiaConsultaIcon";
import { linkGuiaFaturamento } from "../../services/FinanceiroService";

const AuditoriaTab = lazy(() => import('../../components/Auditoria/Auditoria'));

const copiarLinkIcon = require("../../assets/img/svg/copiar-icon.svg");
const deleteIcon = require("../../assets/img/svg/delete-icon.svg");

@inject(
  "atendimentoStore",
  "sujeitoAtencaoStore",
  "extratoStore",
  "filtroHeaderStore",
  "googleAnalyticsStore",
  "procedimentoStore",
  "configuracaoImpressaoStore",
  "unidadeStore",
  "odontogramaStore",
  "auditoriaStore",
)
@observer
class AtendimentoModal extends React.Component {
  constructor(...args) {
    super(...args);
    this.showFinanceiroAzulControle = this.props.unidadeStore?.configuracaoUnidade?.azulControleAtivado && !this.props.unidadeStore?.configuracaoUnidade?.utilizaFinanceiroAppHealth;
    this.tabs = this.showFinanceiroAzulControle ?
    {
      AGENDAMENTO: 0,
      PROCEDIMENTO: 1,
      AUDITORIA: 2,
    }
    :
    {
      AGENDAMENTO: 0,
      PAGAMENTO: 1,
      PROCEDIMENTO: 2,
      AUDITORIA: 3,
    };

    this.state = {
      loadingImpressao: false,
      submitted: false,
      openConfirmarHorarioAgendamento: false,
      openTransferirAgendamentos: false,
      notification: {
        isOpen: false,
        message: "",
        variant: "",
      },
      searchListaNegra: {
        telefone: "",
        cpf: "",
      },
      listaNegra: "",
      procedimentoEdit: null,
      procedimento: null,
      sujeitoAtencaoRecuperado: null,
      printing: false,
      unidade: null,
      openModalRecorrencia: false,
      tabSelected: 0,
      tabs: {
      AGENDAMENTO: 0,
      PAGAMENTO: 1,
      PROCEDIMENTO: 2,
      AUDITORIA: 3,
      },
      modificacoes: [],
      modificacoesPage: 0,
      salas: [],
      procedimentos: [],
      openModalAfericaoVital: false,
      isPrintMustache: false,
      openConfirmaEditRecorrencia: false,
      dadosRecorrencia: null,
      isExcluirAgendamento: false,
      paisAtuacao: '',
      utilizaTelefoneInternacional: false,
      showDocumentoWarning: false,
      openModalAveliacaoAntropometrica: false,
      showFinanceiroAzulControle: false,
      openModalEtiquetaIdentificacao: false,
      openModalMensagemWhatsapp:false,
      consultaListaNegraDados: {
        cpf: '',
        telefone: '',
      },
      openModalInternacao: false,
      dadosInternacao: internacaoDefault,
      isProfissionalOdontologico:false,
      procedimentosOdontologicosPaciente:[],
      openModalOrcamentosVinculadoAgendamento: false,

    };
    this.profissionalSaudeRef = React.createRef();
    this.unidadeRef = React.createRef();
    this.props.atendimentoStore.carregaDocumentosTipo();
    this.getPaisAtuacao();
    this.debounceOnChange = debounce(this.debounceOnChange, 500);
  }

  async componentDidMount() {
    const { atendimentoStore, auditoriaStore, filtroHeaderStore } = this.props;
    const { tipoProfissionalLogado, isModalSala } = atendimentoStore;
    await this.getModalTabs();

    if (isModalSala || tipoProfissionalLogado === "tecnico") {
      filtroHeaderStore.listarSalas()
    }

    if (this.props.sujeitoAtencaoStore.objView.id && this.props.sujeitoAtencaoStore.sujeitoToSetOnAgendamento) {
      this.selectSujeito(this.props.sujeitoAtencaoStore.objView);
    }
    
    if (filtroHeaderStore?.profissionalSaude) {
      this.profissionalSaudeRef.current = filtroHeaderStore.profissionalSaude;
    }
    if (filtroHeaderStore?.unidade) {
      this.unidadeRef.current = filtroHeaderStore.unidade;
    }
    auditoriaStore.auditoriaProps={
      ...auditoriaStore.auditoriaProps,
      auditoria: "o agendamento",
      auditoriaTela: "agendamento",
    }
    this.props.sujeitoAtencaoStore.sujeitoToSetOnAgendamento = false;
    this.props.extratoStore.resetTitulo();
    this.props.extratoStore.isTituloVinculaAgendamento = false;
    atendimentoStore.canViewFincanceiro = await checkUserRole('ROLE_TITULO_PARCELA_CREATE');
    atendimentoStore.canViewPayments = await checkUserRole('ROLE_TITULO_CREATE');
    atendimentoStore.hasAuthorityToEditRecorrencia = await checkUserRole('ROLE_AGENDAMENTO_RECORRENCIA_UPDATE');
    atendimentoStore.isAgendarProcedimentoSolicitado = false;
    this.findEspecialidadeMedica()
  }

  componentDidUpdate() {
    const { atendimentoStore } = this.props;

    const situacao = atendimentoStore.objView.situacao;

    if (situacao === "BLOQUEADO") {
      atendimentoStore.prevSituacao = situacao;
      return;
    }

    if (
      situacao !== "BLOQUEADO" &&
      atendimentoStore.prevSituacao === "BLOQUEADO"
    ) {
      atendimentoStore.prevSituacao = null;
    }
  }

  findEspecialidadeMedica = async () => {
    const { filtroHeaderStore } = this.props
    filtroHeaderStore.getFiltroAtual();
    const profissionalFiltroIsOdontologico = filtroHeaderStore?.profissionalSaude?.especialidades?.some((item) => item?.especialidade === 'Odontologia')
    const profissionalAgendaOdontologico = await localStorageService.get(CURRENT_AGENDA_OWNER)
    const profissionalOdontologico = await localStorageService.get(PROFISSIONAL_SAUDE_LOGADO_KEY)
 
    const profissionalAgendaIsOdontologico = profissionalAgendaOdontologico ? profissionalAgendaOdontologico?.especialidade === "Odontologia" : false
    const profissionalLogadoIsOdontologico = profissionalOdontologico ? profissionalOdontologico?.especialidades?.some((item) => item === 'Odontologia') && profissionalOdontologico?.especialidades?.length === 1 : false
 
 
    this.setState({ isProfissionalOdontologico: profissionalAgendaIsOdontologico || profissionalLogadoIsOdontologico || profissionalFiltroIsOdontologico })
  }
 
 
  findProcedimentoOdontologicoPaciente = async () => {
    try {
      const { atendimentoStore } = this.props
      const { isProfissionalOdontologico } = this.state;
      if(!isProfissionalOdontologico)return
   
      if (atendimentoStore.objView?.sujeitoAtencao?.id) {
        const variables = {
          pageableDto: {
            sortDir: "ASC",
            sortField:"procedimento"
          },
          faturado: true,
          sujeitoAtencaoId: atendimentoStore.objView?.sujeitoAtencao?.id,
          status: ["APROVADO", "EM_ANDAMENTO"],
        }
         const response = await findAllProcedimentoOdontologico(variables)
         this.setState({procedimentosOdontologicosPaciente:response})
      }

    } catch (error) {
      console.log(error)
    }
  }
 

  getModalTabs = async () => {
    const { unidadeStore } = this.props;
    const { configuracaoUnidade } = unidadeStore || {};
    if (!configuracaoUnidade) {
      await unidadeStore.inicializaConfiguracaoUnidade();
    }
    const { azulControleAtivado, utilizaFinanceiroAppHealth } = configuracaoUnidade || {};
    const showFinanceiroAzulControle = azulControleAtivado && !utilizaFinanceiroAppHealth;
    const tabs = showFinanceiroAzulControle ?
    {
      AGENDAMENTO: 0,
      PROCEDIMENTO: 1,
      AUDITORIA: 2,
    }
    :
    {
      AGENDAMENTO: 0,
      PAGAMENTO: 1,
      PROCEDIMENTO: 2,
      AUDITORIA: 3,
    };

    this.setState((prevState) => {
      if (
        JSON.stringify(prevState.tabs) !== JSON.stringify(tabs) ||
        prevState.showFinanceiroAzulControle !== showFinanceiroAzulControle
      ) {

        return {
          tabs,
          showFinanceiroAzulControle,
        };
      }
      return null
    });

  }

  getPaisAtuacao = async() => {
    const paisAtuacao = await getPaisAtuacao();
    this.setState({ paisAtuacao: paisAtuacao?.descricao });
  };

  loadSalas = async(search, loadedOptions, { page }) => {
    return this.handleLoadMoreSalas({
      search,
      data: { page }
    })
  }

  handleLoadMoreSalas = async ({ search, data }) => {
    let pageableDTO = {
      sortDir: 'ASC',
      sortField: 'nome',
      pageNumber: data.page,
      pageSize: 20,
    };

    const response = await findAllSala({
      search,
      ativo: true,
      pageableDTO
    });

    const { content, last } = response?.data?.data?.findAllSala;

    if (content) {
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: data.page + 1
        },
      };
    }
  }

  loadProcedimentos = async (search, loadedOptions, { page }) => {
    return this.handleLoadMoreProcedimentos({
      search,
      data: { page }
    })
  }

  getFiltersProcedimentos = async() => {
    const { unidadeStore, filtroHeaderStore, atendimentoStore } = this.props;
    const { configuracaoUnidade } = unidadeStore;
    const { filtroProcedimento } = configuracaoUnidade || [];
    const { profissionalSaude, convenio, sala } = atendimentoStore.objView || {};
    const { id: salaId } = sala || {};

    let filters = {
      ...(salaId && {salaId}),
    };
    
    if(filtroProcedimento.includes("PROFISSIONAL")) {
      const { profissionalSaude: profissionalSaudeAgendaSelecionada } = filtroHeaderStore || {};
      const profissionalSaudeId = salaId ? 
      profissionalSaude?.id || null : 
      profissionalSaudeAgendaSelecionada?.id || null;

      filters = {
        ...filters,
        ...(profissionalSaudeId && {profissionalSaudeId})
      }
    } 
    
    if(filtroProcedimento.includes("CONVENIO")) {
      filters = {
        ...filters,
        ...( convenio?.id && {convenioId: convenio?.id})
      }
    }

    return filters;
  }

  handleLoadMoreProcedimentos = async ({ search, data }) => {
    const filters = await this.getFiltersProcedimentos();
    
    let pageableDTO = {
      sortDir: 'ASC',
      sortField: 'nome',
      pageNumber: data.page,
      pageSize: 20,
    }

    const response = await findAllProcedimentosAtendimento({
      nome: search,
      ativo: true,
      pageableDTO,
      ...filters
    });

    const {content, last} = response;

    if (content) {
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: data.page + 1
        },
      };
    }
  }

  onExit() {
    const { tabs } = this.state;
    this.setState({modificacoes: [], modificacoesPage: 0, tabSelected: tabs.AGENDAMENTO, profissionalWithoutProcedimentos: false});
    this.props.atendimentoStore.onExit();
    this.props.atendimentoStore.tipo = this.props.atendimentoStore.tipo.filter(
      (tipo) => tipo.ativo
    );
    if (typeof this.props.onClose === "function") {
      this.props.onClose();
    }
  }

  handleClose = () => {
    this.props.atendimentoStore.onExit();
    const {
      profissionalSaude,
      tempoInicioAgendamento,
    } = this.props.googleAnalyticsStore;

    tempoInicioAgendamento &&
      ga(
        profissionalSaude + ".send",
        "timing",
        "Modal agendamento",
        "Tempo total para fechar modal de agendamento",
        moment().format("x") - tempoInicioAgendamento
      );

    ga(
      profissionalSaude + ".send",
      "event",
      "Agenda",
      "Modal agendamento",
      "Fechar modal agendamento"
    );
    this.setState({ listaNegra: "" });

    this.props.atendimentoStore.waitingSelectSujeitoToLink = false;
  };

  handleClickCancelarConsulta = () => {
    const { atendimentoStore } = this.props;
    const {
      profissionalSaude,
      tempoInicioAgendamento,
    } = this.props.googleAnalyticsStore;

    atendimentoStore.idOpened && 
    atendimentoStore.agendamentoRecorrencia?.id && 
    atendimentoStore.hasAuthorityToEditRecorrencia &&
    this.verificaDataConsultaMaiorDataAtual() && 
    !atendimentoStore.isLastAgendamentoOfRecurrence
    ? this.setState({isExcluirAgendamento: true, openConfirmaEditRecorrencia: true})
    : atendimentoStore.openExcluirAgendamentoAlert(atendimentoStore.objView);

    tempoInicioAgendamento &&
      ga(
        profissionalSaude + ".send",
        "timing",
        "Modal agendamento",
        "Tempo total para cancelar um agendamento",
        moment().format("x") - tempoInicioAgendamento
      );

    ga(
      profissionalSaude + ".send",
      "event",
      "Agenda",
      "Modal agendamento",
      "Excluir agendamento"
    );
  };

  handleClickCadastrarNovo = async () => {
    ga(
      this.props.googleAnalyticsStore.profissionalSaude + ".send",
      "event",
      "Agenda",
      "Modal agendamento",
      "Cadastrar novo paciente"
    );
    try {
      const { history, atendimentoStore, sujeitoAtencaoStore, unidadeStore } = this.props;
      const { configuracaoUnidade } = unidadeStore;
      if (atendimentoStore.objView?.paciente?.id) {
        atendimentoStore.loadVincularMessages();
        atendimentoStore.openModalVincularPaciente = true;
        return;
      }
      const result = await atendimentoStore.cadastrarNovo();
      const sujeitoAtencaoId = result?.sujeitoAtencao?.id;
      if (sujeitoAtencaoId) {
        sujeitoAtencaoStore.reset();
        sujeitoAtencaoStore.changeId(sujeitoAtencaoId);
        if (!configuracaoUnidade.cadastroRapidoAgendamento) {
          return history.push(`/sujeito-atencao/edit/${sujeitoAtencaoId}`);
        } 
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleVincularViziPaciente = () => {
    this.props.atendimentoStore.openModalVincularPaciente = false;
    this.props.atendimentoStore.waitingSelectSujeitoToLink = true;
  }

  handleCloseVincularAlertDialog = () => {
    this.props.atendimentoStore.openModalVincularPaciente = false;
  }

  handleCadastrarNovo = () => {
    const { sujeitoAtencaoStore } = this.props;
    sujeitoAtencaoStore.reset();
    const objView = this.createObjViewViziPaciente();
    sujeitoAtencaoStore.setViziPacienteInObjView(objView);
    sujeitoAtencaoStore.screenToBack = this.props.history.location.pathname;
    sujeitoAtencaoStore.willLinkViziHealthPaciente = true;
    this.handleCloseVincularAlertDialog();
    this.props.history.push(`/sujeito-atencao`);
  };

  createObjViewViziPaciente = () => {
    const { atendimentoStore } = this.props;
    const { objView } = atendimentoStore;

    const { 
      nome, 
      documento, 
      paciente, 
      convenio, 
      telefone, 
      numeroCarteira, 
      validadeCarteira, 
    } = objView;

    return {
      nome: nome,
      documento: documento,
      paciente: paciente,
      convenio: convenio,
      dataNascimento: paciente?.dataNascimento || null,
      contato: {
        telefonePrincipal: telefone || paciente?.telefonePrincipal,
        email: paciente?.email,

      },
      dadosConvenio: {
        numeroCarteira: numeroCarteira,
        validadeCarteira: validadeCarteira
      },
      endereco: paciente?.endereco,
      ...objView.sujeitoAtencao
    };
  }

  handleOpenNotification = (notification) => {
    this.setState({notification});
  };

  handleClickEditSujeitoAtencao = async () => {
    ga(
      this.props.googleAnalyticsStore.profissionalSaude + ".send",
      "event",
      "Agenda",
      "Modal agendamento",
      "Editar paciente"
    );
    try {
      const { history, atendimentoStore, sujeitoAtencaoStore } = this.props;
      const sujeitoAtencaoId = atendimentoStore.objView.sujeitoAtencao?.id;
      if (sujeitoAtencaoId) {
        sujeitoAtencaoStore.reset();
        sujeitoAtencaoStore.changeId(sujeitoAtencaoId);
        return history.push(`/sujeito-atencao/edit/${sujeitoAtencaoId}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleClickDocumentoSujeitoAtencao= async(e)=>{
    const { history, atendimentoStore, sujeitoAtencaoStore } = this.props;
    const sujeitoAtencaoId = atendimentoStore.objView.sujeitoAtencao?.id;
    const {objView}= atendimentoStore
    const possuiDocumentos = objView.documentosAssinadosCliente.length > 0 && objView.documentosAssinadosCliente.some((documento)=>documento.situacao !== "Cancelled")
    try {
      if (sujeitoAtencaoId) {
        sujeitoAtencaoStore.reset();
        sujeitoAtencaoStore.changeId(sujeitoAtencaoId);
        return history.push({
          pathname:`/sujeito-atencao/edit/${sujeitoAtencaoId}/documentos`,
          state:{
            agendamentoId:objView.id , 
            possuiDocumento:possuiDocumentos
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleClickAssinaturaPresenca = async (e) => {
    const { history, atendimentoStore, sujeitoAtencaoStore } = this.props;
    const sujeitoAtencaoId = atendimentoStore.objView.sujeitoAtencao?.id;
    const { objView } = atendimentoStore
    try {
      if (sujeitoAtencaoId) {
        sujeitoAtencaoStore.reset();
        sujeitoAtencaoStore.changeId(sujeitoAtencaoId);
        return history.push({
          pathname: `/sujeito-atencao/edit/${sujeitoAtencaoId}/consultas`,
          state: {
            agendamentoId: objView.id,
            assinaturaPresenca: true,
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }  
  
  handleClickOrcamentosSujeitoAtencao = async () => {
    this.setState({ openModalOrcamentosVinculadoAgendamento: true });
  }

  handleClickEditObservacao =  () => {
    const { history, atendimentoStore } = this.props;
    const { id } = atendimentoStore.objView.sujeitoAtencao;

    const redirectPath = `/sujeito-atencao/edit/${id}`;
    const sourceParam = 'source=observacao';

    return history.push(`${redirectPath}?${sourceParam}`);
  };

  handleClickOpenAfericaoVitalModal= async () => {
    this.setState({openModalAfericaoVital: true})
  };

  handleClickOpenAvaliacaoAntropometricaModal= () => {
    this.setState({openModalAveliacaoAntropometrica: true})
  }

  handleClickOpenInternacaoModal = async() => {
    const { atendimentoStore } = this.props;
    try {
      const { objView } = atendimentoStore;
      const internacao = await findSujeitoAtencaoInternacaoByAgendamentoId(objView.id);
  
      this.setState({
        dadosInternacao: internacao,
        openModalInternacao: true,
      });

    } catch (e){
      atendimentoStore.showAlertMessage({
        isOpen: true,
        message: 'Erro ao carregar os dados da internação.',
        variant: 'error',
      })
    }
  };

  handleOpenFinanceiro = () => {
    const { extratoStore, atendimentoStore } = this.props;
    const { objView } = atendimentoStore;
    const { showFinanceiroAzulControle } = this.state;
    showFinanceiroAzulControle ? extratoStore.handleOpenAzulControleFaturamento({agendamentoId: objView.id}) : this.handleClickCadastrarReceitaFinanceiro();
  };

  handleClickCadastrarReceitaFinanceiro = async () => {
    ga(
      this.props.googleAnalyticsStore.profissionalSaude + ".send",
      "event",
      "Agenda",
      "Modal agendamento",
      "Cadastrar receita financeiro"
    );
    try {
      const { history, atendimentoStore, extratoStore } = this.props;
      const { isModalSala } = atendimentoStore;
        history.push("/financeiro")
        const sujeitoAtencao = atendimentoStore.objView?.sujeitoAtencao;
        const profissionalSaude = atendimentoStore.objView?.profissionalSaude;
        const tipoConsulta = atendimentoStore.objView?.tipo;
        const procedimentos = atendimentoStore.objView?.procedimentos;
        const idAgendamento = atendimentoStore.idOpened;

        extratoStore.cadastrarReceitaBySujeito(
          "RECEITA",
          sujeitoAtencao,
          profissionalSaude,
          tipoConsulta,
          procedimentos,
          idAgendamento,
          isModalSala,
        );
    } catch (error) {
      console.log(error);
    }
  };


  onCloseAfericaoVital = () => {
    this.setState({openModalAfericaoVital: false})
  };

  onCloseAvaliacaoAntropometrica = () => {
    this.setState({openModalAveliacaoAntropometrica: false})
  };

  onCloseModalInternacao = () => {
    const { atendimentoStore } = this.props;
    this.setState({openModalInternacao: false})
    atendimentoStore.isModalInternacao = false
  };


  handleClickEraseSujeitoAtencao = async () => {
    try {
      const { atendimentoStore } = this.props;
      if (atendimentoStore.objView.sujeitoAtencao?.id) {
        atendimentoStore.eraseSujeitoAtencaoAgendamento();
      }
      this.debounceOnChange();
    } catch (error) {
      console.log(error);
    }
  };

  handlePrint = async () => {
    const { unidadeStore } = this.props;
    if (!this.state.loadingImpressao) {
      let idOpened = this.props.atendimentoStore.objView.sujeitoAtencao.id;

      this.setState({ loadingImpressao: true });
      await this.props.configuracaoImpressaoStore.getConfig('OUTROS');

      const dadosRecuperados = await findByIdSujeitoAtencaoImpressao(
        idOpened
      ).then((resposta) => {
        return resposta.data.data.findByIdSujeitoAtencao;
      });

      const unidade = await findUnidadeLogada().then((resposta) => {
        return resposta;
      });

      this.setState({
        sujeitoAtencaoRecuperado: dadosRecuperados,
        utilizaTelefoneInternacional: unidadeStore.unidade.utilizaTelefoneInternacional,
        unidade: unidade,
        loadingImpressao: false,
        isPrintMustache: true
      });
    }
  };

  async consultaDataListaNegra() {
    const { consultaListaNegraDados } = this.state;
    const { atendimentoStore } = this.props;
    const { objView } = atendimentoStore;

    const cpf = String.removeSpecialChars(objView.documento);
    const telefone = String.removeSpecialChars(objView.telefone);

    if (cpf === consultaListaNegraDados.cpf && telefone === consultaListaNegraDados.telefone) return

    try {
      const listaNegra = await findByCPFAndTelefone(telefone, cpf);

      this.setState({
        listaNegra: listaNegra,
        consultaListaNegraDados: {
          cpf: cpf,
          telefone: telefone
        }
      })
    } catch (error) {
      console.error(error);
    }
  }

  searchRepeatedDocument = async (document) => {
    try {
      const response = await findSujeitoAtencaoByDocumento(document);
      const pacientes = response?.data?.data?.findSujeitoAtencaoByDocumento;

      if (pacientes) {
        return pacientes?.length > 0;
      }
      return false;
    } catch(error) {
      console.error(response);
    }
  }


  onNomeChange(e) {
    const{atendimentoStore}=this.props;
    const{objView}=atendimentoStore;
    let value = e.target.value;
    this.props.atendimentoStore.errors.nome = String.isEmpty(value);
    this.props.atendimentoStore.objView.nome = value.substring(0, 100);
    this.onDataNascimentoChange(objView.dataNascimento,)
    this.debounceOnChange();
  }

  onMotivoChange(e) {
    let value = e.target.value;
    this.props.atendimentoStore.objView.motivo = value.substring(0, 100);
    this.debounceOnChange();
  }

  onTelefoneChange(e) {
    let value = e.target.value;
    this.props.atendimentoStore.errors.telefone = !String.validaTelefone(value);
    this.props.atendimentoStore.objView.telefone = value;
    this.debounceOnChange();

    if (String.removeSpecialChars(value).length > 9) {
      this.consultaDataListaNegra();
    } else {
      this.setState({ listaNegra: "" });
    }
  }

  handleClickWhatsapp = async() => {
    const { atendimentoStore } = this.props;
    const { objView } = atendimentoStore;

    let message = "";
    await atendimentoStore.getConfiguracaoUnidadeLogada();
    if(atendimentoStore.idOpened) {
      const dadosMensagem = {
        nomePaciente: objView.sujeitoAtencao?.nome || objView.nome,
        nomeFantasiaUnidade: objView.unidade?.nomeFantasia,
        nomeProfissional: objView.profissionalSaude?.nome,
        horaInicio: objView.horaInicio || " - ",
        data: moment(objView.data).format("DD/MM/YYYY"),
        chavePublica: objView.chavePublica,
        isAgendamentoVizi: objView.paciente?.id,
        remoto: objView.remoto,
        horasPermiteAgendamento: objView.horasPermiteAgendamento,
        withEndereco: objView.utilizaEnderecoMensagemAgendamento,
        endereco: objView.unidade?.endereco,
      };
      message = getDadosMensagem(dadosMensagem);

      atendimentoStore.increaseWhatsappMessageCounter(objView.id);
    }
    goToWhatsApp(objView.telefone, objView.telefoneDDI, message)
  };

  onTelefoneChangeComDDI(phone, data) {
    const [dialCode, ...phoneNumber] = String.removeSpecialChars(phone).split(data.dialCode);
    const phoneWithoutDialCode = phoneNumber.join(data.dialCode);
    this.props.atendimentoStore.objView.telefone = phoneWithoutDialCode;
    this.props.atendimentoStore.objView.telefoneDDI = data.dialCode;
    this.debounceOnChange();

    if (String.removeSpecialChars(phoneWithoutDialCode).length > 9) {
      this.consultaDataListaNegra();
    } else {
      this.setState({ listaNegra: "" });
    }
  }

  async onDocumentoChange(e) {
    let value = e.target.value;

    this.props.atendimentoStore.objView.documento = value;

    this.verificaDocumento(value);
    this.debounceOnChange();

    const documentWithoutSpecialChars = String.removeSpecialChars(value);

    if (documentWithoutSpecialChars.length === 11) {
      this.consultaDataListaNegra();
      const documentAlreadyRegistred = await this.searchRepeatedDocument(documentWithoutSpecialChars);
      this.setState({ showDocumentoWarning:  documentAlreadyRegistred});
    }
  }

  verificaDocumento = (value) => {
    const { atendimentoStore } = this.props;

    let errorDocumento = false;

    if(atendimentoStore.objView.documentoTipo){
        if(atendimentoStore.objView.documentoTipo?.descricao === "CPF"){
            errorDocumento = (value == null || string.removeSpecialChars(value).length > 0) && 
                            !string.validaCPF(value);
        } else {
            errorDocumento = !validaDocumentoEspanha(value);
        };
    };
    
    atendimentoStore.errors.documento = errorDocumento;
};


  onGenericChange = (label) => (e) => {
    this.props.atendimentoStore.objView[label] = e.target.value;
  };
  onGenericHoraChange = (label) => (e) => {
    this.props.atendimentoStore.objView[label] = e.format("HH:mm");
    this.recalculaHoraFim();
  };
  onGenericDataChange = (label) => async (date) => {
    const { filtroHeaderStore, objView } = this.props.atendimentoStore;
    const {profissionalSaude} = filtroHeaderStore;
    const { utilizaAgendamentoRetorno, id } = profissionalSaude || {};
    
    const {sujeitoAtencao} = objView;
    if(utilizaAgendamentoRetorno && id && sujeitoAtencao?.id ){
      const agendamentoRetornoData = await verificarAgendamentoRetorno({
        profissionalSaudeId:filtroHeaderStore?.profissionalSaude?.id,
        sujeitoAtencaoId: objView?.sujeitoAtencao?.id,
        dataAgendamento: moment(date).format('YYYY-MM-DD')
      })
      this.props.atendimentoStore.agendamentoRetornoData = agendamentoRetornoData?.data;
    }
    this.props.atendimentoStore.objView[label] = date;
  };

  onConvenioChange = (e) => {
    this.props.atendimentoStore.objView.convenio = { id: e.target.value };
  };

  onDocumentoTipoChange = (documentoTipo) => {
    this.props.atendimentoStore.objView.documentoTipo = documentoTipo;
  };

  onDataNascimentoChange = (date) => {
    const { objView } = this.props.atendimentoStore;
    objView.dataNascimento = date;
    this.debounceOnChange();
  };

  onAtendimentoTipoChange = (e) => {
    this.props.atendimentoStore.objView.tipo = e;
  };

  onAtendimentoSalaChange = (e) => {
    const salaId = e.target.value;
    this.props.atendimentoStore.errors.sala = String.isEmpty(salaId);
    this.props.atendimentoStore.objView.sala = { id: salaId };
  };

  onSolicitanteProcedimentoChange = (e) => {
    this.props.atendimentoStore.objView.solicitante = e;
  };

  onProfissionalSaudeProcedimentoChange = (e) => {
    this.props.atendimentoStore.objView.profissionalSaude = e;
  };

  handleAddProcedimento = (e,tipo) => {
    const { atendimentoStore } = this.props;
    const { objView } = atendimentoStore;
    if (e) {
      const procedimento = {
        procedimento: tipo === 'Odontoloco'? e.procedimento : e,
      };
      if (objView.procedimentos) {
        objView.procedimentos.push(procedimento);
      } else {
        objView.procedimentos = [procedimento];
      }
    }
  };

  moveCursorAtEnd = (e) => {
    const valueInput = e.target.value
    e.target.value = ''
    e.target.value = valueInput
  }

  handleSala = e => {
    const { atendimentoStore } = this.props;
    const { objView } = atendimentoStore;

    objView.sala = e;
  }

  agendarProcedimento = (procedimentosSelecionados) => {
    const { objView } = this.props.atendimentoStore;
    this.props.atendimentoStore.clearIdOpened();
    this.props.atendimentoStore.changeTypeModal("isModalSala");
    objView.procedimentos = procedimentosSelecionados;
  };

  handleRemoveProcedimento = async(index) => {
    const { atendimentoStore } = this.props;
    const { objView, procedimentosModal, isOdontograma } = atendimentoStore;
    
    if(isOdontograma) {
      const procedimentoRemovido = {
        checked: false,
        id: objView.procedimentos[index].procedimentoOdontologico.id,
        procedimento: objView.procedimentos[index].procedimento,
      };

      atendimentoStore.procedimentosModal=[...procedimentosModal, procedimentoRemovido];
    }
    atendimentoStore.objView.procedimentos.splice(index, 1);
  };

  onChangeValueDuracao = (newValue) => {
    let duracao = newValue;

    if (newValue?.label && !newValue?.label.includes("min.")) {
      duracao = {
        ...newValue,
        label: `${newValue.label} min.`,
      };
    }

    this.props.atendimentoStore.objView.duracao = duracao;
    this.recalculaHoraFim();
  };

  handleFormat = (value) => {
    return `Selecionar ${value} min.`;
  };

  onInputChangeDuracao = (value) => {
    const inputApenasNumeros = value.replace(/[^\d]/g, "");
    return inputApenasNumeros;
  };

  recalculaHoraFim = () => {
    this.props.atendimentoStore.objView.horaFim = moment(
      this.props.atendimentoStore.objView.horaInicio,
      "HH:mm"
    )
      .add(this.props.atendimentoStore.objView?.duracao?.value, "minute")
      .format("HH:mm");
  };

  onIsConsultaRemotaChange = (value) => {
    this.props.atendimentoStore.objView.isConsultaRemota = value;
  };

  showAlertMessageDuracao = () => {
    const { atendimentoStore } = this.props;
    const notification = {
      isOpen: true,
      message: "Hora fim não editável, selecione a duração para alterá-la.",
      variant: "error"
    };
    atendimentoStore.showAlertMessage(notification);
    this.showAlertMessage(notification);
  };

  showAlertMessage = (notification) => {
    this.setState({ notification: notification });

    const timeoutId = setTimeout(() => {
      this.closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
      variant: "",
    };
    this.setState({ notification: notification });
  };

  loadMoreSujeitosAtencao = () => {
    const { atendimentoStore } = this.props;

    if (atendimentoStore.sujeitosAtencaoLoading) {
      return;
    }

    if (
      atendimentoStore.sujeitosAtencao &&
      atendimentoStore.sujeitosAtencao.length > 0
    ) {
      atendimentoStore.sujeitosAtencaoCurrentPage += 1;
    } else {
      atendimentoStore.sujeitosAtencaoCurrentPage = 0;
    }

    atendimentoStore.loadSujeitosAtencao({
      pageNumber: atendimentoStore.sujeitosAtencaoCurrentPage,
    });
  };

  onChange() {
    this.props.atendimentoStore.loadSujeitosAtencao();
  }

  debounceOnChange() {
    this.onChange();
  }

  selectSujeito(sujeitoAtencao) {
    this.props.atendimentoStore.objView.sujeitoAtencao = {
      id: sujeitoAtencao.id,
    };
    this.props.atendimentoStore.objView.dataNascimento =
      moment(sujeitoAtencao.dataNascimento).format('YYYY-MM-DD') || "";
    this.props.atendimentoStore.objView.documento = sujeitoAtencao.documento || "";
    this.props.atendimentoStore.objView.nome = sujeitoAtencao.nome || "";

    this.props.atendimentoStore.objView.convenio =
      sujeitoAtencao.dadosConvenio?.convenio || null;

    this.props.atendimentoStore.objView.paciente = 
      sujeitoAtencao.paciente || null;

    let telefone = String.removeSpecialChars(
      this.props.atendimentoStore.objView.telefone
    );
    if (telefone == null || telefone.length < 1)
      telefone = sujeitoAtencao?.contato?.telefonePrincipal || "";
    this.props.atendimentoStore.objView.telefone = telefone;
  }

  handleCheckIsUrgent = () => {
    const { atendimentoStore } = this.props;
    atendimentoStore.objView.urgente = !atendimentoStore.objView.urgente;
  };

  handleCheckListaDeEspera = async () => {
    const { atendimentoStore } = this.props;
    atendimentoStore.objView.listaDeEspera = !atendimentoStore.objView.listaDeEspera;
  }

  renderUrgenteButton = (withPacient) => {
    const { classes, atendimentoStore } = this.props;
    const isChecked = atendimentoStore.objView.urgente || false;
    return (
      <Tooltip title="Urgente" placement="top">
        <Fab
          onClick={this.handleCheckIsUrgent}
          classes={{
            sizeSmall: classNames(classes.btnAddEditMini, classes.buttonUrgente),
            extendedFab: classes.extendedFab,
          }}
          size="small"
          aria-label="addedit"
          disabled={atendimentoStore.disableModalButtons}
          style={{ backgroundColor: isChecked ? 'rgba(247, 207, 207, 1)' : '#F2F2F2', marginRight: withPacient ? 0 : 10 }}
        >
          <WarningIcon size='16' color='#FB7676' />
        </Fab>
      </Tooltip>
    )
  };

  handleOpenAzulControleNotaFiscal = async() => {
    const { atendimentoStore, extratoStore } = this.props;
    const link = await extratoStore.linkNotaAgendamento({agendamentoId: atendimentoStore.objView.id});
    window.open(link, '_blank');
  };

  renderButtonNotaFiscal = () => {
    const { atendimentoStore, classes } = this.props;
    const { objView, disableModalButtons } = atendimentoStore;
    const { procedimentos, situacao } = objView;
    const { showFinanceiroAzulControle } = this.state;

    const procedimentoSolicitadoPagoSemNotaFiscal = procedimentos?.some((procedimento) => {
      const { procedimentoSolicitado } = procedimento;
      return procedimentoSolicitado?.id && procedimentoSolicitado?.titulo?.ativo && !procedimentoSolicitado?.possuiNota && situacao === "ATENDIDO";
    });

    const showButton = atendimentoStore.idOpened && showFinanceiroAzulControle && !disableModalButtons && procedimentoSolicitadoPagoSemNotaFiscal;

    return (<>
      { showButton && 
        <div className={classes.btnHeader}>
          <Tooltip title="Nota fiscal" placement="top">
            <Fab
              onClick={this.handleOpenAzulControleNotaFiscal}
              classes={{
                sizeSmall: classes.btnAddEditMini,
                extendedFab: classes.extendedFab,
              }}
              size="small"
              color="primary"
              aria-label="addedit"
              disabled={disableModalButtons}
            >
              <DocumentOnePageIcon size="16px"/>
            </Fab>
          </Tooltip>
        </div>
      }
    </>
    )
  };

  handlePrintEtiqueta = () => {
    this.setState({ openModalEtiquetaIdentificacao: true })
  }

  handleClickOpenPageGuia = async() => {
    const { atendimentoStore } = this.props;
    try {
      const { objView } = atendimentoStore;
  
      const linkGuiaAzulControle = await linkGuiaFaturamento({ agendamentoId: objView.id });

      window.open(linkGuiaAzulControle);
      
    } catch {
      const notification = {
        isOpen: true,
        message: 'Erro ao entrar no financeiro.',
        variant: 'error',
      }
      atendimentoStore.showAlertMessage(notification);

    }
  };

  renderButtonsTop() {
    const { classes, atendimentoStore, unidadeStore } = this.props;
    const { objView, disableModalButtons, typeCalendarViewSelected, isModalSala, isOdontograma } = atendimentoStore;
    const { configuracaoUnidade } = unidadeStore;
    const { utilizaEtiquetaIdentificacao, realizaInternacao } = configuracaoUnidade || {}; 

    return (
      <div>
        {objView.sujeitoAtencao?.id ? (
          <div className={classes.divButtons}>
          {typeCalendarViewSelected !== "encaixesView" &&  !isModalSala && (
          <Tooltip title="Lista de espera" placement="top">
              <Fab
                onClick={this.handleCheckListaDeEspera}
                classes={{
                  sizeSmall: classNames(classes.btnAddEditMini, objView.listaDeEspera ? classes.buttonListaDeEsperaChecked : classes.buttonListaDeEspera),
                  extendedFab: classes.extendedFab,
                }}
                size="small"
                aria-label="addedit"
                style={{ backgroundColor: objView.listaDeEspera ? '#717FFC' : '#F2F2F2' }}
                disabled={disableModalButtons}
              >
                <ListIcon size="14"  />
              </Fab>
            </Tooltip>
          )}
            {this.renderUrgenteButton(true)}
            { atendimentoStore.objView?.id &&
              (atendimentoStore.objView?.tipo || atendimentoStore.objView?.procedimentos.length > 0) &&
                <div className={classes.btnHeader}>
                  <Tooltip title="Guia" placement="top">
                    <Fab
                      onClick={this.handleClickOpenPageGuia}
                      classes={{
                        sizeSmall: classes.btnAddEditMini,
                        extendedFab: classes.extendedFab,
                      }}
                      size="small"
                      color="primary"
                      aria-label="addedit"
                      disabled={disableModalButtons}
                    >
                      <GuiaConsultaIcon/>
                    </Fab>
                  </Tooltip>
                </div>}
            {this.renderButtonNotaFiscal()}
            {atendimentoStore.idOpened && 
              <div className={classes.btnHeader}>
                <Tooltip title="Financeiro" placement="top">
                  <Fab
                    onClick={this.handleOpenFinanceiro}
                    classes={{
                      sizeSmall: classes.btnAddEditMini,
                      extendedFab: classes.extendedFab,
                    }}
                    size="small"
                    color="primary"
                    aria-label="addedit"
                    disabled={disableModalButtons}
                  >
                    <FinanceiroMenuIcon size="18px"/>
                  </Fab>
                </Tooltip>
              </div>
            }
            {atendimentoStore.objView?.id && (
              <>
                <div className={classes.btnHeader}>
                  <Tooltip title="Aferição vital" placement="top">
                    <Fab
                      onClick={this.handleClickOpenAfericaoVitalModal}
                      classes={{
                        sizeSmall: classes.btnAddEditMini,
                        extendedFab: classes.extendedFab,
                      }}
                      size="small"
                      color="primary"
                      aria-label="addedit"
                      disabled={disableModalButtons}
                    >
                      <AfericaoVitalIcon/>
                    </Fab>
                  </Tooltip>
                </div>
                <div className={classes.btnHeader}>
                  <Tooltip title="Avaliação antropometrica" placement="top">
                    <Fab
                      onClick={this.handleClickOpenAvaliacaoAntropometricaModal}
                      classes={{
                        sizeSmall: classes.btnAddEditMini,
                        extendedFab: classes.extendedFab,
                      }}
                      size="small"
                      color="primary"
                      aria-label="addedit"
                      disabled={disableModalButtons}
                    >
                      <AvaliacaoAntropometricaIcon/>
                    </Fab>
                  </Tooltip>
                </div>
                {realizaInternacao && <div className={classes.btnHeader}>
                  <Tooltip title="Internação" placement="top">
                    <Fab
                      onClick={this.handleClickOpenInternacaoModal}
                      classes={{
                        sizeSmall: classes.btnAddEditMini,
                        extendedFab: classes.extendedFab,
                      }}
                      size="small"
                      color="primary"
                      aria-label="addedit"
                      disabled={disableModalButtons || !objView?.profissionalSaude?.id}
                    >
                      <InternacaoIcon/>
                    </Fab>
                  </Tooltip>
                </div>}
              </>
            )}
            <div className={classes.btnHeader}>
              <Tooltip title="Imprimir" placement="top">
                <Fab
                  onClick={utilizaEtiquetaIdentificacao && objView?.id ? this.handlePrintEtiqueta : this.handlePrint}
                  classes={{
                    sizeSmall: classNames(classes.btnAddEditMini, classes.buttonYellow),
                    extendedFab: classes.extendedFab,
                  }}
                  size="small"
                  color="primary"
                  aria-label="addedit"
                  disabled={disableModalButtons}
                >
                  <PrintIcon />
                </Fab>
              </Tooltip>
            </div>
            {!isOdontograma && <div className={classes.btnHeader}>
              <Tooltip title="Remover paciente" placement="top">
                <Fab
                  onClick={this.handleClickEraseSujeitoAtencao}
                  classes={{
                    sizeSmall: classes.btnEraseMini,
                    extendedFab: classes.extendedFab,
                  }}
                  size="small"
                  color="primary"
                  aria-label="edit"
                  disabled={disableModalButtons || atendimentoStore.controleSessaoData.idPacote}
                >
                  <img
                    alt="delete"
                    src={deleteIcon}
                  />
                </Fab>
              </Tooltip>
            </div>}
          </div>
        ) : (
          <div className={classes.divButtons}>
          {typeCalendarViewSelected !== "encaixesView" &&  !isModalSala && (
            <Tooltip title="Lista de espera" placement="top">
                <Fab
                  onClick={this.handleCheckListaDeEspera}
                  classes={{
                    sizeSmall: classNames(classes.btnAddEditMini, objView.listaDeEspera ? classes.buttonListaDeEsperaChecked : classes.buttonListaDeEspera),
                    extendedFab: classes.extendedFab,
                  }}
                  size="small"
                  aria-label="addedit"
                  style={{ backgroundColor: objView.listaDeEspera ? '#717FFC' : '#F2F2F2' }}
                  disabled={disableModalButtons}
                >
                  <ListIcon size="14"  />
                </Fab>
              </Tooltip>
            )}
            {this.renderUrgenteButton(false)}
            <div className={[classes.btnActionAdd, classes.btnHeader]}>
              <Fab
                onClick={this.handleClickCadastrarNovo}
                classes={{
                  sizeSmall: classes.btnAddEditMini,
                  extendedFab: classes.extendedFab,
                }}
                size="small"
                color="primary"
                aria-label="addedit"
                disabled={disableModalButtons}
                >
                <AddIcon />
              </Fab>
            </div>
          </div>  
        )}
      </div>
    );
  }

  handleClickAgendar = async () => {
    const { atendimentoStore, unidadeStore } = this.props;
    const { objView, isModalSala } = atendimentoStore;
    const { configuracaoUnidade } = unidadeStore;

    let jaPossuiAgendamentoNesteHorario;
    const unidadeLogada = await getUnidadeLogado();
    const {profissionaisSaude} = unidadeLogada
    const correctProfissionalSaude = 
    profissionaisSaude?.some((profissional)=> 
      profissional?.id === this.profissionalSaudeRef?.current?.id)

    if(this.profissionalSaudeRef?.current?.id && !correctProfissionalSaude){
        const notification = {
            isOpen: true,
            message: "Erro ao agendar. O profissional não pertence a esta unidade!",
            variant: "error"
          };
        atendimentoStore.showAlertMessage(notification)
        return
    }

    if(!isModalSala && configuracaoUnidade.preencherTipoConsulta) {
      const tipo = objView?.controleSessaoAgendamentoTipo?.agendamentoTipo ? objView.controleSessaoAgendamentoTipo.agendamentoTipo : (objView.nomeAgendamentoTipo || objView.tipo);

      if(!tipo) {
        const notification = {
          isOpen: true,
          message: "Erro ao agendar. Por favor, selecione o tipo da consulta!",
          variant: "error"
        };
        atendimentoStore.showAlertMessage(notification)
        return
      }
    }
    
    if (atendimentoStore.typeCalendarViewSelected !== "encaixesView") {
      jaPossuiAgendamentoNesteHorario = await atendimentoStore.jaPossuiAgendamentoNesteHorario();
    }

    if (objView.isConsultaRemota && !objView.possuiPaciente){
      this.habilitaAcessoApp();
    }

    if (jaPossuiAgendamentoNesteHorario) {
      this.setState({ openConfirmarHorarioAgendamento: true });
    } else {
      this.verificaAgendamentoRecorrencia();
    }
  };

  habilitaAcessoApp = async() => {
    const { atendimentoStore, filtroHeaderStore } = this.props;
    const { objView } = atendimentoStore;
    const {profissionalSaudeId} = await filtroHeaderStore.getFiltroAtual();

    if (objView.permiteAcessoApp) {    
      const profissionaisQuePermiteAcesso = objView.permiteAcessoApp.map(profissional => profissional.id)
      if (!profissionaisQuePermiteAcesso.includes(profissionalSaudeId)) {
          objView.permiteAcessoApp = [ ...objView.permiteAcessoApp, {id: profissionalSaudeId} ]
          await atendimentoStore.updateSujeitoAtencaoHabilitarRemoto();
      }
    } else {
      objView.permiteAcessoApp = [{id: profissionalSaudeId}]
      await atendimentoStore.updateSujeitoAtencaoHabilitarRemoto();
    }
  }

  handleClickConfirmarHorarioAgendamento = () => {
    this.setState({ openConfirmarHorarioAgendamento: false });
    this.verificaAgendamentoRecorrencia();
  };

  calcularDuracao = (horaInicio, horaFim) => {
    return `${moment(horaFim, 'HH:mm').diff(moment(horaInicio, 'HH:mm'), 'minute')}`;
  }

  onClickTransferirAgendamento = (horario) => {
    const { horaInicio, horaFim } = this.props.atendimentoStore.objView;
    const duracao = this.calcularDuracao(horaInicio, horaFim);
    const informacoesTransferidas = {
      profissionalSaudeId: horario?.profissionalSaudeId,
      data:  moment(horario.data),
      horaInicio: horario.horaInicio,
      horaFim: moment(horario.horaInicio, 'HH:mm').add(duracao, 'minute').format('HH:mm'),
      duracao: {
        label: `${duracao} min.`,
        value: duracao,
      }
    }

    this.props.atendimentoStore.objView = {
      ...this.props.atendimentoStore.objView,
      ...informacoesTransferidas
    }

    this.props.atendimentoStore.isModalHorarioDisponivel = true;

    this.setState({openTransferirAgendamentos: false});
    this.agendar();
  };

  verificaAgendamentoRecorrencia = () => {
    const { atendimentoStore, history } = this.props;
    const { idOpened, agendamentoRecorrencia, isLastAgendamentoOfRecurrence, hasAuthorityToEditRecorrencia } = atendimentoStore;
    const isControleSessaoScreen = history.location.pathname.includes('sessoes-em-andamento');
    idOpened && agendamentoRecorrencia?.id && hasAuthorityToEditRecorrencia && !isLastAgendamentoOfRecurrence ?
      this.setState({isExcluirAgendamento: false, openConfirmaEditRecorrencia: true}) :
      this.agendar(isControleSessaoScreen);
  };

  handleTodosAgendamentos = async() => {
    const { atendimentoStore, extratoStore } = this.props;
    const { objView } = atendimentoStore;
    const { tabSelected, tabs } = this.state;

    if(tabSelected === tabs.PAGAMENTO) {
      extratoStore.titulo?.id ? await extratoStore.vincularTituloAgendamento({
        agendamentoRecorrenciaId: objView.agendamentoRecorrencia,
        tituloId: extratoStore.titulo.id 
    }) : await atendimentoStore.handleSalvarPagamento('recorrencia');
      this.changeTabSelected(0);
      this.setState({ 
        openConfirmaEditRecorrencia: false
      });
    } else {
      const dadosRecorrencia = await atendimentoStore.findByIdAgendamentoRecorrencia(atendimentoStore.agendamentoRecorrencia.id);
      atendimentoStore.updatingRecorrencia = true;
      this.setState({ 
        openConfirmaEditRecorrencia: false
      });

      const listaOrdenadaPorData = dates.sortDatesArray(dadosRecorrencia.agendamentosValidos);
      this.state.isExcluirAgendamento ?
      this.openModalConfirmaExcluir(listaOrdenadaPorData)
      : this.openModalRecorrencia({
        ...dadosRecorrencia,
        agendamentosValidos: listaOrdenadaPorData
      });
    }
  };

  openModalRecorrencia = (dadosRecorrencia) => {
    const {atendimentoStore} = this.props;
    const diferencaData = moment(atendimentoStore.objView.data, 'YYYY-MM-DD').diff(moment(atendimentoStore.dataAgendamentoTemp, 'YYYY-MM-DD'), 'days');
    const isDiferencaDataMaior1 = diferencaData > 0
    
    this.setState({ 
      dadosRecorrencia: {
        ...dadosRecorrencia,
        diferencaData: isDiferencaDataMaior1 && diferencaData
      },
      openModalRecorrencia: true 
    });
  };

  openModalConfirmaExcluir = dadosRecorrencia => {
    const { atendimentoStore } = this.props;
    const dataConsultaSelecionada = moment(atendimentoStore.objView.data, 'YYYY-MM-DD');
    const agendamentosAposDiaConsulta = dadosRecorrencia.filter(item => {
      return moment(item.data).isAfter(dataConsultaSelecionada) || item.data === dataConsultaSelecionada.format('YYYY-MM-DD')
    })

    atendimentoStore.openExcluirAgendamentoAlert(agendamentosAposDiaConsulta);
  }

  handleAgendamentoSelecionado = async() => {
    const { atendimentoStore } = this.props;
    const { tabSelected, tabs } = this.state;

    if(tabSelected === tabs.PAGAMENTO) {
      await atendimentoStore.handleSalvarPagamento('agendamento');
      this.changeTabSelected(0);
    } else {
      this.state.isExcluirAgendamento ? atendimentoStore.openExcluirAgendamentoAlert(atendimentoStore.objView) : this.agendar();
    }
    this.setState({ 
      openConfirmaEditRecorrencia: false,
    });
  };

  agendar = async (isControleSessaoScreen=false) => {
    const { atendimentoStore } = this.props;
    const { objView, controleSessaoData, isAgendamentoModalListaEspera, isModalSala } = atendimentoStore;
    const {
      profissionalSaude,
      tempoInicioAgendamento,
    } = this.props.googleAnalyticsStore;
    try {
      if (
        (isControleSessaoScreen && !objView.profissionalSaude && !controleSessaoData.isProcedure) || 
        (isAgendamentoModalListaEspera && !isModalSala && !objView.profissionalSaude)
      ) {
        throw new Error('Selecione um profissional');
      }

    const result =  await atendimentoStore.agendar(isControleSessaoScreen);
    if(result?.errors) {
      throw new Error(result?.errors[0]?.message || 'Ocorreu um erro ao criar um agendamento');
    }
    if (
      result?.documento ||
      result?.nome ||
      result?.telefone ||
      result?.convenio ||
      (atendimentoStore.isModalSala && result?.sala) ||
      result?.duracao
    ) {
      let messageFields = "Campo(s) inválido(s): ";
      messageFields += result.documento ? "'documento' " : "";
      messageFields += result.nome ? "'nome '" : "";
      messageFields += result.telefone ? "'telefone'" : "";
      messageFields += result.sala ? "'sala'" : "";
      messageFields += result.duracao ? "'duracao'" : "";
      messageFields += result.convenio ? "'convenio'" : "";

      const notification = {
        isOpen: true,
        message: messageFields,
        variant: "error"
      };

      atendimentoStore.showAlertMessage(notification);
      ga(
        this.props.googleAnalyticsStore.profissionalSaude + ".send",
        "event",
        "Agenda",
        "Alertas",
        messageFields
      );
    } else {
      if( atendimentoStore.typeCalendarViewSelected === 'filterPacientView'){
        atendimentoStore.atualizaAgendamentosFilter({clear: true});
      };

      this.setState({ listaNegra: "" });
    }

    tempoInicioAgendamento &&
      ga(
        profissionalSaude + ".send",
        "timing",
        "Modal agendamento",
        "Tempo total para realizar um agendamento",
        moment().format("x") - tempoInicioAgendamento
      );
    ga(
      this.props.googleAnalyticsStore.profissionalSaude + ".send",
      "event",
      "Agenda",
      "Modal agendamento",
      "Agendar"
    );
    } catch (error) {
        const notification = {
          isOpen: true,
          message: error?.message.includes('Exception') ? 'Erro ao agendar' : error?.message,
          variant: "error"
        };
        atendimentoStore.showAlertMessage(notification)
        atendimentoStore.disableModalButtons = false
      }
  };

  renderButtons() {
    const { classes, atendimentoStore } = this.props;
    const { disableModalButtons, tipoProfissionalLogado, isLastAgendamentoOfRecurrence, objView } = atendimentoStore;
    
    const showRecorrenciaButton =
      (atendimentoStore.typeCalendarViewSelected !== 'encaixesView' && tipoProfissionalLogado !== "tecnico") &&
      (!atendimentoStore.agendamentoRecorrencia || isLastAgendamentoOfRecurrence);
      const statusNaoBloqueado = atendimentoStore.objView.situacao !== "BLOQUEADO";

    return (
      <Grid container direction={"row"} className={classes.gridButtons}>
          <div className={classes.buttonsConfirmarTransferir}>
        <Grid item xs={12} className={classes.groupButtons}>
          <Grid item xs={3} className={classes.inlineButtons}>
            <ButtonConfirm
              onClick={this.handleClickAgendar}
              disabled={disableModalButtons}
            >
              {objView?.id ? "Confirmar" : "Agendar"}
            </ButtonConfirm>
          </Grid>
          
          {objView?.id && 
            !atendimentoStore.isModalSala &&
            atendimentoStore.permiteVerAgendaOutrosProfissionais &&
            atendimentoStore.tipoProfissionalLogado !== "tecnico" &&
              <Grid item xs={3} className={classes.inlineButtons}>
                  <ButtonTransferir
                    onClick={() => this.setState({openTransferirAgendamentos: true})}
                    disabled={disableModalButtons}
                  >
                    Transferir
                </ButtonTransferir>
              </Grid>
          }
          {(showRecorrenciaButton && statusNaoBloqueado && objView.sujeitoAtencao?.id) &&
            <Grid xs={3} className={classes.inlineButtons}>
              <ButtonTransferir
                onClick={this.onClickRecorrencia}
                disabled={disableModalButtons}
              >
                Recorrência
              </ButtonTransferir>
            </Grid>
          }
          </Grid>
          </div>
          <Grid item xs={3} className={classes.inlineButtonLast}>
            {objView?.id &&
            atendimentoStore.tipoProfissionalLogado !== "tecnico" ? (
              <span className={classes.buttonCancel}
                onClick={this.handleClickCancelarConsulta}
                disabled={disableModalButtons}
              >
                Excluir
              </span>
            ) : null}
          </Grid>
      </Grid>
    );
  }

  renderSujeitoAgendamentosOrList() {
    const { atendimentoStore, classes } = this.props;
    const { objView, tipoProfissionalLogado } = atendimentoStore;

    const isStatusBloqueado = atendimentoStore.objView.situacao === "BLOQUEADO";

    if (isStatusBloqueado) {
      return null;
    }

    if (tipoProfissionalLogado === "tecnico") {
      return <AtendimentoModalListaProcedimentos onClose={this.handleClose} />;
    }

    return objView.sujeitoAtencao?.id ? (
      <AtendimentoModalSujeitoAgendamentos
        agendarProcedimento={this.agendarProcedimento}
        onClose={this.handleClose}
      />
      ) : (
      <Tooltip
        open={atendimentoStore.waitingSelectSujeitoToLink}
        title={
          <TooltipCard
            title={<>Vincular sujeito de atenção ao portal{" "}{getWhitelabelNome()} </>}
            message={<>Selecione um sujeito de atenção na lista abaixo, no qual o mesmo será vinculado ao{" "}{getWhitelabelNome()}</>}
          />
        }
        classes={{
          tooltip: classes.tooltip
        }}
        placement="top"
      >
        <AtendimentoModalSujeitoList
          onClose={this.handleClose}
          openNotification={this.handleOpenNotification}
          history={this.props.history}
          />
      </Tooltip>
    );
  }


  renderInfoSujeitoAtencaoOrEdit() {
    const { atendimentoStore, classes } = this.props;
    const { errors, objView, agendamentoRetornoData } = atendimentoStore;
    return <>{objView.sujeitoAtencao?.id ? (
      <InfosSujeitoAtencaoModal 
        id={objView.sujeitoAtencao.id} 
        title="Infos do paciente:"
        convenio={objView.convenio?.descricao}
        handleClickEditSujeitoAtencao={this.handleClickEditSujeitoAtencao}
        handleClickEditObservacao={this.handleClickEditObservacao}
        handleClickDocumentoSujeitoAtencao={this.handleClickDocumentoSujeitoAtencao}
        handleClickAssinaturaPresenca={this.handleClickAssinaturaPresenca}
        handleClickOrcamentosSujeitoAtencao={this.handleClickOrcamentosSujeitoAtencao}
        forwardWhattsapp
        objView={objView}
      />
    ) : (
      <div className={classes.boxInfosSujeitoAtencao}>
        <div className={classes.paperListTitle}>
          Infos do paciente:
        </div>
        <Grid
          item
          container
          direction={"row"}
          xs={12}
          className={classes.spaceTop}
        >
          <ItemGrid size={6} label={"Nome*:"}>
            <InputForm
              id="nome"
              value={objView.nome || ""}
              className={classes}
              classes={{
                input: classes.inputInfos,
                inputError: classes.inputInfos,
              }}
              customStyle={classes.input}
              autoFocus={!atendimentoStore.idOpened}
              error={errors.nome}
              onChange={(e) => this.onNomeChange(e)}
              inputProps={{ maxLength: 100 }}
            />
          </ItemGrid>

          <ItemGrid size={6} label={"Telefone*:"}>
            <div className={classes.telefoneWhatsApp}>
              {this.props.unidadeStore.unidade.utilizaTelefoneInternacional ? 
                <PhoneInput
                  value={objView.telefoneDDI + objView.telefone}
                  onChange={(phone, data) => this.onTelefoneChangeComDDI(phone, data)}
                  placeholder='Telefone Principal'
                  preferredCountries={['br', 'es']}
                  localization={pt}
                  masks={{br: '(..) .....-....', ar: '. ... ... ....'}}
                  containerClass={classNames(classes.telefoneInputContainer)}
                  inputClass={classNames(classes.telefoneInput)}
                  buttonClass={classNames(classes.flagButton)}
                  countryCodeEditable={false}
                  disableDropdown={!this.props.unidadeStore.unidade.utilizaTelefoneInternacional}
                /> : 
                <InputPhoneForm
                  id="telefone"
                  value={objView.telefone}
                  classes={{
                    input: classes.inputInfosTelefone,
                    inputError: classes.inputInfos,
                  }}
                  error={errors.telefone}
                  onChange={(e) => this.onTelefoneChange(e)} 
                />
              }
              {String.validaTelefone(objView.telefone) && (
                <ButtonWhatsapp
                  onClick={() => this.handleClickWhatsapp()}
                />
              )}
            </div>
          </ItemGrid>

          { this.state.paisAtuacao === "ESPANHA" ?
            <>
              <ItemGrid size={4} label={"Tipo documento:"}>
                <ReactSelect
                  name={"documentoTipo"}
                  value={objView.documentoTipo}
                  className={classes.selectInputDocumentoTipo}
                  onChange={this.onDocumentoTipoChange}
                  options={atendimentoStore.documentosTipo || []}
                  menuPlacement={'auto'}
                  getOptionLabel={(option) => option.descricao}
                  getOptionValue={(option) => option.id}
                />
              </ItemGrid>
              <ItemGrid size={4} label={"Documento:"}>
                <InputDocumentos
                  name={'Documento'}
                  placeholder=""
                  value={objView.documento}
                  className={classes.inputDivMask}
                  classes={{
                    input: classes.inputMask,
                    inputError: classes.inputMaskError,
                  }}
                  onChange={(e) => this.onDocumentoChange(e)}
                  tipo={objView?.documentoTipo?.descricao}
                />
              </ItemGrid>
            </>
            :
            <ItemGrid size={6} label={"Documento:"}>
              <InputCPFForm
                id="documento"
                value={objView.documento}
                className={classes}
                classes={{
                  input: classes.inputInfos,
                  inputError: classes.inputInfos,
                }}
                error={errors.documento}
                onChange={(e) => this.onDocumentoChange(e)}
              />
            </ItemGrid>
          }
          <ItemGrid size={6} label={"Nascimento:"}>
          <InputDateForm 
            id='data-nascimento'
            className={classes.dateNascimento}
            classes={{
              input: classes.inputInfos,
              inputError: classes.inputInfos,
            }}
            value={objView.dataNascimento}
            onChange={this.onDataNascimentoChange}
            iconposition="end"
            />
        </ItemGrid>

          {this.state.listaNegra !== "" && this.state.listaNegra !== null && (
            <StyledItemGridListaNegra>
              <StyledWarning>
                O paciente faltou no dia
                {moment(this.state.listaNegra).format("LL")}
              </StyledWarning>
            </StyledItemGridListaNegra>
          )}
          {string.validaCPF(objView.documento) && this.state.showDocumentoWarning && (
            <StyledItemGridListaNegra>
              <StyledWarning>
                Existe(m) paciente(s) com o mesmo documento.
              </StyledWarning>
            </StyledItemGridListaNegra>
          )}
        </Grid>
      </div>
    )}
    {agendamentoRetornoData && (
      <div className={classes.notificationAgendamentoRetorno}>
        <InfoIconFilled color='#505050' />
        <span>
          <strong>Aviso:</strong>{' '}
          Esse agendamento é referente a um retorno, iniciado no dia {moment(agendamentoRetornoData).format('DD/MM/YY')}.
        </span>
      </div>
    )}
    </>;
  }

  renderHeaderView() {
    const { classes, atendimentoStore } = this.props;

    return (
      <div className={classes.header}>
        {atendimentoStore.tipoProfissionalLogado !== "tecnico" &&
          this.renderButtonsTop()}
        {this.renderInfoSujeitoAtencaoOrEdit()}
      </div>
    );
  };

  onClickRecorrencia = () => {
    const { atendimentoStore } = this.props;
    const { objView } = atendimentoStore;
    let telefone = objView.sujeitoAtencao || objView.telefone;
    
    if (!objView.nome || !telefone || (atendimentoStore.isModalSala && !objView.sala?.id) || (atendimentoStore.isModalSala && !objView.procedimentos)) {
      const errors = {
        nome: !objView.nome,
        telefone: !telefone,
        procedimentos: atendimentoStore.isModalSala && !objView.procedimentos,
        sala: atendimentoStore.isModalSala && (!objView.sala?.id || objView.sala?.id === '0')
      };
  
      const messageFields = Object.entries(errors)
        .filter(([_, isError]) => isError)
        .map(([field, _]) => `'${field}'`)
        .join(" ");
    
      const notification = {
        isOpen: true,
        message: `Campo(s) inválido(s): ${messageFields}`,
        variant: "error"
      };
    
      atendimentoStore.showAlertMessage(notification);
      return;
    }

    if (objView.isConsultaRemota && !objView.possuiPaciente){
      this.habilitaAcessoApp();
    }
    atendimentoStore.modalRecorrenciaIsOpen = true;
    this.setState({ openModalRecorrencia: true })
  };

  changeTabSelected = async(tabSelected) => {
    const { atendimentoStore, extratoStore, auditoriaStore } = this.props;
    const { isModalSala } = atendimentoStore;
    const { tabs } = this.state;

    auditoriaStore.auditoriaProps={
      ...auditoriaStore.auditoriaProps,
      pageNumber: 0,
      lastPage: false,
      auditorias: [],
    }
    if (tabSelected === tabs.AUDITORIA) {
      auditoriaStore.load={
        query: atendimentoStore.findaAgendamentoAuditById, 
        variableName: "agendamentoId",
        variableId: atendimentoStore.objView?.id, 
      }
      await auditoriaStore.loadAuditItems();
    }
    if(tabSelected === tabs.PAGAMENTO) {
      const nomeConsulta = atendimentoStore.objView.tipo?.label || atendimentoStore.objView.tipo?.descricao || ""
      extratoStore.titulo.nome = isModalSala ? this.getNomeProcedimentos() : nomeConsulta ;
    };

    if (this.state.tabSelected === tabs.PAGAMENTO) {

      if(!extratoStore.titulo?.id && atendimentoStore.objView.convenio?.id) {
        const styleText = {
          fontWeight: 600,
          fontSize: '18px',
          color: '#505050',
        };

        const styleTextRed = {
          color: "#FB7676",
          fontSize: '18px',
          fontWeight: 600
        };

        atendimentoStore.dadosModalConfirmacao= {
          open: true,
          title: "",
          color: "",
          icon: null,
          buttons: {
            labelPrimaryButton: "Cancelar",
            labelSecondButton: "Sair",
            handlePrimaryButton: () => {
              atendimentoStore.dadosModalConfirmacao=modalConfirmacaoDefault;
            },
            handleSecondButton: () => {
              this.setState({
                tabSelected: tabSelected
              });
              atendimentoStore.dadosModalConfirmacao = modalConfirmacaoDefault;
            },
          },
          children: () => (<div style={styleText}> Você não salvou os dados de pagamento, <span style={styleTextRed}>deseja sair sem salvar?</span></div>)
        }

        return;
      }
    }
    if (tabSelected === tabs.PROCEDIMENTO) {
      this.findEspecialidadeMedica()
      this.findProcedimentoOdontologicoPaciente()
    }
    this.setState({
      tabSelected: tabSelected
    });
  };

  getNomeProcedimentos = () => {
    const { atendimentoStore } = this.props;
    const { objView } = atendimentoStore;

    let nomeProcedimento = "";

    objView.procedimentos.forEach(element => {
      nomeProcedimento = `${nomeProcedimento} ${element.nomeProcedimento || element.procedimento?.nome},`
    });

    return nomeProcedimento
  };

  linkConsultaRemota = () => {
    const { atendimentoStore } = this.props;

    const link = `${atendimentoStore.urlVizi}/telemedicina/${atendimentoStore.objView.chavePublica}`

    return link
  };

  renderHistoricoItems = () => {
      return (
        <Suspense fallback={<CircularProgress size={32} />}>
          <AuditoriaTab
            modificationMessageWithValue
          />
        </Suspense>
      );
  };

  copiarLinkDaTeleconsulta = async () => {
    const copyText = document.getElementById("link-consulta-remota");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    try {
      await navigator.clipboard.writeText(copyText.value);
      const notification = {
        isOpen: true,
        message: 'Link da telemedicina copiado para área de transferência.',
        variant: 'success'
      };
      this.showAlertMessage(notification);
    } catch (error) {
      console.error('Erro ao copiar link da telemedicina para área de transferência', error);
      const notification = {
      isOpen: true,
      message: 'Erro ao copiar link da telemedicina para área de transferência.',
      variant: 'error'
      };
      this.showAlertMessage(notification);
    }
  };

  verificaDataConsultaMaiorDataAtual = () => {
    const { atendimentoStore } = this.props;
    const dataAtual = moment();

    const isDataConsultaMaiorDataAtual = moment(atendimentoStore.objView.data).isAfter(dataAtual);
    return isDataConsultaMaiorDataAtual || atendimentoStore.objView.data === dataAtual.format("YYYY-MM-DD");
  }

  getPageTitle = () => {
    const { atendimentoStore } = this.props;
    const { idOpened } = atendimentoStore;

    const acao = idOpened ? "Editar" : "Novo"

    return `Agenda - ${acao} agendamento`
  }

  handleCloseRecorrenciaModal = () => {
    const { atendimentoStore } = this.props;
    this.setState({ openModalRecorrencia: false });
    atendimentoStore.modalRecorrenciaIsOpen = false;
    atendimentoStore.updatingRecorrencia = false;
  }

  handleClickWhatsappTeleconsulta = () => {
    const { atendimentoStore } = this.props;
    const { objView } = atendimentoStore;
    const dadosMensagem = {
      nomePaciente: objView.sujeitoAtencao?.nome || objView.nome,
      nomeFantasiaUnidade: objView.unidade?.nomeFantasia,
      nomeProfissional: objView.profissionalSaude?.nome,
      horaInicio: objView.horaInicio || " - ",
      data: moment(objView.data).format("DD/MM/YYYY"),
      remoto: true,
      chavePublica: objView.chavePublica,
    };
    const mensagemTeleconsulta = getMensagemTeleconsulta(dadosMensagem);
    goToWhatsApp(objView.telefone, objView.telefoneDDI, mensagemTeleconsulta);
  }

  handleChangeConvenio = async (e) => {
    const { atendimentoStore, extratoStore } = this.props;
    const { objView, isModalSala } = atendimentoStore;
    const { sujeitoAtencao, titulo } = objView || {};
    const dadosConvenio = sujeitoAtencao?.dadosConvenios ? sujeitoAtencao.dadosConvenios?.find(convenio => convenio.convenio?.id === e?.id) : [];

    if(!e){
      atendimentoStore.objView.convenio = null;
      atendimentoStore.objView.numeroCarteira = "" ;
      atendimentoStore.objView.validadeCarteira = "";
    } else {
      const isConvenioPaciente = dadosConvenio;
      extratoStore.editMode = true;
      atendimentoStore.objView.convenio = e;
      atendimentoStore.objView.numeroCarteira = isConvenioPaciente ? dadosConvenio?.numeroCarteira : "" ;
      atendimentoStore.objView.validadeCarteira = isConvenioPaciente ? dadosConvenio?.validadeCarteira : "";
    };

    !titulo?.id && atendimentoStore.updateTotal(isModalSala);
    await atendimentoStore.contemErros();
  };

  handleChangeModalAgendamento = (field, value) => {
    this.props.atendimentoStore.objView[field] = value;
  };

  getOptions = () => {
    const { atendimentoStore } = this.props;
    const { disableModalButtons } = atendimentoStore;
    const isDisabled = !atendimentoStore.idOpened || disableModalButtons || !atendimentoStore.canViewPayments
    if (this.showFinanceiroAzulControle) {
      return ['Agendamento', 'Procedimento', 'Auditoria'];
    } else {
      const paymentOption = isDisabled ?
        <Tooltip title="Pagamento será liberado após finalizar o agendamento" placement="top">
          <span>Pagamento</span>
        </Tooltip> : 'Pagamento';

      const options = [
        'Agendamento',
        paymentOption,
        'Procedimento',
        'Auditoria'
      ];

      return options;
    }
  };
  

  getPosicaoDesabilitada = () => {
    const { atendimentoStore, unidadeStore } = this.props;
    const { disableModalButtons } = atendimentoStore;

    const tabs = {
      azulControle: {disabled: [0,1,2], financeiro: []},
      health: {disabled: [0,1,2,3], financeiro: [1]},
    };

    const tipoFinanceiro = unidadeStore.azulControleAtivado ? "azulControle" : "health";
    const tipoFinanceiroTab = tabs[tipoFinanceiro];

    return disableModalButtons ? tipoFinanceiroTab.disabled : tipoFinanceiroTab.financeiro;
  };

  loadAllProfissionais = async (search, loadedOptions, { page }) => {
    const variables = {
      search,
      pageableDTO: {
        pageNumber: page,
        pageSize: 20,
        sortDir: "ASC",
        sortField: "nome",
      },
      externos: true,
      ativo: true,
    };

    const response = await findAllProfissionalSaudeEExterno(variables);
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  }

  render() {
    const { classes, atendimentoStore, filtroHeaderStore, history } = this.props;
    const {
      errors,
      objView,
      isModalSala,
      tipoProfissionalLogado,
      disableModalButtons,
      isOdontograma,
      hasAuthorityToEditRecorrencia,
      listaProfissionalSaudeComAgenda,
      isAgendamentoModalListaEspera,
    } = atendimentoStore;

    const { 
      tabSelected,
      tabs,
      openModalEtiquetaIdentificacao ,
      isProfissionalOdontologico,
      procedimentosOdontologicosPaciente,
      openModalOrcamentosVinculadoAgendamento,
    } = this.state;

    const isStatusBloqueado = atendimentoStore.objView.situacao === "BLOQUEADO";
    const statusNaoBloqueado = atendimentoStore.objView.situacao !== "BLOQUEADO";
    const disabled = atendimentoStore.tipoProfissionalLogado === "tecnico";
    const showProfissionalSaudeSelectTabProcedimento = listaProfissionalSaudeComAgenda;
    const isControleSessaoScreen = history.location.pathname.includes('/sessoes-em-andamento');

    const isSalaInternacao = filtroHeaderStore.sala?.internacao;

    return (
      <>
      <PageTitle title={this.getPageTitle()}/>

      <Dialog
        open={atendimentoStore.open}
        maxWidth={isStatusBloqueado ? "sm" : "md"}
        fullWidth={!isStatusBloqueado}
        classes={{ paper: classNames(classes.paperWidthMd, isStatusBloqueado && classes.paperWidthSm) }}
        onExited={this.onExit.bind(this)}
      >
        <Grid
          container
          className={classes.container}
          direction={"column"}
          wrap={"nowrap"}
        >

          <DialogContent className={[classes.gridFather, isStatusBloqueado && classes.gridFatherBloqueado]}>
            { isStatusBloqueado && <Fab
                onClick={this.handleClose}
                classes={{
                  sizeSmall: classes.btnCloseStatusBloqueado,
                }}
                size="small"
                color="primary"
              >
                <CloseIcon />
              </Fab>}
            <Grid
              container
              direction={"column"}
              wrap={"nowrap"}
              className={[classes.gridFlex, !isStatusBloqueado && classes.gridFlexNaoBloqueado]}
            >
              {!isStatusBloqueado && this.renderHeaderView()}

              <div
                className={
                  !isStatusBloqueado
                    ? classes.boxAgendamento
                    : classes.boxBloqueado
                }
              >
                {!isStatusBloqueado && (
                  <MultiToggleButtons 
                    options={this.getOptions()}
                    tabSelected={tabSelected}
                    changeTabSelected={this.changeTabSelected}
                    disabled={!atendimentoStore.idOpened || disableModalButtons || !atendimentoStore.canViewPayments || isOdontograma}
                    posicaoDesabilitada={this.getPosicaoDesabilitada()}
                  />
                )}
                
                {tabSelected === tabs.AGENDAMENTO && <div className={classes.modalContent}>
                  <Grid
                    item={true}
                    container
                    direction={"row"}
                    xs={12}
                    className={classes.spaceTop}
                  >
                    {!isStatusBloqueado &&
                      (isModalSala || tipoProfissionalLogado === "tecnico") && (
                        <ItemGrid size={4} label={"Sala*:"}>
                          <SelectForm
                            name={"salas"}
                            value={atendimentoStore.objView.sala?.id}
                            onChange={this.onAtendimentoSalaChange}
                            elements={filtroHeaderStore.salas}
                            disabled={disabled}
                            error={errors.sala}
                          />
                        </ItemGrid>
                      )}

                    <ItemGrid size={4} label={"Data da consulta:"}>
                      <InputDateForm
                        id="data-consulta"
                        iconposition="end"
                        className={classes.dateTime}
                        classes={{
                          date: classes.inputDadosAgendamento,
                        }}
                        disabled={disabled}
                        value={objView.data !== "" ? objView.data : null}
                        onChange={this.onGenericDataChange("data")}
                      />
                    </ItemGrid>

                    {!isStatusBloqueado &&
                      (!isModalSala && tipoProfissionalLogado !== "tecnico") && (
                        <ItemGrid size={4} label={"Tipo:"}>
                          <ReactSelect
                            className={classes.selectSearch}
                            classes={{
                              control: classes.selectTipo,
                            }}
                            placeholder={"Selecione"}
                            value={objView?.controleSessaoAgendamentoTipo?.agendamentoTipo ? objView?.controleSessaoAgendamentoTipo?.agendamentoTipo : (objView.nomeAgendamentoTipo || objView.tipo)}
                            onChange={(selectedOption, triggeredAction) => {
                              if (triggeredAction.action === 'clear') {
                                this.onAtendimentoTipoChange(null)
                                objView.controleSessaoAgendamentoTipo = null
                              }
                              this.onAtendimentoTipoChange(selectedOption)
                            }}
                            options={atendimentoStore.tipo || []}
                            isDisabled={atendimentoStore.controleSessaoData.idPacote && !atendimentoStore.controleSessaoData.isProcedure}
                            menuPlacement={'auto'}
                            getOptionLabel={(option) => option.label || option.name || option.descricao}
                            getOptionValue={(option) => option.value || option.id}
                            isClearable
                          />
                        </ItemGrid>
                      )}


                    <ItemGrid size={4} label={"Situação:"}>
                      <SelectForm
                        name={"situacao"}
                        className={classes.selectSearch}
                        value={atendimentoStore.objView.situacao || ""}
                        onChange={this.onGenericChange("situacao")}
                        elements={
                          atendimentoStore.typeCalendarViewSelected ===
                          "encaixesView" || tipoProfissionalLogado === "tecnico"
                            ? atendimentoStore.situacoes.filter(
                                (situacao) => situacao.value !== "BLOQUEADO"
                              )
                            : atendimentoStore.situacoes
                        }
                        classes={{
                          select: classes.inputInfosDuracaoSituacao,
                        }}
                      />
                    </ItemGrid>

                    {isStatusBloqueado && (
                      <ItemGrid size={4} label={"Motivo:"}>
                        <InputForm
                          id="motivo"
                          value={objView.motivo || ""}
                          className={classes}
                          classes={{
                            input: classes.inputInfos,
                            inputError: classes.inputInfos,
                          }}
                          customStyle={classes.input}
                          onChange={(e) => this.onMotivoChange(e)}
                        />
                      </ItemGrid>
                    )}
                  </Grid>

                  {atendimentoStore.typeCalendarViewSelected !==
                    "encaixesView" && (
                    <Grid
                      item={true}
                      container
                      direction={"row"}
                      xs={12}
                    >
                      <ItemGrid size={4}>
                        <InputTimeForm
                          label="Início:"
                          value={moment(
                            atendimentoStore.objView?.horaInicio,
                            "HH:mm"
                          ).toDate()}
                          classes={{
                            date: classes.inputDadosAgendamento,
                            icon: classes.iconTimeDadosAgendamento
                          }}
                          iconPosition="end"
                          className={classes.dateTime}
                          error={false}
                          disabled={disabled}
                          onChange={this.onGenericHoraChange("horaInicio")}
                        />
                      </ItemGrid>

                     { !(isModalSala && isSalaInternacao) &&
                      <>
                      <ItemGrid size={4} label={"Duração:"}>
                        <ReactSelectCreate
                          className={classes.searchDuracao}
                          name="duracao"
                          placeholder="Duração"
                          value={atendimentoStore.objView.duracao}
                          options={atendimentoStore.duracoesDisponives || []}
                          onChange={this.onChangeValueDuracao}
                          onCreateOption={this.handleCreateDuracao}
                          formatCreateLabel={this.handleFormat}
                          onInputChange={this.onInputChangeDuracao}
                        />
                      </ItemGrid>

                      <ItemGrid size={4}>
                        <InputTimeForm
                          label="Fim:"
                          value={moment(
                            atendimentoStore.objView?.horaFim,
                            "HH:mm"
                          ).toDate()}
                          iconPosition="end"
                          className={classes.dateTime}
                          classes={{
                            date: classes.inputDadosAgendamento,
                            icon: classes.iconTimeDadosAgendamento
                          }}
                          helperText={""}
                          error={false}
                          disabled={true}
                          onClickCapture={this.showAlertMessageDuracao}
                        />
                      </ItemGrid>
                      </>
                      }
                    </Grid>
                  )}
                  { statusNaoBloqueado && <div className={classes.contentConvenio}>
                    <ItemGrid
                        size={4}
                        label={"Convênio:"}
                      >
                      <TextFieldSearch
                        placeholder=""
                        loadOptions={atendimentoStore.handleConvenioLoadOptions}
                        withPaginate
                        value={objView?.convenio?.id ? objView?.convenio : null}
                        onChange={(e) => this.handleChangeConvenio(e)}
                        debounceTimeout={300}
                        additional={{
                          page: 0,
                        }}
                        classNotched={classes.notchedOutlineSearch}
                        classInput={classNames(classes.inputSearch, errors?.convenio ? classes.textFieldError : null)}
                        error={errors.convenio}
                      />
                    </ItemGrid>
                    <ItemGrid
                        size={4}
                        label={"Número da carteira:"}
                      >
                      <InputForm
                        value={objView.numeroCarteira}
                        onChange={(e) =>
                          this.handleChangeModalAgendamento("numeroCarteira", e.target.value)
                        }
                        disabled={!objView.convenio?.id}
                        inputProps={{ maxLength: 20 }}
                      />
                    </ItemGrid>
                    <ItemGrid
                      size={4}
                      label={"Data de validade:"}
                    >
                      <InputDateForm
                        value={objView?.validadeCarteira || ""}
                        onChange={(e) =>
                          this.handleChangeModalAgendamento("validadeCarteira", e)
                        }
                        iconposition="end"
                        className={classNames(classes.inputDate)}
                        invalidDateMessage=""
                        disabled={ !objView.convenio?.id}
                      />
                    </ItemGrid>
                  </div>}
                  {
                    !isStatusBloqueado && isAgendamentoModalListaEspera && (
                      <div className={classes.contentConvenio}>
                        <ItemGrid
                          size={12}
                          label={"Profissional saúde:"}
                        >
                          <ReactSelect
                            className={classes.selectSearch}
                            name="Profissional Saúde"
                            placeholder={"Profissional Saúde"}
                            value={objView.profissionalSaude}
                            options={
                              atendimentoStore.listaProfissionaisSaudeListaEspera ||
                              []
                            }
                            getOptionLabel={(option) => option.nome}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => this.handleChangeModalAgendamento("profissionalSaude", e)}
                            menuPlacement={'auto'}
                          />
                        </ItemGrid>
                      </div>
                    )
                  }
                  <div className={classes.consultaObservacoes}>
                    {
                      !isModalSala && tipoProfissionalLogado !== "tecnico" && !isStatusBloqueado && (
                        <StyledItemGridIsConsultaRemota
                            size={2}
                            label={"Consulta:"}
                          >
                            {atendimentoStore.tiposConsulta.map((item) => (
                              <FormControlLabel 
                                key={item.id}
                                value={item.id} 
                                name="isConsultaRemota"
                                className={classes.radioButton}
                                control={<Radio color="primary" />} 
                                label={item.name} 
                                checked={objView.isConsultaRemota === item.value}
                                onChange={() =>
                                      this.onIsConsultaRemotaChange(item.value)
                                    }
                              />

                            ))}
                          </StyledItemGridIsConsultaRemota>
                      )
                    }

                    {!isStatusBloqueado && (
                      <ItemGrid
                        size={10}
                        label={"Observações:"}
                        classes={{itemTitle: classes.itemGridObservacoes}}
                      >
                        <InputForm
                          id="observacao"
                          value={objView.observacao || ""}
                          className={classes.observacao}
                          classes={{
                            input: classes.inputInfos,
                          }}
                          rows={isAgendamentoModalListaEspera ? 2 : 4}
                          multiline
                          autoFocus={!!atendimentoStore.idOpened}
                          onFocus={this.moveCursorAtEnd}
                          disabled={disabled}
                          error={errors.observacao}
                          onChange={this.onGenericChange("observacao")}
                        />
                      </ItemGrid>
                    )}
                  </div>
                  {!isStatusBloqueado && objView.isConsultaRemota && atendimentoStore.objView.chavePublica &&
                    <div>
                      <ItemGrid size={12} label={"Link teleconsulta:"}>
                        <div className={classes.inputComBotao}>
                          <InputForm
                            id="link-consulta-remota"
                            value={this.linkConsultaRemota()}
                            className={classes.inputInfosLink}
                            classes={{
                              input: classes.inputInfosLink,
                            }}
                            customStyle={classes.inputLink}
                            disabled
                          />
                          <ButtonWhatsapp
                            onClick={this.handleClickWhatsappTeleconsulta}
                          />
                          <div onClick={this.copiarLinkDaTeleconsulta} className={classes.botaoCopiarLink}>
                            <img
                              alt="copiar link"
                              src={copiarLinkIcon}
                            />
                          </div>
                        </div>
                      </ItemGrid>
                    </div>
                  }
                </div>
                }

                {
                  tabSelected === tabs.PAGAMENTO && atendimentoStore.idOpened &&
                    <AtendimentoModalTabPagamento history={history}/>
                }

                {(!isStatusBloqueado && tabSelected === tabs.PROCEDIMENTO) && (
                  <div className={classes.modalContentOutros}> 
                    <div className={classes.modalContentProcedimento}> 
                      <Grid
                        item={true}
                        container
                        direction={"row"}
                        xs={12}
                        className={classes.spaceTop}
                      >
                      {!isModalSala && <ItemGrid size={12} label={"Sala:"}>
                        <TextFieldSearch
                          classNotched={classes.notchedOutline}
                          classInput={classes.inputTextField}
                          classIcons={classes.classIcons}
                          placeholder={"Selecione a sala"}
                          loadOptions={this.loadSalas}
                          value={objView.sala}
                          onChange={this.handleSala}
                          menuPlacement={'auto'}
                          isClearable
                          getOptionLabel={(option) => option.nome}
                          getOptionValue={(option) => option.id}
                          withPaginate
                          debounceTimeout={300}
                          additional={{
                            page: 0,
                          }}
                        />
                      </ItemGrid>}

                    <ItemGrid size={12} label={"Procedimentos:"}>
                      {!isProfissionalOdontologico ?
                        <TextFieldSearch
                            classNotched={classes.notchedOutline}
                            classInput={classes.inputTextField}
                            classIcons={classes.classIcons}
                            placeholder={"Selecione o procedimento"}
                            onChange={(e, index) => this.handleAddProcedimento(e, index)}
                            value={null}
                            menuPlacement={'auto'}
                            isClearable
                            getOptionLabel={(option) => option.nome}
                            getOptionValue={(option) => option.id}
                            withPaginate
                            loadOptions={this.loadProcedimentos}
                            debounceTimeout={300}
                            additional={{
                              page: 0,
                            }}
                            isDisabled={isOdontograma}
                          />
                          :
                            <ReactSelect
                              options={procedimentosOdontologicosPaciente}
                              onChange={(e) => this.handleAddProcedimento(e, 'Odontologico')}
                              value={null}
                              getOptionLabel={(option) => `${option.procedimento.nome} - ${option.descricao}`}
                              getOptionValue={(option) => option.procedimento.id}
                              className={classes.selectProcedimento}
                              placeholder={"Selecione"}
                            />
                          }
                        </ItemGrid>
                    </Grid>
                    <div className={classes.listaProcedimentosSelecionados}>
                      {objView.procedimentos &&
                        objView.procedimentos.map((item, index) => (
                          <div className={classes.procedimentoOption} key={index}>
                            {item?.nomeProcedimento || item?.procedimento?.nome || item?.procedimento?.procedimento?.nome}
                            {atendimentoStore.tipoProfissionalLogado !== "tecnico" && <CancelIcon
                              onClick={() =>
                                this.handleRemoveProcedimento(index)
                              }
                            />}
                          </div>
                        ))}
                    </div>
                    </div>
                    {showProfissionalSaudeSelectTabProcedimento && (
                      <ItemGrid size={12} label={"Solicitante / Responsável:"}>
                        <TextFieldSearch
                          placeholder="Selecione"
                          loadOptions={this.loadAllProfissionais}
                          getOptionLabel={(option) => option.nome}
                          getOptionValue={(option) => option.id}
                          value={objView.solicitante}
                          onChange={this.onSolicitanteProcedimentoChange}
                          withPaginate
                          classNotched={classes.notchedOutline}
                          classInput={classes.inputTextField}
                          debounceTimeout={300}
                          additional={{
                            page: 0,
                          }}
                        />
                      </ItemGrid>
                    )}
                    {((showProfissionalSaudeSelectTabProcedimento && isModalSala) || isControleSessaoScreen) &&(
                      <ItemGrid size={12} label={"Profissional de saúde:"}>
                        <ReactSelect
                          className={classes.selectSearch}
                          name="Profissional Saúde"
                          placeholder={"Profissional Saúde"}
                          value={objView.profissionalSaude}
                          options={
                            atendimentoStore.listaProfissionalSaudeComAgenda ||
                            []
                          }
                          getOptionLabel={(option) => option.nome}
                          getOptionValue={(option) => option.id}
                          onChange={this.onProfissionalSaudeProcedimentoChange}
                          isClearable
                        />
                      </ItemGrid>
                    )}
                  </div>
                )}
                {tabSelected === tabs.AUDITORIA && (
                  <>
                    <div className={classes.auditoriaHeader}>
                      <span>
                        Todas Alterações
                      </span>
                    </div>
                    {this.renderHistoricoItems()}
                  </>
                )}
              </div>

              <div className={classes.actionButtons}>
                { tabSelected === tabs.PAGAMENTO ?
                <ButtonsTabPagamento
                  history={this.props.history}
                  changeTabSelected={this.changeTabSelected}
                  handleActionModalConfirmaEditRecorrencia={(action) => this.setState({ openConfirmaEditRecorrencia: action })}
                />
                :
                this.renderButtons()}
              </div>
            </Grid>

            { tabSelected === this.tabs.PAGAMENTO && (atendimentoStore.objView.titulo?.some(item => item.ativo === true) || atendimentoStore.showTitulo) ?
            <Parcelamento handleClose={this.onExit.bind(this)} history={history} />
            :
            this.renderSujeitoAgendamentosOrList()}
          </DialogContent>

          {atendimentoStore.openInfosAgendamentoModal && (
            <InfosConsultaAtendimentoModal
              open={atendimentoStore.openInfosAgendamentoModal}
              onClose={() =>
                (atendimentoStore.openInfosAgendamentoModal = false)
              }
              agendamento={atendimentoStore.infosConsultaSelecionada}
            />
          )}

          {this.state.openConfirmarHorarioAgendamento && (
            <ConfirmarHorarioAgendamentoModal
              open={this.state.openConfirmarHorarioAgendamento}
              onConfirm={() => this.handleClickConfirmarHorarioAgendamento()}
              onClose={() =>
                this.setState({ openConfirmarHorarioAgendamento: false })
              }
            />
          )}
          {this.state.openTransferirAgendamentos &&
            <TransferirAgendamentos
              open={this.state.openTransferirAgendamentos}
              onConfirm={(horario) => this.onClickTransferirAgendamento(horario)}
              onCancel={() => this.setState({openTransferirAgendamentos: false})}
            />}
            {
              this.state.openModalRecorrencia &&
              <RecorrenciaModal
                show={this.state.openModalRecorrencia}
                onClose={this.handleCloseRecorrenciaModal}
                dadosRecorrencia={atendimentoStore.idOpened && atendimentoStore.agendamentoRecorrencia && this.state.dadosRecorrencia}
              />
            }
            {
              this.state.openModalAfericaoVital &&
                <AfericaoVitalModal
                  openModal={this.state.openModalAfericaoVital}
                  handleClose={this.onCloseAfericaoVital}
                  sujeitoAtencao={atendimentoStore.objView?.sujeitoAtencao}
                  idadeSujeitoAtencao={atendimentoStore.objView?.sujeitoAtencao?.dataNascimento && Dates.calculaIdade(atendimentoStore.objView?.sujeitoAtencao?.dataNascimento)}
                  screen="Agenda - Agendamento"
                />
            }
            {
              this.state.openModalAveliacaoAntropometrica &&
                <AvaliacaoAntropometricaModal
                  openModal={this.state.openModalAveliacaoAntropometrica}
                  handleClose={this.onCloseAvaliacaoAntropometrica}
                  sujeitoAtencao={atendimentoStore.objView?.sujeitoAtencao}
                  idadeSujeitoAtencao={Dates.calculaIdade(atendimentoStore.objView?.sujeitoAtencao?.dataNascimento)}
                  isNovoLancamento={true}
                  screen="Agenda - Agendamento"
                />
            }
             {
              this.state.openModalInternacao &&
                <InternacaoModal
                  openModal={this.state.openModalInternacao}
                  handleClose={this.onCloseModalInternacao}
                  dadosInternacao={this.state.dadosInternacao}
                  agendamentoId={atendimentoStore.idOpened}
                />
            }
            {this.state.openConfirmaEditRecorrencia && hasAuthorityToEditRecorrencia && !atendimentoStore.isLastAgendamentoOfRecurrence &&
              <ConfirmEditRecorrencia
                open={this.state.openConfirmaEditRecorrencia}
                onClose={() => this.setState({openConfirmaEditRecorrencia: false})}
                handleAgendamentoSelecionado={this.handleAgendamentoSelecionado}
                handleTodosAgendamentos={this.handleTodosAgendamentos}
                isExcluir={this.state.isExcluirAgendamento}
              />
            }
        </Grid>
        <ModalConfirmacao
          {...this.props.atendimentoStore.dadosModalConfirmacao}
        >
          {this.props.atendimentoStore.dadosModalConfirmacao?.children ? this.props.atendimentoStore.dadosModalConfirmacao?.children() : ""}
        </ModalConfirmacao>
        <Notification
          close={atendimentoStore.closeAlertMessage}
          reset={atendimentoStore.closeAlertMessage}
          isOpen={atendimentoStore.notification.isOpen}
          variant={atendimentoStore.notification.variant}
          message={atendimentoStore.notification.message}
        />
        {this.state.isPrintMustache && 
          <ImpressaoHtml
            isPrintMustache={this.state.isPrintMustache}
            sujeitoAtencaoId={this.state.sujeitoAtencaoRecuperado.id}
            handlePrintMustache={() => {this.setState({ isPrintMustache: false })}}
            htmlStringComponent={ <FichaSujeitoAtencaoPdfDocument
                sala={atendimentoStore?.objView?.sala}
                isModalSala={atendimentoStore.isModalSala}
                procedimentos={atendimentoStore?.objView?.procedimentos}
                dataAgendamento={atendimentoStore?.objView?.data || ""}
                horaInicioAgendamento={atendimentoStore?.objView?.horaInicio || ""}
                convenioAgendamento={atendimentoStore?.objView?.convenio || ""}
                tipoAtendimento={atendimentoStore?.objView?.tipo?.label || atendimentoStore?.objView?.tipo?.descricao || ""}
                profissionalSaude={atendimentoStore?.objView?.profissionalSaude?.nome || atendimentoStore?.objView?.solicitante?.nome || ""}
                InfosSujeitoAtencao
                sujeitoAtencao={this.state.sujeitoAtencaoRecuperado}
                utilizaTelefoneInternacional={this.state.utilizaTelefoneInternacional}
            />}

          />}
        <VincularSujeitoAlertDialog
          open={atendimentoStore.openModalVincularPaciente}
          alertTitle={atendimentoStore.modalVinculoSujeitoAlertTitle}
          alertDescription={atendimentoStore.modalVinculoSujeitoAlertDescription}
          onVincular={this.handleVincularViziPaciente}
          onCadastrarNovo={this.handleCadastrarNovo}
          onClose={this.handleCloseVincularAlertDialog}
        />
        {openModalEtiquetaIdentificacao && (
          <AtendimentoModalEtiquetas
            isOpen={openModalEtiquetaIdentificacao}
            agendamento={objView}
            onClose={() => this.setState({ openModalEtiquetaIdentificacao: false })}
          />
        )}
        {
          openModalOrcamentosVinculadoAgendamento && (
            <ModalOrcamentosListByAgendamento
              open={openModalOrcamentosVinculadoAgendamento}
              onClose={() => this.setState({ openModalOrcamentosVinculadoAgendamento: false })}
              agendamentoId={atendimentoStore.idOpened}
              history={history}
            />
          )
        }
      </Dialog>
      </>
    );
  }
}

export default withRouter(withStyles(styles)(AtendimentoModal));
