import React, { useEffect, useRef, useState } from "react"
import Dialog from "../../../../components/Dialog/Dialog"
import { CircularProgress, Fab, withStyles } from "@material-ui/core"
import PrintIcon from "../../../../components/Icon/Print"
import styles from "./ModalAssinaturaPresencaStyles"
import CloseIcon from "../../../../components/Icon/Close"
import Assinatura from "./Assinatura"
import InfosSujeitoAtencaoModal from "../../../../components/Modal/InfosSujeitoAtencaoModal"
import { inject } from "mobx-react"
import { Button } from "../../../../components/ui/Buttons"
import { observer } from "mobx-react-lite"
import { createAssinaturaAgendamento } from "../../../../services/AssinaturaPresencaService"


const ModalAssinaturaPresenca = observer((
  {
    classes,
    openModalAssinaturaPresenca,
    handleClose,
    atendimentoStore,
    handleImprimir,
    sujeitoAtencaoStore,
  }
) => {
  const { objView, loadingAgendamento ,isLoadingUnidadeConfig} = atendimentoStore;
  const [isLoading, setIsLoading] = useState(false)
  const [isTablet,setIsTablet]=useState(false)
  const assinaturaRef = useRef(null);

  useEffect(() => {
    verificaIsTablet()
  }, [])

  const verificaIsTablet = () => {
    setIsLoading(true)
    const width = window.innerWidth;
    const isTablet = width >= 700 && width <= 1400 && navigator.maxTouchPoints > 0
    setIsTablet(isTablet)
    setIsLoading(false)
  }

  const verificarContemAssinatura = () => {
    if (assinaturaRef.current) {
      const ctx = assinaturaRef.current.getContext('2d');
      const pixels = ctx.getImageData(0, 0, assinaturaRef.current.width, assinaturaRef.current.height).data;
  
      const vazio = pixels.every((pixel, index) => index % 4 !== 3 || pixel === 0);
      return vazio 
    }
  };

  const handleSave = async () => {
   if(verificarContemAssinatura()) {
     sujeitoAtencaoStore.openNotification("Preencha a assinatura!","error")
   }
    try {
      setIsLoading(true)
      const base64 = assinaturaRef.current.toDataURL('image/png').split(",")[1]
      const variables = {
        agendamentoId:objView?.id,
        objetoAmazonS3Dto:{
           base64:base64,
            extensao: ".png",
            nome: `assinatura_${objView.sujeitoAtencao.nome.replace(" ","")}`,
            descricao:`assinaturaPresenca`,
            image:true
          }
      }
     const response= await  createAssinaturaAgendamento(variables)
     if(response.id){
      await sujeitoAtencaoStore.openNotification("Salvo com sucesso!","success")
      handleClose()
     }

    } catch (e) {
      sujeitoAtencaoStore.openNotification("Erro ao salvar!","error")
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const clearCanvas = () => {
   const button = document.getElementById("canvas-clear")
   button.click();
  };


  return (
    <Dialog
      open={openModalAssinaturaPresenca}
      maxWidth={"xl"}
      fullWidth
      classes={{ paper: classes.dialog }}
    >
      {loadingAgendamento ? (
        <div className={classes.circularProgress}>
          <CircularProgress></CircularProgress>
        </div>
      ) : (
        <div className={classes.content}>
          <div>
            <div className={classes.header}>
              <div className={classes.title}>Assinar Confirmação</div>
              <div className={classes.icons}>
                {objView.assinaturaPresenca && (
                  <Fab
                    size={"small"}
                    style={{ backgroundColor: "#F9BE73", color: "#fff" }}
                    onClick={() => handleImprimir(objView?.id)}
                  >
                    <PrintIcon />
                  </Fab>
                )}
                <Fab size={"small"} color={"secondary"} onClick={handleClose}>
                  <CloseIcon />
                </Fab>
              </div>
            </div>
            <div>
              <div className={classes.contentDados}>
                <div className={classes.dadosPaciente}>
                    <InfosSujeitoAtencaoModal
                      id={objView?.sujeitoAtencao?.id}
                      convenio={objView.convenio?.descricao}
                      objView={objView}
                      hiddenIcons
                      isModalAssinatura
                      recize
                    />
                </div>
              </div>
            </div>
          </div>

          <div className={classes.contentLabelAssine}>
            <div className={classes.labelAssine}>Assine aqui</div>
            <div>
              <Assinatura
                objView={objView}
                handleSave={handleSave}
                disabled={isLoading}
                assinaturaRef={assinaturaRef}
                isDisabled={objView.assinaturaPresenca}
                isTablet={isTablet}
              />
            </div>
          </div>

          <div className={classes.contentButtons}>
            <div className={classes.buttonSave}>
              <Button
                onClick={clearCanvas}
                className={classes.buttonLimpar}
                bgColor={"#707C97"}
                disabled={objView.assinaturaPresenca}
              >
                Limpar
              </Button>
              <Button 
              onClick={handleSave} 
              className={classes.buttonSalvar}
              disabled={objView.assinaturaPresenca}
              >
                Salvar assinatura
              </Button>
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
})

const stores = ["atendimentoStore","sujeitoAtencaoStore"]
const ModalAssinaturaPresencaWithStyles = withStyles(styles)(ModalAssinaturaPresenca)

export default inject(...stores)(ModalAssinaturaPresencaWithStyles)