import React, { useRef, useState } from "react";
import { inject } from "mobx-react";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import Prontuario from "../../../Tabs/Prontuario/Prontuario";
import { TypeModalEnum } from "../../../../../stores/SADT.store";
import { CircularProgress, withStyles } from "@material-ui/core";

const filter = [
       "RECEITA",
      "DOCUMENTO",
      "GUIA_SERVICO",
      "GUIA_INTERNACAO",
      "PROCEDIMENTO_REALIZADO",
      "ANAMNESE",
      "AVALIACAO_ANTROPOMETRICA",
      "AFERICAO_VITAL",
      "IMAGEM"
]

const EvolucaoPaciente = observer(({
    classes,
    prontuarioStore,
    atendimentoStore,
    googleAnalyticsStore,
    SADTStore,
    utilizaPlugzapi,
}) => {

    const [isLoading, setIsLoading] = useState(false)

    const {
        listEntradas,
        loadingMoreMensagens
    } = prontuarioStore;

    const {
        objView
    } = atendimentoStore

    const scrollMessagesRef = useRef(null);

    let pageNumberEntradas = 0

    useEffect(() => {
        if (scrollMessagesRef.current && prontuarioStore.messagesScrollToBottom) {
            messagesScrollToBottom();
        }
    }, [listEntradas]);

    useEffect(() => {
        reloadEntradas()
    }, [])

    const messagesScrollToBottom = () => {
        if (scrollMessagesRef && scrollMessagesRef.current) {
            scrollMessagesRef.current.scrollTop = scrollMessagesRef.current.scrollHeight;
            prontuarioStore.messagesScrollToBottom = false;
        }
    };

    const eventoGA = (categoria, acao, rotulo) => {
        if (ga) {
            ga(
                googleAnalyticsStore.profissionalSaude + ".send",
                "event",
                categoria,
                acao,
                rotulo
            );
        }
    };

    const loadMoreMensagens = () => {
        if (prontuarioStore.loadingMoreMensagens) {
            return;
        }
        prontuarioStore.tipos = filter
        pageNumberEntradas += 1;
        prontuarioStore.findAllEntradaProntuario({
            withLoading: false,
            pageNumber: pageNumberEntradas,
            sujeitoAtencao: prontuarioStore.sujeitoAtencaoSelected,
        });
    };

    const handleClickSADT = () => {
        eventoGA('Prontuario', 'Documentos', 'SP/SADT');
        SADTStore.guiaServico = null;
        SADTStore.openModal = true;
        SADTStore.typeModal = TypeModalEnum.SADT_MODAL;
    };

    const reloadEntradas = async ({ withLoading = false } = {}) => {
        pageNumberEntradas = 0;
        prontuarioStore.tipos = filter
        await prontuarioStore.findAllEntradaProntuario({ withLoading, sujeitoAtencao: prontuarioStore.sujeitoAtencaoSelected });
    };

    return (
        <div className={classes.content}>
            {
                isLoading || loadingMoreMensagens ?
                    (
                        <div className={classes.circularProgress}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <Prontuario
                            loadMoreMensagens={loadMoreMensagens}
                            reloadEntradas={reloadEntradas}
                            handleClickSADT={handleClickSADT}
                            prontuarioStore={prontuarioStore}
                            utilizaPlugzapi={utilizaPlugzapi}
                            isModalEvolucao
                            idAgendamento={objView.id}
                        />
                    )
            }
        </div>
    );
})

const styles = {
    inputMessageSujeitoAtencaoRoot: {
        border: "none",
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "flex-end",
        width: "100%"
    },
    inputMessageSujeito: {
        padding: "15px 0",
        maxHeight: "calc(100vh - 474px) !important",
        fontSize: "medium",
        color: "#3e3e3e",
        width: "100%",
    },
    inputMessageAdornment: {
        height: 40,
        maxHeight: "none",
        alignItems: "flex-start",
    },
    buttonAddInput: {
        width: "45px",
        height: "45px",
        minHeight: "1px",
        position: "relative",
        top: "-8px",
        boxShadow: "none !important",
        marginRight: 10,
    },
    documentoFileInput: {
        display: "none",
    },
    buttonSendInput: {
        width: "45px",
        height: "45px",
        minHeight: "1px",
        position: "relative",
        top: "-8px",
        boxShadow: "none !important",
    },
    buttonSendIcon: {
        color: "white",
        width: 20,
        height: 20,
    },
    scroll: {
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    content: {
        width: '100%',
        height: 'calc(100% - 120px)',
        display: 'flex',
        background: '#f2f2f2',
        flexDirection: 'column',
        justifyContent: 'center',
        borderRadius: '20px 20px 0px 0px',
        minWidth: "700px",
        marginTop: '10px'
    },
    searchPaciente: {
        width: "100%"
    },
    formInput: {
        width: "100%",
    },
    wrapperScroll: {
        height: "100%"
    },
    circularProgress: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center"
    }
};



const stores = ["prontuarioStore", "atendimentoStore", "googleAnalyticsStore", "SADTStore"]
const EvolucaoPacienteWithStyles = withStyles(styles)(EvolucaoPaciente)
export default inject(...stores)(EvolucaoPacienteWithStyles);