import React from "react";
import { Button } from "../../../components/ui/Buttons";
import Acompanhante from "./Tabs/Acompanhante/Acompanhante";
import DadosCadastrais from "./Tabs/DadosCadastrais/DadosCadastrais";
import DadosComplementares from "./Tabs/DadosComplementares/DadosComplementares";
import Responsavel from "./Tabs/Responsavel/Responsavel";
import DeleteIcon from "../../../components/Icon/DeleteIcon";
import ProcedimentosExecutados from "./Tabs/Procedimentos/Procedimentos";
import Cids from "./Tabs/Cids/Cids";
import EditIcon from "../../../components/Icon/EditIcon";
import { ContentButtonsAcao } from "./styles";
import moment from "moment";
import EvolucaoPaciente from "./Tabs/EvolucaoPaciente/EvoluçãoPaciente";
import PrescricaoMedica from "./Tabs/PrescricaoMedica/PrescricaoMedica";
import Documentos from "./Tabs/Documentos/Documentos";
import Outrasdespesas from "./Tabs/OutrasDespesas/OutrasDespesas";
import LeitosOcupados from "./Tabs/LeitosOcupados/LeitosOcupados";
import EquipeMedica from "./Tabs/EquipeMedica/EquipeMedica";
import Observacao from "./Tabs/Observacao/Observacao";
import { Tooltip } from "@material-ui/core";
import ProfissionalIcon from "../../../components/Icon/ProfissionalIcon";
import string from "../../../utils/string";

export const optionsTab = [
  "Dados cadast",
  "Acomp",
  "Responsável",
  "Dados Compl",
  "Leitos",
  "Procedimentos",
  "Outras despesas",
  "Doc",
  "Prescrição",
  "Evolução",
  "CIDs",
  "Eq. médica",
  "Observação",
];

export const tabs = {
  DADOS_CADASTRAIS: 0,
  ACOMPANHANTE: 1,
  RESPONSAVEL: 2,
  DADOS_COMPLEMENTARES: 3,
  PROCEDIMENTOS_SOLICITADO: 4,
  PROCEDIMENTOS_REALIZADO: 5,
  CID: 6,
};

export const componentTab = {
  0: DadosCadastrais,
  1: Acompanhante,
  2: Responsavel,
  3: DadosComplementares,
  4: LeitosOcupados,
  5: ProcedimentosExecutados,
  6: Outrasdespesas,
  7: Documentos,
  8: PrescricaoMedica,
  9: EvolucaoPaciente,
  10: Cids,
  11: EquipeMedica,
  12: Observacao,
};

export const altaDefault = {
  id: null,
  alta: null,
};

export const sujeitoAtencaoDefault = {
  id: null,
  nome: "",
  endereco: null,
  contato: null,
  documento: "",
  rg: "",
  nomeConjuge: "",
  nomeMae: "",
  nomePai: "",
  sexo: null,
  dataNascimento: "",
  dadosConvenio: null,
  ocupacao: null,
  estadoCivil: null,
};

export const acompanhanteDefault = {
  nome: "",
  endereco: null,
  contato: null,
  documento: "",
  rg: "",
  vinculo: "",
};

export const responsavelDefault = {
  nome: "",
  endereco: null,
  contato: null,
  documento: "",
  rg: "",
  vinculo: "",
};

export const dadosComplementaresDefault = {
  ambulante: false,
  convenio: null,
  dataGuia: "",
  senha: "",
  dataValidadeSenha: "",
  encaminhamento: {
    value: "INTERNACAO",
    label: "Internação",
  },
  numeroGuia: "",
  observacoesGerais: "",
  observacoesMedico: "",
  profissionalAtendente: null,
  profissionalSolicitante: null,
  transferido: "",
};

export const leitoOcupadoDefault = {
  id: null,
  leito: null,
  tipoLeito: null,
  convenio: null,
  dataInicioOcupacao: "",
  dataFimOcupacao: "",
};

export const procedimentoDefault = {
  id: null,
  sequencia: 1,
  procedimento: null,
  profissionalSaude: null,
  quantidade: "",
  dataRealizacao: null,
  viaAcesso: "",
  tecnicaUtilizada: "",
};

export const cidDefault = {
  id: null,
  sequencia: 1,
  cid: "",
  descricao: "",
  diagnostico: "",
};

export const equipeMedicaDefault = {
  id: null,
  procedimentos: [],
  profissionalSaude: null,
  tuss35TerminologiaGrauParticipacao: "",
};

export const internacaoProdutosDefault = {
  id: null,
  produto: null,
};

export const internacaoPrescricaoItemDefault = {
  id: null,
  medicamento: null,
  quantidade: "",
  tipoUso: "",
  viaAdministracao: "",
  condutaEnfermagem: "",
  unidadeMedida: null,
};

export const internacaoDefault = {
  id: null,
  alta: altaDefault,
  sujeitoAtencao: sujeitoAtencaoDefault,
  acompanhante: acompanhanteDefault,
  responsavel: responsavelDefault,
  dadosComplementares: dadosComplementaresDefault,
  leitosOcupados: [],
  procedimentos: [],
  cids: [],
  observacao: "",
  internacaoProdutos: [],
  internacaoPrescricaoItens: [],
  equipeMedica: [],
};

export const tiposEncaminhamentos = [
  {
    value: "INTERNACAO",
    label: "Internação",
  },
  {
    value: "CENTRO_CIRURGICO",
    label: "Centro cirúrgico",
  },
  {
    value: "PRONTO_SOCORRO",
    label: "Pronto socorro",
  },
  {
    value: "UTI",
    label: "UTI",
  },
];

export const columnsProcedimentosSolicitados = ({
  handleDelete,
  handleEdit,
  handleInsereProfissionalEquipeMedica,
}) => {
  return [
    {
      Header: "Seq",
      align: "start",
      getValue: (data) => {
        return data.sequencia;
      },
    },
    {
      Header: "Procedimento",
      align: "start",
      getValue: (data) => {
        return data.procedimento?.nome || "";
      },
    },
    {
      Header: "Profissional",
      align: "start",
      getValue: (data) => {
        return data.profissionalSaude?.nome || "";
      },
    },
    {
      Header: "Data exame",
      align: "start",
      getValue: (data) => {
        const dataRealizacao = data.dataRealizacao ? moment(data.dataRealizacao).format("DD/MM/YYYY") : "";
        return dataRealizacao;
      },
    },
    {
      Header: "Qtde",
      align: "start",
      getValue: (data) => {
        return data.quantidade || "";
      },
    },
    {
      Header: "Ação",
      align: "start",
      getValue: (data) => {
        return (
          <ContentButtonsAcao>
            <Button
              shape="circle"
              bgColor="#FB7676"
              onClick={() => handleDelete(data)}
              style={{ height: "30px", width: "30px" }}
            >
              <DeleteIcon size={14} />
            </Button>
            <Button
              shape="circle"
              bgColor="#F9BE73"
              onClick={() => handleEdit(data)}
              style={{ height: "30px", width: "30px" }}
            >
              <EditIcon />
            </Button>
            <Tooltip title="Adicionar profissional para equipe médica">
              <Button
                shape="circle"
                bgColor="#00B0AE"
                onClick={() => handleInsereProfissionalEquipeMedica(data)}
                style={{ height: "30px", width: "30px" }}
                disabled={!data.profissionalSaude?.id}
              >
                <ProfissionalIcon />
              </Button>
            </Tooltip>
          </ContentButtonsAcao>
        );
      },
    },
  ];
};

export const columnsCids = ({ handleDelete, handleEdit }) => {
  return [
    {
      Header: "Seq",
      align: "start",
      getValue: (data) => {
        return data.sequencia;
      },
    },
    {
      Header: "Código CID",
      align: "start",
      getValue: (data) => {
        return data.cid?.codigo || data?.cid || "";
      },
    },
    {
      Header: "Descrição",
      align: "start",
      getValue: (data) => {
        return (
          <Tooltip title={data.descricao}>
            <div>{string.truncate(data.descricao || "", 50)}</div>
          </Tooltip>
        );
      }
    },
    {
      Header: "Diagnóstico",
      align: "start",
      getValue: (data) => {
        return <Tooltip title={data.diagnostico}>
          <div>{string.truncate(data.diagnostico || "", 30)}</div>
        </Tooltip>
      },
    },
    {
      Header: "Ação",
      align: "start",
      getValue: (data) => {
        return (
          <ContentButtonsAcao>
            <Button
              shape="circle"
              bgColor="#FB7676"
              onClick={() => handleDelete(data)}
              style={{ height: "30px", width: "30px" }}
            >
              <DeleteIcon size={14} />
            </Button>
            <Button
              shape="circle"
              bgColor="#F9BE73"
              onClick={() => handleEdit(data)}
              style={{ height: "30px", width: "30px" }}
            >
              <EditIcon />
            </Button>
          </ContentButtonsAcao>
        );
      },
    },
  ];
};

export const columnsLeitos = ({ handleDelete, handleEdit }) => {
  return [
    {
      Header: "Leito",
      align: "start",
      getValue: (data) => {
        return data.leito?.nome || "";
      },
    },
    {
      Header: "Tipo leito",
      align: "start",
      getValue: (data) => {
        const { tipoLeito } = data || {};
        const tipo = tipoLeito?.id
          ? `${tipoLeito?.codigo} - ${tipoLeito?.descricao}`
          : "";
        return tipo;
      },
    },
    {
      Header: "Convênio",
      align: "start",
      getValue: (data) => {
        return data.convenio?.descricao || "";
      },
    },
    {
      Header: "Data início",
      align: "start",
      getValue: (data) => {
        return data?.dataInicioOcupacao ? moment(data?.dataInicioOcupacao || "").format(
          "DD/MM/YYYY HH:mm"
        ) : " _/_/_ "
      },
    },
    {
      Header: "Data fim",
      align: "start",
      getValue: (data) => {
        return data?.dataFimOcupacao ? moment(data?.dataFimOcupacao || "").format("DD/MM/YYYY HH:mm") : " _/_/_ "
      } 
    },
    {
      Header: "Ação",
      align: "start",
      getValue: (data) => {
        return (
          <ContentButtonsAcao>
            <Button
              shape="circle"
              bgColor="#FB7676"
              onClick={() => handleDelete(data)}
              style={{ height: "30px", width: "30px" }}
            >
              <DeleteIcon size={14} />
            </Button>
            <Button
              shape="circle"
              bgColor="#F9BE73"
              onClick={() => handleEdit(data)}
              style={{ height: "30px", width: "30px" }}
            >
              <EditIcon />
            </Button>
          </ContentButtonsAcao>
        );
      },
    },
  ];
};

export const columnsEquipeMedica = ({ handleDelete, handleEdit }) => {
  return [
    {
      Header: "Profissional",
      align: "start",
      getValue: (data) => {
        return data?.nome || data.profissionalSaude?.nome || "";
      },
    },
    {
      Header: "Grau de participação",
      align: "start",
      getValue: (data) => {
        return data?.tuss35TerminologiaGrauParticipacao?.termo || "";
      },
    },
    {
      Header: "Procedimento",
      align: "start",
      getValue: (data) => {
        const nomes =
          data?.procedimentos.length > 0 &&
          data?.procedimentos?.map((item) => item.nome).join(", ");

        return nomes || "";
      },
    },
    {
      Header: "Ação",
      align: "start",
      getValue: (data, index) => {
        return (
          <ContentButtonsAcao>
            <Button
              shape="circle"
              bgColor="#FB7676"
              onClick={() => handleDelete(data, index)}
              style={{ height: "30px", width: "30px" }}
            >
              <DeleteIcon size={14} />
            </Button>
            <Button
              shape="circle"
              bgColor="#F9BE73"
              onClick={() => handleEdit(data, index)}
              style={{ height: "30px", width: "30px" }}
            >
              <EditIcon />
            </Button>
          </ContentButtonsAcao>
        );
      },
    },
  ];
};

export const viaAcessos = [
  {
    label: "Única",
    value: "UNICA",
  },
  {
    label: "Mesma via",
    value: "MESMA_VIA",
  },
  {
    label: "Diferentes vias",
    value: "DIFERENTES_VIA",
  },
];

export const tecnicasUtilizadas = [
  {
    label: "Convencional",
    value: "CONVENCIONAL",
  },
  {
    label: "Videolaparoscopia",
    value: "VIDEOLAPAROSCOPIA",
  },
];

export const tissTipoDespesa = [
  {
    label: 'Diarias',
    value: 'DIARIAS'
  },
  {
    label: 'Gases medicinais',
    value: 'GASES_MEDICINAL'
  },
  {
    label: 'Material',
    value: 'MATERIAL'
  },
  {
    label: 'OPME',
    value: 'OPME'
  },
  {
    label: 'Taxa e aluguel',
    value: 'TAXA_E_ALUGUEL'
  },
  {
    label: 'Unidade',
    value: 'UNIDADE',
  }
]
