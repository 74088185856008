import React from "react";
import Dialog from "../../../../components/Dialog/Dialog";
import { withStyles } from "@material-ui/core";
import InputForm from "../../../../components/Input/InputForm";
import Button from "../../../../components/Button/Button";
import colors from "../../../../template/Colors";

import PranchetaIcon from "../../../../components/Icon/PranchetaIcon";

const ModalEditConduta = ({ classes, open, handleCloseModal,handleSave, conduta, handleChangeConduta ,index}) => {
    return (
        <div>
            <Dialog
                open={open}
                classes={{
                    paper: classes.paper
                }}
            >
                <div className={classes.contentIconPrancheta}>
                    <PranchetaIcon ></PranchetaIcon>
                    <label className={classes.labelConduta}> Conduta enfermagem </label>
                </div>
                <div>

                    <InputForm
                        alternativeInputClass={classes.inputObservacoes}
                        className={classes.inputObs}
                        placeholder=""
                        multiline
                        inputProps={{ maxLength: 250 }}
                        value={conduta}
                        onChange={e => handleChangeConduta(e?.target?.value)}
                    ></InputForm>
                </div>
                <div className={classes.contentButton}>
                    <Button classes={{ root: classes.buttonCancelar }} onClick={handleCloseModal}>
                        Cancelar
                    </Button>
                    <Button classes={{ root: classes.button }} onClick={handleSave}>
                        Salvar
                    </Button>
                </div>
            </Dialog>
        </div>
    )
}

const styles = {
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'space-between',
        width: '100%',
        minWidth: '500px',
        minHeight: '500px',
        padding: "15px",
        overflow: "hidden"
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between"
    },
    inputObservacoes: {
        fontFamily: "Poppins!important",
        border: "0",
        fontSize: "14px",
        minHeight: "200px",
        height: "200px !important",
        color: colors.commons.fontColor,
        backgroundColor: colors.commons.gray2,
        margin: "0 0 0 0",
        padding: "9px 0px 5px 10px",
        width: "100%",
        maxWidth: "588px"
    }, inputObs: {
        width: "100%",
    }, labelConduta: {
        display: 'flex',
        width: '100%',
        fontSize: '21px',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '600',
        color: '#505050',
        padding: '10px'
    },
    contentButton: {
        width: '100%',
        display: 'flex',
        alignContent: 'center',
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: '5px'
    },
    button: {
        borderRadius: "26px",
        width: "135px"
    },
    buttonCancelar: {
        color: '#50504F',
        background: 'none',
        fontWeight: '700',
        borderRadius: "26px",
    },
    contentIconPrancheta: {
        width: '100%',
        display: 'flex',
        marginTop: '23px',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '2px'
    }
}

const ModalEditCondutaWithStyles = withStyles(styles)(ModalEditConduta)
export default ModalEditCondutaWithStyles;