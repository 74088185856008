import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TabPagamento from "./TabPagamento";
import TabObservacoes from "./TabObservacoes";
import { findConfiguracaoUnidade } from "../../../../../../services/OdontogramaService";
import CloseIcon from "../../../../../../components/Icon/Close";
import Dialog from "../../../../../../components/Dialog/Dialog";
import { Button } from "../../../../../../components/ui/Buttons";
import { InputForm } from "../../../../../../components/Modal/Input";
import { defaultErrors } from "../../../../../../stores/Orcamento.store";
import MultiToggleButtons from "../../../../../../components/Button/MultiToggleButtons";
import PrintIcon from "../../../../../../components/Icon/Print";
import colors from "../../../../../../template/Colors";
import { checkUserRoles } from "../../../../../../components/RoleProtection";

const tabs = {
  PAGAMENTO: 0,
  OBSERVACOES: 1,
};

const ModalOrcamento = observer(
  ({
    classes,
    open,
    title,
    orcamentoStore,
    children,
    handleSave,
    onClose,
    isLoadingSave,
    setIsLoadingSave,
    handlePrint,
  }) => {
    const { orcamento, handleFieldModal } = orcamentoStore;
    const { nome, id, statusOrcamentoOdontologico } = orcamento || {};

    const [tabSelected, setTabSelected] = useState(0);
    const [configUnidade, setConfigUnidade] = useState(null);

    const isDisabled = id && statusOrcamentoOdontologico?.tipo !== "PENDENTE";

    useEffect(() => {
      getConfigUnidade();
      orcamentoStore.errors = defaultErrors;
    }, []);

    const changeTabSelected = (tabSelected) => {
      setTabSelected(tabSelected);
    };

    const getConfigUnidade = async () => {
      const configUnidade = await findConfiguracaoUnidade();

      setConfigUnidade(configUnidade);
    };

    const handleAction = async (action) => {
      try {
        setIsLoadingSave(true);
        await handleSave(action);
      } catch (e) {
        throw e;
      } finally {
        setIsLoadingSave(false);
      }
    };

    const renderTab = () => {
      return tabSelected === tabs.PAGAMENTO ? (
        <TabPagamento
          configUnidade={configUnidade}
          orcamento={orcamento}
          handleFieldModal={handleFieldModal}
        />
      ) : (
        <TabObservacoes
          orcamento={orcamento}
          handleFieldModal={handleFieldModal}
        />
      );
    };

    const permiteSalvar = async () => {
      const possuiPermissao = await checkUserRoles([
        "ROLE_ORCAMENTO_CREATE",
        "ROLE_ORCAMENTO_UPDATE",
      ]);

      return possuiPermissao;
    };

    return (
      <Dialog
        open={open}
        onClose={onClose}
        classes={{
          paper: classes.dialog,
        }}
      >
        <div className={classes.header}>
          {title}
          <div className={classes.buttonsHeader}>
            <Button
              className={classes.buttonImprimir}
              onClick={() => handlePrint(orcamento, statusOrcamentoOdontologico?.tipo)}
              disabled={isLoadingSave}
            >
              <PrintIcon />
            </Button>
            <Button bgColor="#FB7676" shape="circle" onClick={onClose}>
              <CloseIcon />
            </Button>
          </div>
        </div>
        <div className={classes.content}>
          <div className={classes.contentField}>
            <span className={classes.titleField}>Nome*</span>
            <InputForm
              value={nome}
              disabled={isDisabled}
              onChange={(e) => handleFieldModal("nome", e.target.value)}
              error={orcamentoStore.errors.nome}
            />
          </div>
          <MultiToggleButtons
            options={["Pagamento", "Observações"]}
            tabSelected={tabSelected}
            changeTabSelected={changeTabSelected}
          />
          {renderTab()}
          {children}
        </div>
        <div className={classes.buttons}>
          <Button
            bgColor="#707C97"
            onClick={() => handleAction(statusOrcamentoOdontologico?.tipo)}
            width="205px"
            disabled={
              !permiteSalvar() || isLoadingSave || statusOrcamentoOdontologico?.tipo === "APROVADO"
            }
            isLoading={isLoadingSave}
          >
            Salvar
          </Button>
          <Button
            bgColor="#26ACAA"
            onClick={() => handleAction("APROVADO")}
            width="205px"
            disabled={isLoadingSave || statusOrcamentoOdontologico?.tipo === "APROVADO"}
            isLoading={isLoadingSave}
          >
            Aprovar
          </Button>
        </div>
      </Dialog>
    );
  }
);

const styles = {
  dialog: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px 32px 32px 32px",
    width: "100%",
    maxWidth: "489px",
    height: "100%",
    maxHeight: "640px",
    overflow: "hidden",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "700",
    color: "#333333",
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  contentField: {
    display: "flex",
    flexDirection: "column",
  },
  titleField: {
    color: "#868686",
  },
  buttonImprimir: {
    backgroundColor: colors.commons.secondary,
    width: "40px",
    height: "40px",
    color: "white",
    "&:hover": {
      background: colors.primary.dark,
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px !important",
  },
  buttonsHeader: {
    display: "flex",
    gap: "10px",
  },
};

const ModalOrcamentoWithStyles = withStyles(styles)(ModalOrcamento);
export default inject(
  "odontogramaStore",
  "prontuarioStore",
  "orcamentoStore"
)(ModalOrcamentoWithStyles);
