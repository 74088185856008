import Api from '../config/api'
export const relatorioProfissionalAvaliacao = async (variables) => {
  const response = await Api.post("", {
    query: `query RelatorioProfissionalAvaliacao($profissionalId: Long, $dataFim: LocalDate, $dataInicio: LocalDate, $pageableDto: PageableDTOInput) {
    relatorioProfissionalAvaliacao(profissionalId: $profissionalId, dataFim: $dataFim, dataInicio: $dataInicio, pageableDTO: $pageableDto) {
      content {
        avaliacao
        profissional
        countAvaliacoes
      }
      last
    }
  }`, variables
  })
  if (response.data.errors) {
    throw Error(response.data.errors[0].message)
  } else {
    return response.data.data.relatorioProfissionalAvaliacao
  }
}

export const relatorioProfissionalAvaliacaoCsv = async (variables) => {
  const response = await Api.post("", {
    query: `query relatorioProfissionalAvaliacaoCsv($profissionalId: Long, $dataFim: LocalDate, $dataInicio: LocalDate, $pageableDto: PageableDTOInput) {
    relatorioProfissionalAvaliacaoCsv(profissionalId: $profissionalId, dataFim: $dataFim, dataInicio: $dataInicio, pageableDTO: $pageableDto)
  }`, variables
  })
  if (response.data.errors) {
    throw Error(response.data.errors[0].message)
  } else {
    return response.data.data.relatorioProfissionalAvaliacaoCsv

  }
}